import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {getStudioReportDefinition} from "../../../../api/studioApi";
import {
    LOAD_REPORT_DEFINITION,
    loadReportDefinitionFailureAction,
    loadReportDefinitionSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {pushHistory} from "../../../router/routerActions";
import {i18_KUNDE} from "../../../../i18nReferences";

export function* processLoadReportDefinition({payload}) {
    try {
        const token = yield getArtistToken()
        const reportDefID = payload.reportDefID
        if (token) {
            const reportDefinition = yield call(getStudioReportDefinition, {token, reportDefID})
            yield put(loadReportDefinitionSuccessAction(reportDefinition))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.LOAD_CUSTOMER_LIST_DATA_FAILED_INFO', {ns: i18_KUNDE}), 'loadReportDefinition')))
        yield put(loadReportDefinitionFailureAction())
        yield put(pushHistory('/'))
        console.log(err)
    }
}

export function* watchLoadReportDefinition() {
    yield takeLatest(LOAD_REPORT_DEFINITION, processLoadReportDefinition)
}