import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKundenProjekte} from "../../../api/kundenApi";
import {evaluateProjektAndLoadingImagesIfNeeded} from "./evaluateProjektAndLoadingImagesIfNeeded";
import {CHANGE_TERMIN_KUNDE, changeTerminKundeFailureAction, changeTerminKundeSuccessAction} from "../terminActions";

export function* processChangeTerminKunde({payload}) {
    try {
        const token = yield* getArtistToken()
        const kundId = payload.changeKundeParameters.kundId
        const selectedTerminArtistID = payload.changeKundeParameters.selectedTerminArtistID
        if (token) {
            const selectableProjekte = extractDataFromResponse(yield call(getKundenProjekte, {token, kundId}))
            const projekt = yield* evaluateProjektAndLoadingImagesIfNeeded(selectableProjekte, selectedTerminArtistID, kundId)
            yield put(changeTerminKundeSuccessAction({selectableProjekte, projekt}))
        }
    } catch (err) {
        yield put(changeTerminKundeFailureAction())
        console.log(err)
    }
}

export function* watchChangeTerminKunde() {
    yield takeLatest(CHANGE_TERMIN_KUNDE, processChangeTerminKunde)
}