import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKundenData} from "../../../api/kundenApi";
import {LOAD_KUNDEN_DATA, loadKundeSuccessAction} from "../kundeAction";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {pushHistory} from "../../router/routerActions";
import {call, put, takeLatest} from "redux-saga/effects"

export function* loadKundeData({payload}) {
    try {
        const kundId = payload.kundId
        const token = yield getArtistToken()
        if (token) {
            const kundeData = extractDataFromResponse(yield call(getKundenData, {token, kundId}))
            yield put(loadKundeSuccessAction(kundeData))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.LOAD_DATA_FAILED_INFO', {ns: i18_KUNDE}), 'loadKundeData')))
        yield put(pushHistory('/'))
        console.log(err)
    }
}

export function* watchLoadKundeData() {
    yield takeLatest(LOAD_KUNDEN_DATA, loadKundeData)
}