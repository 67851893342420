import {getArtistToken} from "../../auth/token.saga";
import {downloadSubscriptionContract} from "../../../api/studioApi";
import {PLAIN_PDF} from "../../../models/downloads";
import {DOWNLOAD_SUBSCRIPTION_CONTRACT, downloadSubscriptionContractSuccessAction} from "../commonActions";
import {call, put, takeLatest} from "redux-saga/effects"

function* processDownloadSubscriptionContract() {
    try {
        const token = yield getArtistToken()
        if (token) {
            const response = yield call(downloadSubscriptionContract, {token})
            const pdf = {blob: response.data, type: PLAIN_PDF, filename: `Abovertrag.pdf`}
            yield put(downloadSubscriptionContractSuccessAction(pdf))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchDownloadSubscriptionContract() {
    yield takeLatest(DOWNLOAD_SUBSCRIPTION_CONTRACT, processDownloadSubscriptionContract)
}