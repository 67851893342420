import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {call} from "redux-saga-test-plan/matchers";
import {verifyArtistMailConfiguration} from "../../../api/artistApi";
import {VERIFY_ARTIST_MAIL_CONFIGURATION, verifyArtistMailConfigurationSuccessAction} from "../artistActions";
import {put, takeLatest} from "redux-saga/effects";

function* processVerifyMailConfiguration({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const artistMailConfiguration = payload.artistMailConfiguration
            const result = extractDataFromResponse(yield call(verifyArtistMailConfiguration, {
                token,
                artistMailConfiguration
            }))
            yield put(verifyArtistMailConfigurationSuccessAction(result))
        }
    } catch (err) {
        console.log(err)
    }
}


export function* watchVerifyArtistMailConfiguration() {
    yield takeLatest(VERIFY_ARTIST_MAIL_CONFIGURATION, processVerifyMailConfiguration)
}
