import {
    CHANGE_CALENDAR_SELECTED_LOCATION,
    initiateLoadCalendarAction
} from "../calendarActions";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {selectResourceFilter} from "../calendarResourceSelector";
import {saveArtistResourceFilter} from "../../../api/localArtistPersistence";
import {select, takeLatest} from "redux-saga/effects"
import {selectLoadedZeitraumOfEvents} from "../calendarSelector";
import {put} from "redux-saga-test-plan/matchers";

function* processResourceFilterChanged() {
    const artistId = yield select(state => selectLoggedInArtistID(state))
    const resourceFilter = yield select(state => selectResourceFilter(state))
    const zeitraum = yield select(state => selectLoadedZeitraumOfEvents(state))
    yield put(initiateLoadCalendarAction(zeitraum))
    if (artistId) {
        saveArtistResourceFilter(artistId, resourceFilter)
    }
}

export function* watchChangeResourceFilter() {
    yield takeLatest([CHANGE_CALENDAR_SELECTED_LOCATION], processResourceFilterChanged)
}
