import {getRegisteredCountries, getRegisteredCountryList} from "@kiss-solutions/countries";

export const COUNTRY_MAP = getRegisteredCountries()
export const SUBREGION_CODE_MAP = Object.keys(COUNTRY_MAP).reduce((result, key) => {
    for (let subRegion of COUNTRY_MAP[key].subregions) {
        result[`${key}-${subRegion.code}`] = subRegion.subdivision_name
    }
    return result
}, {})

export const getCountryCodeSelection = () => getRegisteredCountryList().map(c => ({id: c.country_code.toLowerCase(), text: c.name}))