import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_TIMELINE = 'LOAD_TIMELINE'
export const loadTimelineAction = createSimpleAction(LOAD_TIMELINE, 'params')

export const LOAD_TIMELINE_SUCCESS = 'LOAD_TIMELINE_SUCCESS'
export const loadTimelineSuccessAction = createSimpleAction(LOAD_TIMELINE_SUCCESS, 'data')

export const LOAD_TIMELINE_FAILURE = 'LOAD_TIMELINE_FAILURE'
export const loadTimelineFailureAction = createTypeAction(LOAD_TIMELINE_FAILURE)

export const CLEAR_TIMELINE = 'CLEAR_TIMELINE'
export const clearTimelineAction = createTypeAction(CLEAR_TIMELINE)
