import {fork} from "redux-saga-test-plan/matchers";
import {watchLoadKundeData} from "./saga/loadKundeDataSaga";
import {watchSaveKunde} from "./saga/saveKundeSaga";
import {watchSaveAndReloadKunde} from "./saga/saveAndReloadKundeSaga";
import {watchSearchKundenDoubletten} from "./saga/searchKundenDoublettenSaga";
import {watchAddNeukunde} from "./saga/processAddNeukundeSaga";
import {watchUpdateKundeProjekt} from "./saga/writeProjektDataAndReloadKundeSaga";
import {watchSendFileLink} from "./saga/sendFileLinkSaga";
import {watchDeleteKunde} from "./saga/deleteKundeSaga";
import {watchMergeProjekte} from "./saga/mergeProjekteSaga";
import {watchDeleteProjekt} from "./saga/deleteProjektSaga";
import {watchAddKundenBatch} from "./saga/addKundenBatchSaga";

const kundeSaga = [fork(watchLoadKundeData), fork(watchSaveKunde), fork(watchSaveAndReloadKunde)
    , fork(watchSearchKundenDoubletten), fork(watchAddNeukunde), fork(watchUpdateKundeProjekt)
    , fork(watchSendFileLink), fork(watchDeleteKunde), fork(watchMergeProjekte), fork(watchDeleteProjekt)
, fork(watchAddKundenBatch)]

export default kundeSaga