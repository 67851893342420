import {put, takeLatest} from "redux-saga/effects";
import {SAVE_PROJEKT_AND_RELOAD_LIST} from "../../listActions";
import {writeProjektData} from "../../../projekt/projektSaga";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../../i18nReferences";

function* processSaveProjekt({payload}) {
    try {
        yield* writeProjektData(payload.projekt)
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_PROJEKT.PROJEKT_SAVE_DATA_FAILURE_INFO', {
            ns: i18_EDITPROJEKT,
            ProjName: payload.projekt.ProjName
        }), 'saveProject')))
        console.log(err)
    }
}

export function* watchSaveProjekt() {
    yield takeLatest(SAVE_PROJEKT_AND_RELOAD_LIST, processSaveProjekt)
}