import {createSelector} from "reselect"
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";
import {decodeToken} from "../utils/jwt";
import {selectArtistMap} from "../common/commonSelector";
import {
    decompressRechteToken,
    getArtistMapWithEinnahmenBearbeiten,
    getArtistMapWithSchreibrecht,
    hatRecht,
    RECHT_SCHREIBEN
} from "../../models/auth";
import { keyBy, omit } from "../utils/lodash-replacement";

export const selectAuthState = createSelector(
    (state) => state.auth
    , identityFunction
)
export const selectStudioAuth = createFieldSelector(selectAuthState, 'studioAuth')
export const selectStudioToken = createFieldSelector(selectStudioAuth, 'studioToken')
export const selectStudioRefreshToken = createFieldSelector(selectStudioAuth, 'studioRefreshToken')
export const selectIsStudioLoginFailed = createBooleanFieldSelector(selectAuthState, 'studioAuthFailure')
export const selectIsStudioLoginInProgress= createBooleanFieldSelector(selectAuthState, 'studioAuthLoading')

export const selectArtistAuth = createFieldSelector(selectAuthState, 'artistAuth')
export const selectArtistToken = createFieldSelector(selectArtistAuth, 'artistToken')
export const selectIsArtistLoggedIn = createBooleanFieldSelector(selectArtistAuth, 'artistToken')
export const selectArtistRefreshToken = createFieldSelector(selectArtistAuth, 'artistRefreshToken')
export const selectLoggedInArtistID = createSelector(
    selectArtistRefreshToken
    , token => token && decodeToken(token).id
)

const selectSelfRechte = createSelector(
    selectLoggedInArtistID
    , ArtistID => ArtistID && [{ArtistID, Rechte: 15, EditIncome: true}] || []
)


export const selectDecodedToken = createSelector(
    selectArtistToken
    , token => token && decodeToken(token)
)
export const selectLoggedInArtistData = createFieldSelector(selectDecodedToken, 'artist')
export const selectLoggedInArtistKuerzel = createFieldSelector(selectLoggedInArtistData, 'Kuerzel')
export const selectLocale = createSelector(
    selectLoggedInArtistData
    , artist => artist && artist.Lang !== 'German' && 'en' || 'de'
)

export const selectLoggedInIsAdmin = createBooleanFieldSelector(selectLoggedInArtistData, 'isAdmin')
export const selectLoggedInCannotEdit = createBooleanFieldSelector(selectLoggedInArtistData, 'CannotEdit')

export const selectLoggedInArtistRechteToken = createFieldSelector(selectDecodedToken, 'rechteToken')

export const selectLoggedInRechte = createSelector(
    selectLoggedInArtistRechteToken
    , selectSelfRechte
    , (token, selfRechte) => keyBy(decompressRechteToken(token).concat(selfRechte), 'ArtistID') || {}
)

export const selectLeseRechtArtistIDList = createSelector(
    selectLoggedInRechte
    , rechteMap => rechteMap && Object.keys(rechteMap).map(t => +t) || []
)


export const selectReadableArtistMap= createSelector(
    selectArtistMap
    , selectLeseRechtArtistIDList
    , selectLoggedInIsAdmin
    , (artistMap, artistIdList, isAdmin) => artistMap && (isAdmin ? artistMap : artistIdList && artistIdList.reduce((map, key) => {
        map[key] = artistMap[key]
        return map
    }, {}))
)

export const selectSchreibRechteArtistIDList = createSelector(
    selectLoggedInRechte
    , rechteMap => rechteMap && Object.values(rechteMap).filter(recht => hatRecht(recht, [RECHT_SCHREIBEN]))
        .map(t => t.ArtistID) || []
)

function selectFromArtistMap(artistIds, artistMap) {
    const omitList = Object.keys(artistMap).filter(k => !artistIds.includes(+k))
    return omit(artistMap, omitList)
}

export const selectArtistMapWithViewRights = createSelector(
    selectLeseRechtArtistIDList
    , selectArtistMap
    , (viewableArtistIdList, artistMap) => viewableArtistIdList && artistMap && selectFromArtistMap(viewableArtistIdList, artistMap) || {}
)

export const selectArtistMapWithEinnahmenRights = createSelector(
    selectArtistMap
    , selectLoggedInArtistID
    , selectLoggedInRechte
    , (artistMap, loggedInArtistID, rechteMap) => getArtistMapWithEinnahmenBearbeiten(artistMap, loggedInArtistID, rechteMap)
)

export const selectArtistMapWithWriteRights = createSelector(
    selectArtistMap
    , selectLoggedInArtistID
    , selectLoggedInRechte
    , (artistMap, loggedInArtistID, rechteMap) => getArtistMapWithSchreibrecht(artistMap, loggedInArtistID, rechteMap)
)


export const selectLoggedInStudioData = createSelector(
    selectArtistToken
    , token => token && decodeToken(token).studio
)

export const selectStudioDBName = createFieldSelector(selectLoggedInStudioData, 'db')
export const selectStudioUserID = createFieldSelector(selectLoggedInStudioData, 'UserID')
export const selectHasKissSign = createSelector(
    selectLoggedInStudioData
    , studio => studio?.SignPadCount > 0
)
export const selectLoggedInStudioDataRestlaufzeit = createSelector(
    selectLoggedInStudioData
    , studio => studio && Math.floor((new Date(studio.gezBis).getTime() - new Date().getTime()) / (3600 * 1000 * 24)) || 20
)

export const selectLoggedInArtist = createSelector(
    selectLoggedInArtistID
    , selectArtistMap
    , (artistId, artistMap) => artistId && artistMap && artistMap[artistId]
)
export const selectIsArtistLoginFailed = createBooleanFieldSelector(selectAuthState, 'artistAuthFailure')
export const selectIsArtistLoginInProgress= createBooleanFieldSelector(selectAuthState, 'artistAuthLoading')
export const selectStudioLoginResetResult = createFieldSelector(selectAuthState, 'studioLoginResetResult')
export const selectStudioLoginResetResultError = createFieldSelector(selectStudioLoginResetResult, 'error')
export const selectArtistLoginResetResult = createFieldSelector(selectAuthState, 'artistLoginResetResult')
export const selectArtistLoginResetResultError = createFieldSelector(selectArtistLoginResetResult, 'error')


