import {
    ARTIST_DATA_CHANGED,
    ARTIST_DATA_RESET,
    CHANGE_ARTIST_RECHT,
    CHANGE_ARTIST_RECHT_BASE,
    CLEAR_ARTIST_DATA,
    LOAD_ARTIST_BASE_DATA,
    LOAD_ARTIST_BASE_DATA_SUCCESS,
    LOAD_ARTIST_MAIL_DATA,
    LOAD_ARTIST_MAIL_DATA_SUCCESS,
    LOAD_ARTIST_RECHTE_DATA,
    LOAD_ARTIST_RECHTE_DATA_SUCCESS,
    VERIFY_ARTIST_MAIL_CONFIGURATION,
    VERIFY_ARTIST_MAIL_CONFIGURATION_SUCCESS
} from "./artistActions";
import {
    asyncLoadingActionSuccess,
    initiateAsyncLoadingWithParamsAction,
    replacePayloadFields
} from "../utils/reducerUtils";

const INITIAL = {}

export default function artistEditReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_ARTIST_BASE_DATA:
            return initiateAsyncLoadingWithParamsAction(state, 'artistBaseData', {artistId: action.payload.artistId})

        case LOAD_ARTIST_BASE_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'artistBaseData')

        case LOAD_ARTIST_MAIL_DATA:
            return initiateAsyncLoadingWithParamsAction(state, 'artistMailData', {artistId: action.payload.artistId})

        case LOAD_ARTIST_MAIL_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'artistMailData')

        case LOAD_ARTIST_RECHTE_DATA:
            return initiateAsyncLoadingWithParamsAction(state, 'artistRechteData', {artistId: action.payload.artistId})

        case LOAD_ARTIST_RECHTE_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'artistRechteData')

        case CHANGE_ARTIST_RECHT:
            return {
                ...state,
                rechteChanged: true,
                artistRechteData: {
                    ...state.artistRechteData
                    , rechte: state.artistRechteData.rechte.filter(r => r.RechteID !== action.payload.artistRecht.RechteID).concat([action.payload.artistRecht])
                }
            }

        case CHANGE_ARTIST_RECHT_BASE:
            return {
                ...state,
                rechteBaseChanged: true,
                artistRechteData: {
                    ...state.artistRechteData,
                    ...action.payload.rechteBaseChanges
                }
            }

        case VERIFY_ARTIST_MAIL_CONFIGURATION:
            return {
                ...state,
                verifyMailResult: undefined
            }

        case VERIFY_ARTIST_MAIL_CONFIGURATION_SUCCESS:
            return replacePayloadFields(state, action)

        case CLEAR_ARTIST_DATA:
            return INITIAL

        case ARTIST_DATA_CHANGED:
            return {
                ...state
                , artistDataChanged: true
            }
        case ARTIST_DATA_RESET:
            return {
                ...state
                , artistDataChanged: undefined,
                rechteChanged: false
            }

        default:
            return state
    }
}