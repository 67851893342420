import {i18_TEMPLATES, i18_TRANSLATION} from "../i18nReferences";
import * as yup from "yup";

export const MSG_VORLAGE_TYP_AUTO_REMINDER = 2
export const MSG_VORLAGE_TYP_AUTO_GEBURTSTAG = 1

export const getMessageTemplateColumns = (t, translatedMsgVorlagenAtomationListTextMap) => [
    {field: 'Bezeichnung', headerName: t('MSG_VORLAGE.BEZEICHNUNG_LABEL', {ns: i18_TEMPLATES}), flex: 1},
    {field: 'Reminder', headerName: t('MSG_VORLAGE.REMINDER_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {
        field: 'MsgTyp',
        headerName: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_HEADER', {ns: i18_TEMPLATES}),
        minWidth: 150,
        valueGetter: ({value}) => value && translatedMsgVorlagenAtomationListTextMap[value]
    },
]

export const createMsgVorlageSchema = () => {
    return yup.object().shape({
        Betreff: yup.string().required().min(3).max(150),
        Bezeichnung: yup.string().required().min(3).max(200),
        TextEmail: yup.string().required().min(3).max(1000),
        TextSMS: yup.string().required().min(3).max(1000)
    })
}

export const getTranslatedMsgVorlagenAtomationInfoTextMap = (t) => ({
    [MSG_VORLAGE_TYP_AUTO_REMINDER]: t('MSG_VORLAGE.AUTO_REMINDER_INFO_TEXT', {ns: i18_TEMPLATES}),
    [MSG_VORLAGE_TYP_AUTO_GEBURTSTAG]: t('MSG_VORLAGE.AUTO_GEBURTSTAG_INFO_TEXT', {ns: i18_TEMPLATES})
})

export const getTranslatedMsgVorlagenAtomationListTextMap = (t) => ({
    [MSG_VORLAGE_TYP_AUTO_REMINDER]: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_REMINDER_TEXT', {ns: i18_TEMPLATES}),
    [MSG_VORLAGE_TYP_AUTO_GEBURTSTAG]: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_BIRTHDAY_TEXT', {ns: i18_TEMPLATES})
})

export const sortByBezeichnung = (v1, v2) => v1.Bezeichnung?.toUpperCase() > v2.Bezeichnung?.toUpperCase() ? 1 : -1
export const convertMsgVorlagenMapToList = map => map && Object.values(map).sort(sortByBezeichnung) || []
export const convertPdfVorlagenMapToList = map => map && Object.values(map).sort(sortByBezeichnung) || []
export const STANDARD_AUTO_MESSAGE_SMS_SEND_TIME = [10, 12, 14, 16]
export const getPdfTemplateColumns = (t, translatedPDFVorlagenMap) => [
    {
        field: 'Bezeichnung',
        headerName: t('PDF_VORLAGEN.BEZEICHNUNG_LABEL', {ns: i18_TEMPLATES}),
        flex: 1,
        minWidth: 300
    },
    {
        field: 'Typ',
        headerName: t('PDF_VORLAGEN.TYP', {ns: i18_TEMPLATES}),
        valueGetter: ({value}) => value && translatedPDFVorlagenMap[value],
        minWidth: 150
    },
    {field: 'Anamnese', headerName: t('ANAMNESIS_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {field: 'Datenschutz', headerName: t('PRIVACY_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {field: 'Einverstaendnis', headerName: t('CONSENT_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {field: 'ElternEve', headerName: t('PARENT_CONSENT_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {field: 'TerminDoku', headerName: t('DOCU_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'}
]
export const PDF_VORLAGENTYP_GUTSCHEIN = 3
export const PDF_VORLAGENTYP_TERMINLISTE = 1
export const PDF_VORLAGENTYP_KUNDENFORMULAR = 2
export const PDF_VORLAGENTYP_MAP = {
    PDF_VORLAGENTYP_TERMINLISTE,
    PDF_VORLAGENTYP_KUNDENFORMULAR,
    PDF_VORLAGENTYP_GUTSCHEIN
}
export const PDF_VORLAGENTYP_VALUES = Object.values(PDF_VORLAGENTYP_MAP)
export const getTranslatedPDFVorlagenMap = (t) => Object.keys(PDF_VORLAGENTYP_MAP).reduce((result, key) => ({
    ...result,
    [PDF_VORLAGENTYP_MAP[key]]: t(key, {ns: i18_TRANSLATION})
}), {})

export const getTranslatedPDFVorlagenSelectionList = (t) => Object.keys(PDF_VORLAGENTYP_MAP).map(key => ({
    id: PDF_VORLAGENTYP_MAP[key],
    text: t(key, {ns: i18_TRANSLATION})
}))

export const createPdfVorlageSchema = () => {
    return yup.object().shape({
        Typ: yup.number().required(),
        Bezeichnung: yup.string().required().min(3).max(150),
        Beschreibung: yup.string().nullable().max(1000),
        file: yup.mixed().when(['$isNew'], ([isNew], schema) => isNew ? schema.required() : schema.nullable()),
        Anamnese: yup.boolean().required(),
        Datenschutz: yup.boolean().required(),
        Einverstaendnis: yup.boolean().required(),
        ElternEve: yup.boolean().required(),
        TerminDoku: yup.boolean().required()
    })
}

const PDF_VORLAGE_DEFAULTS = {
    Typ: PDF_VORLAGENTYP_KUNDENFORMULAR
    , Bezeichnung: ''
    , Beschreibung: ''
    , Anamnese: false
    , Datenschutz: false
    , Einverstaendnis: false
    , ElternEve: false
    , TerminDoku: false
}

export const setDefaultForMissing = (pdfVorlage) => pdfVorlage ? ({
    ...PDF_VORLAGE_DEFAULTS,
    ...pdfVorlage
}) : PDF_VORLAGE_DEFAULTS
