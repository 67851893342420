import {createSelector} from "reselect";
import {createBooleanFieldSelector, identityFunction} from "../../utils/selectors";
import {removeExpiredSecrets} from "../../../models/pads";

export const selectPdfSignSettingState = createSelector(
    (state) => state.pdfSignSettings
    , identityFunction
)

export const selectIsPadsLoading = createBooleanFieldSelector(selectPdfSignSettingState, 'loading')
export const selectPads = createSelector(
    selectPdfSignSettingState
    , state => state?.pads?.length ? state.pads.map(p => removeExpiredSecrets(p)) : []
)
export const selectUnfinishedFilesFromPads = createSelector(
    selectPads
    , pads => pads.reduce((result, pad) => pad.unfinishedFiles ? result.concat(pad.unfinishedFiles) : result, [])
)