import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";
import {TERMIN_DATA_NAME} from "./terminReducer";
import {selectArtistMap} from "../common/commonSelector";
import {constructTitle} from "../../components/calendar/eventModel";
import {convertImagesRaw2ProjektImages} from "../../models/projekt";
import { keyBy } from "../utils/lodash-replacement";

export const selectTerminState = createSelector(
    (state) => state.termin
    , identityFunction
)

export const selectTerminData = createFieldSelector(selectTerminState, TERMIN_DATA_NAME)
export const selectTerminKunden = createFieldSelector(selectTerminState, 'terminKunden', [])
export const selectTerminEditState = createFieldSelector(selectTerminState, 'editState', {})
export const selectTerminEditStateTermin = createFieldSelector(selectTerminEditState, 'termin', {})
export const selectTerminHoliday = createFieldSelector(selectTerminState, 'terminHoliday')
export const selectTermin = createFieldSelector(selectTerminData, 'termin')
export const selectIsTerminDataLoading = createBooleanFieldSelector(selectTerminState, 'terminDataLoading')
export const selectSelectedTerminKundID = createSelector(
    selectTermin
    , selectTerminEditStateTermin
    , (termin, terminChanges) => terminChanges?.KundID !== undefined ? terminChanges.KundID : termin?.KundID
)
export const selectTerminOwnerArtist = createSelector(
    selectTermin
    , selectArtistMap
    , (termin, artistMap) => termin && artistMap && artistMap[termin.ArtistID]
)
export const selectSelectedTerminArtistID = createSelector(
    selectTermin
    , selectTerminEditStateTermin
    , (termin, terminChanges) => terminChanges?.ArtistID !== undefined ? terminChanges.ArtistID : termin?.ArtistID
)
export const selectTerminTitle = createSelector(
    selectTermin
    , selectTerminOwnerArtist
    , (termin, artist) => termin && artist && constructTitle({termin, artistKuerzel: artist.Kuerzel})
)

export const selectSelectableTerminKundenMap = createSelector(
    selectTerminData
    , selectTerminKunden
    , (terminData, terminKunden) => terminData && keyBy((terminData.kunde && [terminData.kunde] || []).concat(terminKunden), 'KundID') || {}
)
export const selectSelectableProjekteOfTermin = createFieldSelector(selectTerminData, 'selectableProjekte')
export const selectSelectedProjektOfTermin = createFieldSelector(selectTerminData, 'projekt')
export const selectSelectedProjektBaseOfTermin = createSelector(
    selectTerminData
    , terminData => terminData?.projektBase || terminData?.projekt
)

export const selectProjektImagesRaw = createFieldSelector(selectTerminData, 'projektImages', [])
export const selectProjektImages = createSelector(
    selectProjektImagesRaw
    , convertImagesRaw2ProjektImages
)
export const selectInsertedTempImages = createFieldSelector(selectTerminEditState, 'insertedImages', [])
export const selectDeletedProjektImages = createFieldSelector(selectTerminEditState, 'deletedImages', [])

export const selectIsUploadingImages = createBooleanFieldSelector(selectTerminState, 'uploadingImages')
export const selectDeletedProjektImageKeys = createSelector(
    selectDeletedProjektImages
    , images => images.map(t => t.key)
)

export const selectEditTerminImages = createSelector(
    selectProjektImages
    , selectDeletedProjektImageKeys
    , selectInsertedTempImages
    , (projektImages, deletedKeys, insertedImages) => projektImages.filter(t => !deletedKeys.includes(t.key))
        .concat(insertedImages.map(key =>({type: 'temp', key})))
)

export const selectTerminEditEinnahmeVoucher = createFieldSelector(selectTerminState, 'editEinnahmeVoucher')
export const selectTerminEditEinnahmen = createFieldSelector(selectTerminData, 'einnahmen', [])
export const selectTerminEditTeilnehmer = createFieldSelector(selectTerminData, 'teilnehmer', [])
export const selectIsSavingTermin = createBooleanFieldSelector(selectTerminState, 'isSaving')
export const selectICalTermineCounters = createFieldSelector(selectTerminState, 'iCalTermineCounters')
export const selectImportedICalTermineCount = createSelector(
    selectICalTermineCounters
    , counters => (counters?.success || 0) + (counters?.failed || 0) + (counters?.skipped || 0)
)
export const selectIsImportingICalTermine = createBooleanFieldSelector(selectTerminState, 'iCalTermineImporting')
export const selectIsImportingICalResetRequested = createBooleanFieldSelector(selectTerminState, 'importICalResetRequested')
