import {put, takeLatest} from "redux-saga/effects";
import {COPY_LIST_DEFINITION_AND_NAVIGATE_TO} from "../../listActions";
import {writeReportDefinition} from "./writeReportDefinition";
import {pushHistory} from "../../../router/routerActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processCopyListDefinitionAndNavigate({payload}) {
    try {
        const result = yield writeReportDefinition({payload})
        yield put(pushHistory(`lists/${result.Typ}/${result.ReportDefID}`))
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.ERROR.SAVE_DATA_FAILED_INFO', {ns: i18_LIST}), 'saveReportDefinition')))
        console.log(err)
    }
}

export function* watchCopyListDefinitionAndNavigate() {
    yield takeLatest(COPY_LIST_DEFINITION_AND_NAVIGATE_TO, processCopyListDefinitionAndNavigate)
}