import {put, takeLatest, select} from "redux-saga/effects";
import {getArtistToken} from "../../auth/token.saga";
import {call} from "redux-saga-test-plan/matchers";
import {updateArtist} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../models/notification";
import i18next from "i18next";
import {loadArtistMailDataAction, SAVE_ARTIST_MAIL} from "../artistActions";
import {selectArtistMap} from "../../common/commonSelector";
import {i18_ARTIST} from "../../../i18nReferences";

function* processSaveArtistMail({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    const name = payload.artistMailData?.ArtistID ? artistMap[payload.artistMailData.ArtistID].Kuerzel : ''
    try {
        const token = yield getArtistToken()
        if (token) {
            const artist = payload.artistMailData
            yield call(updateArtist, {token, artist})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('EDIT_ARTIST.SAVE_MAIL_DATA_SUCCESS_INFO', {
                ns: i18_ARTIST,
                name
            }), 'saveArtist')))
            yield put(loadArtistMailDataAction(payload.artistMailData.ArtistID))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_MAIL_DATA_FAILED_INFO', {
            ns: i18_ARTIST,
            name
        }), 'saveArtist')))
        console.log(err)
    }
}

export function* watchSaveArtistMail() {
    yield takeLatest(SAVE_ARTIST_MAIL, processSaveArtistMail)
}