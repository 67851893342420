import {decodeToken} from "../store/utils/jwt";
import { pick } from "../store/utils/lodash-replacement";

export const RECHT_LESEN = 1
export const RECHT_SCHREIBEN = 2
export const RECHT_LOESCHEN = 4
export const RECHT_EINNAHMENSCHREIBEN = 8

export const MINIMUM_VALIDITY_SECONDS = 10

function readFromToken(token) {
    const parts = token.split('-');
    const ArtistID = +parts[0];
    const Rechte = parseInt(parts[1].substr(0, 1), 16);
    const EditIncome = +parts[1].substr(1, 2) !== 0;
    return {ArtistID, Rechte, EditIncome};
}

export function decompressRechteToken(rechteToken) {
    if (rechteToken && rechteToken.length > 1) {
        const parts = rechteToken.split('#');
        return parts.map(t => readFromToken(t));
    } else {
        return [];
    }
}

export function hatRecht(recht, rechteList) {
    let ret = true;
    rechteList.forEach(c => {
        if (c === RECHT_EINNAHMENSCHREIBEN) {
            ret = ret
                && (!recht.EditIncome || ((recht.Rechte & RECHT_EINNAHMENSCHREIBEN) > 0))
                && ((recht.Rechte & RECHT_SCHREIBEN) > 0)
        } else {
            ret = ret && ((recht.Rechte & c) > 0)
        }
    });
    return ret;
}

export const checkValidSeconds = (token, seconds = MINIMUM_VALIDITY_SECONDS) => {
    if (!token) {
        return false
    }
    try {
        const decoded = decodeToken(token)
        return (decoded.exp - seconds) * 1000 > new Date().getTime()
    } catch (err) {
        console.log(err)
        return false
    }
}

const getArtistMapWithAdvancedRights = (artistMap, loggedInArtist, rechteMap, rechteList) => {
    const {isAdmin, CannotEdit} = loggedInArtist
    if (isAdmin) {
        return artistMap
    }
    if (CannotEdit) {
        return {}
    }
    let artistIdListWithRecht = Object.values(rechteMap).filter(recht => hatRecht(recht, rechteList))
        .map(t => t.ArtistID) || []
    return pick(artistMap, artistIdListWithRecht)
}

export const getArtistMapWithEinnahmenBearbeiten = (artistMap, loggedInArtistId, rechteMap) => {
    if (!artistMap || !loggedInArtistId || !rechteMap) {
        return {}
    }
    const loggedInArtist = artistMap[loggedInArtistId]
    return getArtistMapWithAdvancedRights(artistMap, loggedInArtist, rechteMap, [RECHT_SCHREIBEN, RECHT_EINNAHMENSCHREIBEN])
}

export const getArtistMapWithSchreibrecht = (artistMap, loggedInArtistId, rechteMap) => {
    if (!artistMap || !loggedInArtistId || !rechteMap) {
        return {}
    }
    const loggedInArtist = artistMap[loggedInArtistId]
    return getArtistMapWithAdvancedRights(artistMap, loggedInArtist, rechteMap, [RECHT_SCHREIBEN])
}
