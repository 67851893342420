import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_NACHRICHT_KUNDE = 'LOAD_NACHRICHT_KUNDE'
export const loadNachrichtKunde = createSimpleAction(LOAD_NACHRICHT_KUNDE, 'kundId')

export const LOAD_NACHRICHT_KUNDE_SUCCESS = 'LOAD_NACHRICHT_KUNDE_SUCCESS'
export const loadNachrichtKundeSuccess = createSimpleAction(LOAD_NACHRICHT_KUNDE_SUCCESS, 'kundeData')

export const LOAD_NACHRICHT_TERMIN = 'LOAD_NACHRICHT_TERMIN'
export const loadNachrichtTermin = createSimpleAction(LOAD_NACHRICHT_TERMIN, 'terminId')

export const LOAD_NACHRICHT_TERMIN_SUCCESS = 'LOAD_NACHRICHT_TERMIN_SUCCESS'
export const loadNachrichtTerminSuccess = createSimpleAction(LOAD_NACHRICHT_TERMIN_SUCCESS, 'terminData')

export const SEND_NACHRICHT_ACTION = 'SEND_NACHRICHT_ACTION'
export const sendNachrichtAction = createSimpleAction(SEND_NACHRICHT_ACTION, 'nachricht')

export const SEND_NACHRICHT_ACTION_SUCCESS = 'SEND_NACHRICHT_ACTION_SUCCESS'
export const sendNachrichtSuccessAction = createTypeAction(SEND_NACHRICHT_ACTION_SUCCESS)

export const SEND_NACHRICHT_ACTION_FAILED = 'SEND_NACHRICHT_ACTION_FAILED'
export const sendNachrichtFailedAction = createTypeAction(SEND_NACHRICHT_ACTION_FAILED)
