import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const KundenTabelle = ({kunden, t, tableContainerProps, noEntryText, onChange, selectedKundId}) => {
    return (
        <TableContainer component={Paper} {...tableContainerProps}>
            <Table aria-label="customer table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t('EDIT_KUNDE.NAME_LABEL')}</TableCell>
                        <TableCell align="left">{t('EDIT_KUNDE.MOBILE_LABEL')}</TableCell>
                        <TableCell align="left">{t('EDIT_KUNDE.EMAIL_LABEL')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!kunden.length ? <TableRow>
                        <TableCell align="left" colSpan={3}>{noEntryText}</TableCell>
                    </TableRow> : null}
                    {kunden.length ? kunden.map(kunde => (
                        <TableRow hover
                            key={kunde.KundID}
                                  selected={kunde.KundID === selectedKundId}
                                  onClick={() => onChange(kunde.KundID)}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{kunde.Name}</TableCell>
                            <TableCell align="left">{kunde.Mobil}</TableCell>
                            <TableCell align="left">{kunde.Email}</TableCell>
                        </TableRow>
                    )) : null}
                </TableBody>
            </Table>

        </TableContainer>
    )
}

export default KundenTabelle;