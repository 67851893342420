import React from 'react';
import {Box, CircularProgress} from "@mui/material";
import TimelineHeader from "./TimelineHeader";
import TimelineData from "./TimelineData";

const Timeline = ({timelineData, start, locale, isDark, onClickDay, onClickArtist}) => {
    if (!timelineData) {
        return (
            <CircularProgress/>
        )
    }
    return (
        <Box width="100%" display="block">
            <TimelineHeader start={start} locale={locale}/>
            {timelineData.map((d, idx) => <TimelineData key={'data_' + idx} artistData={d} isDark={isDark}
                                                        onClickArtist={onClickArtist}
                                                        onClickDay={value => onClickDay({
                                                            ...value,
                                                            dataIndex: idx,
                                                            ArtistID: d.ArtistID
                                                        })}/>)}
        </Box>
    );
};

export default Timeline;
