import {writeProjektData} from "../../projekt/projektSaga";
import {selectKunde} from "../kundeSelector";
import {loadKundeData} from "./loadKundeDataSaga";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../i18nReferences";
import {UPDATE_KUNDE_PROJEKT} from "../kundeAction";
import {put, select, takeLatest} from "redux-saga/effects"

export function* writeProjektDataAndReloadKunde({payload}) {
    try {
        const success = yield* writeProjektData(payload.projekt)
        if (success) {
            const {KundID} = yield select(state => selectKunde(state))
            yield* loadKundeData({payload: {kundId: KundID}})
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_PROJEKT.PROJEKT_SAVE_DATA_FAILURE_INFO', {
            ns: i18_EDITPROJEKT,
            ProjName: payload.projekt.ProjName
        }), 'saveProject')))
        console.log(err)
    }
}

export function* watchUpdateKundeProjekt() {
    yield takeLatest(UPDATE_KUNDE_PROJEKT, writeProjektDataAndReloadKunde)
}