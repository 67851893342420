import {selectArtistMap} from "../../common/commonSelector";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {processUpdateArtist} from "./updateArtist";
import {SET_ARTIST_ISSUES_VOUCHER} from "../artistActions";
import {select, takeLatest} from "redux-saga/effects"
import {i18_ARTIST} from "../../../i18nReferences";

function* processSetArtistVoucherRight({payload}) {
    const artistData = payload.artistChanges
    const artistMap = yield select(state => selectArtistMap(state))
    const kuerzel = artistMap[artistData.ArtistID].Kuerzel
    const key = artistData.GetVoucher ? 'EDIT_ARTIST.GRANTED_ARTISTS_ISSUE_VOUCHER_RIGHT_SUCCESS_INFO' : 'EDIT_ARTIST.REVOKED_ARTISTS_ISSUE_VOUCHER_RIGHT_SUCCESS_INFO'
    const successNotification = createInfoNotification(i18next.t(key, {
        ns: i18_ARTIST,
        kuerzel,
    }), 'changeVoucherRights')
    const failureNotification = createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_FAILED_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'changeVoucherRights')
    yield processUpdateArtist( payload.artistChanges, successNotification, failureNotification)
}

export function* watchSetArtistVoucherRights() {
    yield takeLatest([SET_ARTIST_ISSUES_VOUCHER], processSetArtistVoucherRight)
}