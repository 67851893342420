import {istVolljaehrig} from "./kunde";

export const evaluateFormularstatus = (formularstatus) => {
    const result = []
    if (!formularstatus.Anamnese) {
        result.push('ANAMNESE')
    }
    if (!formularstatus.Einverstaendnis) {
        result.push('EINVERSTAENDNIS')
    }
    if (!formularstatus.Datenschutz) {
        result.push('DATENSCHUTZ')
    }
    if (formularstatus.Birthday && !istVolljaehrig(formularstatus.Birthday) && !formularstatus.ElternEve) {
        result.push('EINVERSTAENDNIS_ELTERN')
    }
    return result;
}