import {getArtistToken} from "../auth/token.saga";
import {getArtistEvents} from "../../api/artistApi";
import {call, fork, put, takeLatest} from "redux-saga/effects"
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {LOAD_TIMELINE, loadTimelineFailureAction, loadTimelineSuccessAction} from "./timelineActions";

function* processLoadTimeline({payload}) {
    const {tatortId, datVon, datBis} = payload.params
    const token = yield* getArtistToken()
    if (token) {
        try {
            const data = extractDataFromResponse(yield call(getArtistEvents, {
                token,
                params: {tatortid: tatortId, datvon: datVon, datbis: datBis}
            }))
            yield put(loadTimelineSuccessAction(data))
        } catch (err) {
            console.log(err)
            yield put(loadTimelineFailureAction())
        }
    }
}

function* watchLoadTimeline() {
    yield takeLatest(LOAD_TIMELINE, processLoadTimeline)
}

const timelineSagas = [fork(watchLoadTimeline)]
export default timelineSagas
