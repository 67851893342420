import {
    getAnzahlungsEinnahmen,
    getAnzahlungsVerrechnungen,
    isGespeicherterTermin
} from "./termin";
import {
    checkForWarteDateChanges,
    fehltAnzahlungKomplett,
    isGespeichertesProjekt,
    PROJEKTSTATUS_GESCHLOSSEN,
    PROJEKTSTATUS_OFFEN,
    PROJEKTSTATUS_RUHEND,
    PROJEKTSTATUS_WARTEND
} from "./projekt";
import {EINNAHME_ANZAHLUNG, EINNAHME_BAR, isVoucherEinnahme} from "./einnahme";
import {createNewPeriod} from "../store/utils/period";
import {ARTIST_VERTRAGSTYP_SELBSTAENDIG} from "./artist";
import {GUTSCHEINVERKAUF_STUDIOKASSE} from "./studio";
import {createVouchIn} from "./voucher";
import {getDatePartAtTimezone} from "../store/utils/dateHelper";
import {convertStringToNumber} from "../store/utils/numericHelper";
import {
    TERMINSTATUS_ERLEDIGT,
    TERMINSTATUS_OFFEN,
    TERMINSTATUS_VORGEMERKT,
    TERMINTYPEN_DEPOSIT,
    TERMINTYP_BESPRECHUNG
} from "./terminStatus";
import { keyBy } from "../store/utils/lodash-replacement";

export const ACTIVATE_WAITING_PROJECT = 'FINALIZER.QUESTION_ACTIVATE_WAITING_PROJECT'
export const ACTIVATE_PENDING_PROJECT = 'FINALIZER.QUESTION_ACTIVATE_PENDING_PROJECT'
export const ANZAHLUNG_ERHALTEN = 'FINALIZER.QUESTION_ANZAHLUNG_ERHALTEN'
export const KUNDE_FEHLT = 'FINALIZER.QUESTION_KUND_FEHLT'

export const FINALIZER_YES_NO = 'FINALIZER_YES_NO'
export const FINALIZER_CONFIRM = 'FINALIZER_CONFIRM'
export const EINNAHMEN_VERRECHNEN = 'FINALIZER.QUESTION_EINNAHMENVERRECHNUNG'
export const EINNAHMENVERRECHNUNG_ABWEICHEND = 'FINALIZER.QUESTION_EINNAHMENVERRECHNUNG_ABWEICHEND'
export const EINNAHMENVERRECHNUNG_NEGATIV = 'FINALIZER.QUESTION_EINNAHMENVERRECHNUNG_NEGATIV'
export const EINNAHMEN_ZUM_AZSTATUS = 'FINALIZER.QUESTION_EINNAHMEN_ZUM_AZSTATUS'
export const VERR_ANZ_EINN_ERG = 'FINALIZER.QUESTION_VERR_ANZ_EINN_ERG'
export const EINNAHMEN_FEHLERHAFT = 'FINALIZER.QUESTION_EINNAHMEN_FEHLERHAFT'
export const BESPRECHUNG_ANLEGEN = 'FINALIZER.QUESTION_BESPRECHUNG_ANLEGEN'
export const BESPRECHUNG_PROJEKT_UEBERTRAGEN = 'FINALIZER.QUESTION_BESPRECHUNG_PROJEKT_UEBERTRAGEN'
export const PROJEKT_SCHLIESSEN_LETZTER_TERMIN = 'FINALIZER.QUESTION_PROJEKT_SCHLIESSEN_LETZTER_TERMIN'
export const PROJEKT_SCHLIESSEN_ANZAHLUNG_VERRECHNET = 'FINALIZER.QUESTION_PROJEKT_SCHLIESSEN_ANZAHLUNG_VERRECHNET'
export const PROJEKT_SCHLIESSEN_QUESTIONS = [PROJEKT_SCHLIESSEN_LETZTER_TERMIN, PROJEKT_SCHLIESSEN_ANZAHLUNG_VERRECHNET]
export const PROJEKT_WITH_FUTURE_TERMINE = 'FINALIZER.QUESTION_PROJEKT_WITH_FUTURE_TERMINE'
export const EINNAHMEN_BEI_ARTIST = 'FINALIZER.QUESTION_EINNAHMEN_BEI_ARTIST'
export const EINNAHMEN_STUDIO_KASSE = 'FINALIZER.QUESTION_EINNAHMEN_STUDIO_KASSE'
export const GESCHLOSSENES_PROJEKT_MIT_ANZAHLUNG = 'FINALIZER.QUESTION_GESCHLOSSENES_PROJEKT_MIT_ANZAHLUNG'

const isNeuerFaelligerTerminMitBestehendemProjekt = ({termin, projekt, termintypMap}) => termin && projekt
    && !isGespeicherterTermin(termin.TerminID)
    && isGespeichertesProjekt(termin.ProjektID)
    && termintypMap[termin.Typ].ShowInFaellig

const isNeuerOffenerOderVorgemerkterFaelligerTerminMitBestehendemProjekt = ({termin, projekt, termintypMap}) =>
    isNeuerFaelligerTerminMitBestehendemProjekt({termin, projekt, termintypMap})
    && (termin.TrmStat === TERMINSTATUS_OFFEN
        || termin.TrmStat === TERMINSTATUS_VORGEMERKT)

const istWartendMitTermin = ({termin, projekt, termintypMap}) => {
    return isNeuerOffenerOderVorgemerkterFaelligerTerminMitBestehendemProjekt({termin, projekt, termintypMap})
        && projekt.ProjStat === PROJEKTSTATUS_WARTEND
}

const istRuhendMitTermin = ({termin, projekt, termintypMap}) => {
    return isNeuerOffenerOderVorgemerkterFaelligerTerminMitBestehendemProjekt({termin, projekt, termintypMap})
        && projekt.ProjStat === PROJEKTSTATUS_RUHEND
}

const checkWartendProjektOeffenen = (finalizerData) => {
    const {answers} = finalizerData
    if (notAnswered(answers, [ACTIVATE_WAITING_PROJECT]) && istWartendMitTermin(finalizerData)) {
        return {question: ACTIVATE_WAITING_PROJECT, type: FINALIZER_YES_NO}
    }
}

const checkRuhendesProjektAktivieren = (finalizerData) => {
    const {answers} = finalizerData
    if (notAnswered(answers, [ACTIVATE_PENDING_PROJECT]) &&istRuhendMitTermin(finalizerData)) {
        return {question: ACTIVATE_PENDING_PROJECT, type: FINALIZER_YES_NO}
    }
}

const getBaseAnzStatus = ({projekt, projektBase}) => {
    return projektBase && projekt && isGespeichertesProjekt(projektBase.ProjektID)
    && projektBase.ArtistID === projekt.ArtistID ? convertStringToNumber(projektBase.AnzStatus, 0) : 0
}

const getAnzStatusChange = ({projekt, projektBase}) => projekt ? convertStringToNumber(projekt.AnzStatus, 0) - getBaseAnzStatus({
    projekt,
    projektBase
}) : 0

const getChangeOfAnzahlungsverrechnungen = ({
                                                termin,
                                                terminBase
                                            }) => {
    if (!termin) {
        return 0
    }
    const verrechnungenTermin = (getAnzahlungsVerrechnungen(termin) || 0)
    const verrechnungenBase = getAnzahlungsVerrechnungen(terminBase) || 0
    return verrechnungenTermin - verrechnungenBase
}

const getChangeOfAnzahlungseinnahmen = ({
                                            termin,
                                            terminBase
                                        }) => {
    if (!termin) {
        return 0
    }
    return (getAnzahlungsEinnahmen(termin) || 0) - (getAnzahlungsEinnahmen(terminBase) || 0)
}

const getOwnerArtist = (termin, projekt, artistMap) => {
    const artistId = projekt && projekt.ArtistID || termin && termin.ArtistID
    return artistId && artistMap[artistId]
}

const isProjektArtistEditIncome = (projekt, artistMap) => projekt?.ArtistID && artistMap[projekt.ArtistID]?.EditIncome

const notAnswered = (answers, questions) => !answers || questions.find(q => answers[q] !== undefined) === undefined

const checkAnzErhalten = ({projekt, projektBase, termin, terminBase, artistSettingsMap, answers}) => {
    const ownerArtist = getOwnerArtist(projekt, termin, artistSettingsMap)
    if (notAnswered(answers, [ANZAHLUNG_ERHALTEN])
        && projekt && ownerArtist?.ManageAZ
        && (!isGespeichertesProjekt(projekt.ProjektID) && projekt.ProjStat === PROJEKTSTATUS_OFFEN
            || projekt && projektBase && projekt.ProjStat === PROJEKTSTATUS_OFFEN && projektBase.ProjStat === PROJEKTSTATUS_WARTEND)
        && fehltAnzahlungKomplett(projekt)
        && !getChangeOfAnzahlungsverrechnungen({termin, terminBase})
        && !getChangeOfAnzahlungseinnahmen({termin, terminBase})
    ) {
        return {
            question: ANZAHLUNG_ERHALTEN,
            values: {AnzAgreed: projekt.AnzAgreed},
            type: FINALIZER_YES_NO
        }
    }
}

const checkKeinKundeOderProjekt = ({termin, termintypMap, answers}) => {
    if (notAnswered(answers, [KUNDE_FEHLT])
        && termin && termintypMap[termin.Typ].ShowInFaellig && !termin.KundID) {
        return {
            question: KUNDE_FEHLT,
            type: FINALIZER_CONFIRM
        }
    }
}

const checkAnzahlungVerrechnen = ({projekt, projektBase, termin, terminBase, artistSettingsMap, answers}) => {
    const ownerArtist = getOwnerArtist(projekt, termin, artistSettingsMap)
    if (notAnswered(answers, [EINNAHMEN_VERRECHNEN, EINNAHMENVERRECHNUNG_ABWEICHEND, EINNAHMENVERRECHNUNG_NEGATIV])
        && projekt && termin && ownerArtist?.ManageAZ
        && !getAnzStatusChange({projekt, projektBase})
        && getChangeOfAnzahlungsverrechnungen({termin, terminBase})
    ) {
        const diff = convertStringToNumber(projekt.AnzStatus, 0) - getChangeOfAnzahlungsverrechnungen({termin, terminBase})
        const question = !diff ? EINNAHMEN_VERRECHNEN : (diff > 0 ? EINNAHMENVERRECHNUNG_ABWEICHEND : EINNAHMENVERRECHNUNG_NEGATIV)
        return {
            question,
            type: FINALIZER_YES_NO
        }
    }
}

const checkEinnMitAnzStatus = ({projekt, projektBase, termin, terminBase, artistSettingsMap, answers}) => {
    const ownerArtist = getOwnerArtist(projekt, termin, artistSettingsMap)
    if (notAnswered(answers, [EINNAHMEN_ZUM_AZSTATUS])
        && projekt && termin && ownerArtist?.ManageAZ
        && !getAnzStatusChange({projekt, projektBase})
        && getChangeOfAnzahlungseinnahmen({termin, terminBase})
    ) {
        return {
            question: EINNAHMEN_ZUM_AZSTATUS,
            type: FINALIZER_YES_NO
        }
    }
}

const checkVerrAnzEinnahmeHinzu = ({
                                       projekt,
                                       projektBase,
                                       termin,
                                       terminBase,
                                       artistSettingsMap,
                                       canEditEinnahme,
                                       answers
                                   }) => {
    const anStatusChange = getAnzStatusChange({projekt, projektBase})
    if (notAnswered(answers, [VERR_ANZ_EINN_ERG])
        && projekt && termin
        && isProjektArtistEditIncome(projekt, artistSettingsMap)
        && canEditEinnahme
        && termin.ArtistID === projekt.ArtistID
        && anStatusChange
        && !getChangeOfAnzahlungsverrechnungen({termin, terminBase})
        && !getChangeOfAnzahlungseinnahmen({termin, terminBase})
        && (TERMINTYPEN_DEPOSIT.includes(termin.Typ) || anStatusChange < 0)
    ) {
        return {
            question: VERR_ANZ_EINN_ERG,
            type: FINALIZER_YES_NO
        }
    }
}

/**
 * Diese Funktion gleich evtl. manuelle Änderungen am Anzahlungs-Status des Projektes mit den
 * Eingaben bei den Einnahmen ab."
 */
const getDiffAnzStatAenderungZuEinnahmen = ({projekt, projektBase, termin, terminBase}) => convertStringToNumber(projekt.AnzStatus, 0)
    - getBaseAnzStatus({projekt, projektBase})
    - getChangeOfAnzahlungseinnahmen({termin, terminBase})
    + getChangeOfAnzahlungsverrechnungen({termin, terminBase})

const checkAnzahlungFehlerhaft = ({projekt, projektBase, termin, terminBase, answers}) => {
    const anzStatusChange = getAnzStatusChange({projekt, projektBase})
    const anzVerrechnungChange = getChangeOfAnzahlungsverrechnungen({termin, terminBase})
    const anzahlungseinnahmenChange = getChangeOfAnzahlungseinnahmen({termin, terminBase})
    if (notAnswered(answers, [EINNAHMEN_FEHLERHAFT])
        && anzStatusChange
        && (anzVerrechnungChange || anzahlungseinnahmenChange)
        && getDiffAnzStatAenderungZuEinnahmen({projekt, projektBase, termin, terminBase})) {
        const einnahmen = anzahlungseinnahmenChange - anzVerrechnungChange
        return {
            question: EINNAHMEN_FEHLERHAFT,
            values: {anzStatusChange, einnahmen},
            type: FINALIZER_CONFIRM
        }
    }
}

const checkTerminAnlegen = ({
                                projekt,
                                projektBase,
                                termin,
                                terminBase,
                                artistSettingsMap,
                                canEditEinnahme,
                                answers
                            }) => {
    if (notAnswered(answers, [BESPRECHUNG_ANLEGEN])
        && projekt
        && isProjektArtistEditIncome(projekt, artistSettingsMap)
        && !getChangeOfAnzahlungsverrechnungen({termin, terminBase})
        && !getChangeOfAnzahlungseinnahmen({termin, terminBase})
        && getDiffAnzStatAenderungZuEinnahmen({projekt, projektBase, termin, terminBase})
        && (!termin
            || termin.ArtistID !== projekt.ArtistID
            || ! TERMINTYPEN_DEPOSIT.includes(termin.Typ))
        && !checkVerrAnzEinnahmeHinzu({
            projekt,
            projektBase,
            termin,
            terminBase,
            artistSettingsMap,
            canEditEinnahme,
            answers
        })
    ) {
        return {
            question: BESPRECHUNG_ANLEGEN,
            values: {Kuerzel: artistSettingsMap[projekt.ArtistID].Kuerzel},
            type: FINALIZER_YES_NO
        }
    }
}

const checkProjektUebertragen = ({projekt, projektBase, artistSettingsMap, answers}) => {
    if (notAnswered(answers, [BESPRECHUNG_PROJEKT_UEBERTRAGEN])
        && projekt
        && projektBase
        && artistSettingsMap[projektBase.ArtistID].ManageAZ
        && isGespeichertesProjekt(projektBase.ProjektID)
        && projekt.ArtistID !== projektBase.ArtistID
        && isProjektArtistEditIncome(projekt, artistSettingsMap)
        && convertStringToNumber(projektBase.AnzStatus, 0) !== 0
    ) {
        return {
            question: BESPRECHUNG_PROJEKT_UEBERTRAGEN,
            values: {Kuerzel: artistSettingsMap[projektBase.ArtistID].Kuerzel},
            type: FINALIZER_YES_NO
        }
    }
}

const istKannProjektGeschlossenWerdenEinnahmen = ({termin, projekt, termintypMap, answers}) => termin
    && projekt
    && termin.ProjektID
    && termin.TrmStat === TERMINSTATUS_ERLEDIGT
    && termintypMap[termin.Typ].CountInPs
    && projekt.ProjStat === PROJEKTSTATUS_OFFEN
    && convertStringToNumber(projekt.AnzStatus, 0) === 0
    && answers && answers[EINNAHMEN_VERRECHNEN]

const kannProjektGeschlossenWerdenLetztTerm = ({
                                                   termin,
                                                   terminBase,
                                                   projekt,
                                                   termintypMap
                                               }) => isGespeicherterTermin(termin?.TerminID)
    && terminBase?.ProjektID
    && termin.TrmStat === TERMINSTATUS_ERLEDIGT
    && terminBase.TrmStat !== TERMINSTATUS_ERLEDIGT
    && termin?.ProjektID
    && termintypMap[termin.Typ].CountInPs
    && projekt
    && projekt.ProjStat === PROJEKTSTATUS_OFFEN
    && projekt.LetzArbTerm && new Date(projekt.LetzArbTerm).getTime() <= new Date(termin.Start).getTime()


const checkProjektSchliessen = ({termin, terminBase, projekt, termintypMap, answers}) => {
    if (notAnswered(answers, [PROJEKT_SCHLIESSEN_ANZAHLUNG_VERRECHNET, PROJEKT_SCHLIESSEN_LETZTER_TERMIN])) {
        if (
            istKannProjektGeschlossenWerdenEinnahmen({termin, terminBase, projekt, termintypMap, answers})) {
            return {
                question: PROJEKT_SCHLIESSEN_ANZAHLUNG_VERRECHNET,
                type: FINALIZER_YES_NO
            }
        }
        if (kannProjektGeschlossenWerdenLetztTerm({termin, terminBase, projekt, termintypMap, answers})) {
            return {
                question: PROJEKT_SCHLIESSEN_LETZTER_TERMIN,
                type: FINALIZER_YES_NO
            }
        }
    }
}

const checkFutureTermineInClosedProjekt = ({projekt, answers}) => {
    if (notAnswered(answers, [PROJEKT_WITH_FUTURE_TERMINE])
        && projekt
        && projekt.ProjStat === PROJEKTSTATUS_GESCHLOSSEN
        && projekt.LetzArbTerm && new Date(projekt.LetzArbTerm).getTime() > new Date().getTime()
    ) {
        return {
            question: PROJEKT_WITH_FUTURE_TERMINE,
            type: FINALIZER_CONFIRM
        }
    }
}

export const wirdVoucherAusgezahlt = ({gutscheinverkauf, artistSettingsMap, einnahme, termin}) => {
    return termin
        && einnahme?.voucher
        && (artistSettingsMap[termin.ArtistID].Vertragstyp === ARTIST_VERTRAGSTYP_SELBSTAENDIG
            && gutscheinverkauf === GUTSCHEINVERKAUF_STUDIOKASSE
            || einnahme.voucher.ArtistID !== termin.ArtistID)
}

const checkConvertCashVouchin = ({termin, gutscheinverkauf, artistSettingsMap, answers}) => {
    if (termin && termin.einnahmen?.length) {
        return termin.einnahmen.filter(einnahme => isVoucherEinnahme(einnahme)
            && wirdVoucherAusgezahlt({
                termin,
                einnahme,
                gutscheinverkauf,
                artistSettingsMap
            }))
            .map(e => {
                    const question = gutscheinverkauf === GUTSCHEINVERKAUF_STUDIOKASSE ?
                        `${EINNAHMEN_STUDIO_KASSE}-${e.id}` : `${EINNAHMEN_BEI_ARTIST}-${e.id}`
                    if (notAnswered(answers, [question])) {
                        if (gutscheinverkauf === GUTSCHEINVERKAUF_STUDIOKASSE) {
                            return {
                                question: `${EINNAHMEN_STUDIO_KASSE}-${e.id}`,
                                values: {Betrag: e.Betrag, VouchNo: e.voucher.VouchNo},
                                type: FINALIZER_CONFIRM
                            }
                        }
                        return {
                            question: `${EINNAHMEN_BEI_ARTIST}-${e.id}`,
                            values: {
                                Betrag: e.Betrag,
                                VouchNo: e.voucher.VouchNo,
                                Kuerzel: artistSettingsMap[e.voucher.ArtistID].Kuerzel
                            },
                            type: FINALIZER_CONFIRM
                        }
                    }
                }
            ).filter (t => t != null)
    }
}

const checkWarnProjektSchliessenAnzVerrechnen = ({projekt, answers}) => {
    if (notAnswered(answers, [GESCHLOSSENES_PROJEKT_MIT_ANZAHLUNG])
        && projekt && projekt.ProjStat === PROJEKTSTATUS_GESCHLOSSEN && convertStringToNumber(projekt.AnzStatus, 0) > 0) {
        return {
            question: GESCHLOSSENES_PROJEKT_MIT_ANZAHLUNG,
            values: {AnzStatus: projekt.AnzStatus},
            type: FINALIZER_CONFIRM
        }
    }
}

let questionCounter = 1

export const evaluateOpenQuestions = (finalizerData) => {
    return [checkWartendProjektOeffenen
        , checkRuhendesProjektAktivieren
        , checkAnzErhalten
        , checkKeinKundeOderProjekt
        , checkAnzahlungVerrechnen
        , checkEinnMitAnzStatus
        , checkVerrAnzEinnahmeHinzu
        , checkAnzahlungFehlerhaft
        , checkTerminAnlegen
        , checkProjektUebertragen
        , checkProjektSchliessen
        , checkFutureTermineInClosedProjekt
        , checkConvertCashVouchin
        , checkWarnProjektSchliessenAnzVerrechnen].map(checker => checker(finalizerData)).filter(t => t !== undefined)
        .reduce((list, q) => Array.isArray(q) ? list.concat(q) : list.concat([q]), [])
        .map(q => ({...q, id: questionCounter++}))
}

const checkForProjektStatusChange = (question, projekt, decision) => {
    if (decision.question === question) {
        return decision.answer ? {
            projekt: {
                ...projekt,
                ProjStat: PROJEKTSTATUS_OFFEN,
                ...checkForWarteDateChanges(projekt, PROJEKTSTATUS_OFFEN)
            }
        } : {}
    }
}
/**
 * Unabhängig davon, ob der eingeloggte Artist die Einnahmen ändern kann, soll in diesem
 * Fall der AZ-Status gesetzt werden, da die AZ ja geleistet wurde. Der betreffende Artist
 * wird darüber automatisch informiert: zum einen über den autom. Angelegten Bespr.-Termin,
 * zum Anderen über die Differenz des AZ-Status des Projektes zu den Einnahmen.
 */
const checkForAnzahlungErhalten = ({projekt, decision}) => {
    if (decision.question === ANZAHLUNG_ERHALTEN && decision.answer) {
        return decision.answer ? {
            projekt: {
                ...projekt,
                AnzStatus: projekt.AnzAgreed
            }
        } : {}
    }
}

const checkForAnzahlungVerrechnen = ({projekt, termin, terminBase, decision}) => {
    const matchingQuestions = [EINNAHMEN_VERRECHNEN, EINNAHMENVERRECHNUNG_ABWEICHEND, EINNAHMENVERRECHNUNG_NEGATIV]
    if (matchingQuestions.includes(decision.question) && decision.answer) {
        const AnzStatus = convertStringToNumber(projekt.AnzStatus, 0) - getChangeOfAnzahlungsverrechnungen({termin, terminBase})
        return decision.answer ? {
            projekt: {
                ...projekt,
                AnzStatus
            }
        } : {}
    }
}

const checkForEinnMitAnzStatus = ({projekt, termin, terminBase, decision}) => {
    if (EINNAHMEN_ZUM_AZSTATUS === decision.question && decision.answer) {
        const AnzStatus = convertStringToNumber(projekt.AnzStatus,0) + getChangeOfAnzahlungseinnahmen({termin, terminBase})
        return decision.answer ? {
            projekt: {
                ...projekt,
                AnzStatus
            }
        } : {}
    }
}

const checkForVerrAnzEinnahmeHinzu = ({projekt, projektBase, termin, decision}) => {
    if (VERR_ANZ_EINN_ERG === decision.question && decision.answer) {
        const isBesprechnung = TERMINTYPEN_DEPOSIT.includes(termin.Typ)
        const EinnArt = !isBesprechnung ? EINNAHME_ANZAHLUNG : EINNAHME_BAR
        const rawBetrag = getAnzStatusChange({projekt, projektBase})
        const Betrag = EinnArt === EINNAHME_ANZAHLUNG ? Math.abs(rawBetrag) : rawBetrag
        const einnahme = {
            TerminID: termin.TerminID
            , EinnArt
            , Betrag
            , Anmerkung: 'system generated'

        }
        return {
            termin: {
                ...termin
                , einnahmen: termin.einnahmen && termin.einnahmen.concat([einnahme]) || [einnahme]
            }
        }
    }
}

const checkForTerminAnlegen = ({projekt, projektBase, loggedInArtistId, decision, besprechungen = []}) => {
    if (BESPRECHUNG_ANLEGEN === decision.question && decision.answer) {
        const TrmStat = TERMINSTATUS_ERLEDIGT
        const period = createNewPeriod(new Date(), 20, 5)
        const Betrag = getAnzStatusChange({projekt, projektBase})
        const besprechung = {
            ArtistID: projekt.ArtistID
            , ProjektID: isGespeichertesProjekt(projekt.ProjektID) ? projekt.ProjektID : undefined
            , KundID: projekt.KundID
            , Betreff: 'Termine+AZ'
            , Start: period.start.toISOString()
            , DatBis: period.end.toISOString()
            , Ganztags: false
            , Typ: TERMINTYP_BESPRECHUNG
            , _createdID: loggedInArtistId
            , _createdTS: new Date().toISOString()
            , TrmStat
            , einnahmen: [
                {EinnArt: EINNAHME_BAR, Betrag}
            ]
        }
        return {besprechungen : besprechungen.concat([besprechung])}
    }
}

const checkForProjektUebertragen = ({projektBase, loggedInArtistId, decision, besprechungen = []}) => {
    if (BESPRECHUNG_PROJEKT_UEBERTRAGEN === decision.question && decision.answer) {
        const TrmStat = TERMINSTATUS_ERLEDIGT
        const period = createNewPeriod(new Date(), 20, 5)
        const Betrag = -convertStringToNumber(projektBase.AnzStatus, 0)
        const uebertragsBesprechung = {
            ArtistID: projektBase.ArtistID
            , ProjektID: projektBase.ProjektID
            , KundID: projektBase.KundID
            , Betreff: 'Projektübergabe'
            , Start: period.start.toISOString()
            , DatBis: period.end.toISOString()
            , Ganztags: false
            , Typ: TERMINTYP_BESPRECHUNG
            , _createdID: loggedInArtistId
            , _createdTS: new Date().toISOString()
            , TrmStat
            , einnahmen: [
                {EinnArt: EINNAHME_BAR, Betrag}
            ]
        }
        return {besprechungen : besprechungen.concat([uebertragsBesprechung])}
    }
}

const check4ProjektSchliessen = ({projekt, decision}) => {
    if (decision.answer && PROJEKT_SCHLIESSEN_QUESTIONS.includes(decision.question)) {
        return {
            projekt: {
                ...projekt
                , ProjStat: PROJEKTSTATUS_GESCHLOSSEN
            }
        }
    }

}


export const applyDecision = ({
                                  finalizerData: {
                                      projekt,
                                      projektBase,
                                      decisions,
                                      termin,
                                      terminBase,
                                      loggedInArtistId,
                                      besprechungen
                                  }, decision
                              }) =>
    checkForProjektStatusChange(ACTIVATE_WAITING_PROJECT, projekt, decision)
    || checkForProjektStatusChange(ACTIVATE_PENDING_PROJECT, projekt, decision)
    || checkForAnzahlungErhalten({projekt, decision, decisions})
    || checkForAnzahlungVerrechnen({projekt, termin, terminBase, decision})
    || checkForEinnMitAnzStatus({projekt, termin, terminBase, decision})
    || checkForVerrAnzEinnahmeHinzu({projekt, termin, projektBase, decision})
    || checkForTerminAnlegen({projekt, projektBase, loggedInArtistId, decision, besprechungen})
    || checkForProjektUebertragen({projektBase, loggedInArtistId, decision, besprechungen})
    || check4ProjektSchliessen({projekt, decision})


export const convertTerminGutscheinEinnahmen = ({
                                                    termin,
                                                    artistSettingsMap,
                                                    gutscheinverkauf,
                                                    gutscheinText,
                                                    loggedInArtistId,
                                                    timezone
                                                }) => {
    const einnahmen = []
    const vouchInList = []
    for (let einnahme of termin.einnahmen) {
        if (isVoucherEinnahme(einnahme)) {
            const newEinnahme = {...einnahme}
            const oldAnmerkung = einnahme.Anmerkung ? ` // ${einnahme.Anmerkung}` : ''
            newEinnahme.Anmerkung = `${gutscheinText} ${einnahme.voucher.VouchNo}${oldAnmerkung}`.substring(0, 100)
            const datIn = getDatePartAtTimezone(termin.Start, timezone)
            const vouchIn = createVouchIn(einnahme.voucher.VouchOutID, einnahme.Betrag, artistSettingsMap[termin.ArtistID].Kuerzel, loggedInArtistId, datIn)
            if (wirdVoucherAusgezahlt({gutscheinverkauf, artistSettingsMap, einnahme, termin})) {
                newEinnahme.EinnArt = EINNAHME_BAR
            }
            einnahmen.push(newEinnahme)
            vouchInList.push(vouchIn)
        } else {
            einnahmen.push(einnahme)
        }
    }
    const newTermin = {
        ...termin
        , einnahmen
    }
    return {termin: newTermin, vouchInList}
}

export const sortFinalizerQuestions = questions => questions.sort((q1, q2) => q1.id > q2.id ? 1 : -1)

const consolidateQuestions = (unansweredBaseQuestions, openQuestions) => {
    const unansweredQuestionMap = keyBy(unansweredBaseQuestions, 'question')
    return openQuestions.reduce((list, question) => {
        if (unansweredQuestionMap[question.question] != null) {
            list.push(unansweredQuestionMap[question.question])
        } else {
            list.push(question)
        }
        return list
    }, [])
}

export const applyAnswers = (questionWithAnswer, finalizerData, baseQuestionsWithAnswers) => {
    const answeredQuestion = keyBy(baseQuestionsWithAnswers.filter(q => q.answer !== undefined && q.question !== questionWithAnswer.question), 'question')
    const unansweredQuestions = baseQuestionsWithAnswers.filter(q => q.answer === undefined)
    let actAnsweredQuestion, tmpQuestions, tempResult = finalizerData
    const allAnswers = {}
    const applySingleAnswer = (answer) => {
        allAnswers[answer.question] = answer
        const decisionResult = applyDecision({finalizerData: tempResult, decision: answer})
        tempResult = {...tempResult, ...decisionResult, answers: allAnswers}
    }
    do {
        tmpQuestions = evaluateOpenQuestions(tempResult)
        actAnsweredQuestion = tmpQuestions.find(q => answeredQuestion[q.question] !== undefined)
        if (actAnsweredQuestion !== undefined) {
            applySingleAnswer(answeredQuestion[actAnsweredQuestion.question])
        }
    } while (actAnsweredQuestion !== undefined)
    if (tmpQuestions.find(q => questionWithAnswer.question === q.question) !== undefined) {
        applySingleAnswer(questionWithAnswer)
        tmpQuestions = evaluateOpenQuestions(tempResult)
    }
    const newOpenQuestions = consolidateQuestions(unansweredQuestions, tmpQuestions)
    return {
        finalizerData: tempResult,
        questionsWithAnswers: sortFinalizerQuestions(newOpenQuestions.concat(Object.values(allAnswers)))
    }
}