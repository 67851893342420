import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectLoggedInArtistID, selectStudioUserID } from "../store/auth/authSelector";
import { useEventLog } from "./useEventLog";

export function useEventLogger() {
    const loggedInArtistId = useSelector((state) => selectLoggedInArtistID(state));
    const userId = useSelector((state) => selectStudioUserID(state));
    const { logEvent, popEvents } = useEventLog();
    

    // Funktion zum Erfassen von Klick-Events
    const captureClickEvent = useCallback(
        (type, payload) => {
            setTimeout(() => {
                logEvent({
                    type,
                    payload,
                    artistId: loggedInArtistId,
                    userId: userId,
                    createdAt: Date.now(),
                });
            }, 0);
        },
        [logEvent, loggedInArtistId, userId]
    );

    const capturePathEvents = useCallback(
        (pathname) => {
            setTimeout(() => {
                logEvent({
                    type: "pageview",
                    payload: pathname,
                    artistId: loggedInArtistId || null,
                    userId: userId || null,
                    createdAt: Date.now(),
                });
            }, 0);
        },
        [logEvent, loggedInArtistId, userId]
    );

    return { captureClickEvent, capturePathEvents, popEvents };
}
