import {put, takeLatest, call} from "redux-saga/effects";
import {DELETE_LIST_DEFINITION, loadAllReportDefinitionsAction} from "../../listActions";
import {getArtistToken} from "../../../auth/token.saga";
import {deleteStudioReportDefinition} from "../../../../api/studioApi";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processDeleteListDefinition({payload}) {
    try {
        const token = yield getArtistToken()
        const reportDefID = payload.reportDefID
        if (token) {
            yield call(deleteStudioReportDefinition, {token, reportDefID})
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('LIST.DELETE_DATA_SUCCESS_INFO', {ns: i18_LIST}), 'deleteReportDefinition')))
            yield put(loadAllReportDefinitionsAction())
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.ERROR.DELETE_DATA_FAILED_INFO', {ns: i18_LIST}), 'deleteReportDefinition')))
        console.log(err)
    }
}

export function* watchDeleteListDefinition() {
    yield takeLatest(DELETE_LIST_DEFINITION, processDeleteListDefinition)
}