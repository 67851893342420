import { fork } from "redux-saga-test-plan/matchers";
import { watchLoadArtistBaseData } from "./sagas/loadArtistBaseData";
import { watchSaveArtistBase } from "./sagas/saveArtistBase";
import { watchSaveArtistMail } from "./sagas/saveArtistMail";
import { watchLoadArtistMailData } from "./sagas/loadArtistMailData";
import { watchLoadArtistRechteData } from "./sagas/loadArtistRechteData";
import { watchVerifyArtistMailConfiguration } from "./sagas/verifyMailConfiguration";
import { watchSaveArtistWorkTime } from "./sagas/saveArtistWorkTime";
import { watchDeleteWorkTime } from "./sagas/deleteWorkTime";
import { watchSaveArtistRechte } from "./sagas/saveArtistRechte";
import { watchSaveArtistAssistData } from "./sagas/saveArtistAssistData";
import { watchDeactivateArtists } from "./sagas/deactivate";
import { watchSetArtistAdmin } from "./sagas/setArtistAdmin";
import { watchSetArtistVoucherRights } from "./sagas/setArtistIssuesVouchers";
import { watchReactivateArtist } from "./sagas/reactivateArtist";
import { watchCopyArtists } from "./sagas/copyArtist";
import { watchDeleteArtists } from "./sagas/deleteArtist";
import { watchSetArtistGglSync } from "./sagas/setArtistGglSync";

const artistSaga = [
    fork(watchSaveArtistBase),
    fork(watchLoadArtistMailData),
    fork(watchVerifyArtistMailConfiguration),
    fork(watchSaveArtistMail),
    fork(watchLoadArtistBaseData),
    fork(watchSaveArtistWorkTime),
    fork(watchDeleteWorkTime),
    fork(watchLoadArtistRechteData),
    fork(watchSaveArtistRechte),
    fork(watchSaveArtistAssistData),
    fork(watchDeactivateArtists),
    fork(watchSetArtistAdmin),
    fork(watchSetArtistVoucherRights),
    fork(watchSetArtistGglSync),
    fork(watchReactivateArtist),
    fork(watchCopyArtists),
    fork(watchDeleteArtists),
];
export default artistSaga;
