import {
    LOAD_COMMON,
    loadCommonFailureAction,
    loadCommonSuccessAction,
    loadLoggedArtistSettingsSuccessAction
} from "../commonActions";
import {bootstrapAuth} from "../../auth/authSaga";
import {getArtistToken} from "../../auth/token.saga";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {loadArtistSettings} from "../../../api/localArtistPersistence";
import {getCountryMap, getMessageVorlagen, getPdfVorlagen, getStudio, getTermintypen} from "../../../api/commonApi";
import {getAllArtists} from "../../../api/artistApi";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {pushHistory} from "../../router/routerActions";
import {put, takeLatest, select, call, all} from "redux-saga/effects"
import {getStudioSubscriptionStatus} from "../../../api/studioApi";
function* bootstrapCommon() {
    try {
        const {isArtistLoggedIn, isStudioLoggedIn} = yield* bootstrapAuth()
        const token = yield getArtistToken()
        if (isArtistLoggedIn && token) {
            const artistID = yield select(state => selectLoggedInArtistID(state))
            const {
                artistSettings,
                countryMap,
                termintypMap,
                msgVorlagenMap,
                pdfVorlagenMap,
                artistMap,
                studio,
                subscriptionStatus
            } = yield all({
                artistSettings: call(loadArtistSettings, artistID),
                countryMap: call(getCountryMap),
                termintypMap: call(getTermintypen, {token}),
                msgVorlagenMap: call(getMessageVorlagen, {token}),
                pdfVorlagenMap: call(getPdfVorlagen, {token}),
                artistMap: call(getAllArtists, {token}),
                studio: call(getStudio, {token}),
                subscriptionStatus: call(getStudioSubscriptionStatus, {token})
            })
            yield put(loadCommonSuccessAction({
                countryMap: extractDataFromResponse(countryMap)
                , artistMap: extractDataFromResponse(artistMap)
                , termintypMap: extractDataFromResponse(termintypMap)
                , msgVorlagenMap: extractDataFromResponse(msgVorlagenMap)
                , pdfVorlagenMap: extractDataFromResponse(pdfVorlagenMap)
                , studio: extractDataFromResponse(studio)
                , subscriptionStatus: extractDataFromResponse(subscriptionStatus)
            }))
            yield put(loadLoggedArtistSettingsSuccessAction(artistSettings))


        } else {
            yield put(loadCommonFailureAction())
            if (isStudioLoggedIn) {
                yield put(pushHistory('/login-artist'))
            } else {
                yield put(pushHistory('/login-studio'))
            }
        }

    } catch (e) {
        console.log(e)
        yield put(loadCommonFailureAction())
        yield put(pushHistory('/error'))
    }
}

export function* watchLoadCommon() {
    yield takeLatest(LOAD_COMMON, bootstrapCommon)
}
