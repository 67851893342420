import {selectArtistMap} from "../../common/commonSelector";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {processUpdateArtist} from "./updateArtist";
import {REACTIVATE_ARTISTS} from "../artistActions";
import {select, takeLatest} from "redux-saga/effects"
import {i18_ARTIST} from "../../../i18nReferences";

function* processReactivateArtist({payload}) {
    const artistData = payload.artistChanges
    const artistMap = yield select(state => selectArtistMap(state))
    const kuerzel = artistMap[artistData.ArtistID].Kuerzel
    const successNotification = createInfoNotification(i18next.t('EDIT_ARTIST.REACTIVATE_ARTIST_SUCCESS_INFO', {
        ns: i18_ARTIST,
        kuerzel,
    }), 'changeArtistActivState')
    const failureNotification = createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_FAILED_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'changeArtistActivState')
    yield processUpdateArtist( payload.artistChanges, successNotification, failureNotification)
}

export function* watchReactivateArtist() {
    yield takeLatest([REACTIVATE_ARTISTS], processReactivateArtist)
}