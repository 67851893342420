import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {
    LOAD_DOUBLETTEN_LIST_DATA,
    loadDoublettenListDataFailureAction,
    loadDoublettenListDataSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {getDoubletten} from "../../../../api/kundenApi";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadDoublettenListData() {
    try {
        const token = yield getArtistToken()
        if (token) {
            const vouchers = extractDataFromResponse(yield call(getDoubletten, {token}))
            yield put(loadDoublettenListDataSuccessAction(vouchers))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.LOAD_DOUBLETTEN_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadDuplicates')))
        yield put(loadDoublettenListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadDoublettenListData() {
    yield takeLatest(LOAD_DOUBLETTEN_LIST_DATA, processLoadDoublettenListData)
}