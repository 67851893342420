import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {enrichWithArtistIdListForNoAdmin} from "../common/enrichWithArtistIdListForNoAdmin";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {searchTermine} from "../../../../api/terminApi";
import {LOAD_TERMIN_LIST_DATA, loadTerminListDataFailureAction, loadTerminListDataSuccessAction} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadTerminListData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const parameters = yield enrichWithArtistIdListForNoAdmin(payload.parameters)
            const projekte = extractDataFromResponse(yield call(searchTermine, {token, parameters}))
            yield put(loadTerminListDataSuccessAction(projekte))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.LOAD_TERMIN_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadTerminListData')))
        yield put(loadTerminListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadTerminListData() {
    yield takeLatest(LOAD_TERMIN_LIST_DATA, processLoadTerminListData)
}