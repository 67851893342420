import {PlainDate, TIMEUNIT_DAYS} from "@kiss-solutions/plaindate";
import {isOverlapping} from "@kiss-solutions/plaindate/plainPeriod";
import {DEPOSIT_MEETING_TEXT_PART, getColorForTypAndTrmStat} from "./termin";
import {sortAndDistributePeriodsInRows} from "./period";
import {TIMEUNIT_MINUTES} from "@kiss-solutions/plaindate/plainDate";

export const addStartEnd = p => ({
    ...p, start: new PlainDate(p.start),
    end: new PlainDate(p.end)
})

const SLOT_SIZE_FACTOR = 24 * 60

export const convertRawArtistData = rawArtistData => ({
    ...rawArtistData,
    bookedSlots: rawArtistData.bookedSlots.map(s => addStartEnd(s)),
    offBusinessSlots: rawArtistData.offBusinessSlots.map(s => addStartEnd(s)),
    notAvailableAtLocation: rawArtistData.notAvailableAtLocation.map(s => addStartEnd(s)),
})

function splitTargetPeriodIntoDays({targetPeriod, startTimes, endTimes}) {
    let day = new PlainDate(targetPeriod.start)
    const dayPeriods = []
    while (targetPeriod.end.isAfter(day)) {
        dayPeriods.push({
            start: new PlainDate(day).setTime(...startTimes, 0),
            end: endTimes[0] === 24 ? new PlainDate(day).add(1, TIMEUNIT_DAYS)
                : new PlainDate(day).setTime(...endTimes, 0)
        })
        day.add(1)
    }
    return dayPeriods
}

const getPeriodLength = (period) => {
    if (period.start.date != period.end.date) {
        return Math.round((24 * 3600 - period.start.time) / 60)
    }
    return Math.round((period.end.time - period.start.time) / 60)
}
const addLengthToPeriod = (period) => ({...period, length: getPeriodLength(period)})
const createBlankPeriod = (start, end) => {
    const blankPeriod = {start, end, isBlank: true}
    blankPeriod.length = getPeriodLength(blankPeriod)
    return blankPeriod
}
export const toCompleteDaySlotList = (periods, dayPeriod) => periods.reduce((result, period, idx) => {
    const {start, end} = dayPeriod
    const lastPeriod = result.length ? result[result.length - 1] : null
    if (!lastPeriod) {
        if (start.isAfter(period.start)) {
            result.push(addLengthToPeriod({...period, start, end: period.end.isAfter(end) ? end : period.end}))
        } else if (period.start.isAfter(start)) {
            result.push(createBlankPeriod(start, period.start))
            result.push(addLengthToPeriod({
                ...period,
                start: period.start,
                end: period.end.isAfter(end) ? end : period.end
            }))
        } else {
            result.push(addLengthToPeriod({
                ...period,
                start: period.start,
                end: period.end.isAfter(end) ? end : period.end
            }))
        }
    } else {
        if (period.start.isAfter(lastPeriod.end)) {
            result.push(createBlankPeriod(lastPeriod.end, period.start))
        }
        result.push(addLengthToPeriod({
            ...period,
            start: period.start,
            end: period.end.isAfter(end) ? end : period.end
        }))
    }
    if (idx === periods.length - 1 && end.isAfter(period.end)) {
        result.push(createBlankPeriod(period.end, end))
    }
    return result
}, [])

export function convertToTimelineData({
                                          artistMap,
                                          timelineArtistData,
                                          targetPeriod,
                                          startTimeString= '00:00',
                                          endTimeString= '24:00',
                                          termintypMap,
                                          tatortMap
                                      }) {
    const startTimes = startTimeString.split(':').map(i => parseInt(i, 10))
    const endTimes = endTimeString.split(':').map(i => parseInt(i, 10))
    const artistData = convertRawArtistData(timelineArtistData)
    const dayPeriods = splitTargetPeriodIntoDays({targetPeriod, startTimes, endTimes})
    const result = {}
    result.ArtistID = artistData.ArtistID
    result.Kuerzel = artistMap[artistData.ArtistID].Kuerzel
    result.bookedSlots = []
    result.offBusiness = []
    result.offSite = []
    for (let idx = 0; idx < dayPeriods.length; idx++) {
        const offSite = artistData.notAvailableAtLocation.find(t => isOverlapping(t, dayPeriods[idx], TIMEUNIT_MINUTES, false))
        if (offSite != null) {
            result.offSite.push([{
                title: tatortMap[offSite.TatOrtID].Ort,
                color: '#' + tatortMap[offSite.TatOrtID].Farbe,
                length: SLOT_SIZE_FACTOR
            }])
        } else {
            result.offSite.push([])
        }
        const offBusiness = artistData.offBusinessSlots.filter(s => isOverlapping(s, dayPeriods[idx], TIMEUNIT_MINUTES, false))
            .map(slot =>({
                start: slot.start,
                end: slot.end,
                color: '#240175',
                title: 'out of office',
            }))
        result.offBusiness.push(toCompleteDaySlotList(offBusiness, dayPeriods[idx]))
        const bookedSlots = artistData.bookedSlots.filter(s => s.title.indexOf(DEPOSIT_MEETING_TEXT_PART) === -1
            && isOverlapping(s, dayPeriods[idx], TIMEUNIT_MINUTES, false))
            .map(slot => {
                return {
                    start: slot.start,
                    end: slot.end,
                    color: getColorForTypAndTrmStat(termintypMap[slot.Typ], slot.TrmStat),
                    title: slot.title,
                }
            })
        result.bookedSlots.push(sortAndDistributePeriodsInRows(bookedSlots).map(row => toCompleteDaySlotList(row, dayPeriods[idx])))
    }
    return result
}
