import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKundenTermine} from "../../../api/kundenApi";
import {
    LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE,
    loadSelectedKundenTermineFailureAction,
    loadSelectedKundenTermineSuccessAction
} from "../calendarActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects"

function* loadSelectedKundenTermine({payload}) {
    try {
        const token = yield* getArtistToken()
        if (token) {
            const selectedKundenTermine = extractDataFromResponse(yield call(getKundenTermine, {token, kundId: payload.selectedKundID}))
            yield put(loadSelectedKundenTermineSuccessAction(selectedKundenTermine))
        }
    } catch (e) {
        console.log(e)
        yield put(loadSelectedKundenTermineFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.LOAD_DATA_FAILED_INFO'), 'loadSelectedKundenTermine')))
    }
}

export function* watchLoadSelectedKundenTermine() {
    yield takeLatest(LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE, loadSelectedKundenTermine)
}