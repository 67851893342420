import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    clearArtistAuthFailure,
    initiateArtistLoginReset,
    initiateLogoutStudio,
    loginArtistAction
} from "../../store/auth/authActions";
import {
    selectArtistLoginResetResult,
    selectArtistLoginResetResultError,
    selectArtistToken,
    selectIsArtistLoginFailed
} from "../../store/auth/authSelector";
import {useTranslation} from "react-i18next";
import Login from "./Login";
import {i18_TRANSLATION} from "../../i18nReferences";

const LoginArtistContainer = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation([i18_TRANSLATION])
    const isArtistLoginFailed = useSelector(state => selectIsArtistLoginFailed(state))
    const istArtistLoggedIn = useSelector(state => selectArtistToken(state))
    const loginHandler = data => dispatch(loginArtistAction(data))
    const artistLoginResetResult = useSelector(state => selectArtistLoginResetResult(state))
    const artistLoginResetResultError = useSelector(state => selectArtistLoginResetResultError(state))

    const handleLogoutStudio = () => {
        dispatch(initiateLogoutStudio())
    }

    useEffect(() => {
        dispatch(clearArtistAuthFailure())
    }, [dispatch])

    const handleReset = (kuerzel) => dispatch(initiateArtistLoginReset(kuerzel))

    return (
        <Login t={t}
               onSubmit={loginHandler}
               onLogoutStudio={handleLogoutStudio}
               isLoginFailed={isArtistLoginFailed}
               istLoggedIn={!!istArtistLoggedIn}
               successText={t('LOGIN_ARTIST.SUCCESS_TEXT')}
               loginFailedText={t('LOGIN_ARTIST.FAILED_TEXT')}
               namespace={'Artist'}
               nameText={t('LOGIN.ARTIST_KUERZEL')}
               onClickReset={handleReset}
               loginResetResult={artistLoginResetResult}
               loginResetError={artistLoginResetResultError}
        />
    );
};

export default LoginArtistContainer;