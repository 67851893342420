import React from 'react';
import {Snackbar} from "@mui/material";
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SessionNotification = ({open, onClose, message, severity}) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway' || !open) {
            return;
        }

        onClose()
    };

    return (
        <React.Fragment>
            <Snackbar open={open} autoHideDuration={severity !== 'success' ? 5000 : 2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default SessionNotification