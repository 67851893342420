import {DOWNLOAD_VOUCHER, downloadVoucherSuccessAction} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {selectPdfVorlagenMap} from "../commonSelector";
import {getVoucherBlob} from "../../../api/voucherApi";
import {FILLED_VORLAGE_PDF} from "../../../models/downloads";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processDownloadVoucher({payload}) {
    try {
        const token = yield getArtistToken()
        const {vouchNo, pdfVorlagenId} = payload.vorlagenConfig
        if (token) {
            const pdfVorlagenMap = yield select(state => selectPdfVorlagenMap(state))
            const bezeichnung = pdfVorlagenMap[pdfVorlagenId].Bezeichnung.replaceAll(" ", '_')
            const fileName = `${bezeichnung}-${vouchNo}.pdf`
            const response = yield call(getVoucherBlob, {
                token,
                ...payload.vorlagenConfig
            })
            const pdf = {blob: response.data, type: FILLED_VORLAGE_PDF, fileName}
            yield put(downloadVoucherSuccessAction(pdf))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchDownloadVoucher() {
    yield takeLatest(DOWNLOAD_VOUCHER, processDownloadVoucher)
}