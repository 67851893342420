import axios from "axios";
import {BACKEND} from "../config/backend";
import {createAuthHeader} from "./commonApi";

export const getMandantenJobs = ({token, parameters}) => {
    return axios.get(`${BACKEND}/api/v1/mandantenjobs`, {
        ...createAuthHeader(token)
        , params: parameters
    })
}

export const getMandantenJobLogsByJobId = ({token, jobId}) => {
    return axios.get(`${BACKEND}/api/v1/mandantenjobs/${jobId}/logs`, {
        ...createAuthHeader(token)
    })
}

export const deleteMandantenJobs = ({token, parameters}) => {
    return axios.delete(`${BACKEND}/api/v1/mandantenjobs`, {
        ...createAuthHeader(token)
        , params: parameters
    })
}