const STUDIO_TOKEN_NAME = 'studioToken'
const ARTIST_TOKEN_NAME = 'artistToken'
const STUDIO_REFRESH_TOKEN_NAME = 'studioRT'
const ARTIST_REFRESH_TOKEN_NAME = 'artistRT'

export const saveStudioToken = ({studioToken}) => {
    if (studioToken) {
        localStorage.setItem(STUDIO_TOKEN_NAME, studioToken)
    }
}

export const saveStudioTokens = ({studioToken, studioRefreshToken}) => {
    saveStudioToken({studioToken})
    if (studioRefreshToken) {
        localStorage.setItem(STUDIO_REFRESH_TOKEN_NAME, studioRefreshToken)
    }
}

export const saveArtistToken = ({artistToken}) => {
    if (artistToken) {
        localStorage.setItem(ARTIST_TOKEN_NAME, artistToken)
    }
}

export const saveArtistTokens = ({artistToken, artistRefreshToken}) => {
    saveArtistToken({artistToken})
    if (artistRefreshToken) {
        localStorage.setItem(ARTIST_REFRESH_TOKEN_NAME, artistRefreshToken)
    }
}

export const loadStudioTokens = () => {
    return {studioToken: localStorage.getItem(STUDIO_TOKEN_NAME),
        studioRefreshToken: localStorage.getItem(STUDIO_REFRESH_TOKEN_NAME)
    }
}

export const loadArtistTokens = () => {
    return {artistToken: localStorage.getItem(ARTIST_TOKEN_NAME),
        artistRefreshToken: localStorage.getItem(ARTIST_REFRESH_TOKEN_NAME)
    }
}

export const removeStudioTokens = (removeRefresh) => {
    console.log(`removeStudioTokens refresh=${removeRefresh}`)
    removeArtistTokens(true)
    localStorage.removeItem(STUDIO_TOKEN_NAME)
    if (removeRefresh) {
        localStorage.removeItem(STUDIO_REFRESH_TOKEN_NAME)
    }
}

export const removeArtistTokens = (removeRefresh) => {
    console.log(`removeArtistTokens refresh=${removeRefresh}`)
    localStorage.removeItem(ARTIST_TOKEN_NAME)
    if (removeRefresh) {
        localStorage.removeItem(ARTIST_REFRESH_TOKEN_NAME)
    }
}