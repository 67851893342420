import {getArtistToken} from "../../../auth/token.saga";
import {selectParameterForDblExclusionList} from "../../listSelector";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {DELETE_DBLEXCLUSION, loadDblExclusionListDataAction} from "../../listActions";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {deleteDblExclusion} from "../../../../api/kundenApi";
import {i18_LIST} from "../../../../i18nReferences";

function* processDeleteDblExclusion({payload}) {
    try {
        const token = yield getArtistToken()
        const kdExDblId = payload.kdExDblId
        if (token) {
            const selectionParameters = yield select(state => selectParameterForDblExclusionList(state))
            const {affectedRows} = extractDataFromResponse(yield call(deleteDblExclusion, {token, kdExDblId}))
            if (affectedRows) {
                yield put(loadDblExclusionListDataAction(selectionParameters))
            }
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.DELETE_DBLEXCLUSION_DATA_FAILED_INFO'
            , {ns: i18_LIST}), 'deleteDblExclusions')))
        console.log(err)
    }
}

export function* watchDeleteDblExclusion() {
    yield takeLatest(DELETE_DBLEXCLUSION, processDeleteDblExclusion)
}