import { i18_LIST, i18_TRANSLATION } from "../i18nReferences";

export const getJobsListColumns = (artistMap, t) => [
    { field: "JobID", type: "number", headerName: "JobID" },
    {
        field: "Type",
        headerName: t("JOBS_LIST.JOB_TYPE_HEADER", { ns: i18_LIST }),
        valueGetter: ({ value }) => value && t("JOBS_LIST.JOB_TYPE_" + value, { ns: i18_LIST }),
        minWidth: 150,
    },
    {
        field: "_createdTS",
        type: "dateTime",
        headerName: t("JOBS_LIST.CREATED_AT", { ns: i18_LIST }),
        valueGetter: ({ value }) => value && new Date(value),
        minWidth: 150,
    },
    {
        field: "_createdID",
        headerName: "Artist",
        valueGetter: ({ value }) => artistMap[value] && artistMap[value].Kuerzel,
        minWidth: 150,
    },
    {
        field: "Status",
        type: "boolean",
        headerName: t("JOBS_LIST.IS_FINISHED_HEADER", { ns: i18_LIST }),
    },
    {
        field: "FinishedAt",
        type: "dateTime",
        headerName: t("JOBS_LIST.FINISHED_AT_LIST_HEADER", { ns: i18_LIST }),
        valueGetter: ({ value }) => value && new Date(value),
        minWidth: 150,
    },
    {
        field: "detailText",
        headerName: t("JOBS_LIST.BROADCAST_DETAIL_INFO_TEXT_HEADER", { ns: i18_LIST }),
        minWidth: 300,
    },
    { field: "success", type: "number", headerName: t("JOBS_LIST.SUCCESSFUL_HEADER", { ns: i18_LIST }) },
    { field: "error", type: "number", headerName: t("JOBS_LIST.FAILED_HEADER", { ns: i18_LIST }) },
    { field: "isComplete", type: "boolean", headerName: t("JOBS_LIST.IS_COMPLETE_HEADER", { ns: i18_LIST }) },
];

export const JOBTYPE_BROADCAST_KUNDEN = "BROADCAST_KUNDEN";
export const BROADCAST_PROJEKT_KUNDEN = "BROADCAST_PROJEKT_KUNDEN";
export const BROADCAST_TERMIN_KUNDEN = "BROADCAST_TERMIN_KUNDEN";
export const AUTO_TERMIN_CLOSE_4_ARTIST = "AUTO_TERMIN_CLOSE_4_ARTIST";
export const JOB_TYPE_GOOGLE_SYNC = "GOOGLE_SYNC";

export const JOBTYPE_BROADCAST_LIST = [JOBTYPE_BROADCAST_KUNDEN, BROADCAST_PROJEKT_KUNDEN, BROADCAST_TERMIN_KUNDEN];

export const getJobCountFromParsedMetaByJobType = (jobType, parsedMeta) => {
    switch (jobType) {
        case JOBTYPE_BROADCAST_KUNDEN:
            return parsedMeta.kundIdList?.length || 0;
        case BROADCAST_PROJEKT_KUNDEN:
            return parsedMeta.projektList?.length || 0;
        case BROADCAST_TERMIN_KUNDEN:
            return parsedMeta.terminList?.length || 0;
        case JOB_TYPE_GOOGLE_SYNC:
            return parsedMeta.artistIds?.length || 0;
    }
};

const enrichWithDetailsJobTypeBroadcast = ({ job, t, parsedMeta, artistMap }) => {
    const artistId = parsedMeta?.artistId || 0;
    const jobCount = getJobCountFromParsedMetaByJobType(job.Type, parsedMeta);
    job.detailText = t("JOBS_LIST.BROADCAST_DETAIL_INFO_TEXT", {
        ns: i18_LIST,
        Kuerzel: artistMap[artistId]?.Kuerzel,
        Betreff: parsedMeta.betreff,
        count: jobCount,
    });
    job.isComplete = jobCount === job.success;
};

const enrichWithDetailsJobTypeAutoTerminClose = ({ job, t, parsedMeta, artistMap }) => {
    const kuerzelList = parsedMeta?.artists?.length
        ? parsedMeta?.artists
              .filter((a) => artistMap[a].Kuerzel != null)
              .map((a) => artistMap[a].Kuerzel)
              .join(", ")
        : "";
    job.detailText = t("JOBS_LIST.AUTO_TERMIN_CLOSE_INFO_TEXT", {
        ns: i18_LIST,
        kuerzelList,
    });
    job.success = job.FinishedAt != null ? parsedMeta?.artists?.length || 0 : 0;
    job.error = 0;
    job.isComplete = job.FinishedAt != null;
};

const MAX_ARTISTS_PER_JOB = 5
const enrichWithDetailsJobTypeGglSync = ({ job, t, parsedMeta, artistMap }) => {
    const kuerzelList = parsedMeta?.artistIds?.length
        ? parsedMeta?.artistIds.filter((a) => artistMap[a].Kuerzel != null).map((a) => artistMap[a].Kuerzel)
        : []
    let kuerzelText = kuerzelList.slice(0, MAX_ARTISTS_PER_JOB).join(", ")
    if (kuerzelList.length > MAX_ARTISTS_PER_JOB) {
        kuerzelText += t("PLUS_X_MORE", {ns: i18_TRANSLATION, count: kuerzelList.length - MAX_ARTISTS_PER_JOB})
    }
    job.detailText = t("JOBS_LIST.GGL_SYNC_INFO_TEXT", {
        ns: i18_LIST,
        kuerzelList: kuerzelText,
    });
    job.success = job.FinishedAt != null ? kuerzelList.length : 0;
    job.error = 0;
    job.isComplete = job.FinishedAt != null;
};
export const extractMetaDataFromJob = (job, t, artistMap) => {
    try {
        if (job?.Meta) {
            const parsedMeta = JSON.parse(job.Meta);
            if (JOBTYPE_BROADCAST_LIST.includes(job.Type)) {
                enrichWithDetailsJobTypeBroadcast({ job, t, artistMap, parsedMeta });
            }
            if (job.Type === AUTO_TERMIN_CLOSE_4_ARTIST) {
                enrichWithDetailsJobTypeAutoTerminClose({ job, t, artistMap, parsedMeta });
            }
            if (job.Type === JOB_TYPE_GOOGLE_SYNC) {
                enrichWithDetailsJobTypeGglSync({ job, t, artistMap, parsedMeta });
            }
        }
        return job;
    } catch (err) {
        console.log(err);
        return job;
    }
};
