// eslint-disable-next-line no-unused-vars
import React from 'react';
import {FormControl, styled} from "@mui/material";


const WarningFormControl = styled(FormControl, {
    shouldForwardProp: (prop) => prop !== 'isWarning',
})(({ isWarning, theme }) => ({

    ...(isWarning && {
        "& .MuiFormLabel-root.Mui-error": {
            color: theme.palette.warning.main
        },
        "& .MuiInput-underline.Mui-error:after": {
            borderBottomColor: theme.palette.warning.main
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.warning.main
        }
    }),
}));


export default WarningFormControl;