import React from 'react';
import {FormHelperText, InputLabel, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import WarningFormControl from "./WarningFormControl";
import {wrapOnFieldChanged} from "../../store/utils/wrapper";
import {getArtistTatorte} from "../../models/artist";
import {renderOptionGroup} from "./ArtistOptionGroup";


const ArtistSelection = ({
                             control,
                             sx,
                             controllerName,
                             fullWidth,
                             artistsMap,
                             tatortMap,
                             studioTatOrtID,
                             selectedArtistID,
                             onChange,
                             disabled,
                             error,
                             isWarning,
                             helperText,
                             label
                         }) => {


    if (!artistsMap || !tatortMap || !onChange && !control) {
        return null
    }
    if (!control) {
        return (
            <React.Fragment>
                <Select
                    disabled={disabled}
                    fullWidth={fullWidth}
                    value={selectedArtistID || ''}
                    sx={sx}
                    onChange={e => onChange(e.target.value)}
                    variant="outlined"
                    inputProps={{
                        name: 'artist',
                        id: 'artist-native'
                    }}
                >
                    {getArtistTatorte(artistsMap, tatortMap, studioTatOrtID).map(id => renderOptionGroup({
                        artistsMap,
                        tatortMap,
                        tatortID: id,
                        ausgeschiedene: false,
                        key: id
                    }))}
                    {renderOptionGroup({
                        artistsMap,
                        tatortMap,
                        tatortID: 0,
                        ausgeschiedene: true,
                        key: 'artist-ausgeschiedene'
                    })}
                </Select>
            </React.Fragment>
        )
    }

    return (

        <React.Fragment>
            <Controller name={controllerName}
                        control={control}
                        error={error}
                        defaultValue=''
                        render={({field}) =>
                            <WarningFormControl variant="standard" fullWidth={fullWidth} error={error}
                                                isWarning={isWarning} sx={sx}>
                                <InputLabel id="artist-label">{label || 'Artist'}</InputLabel>
                                <Select {...field}
                                        disabled={disabled}
                                        onChange={wrapOnFieldChanged(field.onChange, onChange)}
                                        labelId="artist-label"
                                >
                                    {getArtistTatorte(artistsMap, tatortMap).map(id => renderOptionGroup({
                                        tatortMap,
                                        artistsMap,
                                        tatortID: id,
                                        ausgeschiedene: false,
                                        key: id
                                    }))}
                                    {renderOptionGroup({
                                        tatortMap,
                                        artistsMap,
                                        tatortID: 0,
                                        ausgeschiedene: true,
                                        key: 'artist-ausgeschiedene'
                                    })}
                                </Select>
                                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                            </WarningFormControl>
                        }
            />
        </React.Fragment>
    )

}

export default ArtistSelection