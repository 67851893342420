import {selectMsgVorlagenMap} from "../../common/commonSelector";
import {getArtistToken} from "../../auth/token.saga";
import {reloadCommonMsgVorlagenAction} from "../../common/commonActions";
import i18next from "i18next";
import {i18_TEMPLATES} from "../../../i18nReferences";
import {call, put, select, takeLatest} from "redux-saga/effects"
import {insertMessageVorlage} from "../../../api/commonApi";
import {COPY_MSG_VORLAGE} from "../templatesActions";
import {emitChangedNotification} from "../../notification/notificationSaga";
import { INFO_SAVED_SUCCESSFULLY, SAVING_FAILED } from "../../../models/notification";

function* processCopyMsgVorlage({payload}) {
    const {msgVorlageId} = payload
    const msgVorlagenMap = yield select(state => selectMsgVorlagenMap(state))
    const bezeichnung = msgVorlagenMap[msgVorlageId].Bezeichnung + ' (copy)'
    const category = i18next.t('VORLAGEN_DIALOG.MESSAGE_MENU_ITEM_TEXT', {ns: i18_TEMPLATES})

    try {
        const token = yield getArtistToken()
        if (token) {
            const base = msgVorlagenMap[msgVorlageId]
            const msgVorlage = {...base, Bezeichnung: bezeichnung, MsgVorlageID: undefined}
            yield call(insertMessageVorlage, {token, msgVorlage})
            yield put(reloadCommonMsgVorlagenAction())
            yield* emitChangedNotification({
                type: INFO_SAVED_SUCCESSFULLY,
                data: bezeichnung,
                category
            })
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data: bezeichnung,
            category
        })
        console.log(err)
    }
}

export function* watchCopyMsgVorlage() {
    yield takeLatest(COPY_MSG_VORLAGE, processCopyMsgVorlage)
}