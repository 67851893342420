import {getArtistToken} from "../../auth/token.saga";
import {updateAutoMsgSettings} from "../../../api/commonApi";
import {reloadCommonMsgVorlagenAction} from "../../common/commonActions";
import {call, put, takeLatest} from "redux-saga/effects"
import {SAVE_AUTO_MSG_SETTINGS} from "../templatesActions";
import i18next from "i18next";
import {emitChangedNotification} from "../../notification/notificationSaga";
import {getAutoMsgTranslation} from "../../../components/templates/helper";
import {getTranslatedMsgVorlagenAtomationListTextMap} from "../../../models/vorlagen";
import { INFO_SAVED_SUCCESSFULLY, SAVING_FAILED } from "../../../models/notification";

function* processSaveAutoMsgSettings({payload}) {
    const autoMsgSettings = payload.autoMsgSettings
    const category = getAutoMsgTranslation(i18next.t, 'HEADER')
    const translationMap = getTranslatedMsgVorlagenAtomationListTextMap(i18next.t)
    const data = translationMap[autoMsgSettings.MsgTyp]
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(updateAutoMsgSettings, {token, autoMsgSettings})
            yield put(reloadCommonMsgVorlagenAction())
            yield* emitChangedNotification({
                type: INFO_SAVED_SUCCESSFULLY,
                data,
                category
            })
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

export function* watchSaveAutoMsgSettings() {
    yield takeLatest(SAVE_AUTO_MSG_SETTINGS, processSaveAutoMsgSettings)
}