import {put, takeLatest} from "redux-saga/effects";
import {loadAllReportDefinitionsAction, SAVE_LIST_DEFINITION} from "../../listActions";
import {writeReportDefinition} from "./writeReportDefinition";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processSaveListDefinition({payload}) {
    try {
        yield writeReportDefinition({payload})
        yield put(loadAllReportDefinitionsAction())
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.ERROR.SAVE_DATA_FAILED_INFO', {ns: i18_LIST}), 'saveReportDefinition')))
        console.log(err)
    }
}

export function* watchSaveListDefinition() {
    yield takeLatest(SAVE_LIST_DEFINITION, processSaveListDefinition)
}