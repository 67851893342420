import * as yup from "yup";
import { keyBy } from "../store/utils/lodash-replacement";

export const removeExpiredSecrets = (pad) => {
    const showSecret = new Date(pad.SecretValid).getTime() >= new Date().getTime()
    return {
        ...pad,
        id: pad.PadID,
        Secret: showSecret ? pad.Secret : undefined,
        SecretValid: showSecret ? pad.SecretValid : undefined
    }
}

export const IPAD_PRO = {Type: 'IPad Pro', Ppi: 264, id: 'IPAD_PRO'};
export const IPAD = {Type: 'IPad', Ppi: 264, id: 'IPAD'};
export const IPAD_AIR = {Type: 'IPad Air', Ppi: 264, id: 'IPAD_AIR'};
export const GALAXY_L = {Type: 'Samsung S6 Lite', Ppi: 225, id: 'GALAXY_L'};
export const WACOM_CINTIC_COMP = {Type: 'Wacom Cintiq Comp', Ppi: 190, id: 'WACOM_CINTIC_COMP'};

export const PAD_TYPES = [
    IPAD_PRO, IPAD,IPAD_AIR,GALAXY_L,WACOM_CINTIC_COMP
]

export const PAD_TYPE_MAP = keyBy(PAD_TYPES, 'id')

export const createPadSchema = () => {
    return yup.object().shape({
        Type: yup.string().required(),
        Name: yup.string().required().max(100).when(['$allPadNames'], ([allPadNames], schema) => schema.notOneOf(allPadNames, {key: 'YUP.ERROR.PAD_NAME_IN_USE'})),
    })
}





