import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";

export const selectKundeState = createSelector(
    (state) => state.kunde
    , identityFunction
)

export const selectKundeData = createFieldSelector(selectKundeState, 'kundeData')
export const selectPageOfKundeData = createFieldSelector(selectKundeState, 'page', 'KUNDE')

export const selectKundenDoubletten = createFieldSelector(selectKundeState, 'doubletten', [])
export const selectIsKundeDoublettenLoaded = createBooleanFieldSelector(selectKundeState, 'doublettenParams')
export const selectIsKundeDataLoading = createBooleanFieldSelector(selectKundeState, 'terminDataLoading')
export const selectKunde = createFieldSelector(selectKundeData, 'kunde')
export const selectKundenBerufe = createFieldSelector(selectKundeData, 'berufe')
export const selectKundenProjekte = createFieldSelector(selectKundeData, 'projekte')
export const selectKundenAnzahlungen = createFieldSelector(selectKundeData, 'anzahlungen')
export const selectKundenNachrichten = createFieldSelector(selectKundeData, 'nachrichten')
export const selectKundenFiles = createFieldSelector(selectKundeData, 'files')
export const selectKundenUnfinishedFiles = createFieldSelector(selectKundeData, 'unfinishedFiles')
export const selectKundenTermine = createFieldSelector(selectKundeData, 'termine')
export const selectIsKundeDeletable = createSelector(
    selectKundenProjekte
    , selectKundenUnfinishedFiles
    , selectKundenTermine
    , (projekte, unfinishedFiles, termine) => !projekte?.length && !unfinishedFiles?.length && !termine?.length && !unfinishedFiles?.length
)
export const selectIsProjektMergeOngoing = createBooleanFieldSelector(selectKundeState, 'mergingProjectsOngoing')
export const selectIsProjektDeleteOngoing = createBooleanFieldSelector(selectKundeState, 'deleteProjectOngoing')
export const selectIsBatchProcessing = createBooleanFieldSelector(selectKundeState, 'batchInProgress')
export const selectImportResult = createFieldSelector(selectKundeState, 'importResult')