import React, {useEffect} from 'react';
import DialogWithMenuContainer from "../../common/menu/DialogWithMenuContainer";
import useCommonState from "../../../hooks/UseCommonState";
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@mui/material";
import NeuKundeDoublettenCheck from "./NeuKundeDoublettenCheck";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addNeukunde, clearKundenDoublettenAction, searchKundenDoubletten} from "../../../store/kunde/kundeAction";
import {selectIsKundeDoublettenLoaded, selectKundenDoubletten} from "../../../store/kunde/kundeSelector";
import {unpackLocation, wrapUrlComponents} from "../../../store/utils/urlHelper";
import {createKundeFromSchema} from "../../../models/kunde";
import {i18_KUNDE, i18_TRANSLATION} from "../../../i18nReferences";

const NeuKundeDoublettenCheckContainer = () => {
    const [search] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const commonState = useCommonState()
    const {t} = useTranslation([i18_KUNDE, i18_TRANSLATION])
    const doubletten = useSelector(state => selectKundenDoubletten(state))
    const isDoublettenLoaded = useSelector(state => selectIsKundeDoublettenLoaded(state))

    useEffect(() => {
        return () => dispatch(clearKundenDoublettenAction())
    }, [dispatch])

    const handleCancel = () => {
        const back = search.get('back')
        if (back) {
            navigate(unpackLocation(back))
        } else {
            navigate('/')
        }
    }

    const handleAddKunde = (formData) => {
        const back = search.get('back')
        const onlyBase = search.get('onlyBase')
        dispatch(addNeukunde({
            kunde: createKundeFromSchema(formData, commonState.loggedInArtistId, commonState.timezone),
            search: {back, onlyBase}
        }))
    }

    const handleSelectKunde = ({kundId, email, mobil}) => {
        const back = search.get('back')
        const onlyBase = search.get('onlyBase')
        let suffix = wrapUrlComponents({email, mobil, onlyBase})
        if (back) {
            navigate({
                pathname: `/kunde/${kundId}`
                , search: `?back=${encodeURIComponent(back)}${suffix}`
            })
        } else {
            navigate(`/kunde/${kundId}`)
        }
    }

    const handleSearchDoubletten = (data) => {
        dispatch(searchKundenDoubletten({...data, Mobil: data.Mobil?.length > 2 ? data.Mobil : undefined}))
    }

    return (
        <DialogWithMenuContainer maxWidth='sm' t={t} onExit={handleCancel}>
            {!commonState?.isStateLoaded ? <CircularProgress disableShrink/> : null}
            {commonState?.isStateLoaded ?
                <NeuKundeDoublettenCheck t={t} commonState={commonState} onAddKunde={handleAddKunde}
                                         onCancel={handleCancel} studioTatOrtID={commonState.studioTatOrtID}
                                         doubletten={doubletten} isDoublettenLoaded={isDoublettenLoaded}
                                         onSelectKunde={handleSelectKunde}
                                         onSearchDoubletten={handleSearchDoubletten}/> : null}
        </DialogWithMenuContainer>

    )
}

export default NeuKundeDoublettenCheckContainer;