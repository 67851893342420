import {ADD_NEUKUNDE} from "../kundeAction";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {insertKunde} from "../../../api/kundenApi";
import {wrapUrlComponents} from "../../utils/urlHelper";
import {pushHistory} from "../../router/routerActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* processAddNeukunde({payload}) {
    const {kunde, search} = payload.kundeAndSearchParameters
    try {
        const token = yield getArtistToken()
        if (token) {
            const result = extractDataFromResponse(yield call(insertKunde, {token, kunde}))
            const searchUrlComponent = wrapUrlComponents(search)
            const path = `/kunde/${result.insertId}?${searchUrlComponent}`
            yield put(pushHistory(path))
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_SUCCESS_INFO', {
                ns: i18_KUNDE,
                name: payload.kundeAndSearchParameters.kunde.Name
            }), 'processAddNeukunde')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_FAILED_INFO', {
            ns: i18_KUNDE,
            name: payload.kundeAndSearchParameters.kunde.Name
        }), 'processAddNeukunde')))
        console.log(err)
    }
}

export function* watchAddNeukunde() {
    yield takeLatest(ADD_NEUKUNDE, processAddNeukunde)
}