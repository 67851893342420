import {
    LOAD_CALENDAR_TERMIN_TOASTER_DATA, LOAD_CALENDAR_TERMIN_TOASTER_IMAGES,
    loadCalendarTerminToasterDataFailureAction,
    loadCalendarTerminToasterDataSuccessAction,
    loadCalendarTerminToasterImagesAction,
    loadCalendarTerminToasterImagesFailureAction, loadCalendarTerminToasterImagesSuccessAction
} from "../calendarActions";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {call, put, select, takeLatest} from "redux-saga/effects"
import {selectSelectedTerminIDCalendar} from "../calendarSelector";
import {getCalendarTerminToasterData, getCalendarTerminToasterimages} from "../../../api/artistApi";

function* loadCalendarToasterData() {
    try {
        const token = yield* getArtistToken()
        if (token) {
            const terminId = yield select(state => selectSelectedTerminIDCalendar(state))
            const toasterData = extractDataFromResponse(yield call(getCalendarTerminToasterData, {token, terminId}))
            if (toasterData.projekt) {
                yield put(loadCalendarTerminToasterImagesAction(toasterData.projekt.ProjektID))
            }
            yield put(loadCalendarTerminToasterDataSuccessAction(toasterData))
            if (toasterData.errors?.find(e => e.projekt?.type === 'imageList')) {
                yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_IMAGES_FAILED_INFO'), 'loadCalendarToasterData-images')))
            }
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.LOAD_DATA_FAILED_INFO'), 'loadCalendarToasterData')))
        yield put(loadCalendarTerminToasterDataFailureAction())
    }
}

function* loadCalendarToasterImages({payload}) {
    try {
        const token = yield* getArtistToken()
        if (token) {
            const {projektId} = payload
            const toasterData = extractDataFromResponse(yield call(getCalendarTerminToasterimages, {token, projektId}))
            yield put(loadCalendarTerminToasterImagesSuccessAction(toasterData))
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_IMAGES_FAILED_INFO'), 'loadCalendarToasterData-images')))
        yield put(loadCalendarTerminToasterImagesFailureAction())
    }
}

export function* watchLoadCalendarToasterData() {
    yield takeLatest(LOAD_CALENDAR_TERMIN_TOASTER_DATA, loadCalendarToasterData)
}

export function* watchLoadCalendarToasterImages() {
    yield takeLatest(LOAD_CALENDAR_TERMIN_TOASTER_IMAGES, loadCalendarToasterImages)
}
