export const transformNoNumberToUndefined = value => (isNaN(value) ? undefined : value)
export const transformNoNumberToZero = value => (isNaN(value) ? 0 : value)
export const transformNoNumberToNull = value => (isNaN(value) ? null : value)


export function mergeYupSchemas(...schemas) {
    const [first, ...rest] = schemas;

    return rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );
}