import {call, put, select, takeLatest} from "redux-saga/effects";
import {refreshLoadCalendarAction} from "../../calendar/calendarActions";
import {getArtistToken} from "../../auth/token.saga";
import {deleteTermin} from "../../../api/terminApi";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import {emitNotificationAction} from "../../notification/notificationAction";
import i18next from "i18next";
import {selectCalendarEventZeitraum} from "../../calendar/calendarSelector";
import {clearTerminDataAction, DELETE_TERMIN} from "../terminActions";

function* processDeleteTermin({payload}) {
    try {
        const token = yield* getArtistToken()
        if (token) {
            yield call(deleteTermin, {token, terminId: payload.terminId})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('TERMIN.DELETE_TERMIN_SUCCESS_INFO'), 'processDeleteTermin')))
            const selectedZeitraum = yield select(state => selectCalendarEventZeitraum(state))
            if (selectedZeitraum) {
                yield put(refreshLoadCalendarAction(selectedZeitraum))
            }
            yield put(clearTerminDataAction())
        }
    } catch (err) {
        if (err.response?.status === 404) {
            yield put(emitNotificationAction(createInfoNotification(i18next.t('TERMIN.DELETE_TERMIN_SUCCESS_INFO'), 'processDeleteTermin')))
        } else {
            yield put(emitNotificationAction(createErrorNotification(i18next.t('TERMIN.DELETE_DATA_FAILED_INFO'), 'processDeleteTermin')))
            console.log(err)
        }
    }
}

export function* watchDeleteTermin() {
    yield takeLatest(DELETE_TERMIN, processDeleteTermin)
}