import {useSelector} from "react-redux";
import {
    selectArtistMap,
    selectIsCommonStateLoading,
    selectStudioGutscheinverkauf,
    selectStudioTatOrtID,
    selectStudioTatortMap,
    selectStudioTimezone,
    selectStudioWaehrung,
    selectTermintypMap
} from "../store/common/commonSelector";
import {
    selectHasKissSign,
    selectLocale,
    selectLoggedInArtist,
    selectLoggedInArtistID, selectLoggedInCannotEdit,
    selectLoggedInIsAdmin,
    selectLoggedInRechte, selectSchreibRechteArtistIDList
} from "../store/auth/authSelector";
import {deDE, enUS} from "@mui/x-data-grid-pro";

export const dataGridTranslationMap = {
    de: deDE,
    en: enUS
}

const useCommonState = () => {
    const timezone = useSelector(state => selectStudioTimezone(state))
    const gutscheinverkauf = useSelector(state => selectStudioGutscheinverkauf(state))
    const isStateLoaded = !useSelector(state => selectIsCommonStateLoading(state))
    const termintypMap = useSelector(state => selectTermintypMap(state))
    const locale = useSelector(state => selectLocale(state))
    const hasKissSign = useSelector(state => selectHasKissSign(state))
    const waehrung = useSelector(state => selectStudioWaehrung(state))
    const artistMap = useSelector(state => selectArtistMap(state))
    const tatortMap = useSelector(state => selectStudioTatortMap(state))
    const studioTatOrtID = useSelector(state => selectStudioTatOrtID(state))
    const artistIdListWrite = useSelector(state => selectSchreibRechteArtistIDList(state))

    const isAdmin = useSelector(state => selectLoggedInIsAdmin(state))
    const cannotEdit = useSelector(state => selectLoggedInCannotEdit(state))
    const loggedInRechte = useSelector(state => selectLoggedInRechte(state))
    const loggedInArtistId = useSelector(state => selectLoggedInArtistID(state))
    const loggedInArtist = useSelector(state => selectLoggedInArtist(state))

    const dataGridTranslations = dataGridTranslationMap[locale].components.MuiDataGrid.defaultProps.localeText

    return {timezone, gutscheinverkauf, isStateLoaded, locale, waehrung, hasKissSign, artistMap, tatortMap,
        studioTatOrtID, isAdmin, loggedInRechte, loggedInArtistId, loggedInArtist, cannotEdit,
        termintypMap, dataGridTranslations, artistIdListWrite}
}

export default useCommonState