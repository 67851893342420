import axios from "axios";
import {createAuthHeader} from "./commonApi";
import {BACKEND} from "../config/backend";


export const getAllArtists = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/artists`, {
        ...createAuthHeader(token)
    })
}

export const getCalendarData = ({token, zeitraum: {start, end}, artistIds}) => {
    return axios.get(`${BACKEND}/api/v1/artists/calendar`, {
        ...createAuthHeader(token)
        , params: {start, end, artistids: artistIds}
    })
}

export const getTerminslots = ({token, options}) => {
    return axios.get(`${BACKEND}/api/v1/artists/terminslots`, {
        ...createAuthHeader(token)
        , params: options
    })
}

export const getCalendarTerminToasterData = ({token, terminId}) => {
    return axios.get(`${BACKEND}/api/v1/artists/calendar/termintoaster/${terminId}`, {
        ...createAuthHeader(token)
    })
}

export const getCalendarTerminToasterimages = ({token, projektId}) => {
    return axios.get(`${BACKEND}/api/v1/artists/calendar/termintoasterimages/${projektId}`, {
        ...createAuthHeader(token)
    })
}

export const updateTermin = ({token, artistId, termin}) => {
    return axios.put(`${BACKEND}/api/v1/artists/${artistId}/termine`, termin, {
        ...createAuthHeader(token)
    })
}

export const updateTerminParticipant = ({token, artistId, terminParticipantChange}) => {
    return axios.put(`${BACKEND}/api/v1/artists/${artistId}/termine/participant`, terminParticipantChange, {
        ...createAuthHeader(token)
    })
}

export const deleteCalendarTerminToaster = ({token, terminId}) => {
    return axios.delete(`${BACKEND}/api/v1/artists/calendar/termintoaster/${terminId}`, {
        ...createAuthHeader(token)
    })
}

export const insertArtistLocation = ({token, artistLocation}) => {
    return axios.post(`${BACKEND}/api/v1/artists/${artistLocation.ArtistID}/locations`, artistLocation, {
        ...createAuthHeader(token)
    })
}

export const deleteArtistLocation = ({token, artistLocation}) => {
    return axios.delete(`${BACKEND}/api/v1/artists/${artistLocation.ArtistID}/locations/${artistLocation.ArtAtOrtID}`, {
        ...createAuthHeader(token)
    })
}

export const updateArtistLocation = ({token, artistLocation}) => {
    return axios.put(`${BACKEND}/api/v1/artists/${artistLocation.ArtistID}/locations`, artistLocation, {
        ...createAuthHeader(token)
    })
}

export const updateArtistWorkTime = ({token, artistWorkTime}) => {
    return axios.put(`${BACKEND}/api/v1/artists/${artistWorkTime.ArtistID}/worktime`, artistWorkTime, {
        ...createAuthHeader(token)
    })
}

export const insertArtistWorkTime = ({token, artistWorkTime}) => {
    return axios.post(`${BACKEND}/api/v1/artists/${artistWorkTime.ArtistID}/worktime`, artistWorkTime, {
        ...createAuthHeader(token)
    })
}

export const deleteArtistWorkTime = ({token, artistWorkTime}) => {
    return axios.delete(`${BACKEND}/api/v1/artists/${artistWorkTime.ArtistID}/worktime/${artistWorkTime.ArtWorkTmID}`, {
        ...createAuthHeader(token)
    })
}

export const getArtistHolidays = ({token, artistIdList, start, end}) => {
    return axios.get(`${BACKEND}/api/v1/artists/holidays`, {
        ...createAuthHeader(token)
        , params: {start, end, artistids: artistIdList.join(",")}
    })
}

export const updateArtist = ({token, artist}) => {
    return axios.put(`${BACKEND}/api/v1/artists`, artist, {
        ...createAuthHeader(token)
    })
}

export const deactivateArtists = ({token, artistIdList}) => {
    return axios.put(`${BACKEND}/api/v1/artists/deactivate`, {artistIdList}, {
        ...createAuthHeader(token)
    })
}

export const getArtistDetailData = ({token, artistId, segments}) => {
    return axios.get(`${BACKEND}/api/v1/artists/${artistId}`, {
        ...createAuthHeader(token)
        , params: {segments: segments}
    })
}

export const verifyArtistMailConfiguration = ({token, artistMailConfiguration}) => {
    return axios.post(`${BACKEND}/api/v1/artists/mailconfiguration/verify`, artistMailConfiguration, {
        ...createAuthHeader(token)
    })
}

export const saveArtistRechte = ({token, artistRechteData}) => {
    return axios.put(`${BACKEND}/api/v1/artists/${artistRechteData.ArtistID}/rechte`, artistRechteData, {
        ...createAuthHeader(token)
    })
}

export const initiateArtistPasswordReset = ({token, kuerzel}) => {
    return axios.post(`${BACKEND}/api/v1/artists/password/reset`, {kuerzel}, {
        ...createAuthHeader(token)
    })
}

export const copyArtist = ({token, artistId}) => {
    return axios.post(`${BACKEND}/api/v1/artists/copy`, {artistId}, {
        ...createAuthHeader(token)
    })
}

export const deleteArtist = ({token, artistId}) => {
    return axios.delete(`${BACKEND}/api/v1/artists/${artistId}`, {
        ...createAuthHeader(token)
    })
}

export const getArtistEvents = ({token, params}) => {
    return axios.get(`${BACKEND}/api/v1/artists/events`, {
        ...createAuthHeader(token),
        params
    })
}
