import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOGIN_STUDIO = 'LOGIN_STUDIO'
export const LOGIN_STUDIO_REFRESH_SUCCESS = 'LOGIN_STUDIO_REFRESH_SUCCESS'
export const LOGOUT_STUDIO = 'LOGOUT_STUDIO'
export const INITIATE_LOGOUT_STUDIO = 'INITIATE_LOGOUT_STUDIO'
export const LOGIN_STUDIO_SUCCESS = 'LOGIN_STUDIO_SUCCESS'
export const LOGIN_STUDIO_FAILURE = 'LOGIN_STUDIO_FAILURE'
export const STUDIO_TOKEN_LOADED = 'STUDIO_TOKEN_LOADED'

export const LOGIN_ARTIST = 'LOGIN_ARTIST'
export const LOGIN_ARTIST_REFRESH_SUCCESS = 'LOGIN_ARTIST_REFRESH_SUCCESS'
export const LOGOUT_ARTIST = 'LOGOUT_ARTIST'
export const INITIATE_LOGOUT_ARTIST = 'INITIATE_LOGOUT_ARTIST'
export const LOGIN_ARTIST_SUCCESS = 'LOGIN_ARTIST_SUCCESS'
export const LOGIN_ARTIST_FAILURE = 'LOGIN_ARTIST_FAILURE'
export const ARTIST_TOKEN_LOADED = 'ARTIST_TOKEN_LOADED'
export const CLEAR_ARTIST_AUTH_FAILURE = 'CLEAR_ARTIST_AUTH_FAILURE'

export const loginStudioAction = createSimpleAction(LOGIN_STUDIO, 'loginData')
export const refreshStudioTokenSuccessAction = createSimpleAction(LOGIN_STUDIO_REFRESH_SUCCESS, 'studioToken')
export const logoutStudio = createTypeAction(LOGOUT_STUDIO)
export const initiateLogoutStudio = createTypeAction(INITIATE_LOGOUT_STUDIO)
export const studioTokenLoaded = createSimpleAction(STUDIO_TOKEN_LOADED, 'studioAuth')
export const loginStudioSuccessAction = createSimpleAction(LOGIN_STUDIO_SUCCESS, 'studioAuth')
export const loginStudioFailureAction = createTypeAction(LOGIN_STUDIO_FAILURE)

export const loginArtistAction = createSimpleAction(LOGIN_ARTIST, 'loginData')
export const refreshArtistTokenSuccessAction = createSimpleAction(LOGIN_ARTIST_REFRESH_SUCCESS, 'artistToken')
export const logoutArtist = createTypeAction(LOGOUT_ARTIST)
export const initiateLogoutArtist = createTypeAction(INITIATE_LOGOUT_ARTIST)
export const artistTokenLoaded = createSimpleAction(ARTIST_TOKEN_LOADED, 'artistAuth')
export const loginArtistSuccessAction = createSimpleAction(LOGIN_ARTIST_SUCCESS, 'artistAuth')
export const loginArtistFailureAction = createTypeAction(LOGIN_ARTIST_FAILURE)
export const clearArtistAuthFailure = createTypeAction(CLEAR_ARTIST_AUTH_FAILURE)

export const INITIATE_STUDIO_LOGIN_RESET = 'INITIATE_STUDIO_LOGIN_RESET'
export const initiateStudioLoginReset = createSimpleAction(INITIATE_STUDIO_LOGIN_RESET,'studioName')

export const REGISTER_STUDIO_LOGIN_RESET_RESPONSE = 'REGISTER_STUDIO_LOGIN_RESET_RESPONSE'
export const registerStudioLoginResetResponseAction = createSimpleAction(REGISTER_STUDIO_LOGIN_RESET_RESPONSE, 'studioLoginResetResult')

export const INITIATE_ARTIST_LOGIN_RESET = 'INITIATE_ARTIST_LOGIN_RESET'
export const initiateArtistLoginReset = createSimpleAction(INITIATE_ARTIST_LOGIN_RESET,'kuerzel')

export const REGISTER_ARTIST_LOGIN_RESET_RESPONSE = 'REGISTER_ARTIST_PASSWORD_RESET_RESPONSE'
export const registerArtistLoginResetResponseAction = createSimpleAction(REGISTER_ARTIST_LOGIN_RESET_RESPONSE, 'artistLoginResetResult')
