import {selectProjektListParameters} from "../../listSelector";
import {loadProjektListDataAction} from "../../listActions";
import {put, select, takeLatest} from "redux-saga/effects";
import {UPDATE_PROJEKT_SUCCESS} from "../../../projekt/projektAction";

function* processUpdateProjektList() {
    try {
        const parameters = yield select(state => selectProjektListParameters(state))
        if (parameters) {
            yield put(loadProjektListDataAction(parameters))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchUpdateProjektSuccess() {
    yield takeLatest(UPDATE_PROJEKT_SUCCESS, processUpdateProjektList)
}