import axios from "axios";
import {createAuthHeader} from "./commonApi";
import {BACKEND} from "../config/backend";

export const PROJEKT_BASE_URL = `${BACKEND}/api/v1/projekte`

export const getProjektById = ({token, projektId}) => {
    return axios.get(`${PROJEKT_BASE_URL}/${projektId}`, {
        ...createAuthHeader(token)
    })
}

export const deleteProjektImages = ({token, projektId, imageKeys}) => {
    return axios.delete(`${PROJEKT_BASE_URL}/${projektId}/images`, {
        ...createAuthHeader(token)
        , params: {keys: imageKeys.join(',')}
    })
}

export const updateProjekt = ({token, projektData}) => {
    return axios.put(`${PROJEKT_BASE_URL}/${projektData.projekt.ProjektID}`, projektData, {
        ...createAuthHeader(token)
    })
}

export const insertProjekt = ({token, projektData}) => {
    return axios.post(`${PROJEKT_BASE_URL}`, projektData, {
        ...createAuthHeader(token)
    })
}

export const getPagedProjekte = ({token, artistIdList, projStatList, nextPageOffsetId, pageSize}) => {
    return axios.get(`${PROJEKT_BASE_URL}`, {
        ...createAuthHeader(token)
        , params: {
            artistIdList, projStatList, nextPageOffsetId, pageSize
        }
    })
}

export const getProjektListe = ({token, parameters}) => {
    return axios.get(`${PROJEKT_BASE_URL}`, {
        ...createAuthHeader(token)
        , params: parameters
    })
}

export const mergeProjekte = ({token, mergeConfig}) => {
    return axios.put(`${PROJEKT_BASE_URL}/merge`, mergeConfig, {
        ...createAuthHeader(token)
    })
}

export const deleteProjekt = ({token, projektId}) => {
    return axios.delete(`${PROJEKT_BASE_URL}/${projektId}`, {
        ...createAuthHeader(token)
    })
}

export const updateProjektList = ({token, parameters}) => {
    return axios.put(`${PROJEKT_BASE_URL}/list`, parameters,{
        ...createAuthHeader(token)
    })
}
