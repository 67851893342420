import {
    KUNDE_101_GEBURTSTAGSAKTION,
    KUNDE_102_KUNDEN_NACH_STILRICHTUNGEN,
    KUNDE_103_KRANKHEITEN_BEREINIGEN,
    KUNDE_104_KONTAKTPROBLEME,
    KUNDE_105_FEHLENDE_GEBURTSTAGE,
    PROJEKT_201_11_AUSSTEHENDE_TERMINE_PRUEFEN,
    PROJEKT_202_30_RUHENDE_PROJEKTE_MIT_TKAZ,
    PROJEKT_203_11_ERLEDIGTE_PROJEKTE_MIT_TKAZ,
    PROJEKT_204_20_ALLES_OK_MIT_NEUEM_TATTOO,
    PROJEKT_205_GUEST_ARTIST_WIEDER_DA,
    PROJEKT_206_12_FEHLER_BEIM_ANZAHLUNGSSALDO,
    PROJEKT_207_PROJEKTNAMEN_PFLEGEN,
    PROJEKT_208_WARTELISTE,
    PROJEKT_209_TERMIN_ERSATZ,
    PROJEKT_210_OFFENE_PROJEKTANFRAGEN,
    PROJEKT_211_LAUFENDE_PROJEKTE_OHNE_TERMIN,
    PROJEKT_212_ANZAHLUNG_AUSBUCHEN,
    TERMIN_301_FEHLENDE_ANZAHLUNGEN,
    TERMIN_302_HINFAELLIGE_TERMINE,
    TERMIN_303_TAGESABSCHLUSS,
    TERMIN_304_VORGEMERKTE_TERMINE,
    TERMIN_305_FEHLENDE_DOKUMENTE,
    TERMIN_306_BITTE_UM_BESTAETIGUNG,
    TERMIN_307_TERMINVERSCHIEBUNG,
    TERMIN_309_TERMIN_VORZIEHEN
} from "./definitions/projektDefinitions";
import {constructRelativeDateToActual, LIST_DAY_UNIT, LIST_YEAR_UNIT} from "../../models/lists";
import {
    PROJEKTSTATUS_GESCHLOSSEN,
    PROJEKTSTATUS_OFFEN,
    PROJEKTSTATUS_OFFEN_RUHEND_OR_WARTEND,
    PROJEKTSTATUS_RUHEND,
    PROJEKTSTATUS_WARTEND
} from "../../models/projekt";
import {TERMINSTATUS_ERLEDIGT, TERMINSTATUS_OFFEN, TERMINSTATUS_VORGEMERKT} from "../../models/terminStatus";
import {VOUCHERS_AUSBUCHEN_FILTER_DEFINITION} from "./definitions/vouchersDefinitions";
import {i18_LIST, i18_LISTDOC} from "../../i18nReferences";

const STANDARD_KUNDEN_LISTEN_VISIBILITY_DEFINITION = {KundID: false}
const STANDARD_DOUBLETTEN_LISTEN_VISIBILITY_DEFINITION = {kundIdFrom: false, kundIdTo: false, id: false}
const STANDARD_PROJEKT_LISTEN_VISIBILITY_DEFINITION = {KundID: false, ProjektID: false}
const STANDARD_TERMIN_LISTEN_VISIBILITY_DEFINITION = {KundID: false, ProjektID: false, TerminID: false, Dauer: false}
const STANDARD_EINNAHMEN_LISTEN_VISIBILITY_DEFINITION = {
    KundID: false,
    ProjektID: false,
    TerminID: false,
    EinnID: false
}
const EINNAHMENBUECHER_ARTISTS_VISIBILITY_DEFINITION = {
    ...STANDARD_EINNAHMEN_LISTEN_VISIBILITY_DEFINITION
    , ZahlungsartStudio: false
    , ArtistID: false
    , Betreff: false
}
const EIN_AUS_TERMINKAUTION_VISIBILITY_DEFINITION = {
    ...STANDARD_EINNAHMEN_LISTEN_VISIBILITY_DEFINITION
    , Betreff: false
    , Zahlungsart: false
}
const STANDARD_NACHRICHTEN_LISTEN_VISIBILITY_DEFINITION = {
    mSGid: false,
    KundID: false,
    ProjektID: false,
    TerminID: false,
    ArtistID: false
}
const EINNAHMENBUECHER_ARTISTS_SORT_DEFINITION = [
    {
        field: "Start",
        sort: "asc"
    }
]
const EINNAHMENBUECHER_ARTISTS_FILTER_DEFINITION = {
    items: [
        {
            field: "Leistung",
            id: 43612,
            operator: "isAnyOf",
            value: [
                "Tattoo",
                "Piercing"
            ]
        }
    ],
    logicOperators: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and"
}

const EIN_AUS_TERMINKAUTION_FILTER_DEFINITION = {
    items: [
        {
            field: "Leistung",
            operator: "isAnyOf",
            id: 91439,
            value: [
                "Terminkaution",
                "Anzahlung",
                "Deposit"
            ]
        },
        {
            field: "ZahlungsartStudio",
            operator: "isAnyOf",
            id: 55816,
            value: [
                "Terminkaution",
                "Anzahlung",
                "Deposit"
            ]
        }
    ],
    logicOperators: "or",
    quickFilterValues: [],
    quickFilterLogicOperator: "and"
}
const STANDARD_CHECKOUT_LISTEN_VISIBILITY_DEFINITION = {id: false}
const STANDARD_VOUCHERS_LISTEN_VISIBILITY_DEFINITION = {VouchOutID: false}
const MANAGE_VOUCHERS_LISTEN_VISIBILITY_DEFINITION = {VouchOutID: false, _createdTS: false, _createdID: false}
const STANDARD_VOUCHERS_SORT_DEFINITION = [
    {
        field: "VouchNo",
        sort: "desc"
    }
]
const STANDARD_VOUCHIN_LISTEN_VISIBILITY_DEFINITION = {VouchInID: false}
const STANDARD_DBLEXCLUSIONS_LISTEN_VISIBILITY_DEFINITION = {KdExDblID: false, KundIDFrom: false, KundIDTo: false}
const STANDARD_KUNDEN_LISTEN_SORT_DEFINITION = [{field: 'Nachname', sort: 'asc'}, {field: 'Vorname', sort: 'asc'}]
const STANDARD_KUNDEN_LISTEN_DEFINITION = {
    sort: STANDARD_KUNDEN_LISTEN_SORT_DEFINITION,
    visibility: STANDARD_KUNDEN_LISTEN_VISIBILITY_DEFINITION
}
const STANDARD_DOUBLETTEN_LISTEN_DEFINITION = {
    sort: STANDARD_KUNDEN_LISTEN_SORT_DEFINITION,
    visibility: STANDARD_DOUBLETTEN_LISTEN_VISIBILITY_DEFINITION
}
const STANDARD_PROJEKT_LISTEN_DEFINITION = {
    visibility: STANDARD_PROJEKT_LISTEN_VISIBILITY_DEFINITION
}

const STANDARD_JOBS_LISTEN_DEFINITION = {}

const STANDARD_TERMIN_LISTEN_DEFINITION = {
    visibility: STANDARD_TERMIN_LISTEN_VISIBILITY_DEFINITION
}

const STANDARD_EINNAHMEN_LISTEN_DEFINITION = {
    visibility: STANDARD_EINNAHMEN_LISTEN_VISIBILITY_DEFINITION
}

const EINNAHMENBUECHER_ARTISTS = {
    visibility: EINNAHMENBUECHER_ARTISTS_VISIBILITY_DEFINITION,
    sort: EINNAHMENBUECHER_ARTISTS_SORT_DEFINITION,
    filter: EINNAHMENBUECHER_ARTISTS_FILTER_DEFINITION
}

const EIN_AUS_TERMINKAUTION = {
    visibility: EIN_AUS_TERMINKAUTION_VISIBILITY_DEFINITION,
    sort: EINNAHMENBUECHER_ARTISTS_SORT_DEFINITION,
    filter: EIN_AUS_TERMINKAUTION_FILTER_DEFINITION
}

const STANDARD_CHECKOUT_LISTEN_DEFINITION = {
    visibility: STANDARD_CHECKOUT_LISTEN_VISIBILITY_DEFINITION
}

const STANDARD_VOUCHERS_LISTEN_DEFINITION = {
    visibility: STANDARD_VOUCHERS_LISTEN_VISIBILITY_DEFINITION
}

const STANDARD_VOUCHIN_LISTEN_DEFINITION = {
    visibility: STANDARD_VOUCHIN_LISTEN_VISIBILITY_DEFINITION
}

const STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_VOR_7_TAGE = {
    letzterTermin: constructRelativeDateToActual(-7)
}

const STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_AB_HEUTE = {
    letzterTermin: constructRelativeDateToActual(0)
}

const STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_VOR_3_JAHREN = {
    letzterTermin: constructRelativeDateToActual(-3, LIST_YEAR_UNIT)
}

const STANDARD_DBLEXCLUSIONS_LISTEN_DEFINITION = {
    visibility: STANDARD_DBLEXCLUSIONS_LISTEN_VISIBILITY_DEFINITION
}

const createProjStatusFetchFilter = (projStatList) => ({projStatList})
const createTerminVonFetchFilter = (count, timeUnit = LIST_DAY_UNIT) => ({datVon: constructRelativeDateToActual(count, timeUnit)})
const createTerminBisFetchFilter = (count, timeUnit = LIST_DAY_UNIT) => ({datBis: constructRelativeDateToActual(count, timeUnit)})
const OHNE_TERMIN_CHECKED = {ohneTermin: true}

export const TERMINTYP_LIST_COUNT_IN_PS = 'TERMINTYP_LIST_COUNT_IN_PS'
export const TERMINTYP_LIST_SHOW_IN_FAELLIG = 'TERMINTYP_LIST_SHOW_IN_FAELLIG'
const TERMINTYP_LIST_COUNT_IN_PS_FETCH_FILTER = {termintypList: TERMINTYP_LIST_COUNT_IN_PS}
const TERMINTYP_LIST_SHOW_IN_FAELLIG_FETCH_FILTER = {termintypList: TERMINTYP_LIST_SHOW_IN_FAELLIG}
const createTerminStatusIdListFetchFilter = (terminStatusIdList) => ({terminStatusIdList})

const STANDARD_LISTEN = [
    {
        ReportDefID: -100,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.KUNDEN_SORTIERT_NACH_ANLAGE',
        Definition: STANDARD_KUNDEN_LISTEN_DEFINITION
    },
    {
        ReportDefID: -150,
        Typ: 'dblexclusions',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.DOUBLETTEN_AUSSCHLUESSE',
        Definition: STANDARD_DBLEXCLUSIONS_LISTEN_DEFINITION,
        isAdmin: true
    },
    {
        ReportDefID: -101,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.CRM',
        Bezeichnung: 'LIST_BEZEICHNUNG.GEBURTSTAGSAKTION',
        Definition: KUNDE_101_GEBURTSTAGSAKTION,
        FetchFilter: {...STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_VOR_3_JAHREN}
    },
    {
        ReportDefID: -102,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.CRM',
        Bezeichnung: 'LIST_BEZEICHNUNG.KUNDEN_NACH_STILRICHTUNGEN',
        Definition: KUNDE_102_KUNDEN_NACH_STILRICHTUNGEN,
        FetchFilter: {...STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_VOR_3_JAHREN}
    },
    {
        ReportDefID: -103,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.KRANKHEITEN_BEREINIGEN',
        Definition: KUNDE_103_KRANKHEITEN_BEREINIGEN,
        FetchFilter: {...STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_VOR_7_TAGE}
    },
    {
        ReportDefID: -104,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.KONTAKTPROBLEME',
        Definition: KUNDE_104_KONTAKTPROBLEME,
        FetchFilter: {...STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_AB_HEUTE}
    },
    {
        ReportDefID: -105,
        Typ: 'kunde',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.FEHLENDE_GEBURTSTAGE',
        Definition: KUNDE_105_FEHLENDE_GEBURTSTAGE,
        FetchFilter: {...STANDARD_KUNDEN_FETCHFILTER_LETZTER_TERMIN_AB_HEUTE}
    },
    {
        ReportDefID: -106,
        Typ: 'doubletten',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.KUNDEN_DOUBLETTEN',
        Definition: STANDARD_DOUBLETTEN_LISTEN_DEFINITION,
        isAdmin: true
    },
    {
        ReportDefID: -200,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.PROJEKTE_SORTIERT_NACH_ANALGE',
        Definition: STANDARD_PROJEKT_LISTEN_DEFINITION
    },
    {
        ReportDefID: -201,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.AUSSTEHENDE_TERMINE_PRUEFEN',
        Definition: PROJEKT_201_11_AUSSTEHENDE_TERMINE_PRUEFEN,
        FetchFilter: {...createProjStatusFetchFilter([PROJEKTSTATUS_OFFEN])}
    },
    {
        ReportDefID: -202,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.FOLLOWUP',
        Bezeichnung: 'LIST_BEZEICHNUNG.RUHENDE_PROJEKTE_MIT_TKAZ',
        Definition: PROJEKT_202_30_RUHENDE_PROJEKTE_MIT_TKAZ,
        FetchFilter: {
            ...createProjStatusFetchFilter([PROJEKTSTATUS_RUHEND]),
            ...createTerminBisFetchFilter(-1, LIST_YEAR_UNIT)
        }
    },
    {
        ReportDefID: -203,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.CHECK',
        Bezeichnung: 'LIST_BEZEICHNUNG.ERLEDIGTE_PROJEKTE_MIT_TKAZ',
        Definition: PROJEKT_203_11_ERLEDIGTE_PROJEKTE_MIT_TKAZ,
        FetchFilter: {...createProjStatusFetchFilter([PROJEKTSTATUS_GESCHLOSSEN])}
    },
    {
        ReportDefID: -204,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.FOLLOWUP',
        Bezeichnung: 'LIST_BEZEICHNUNG.ALLES_OK_MIT_NEUEM_TATTOO',
        Definition: PROJEKT_204_20_ALLES_OK_MIT_NEUEM_TATTOO,
        FetchFilter: {
            ...createProjStatusFetchFilter([PROJEKTSTATUS_GESCHLOSSEN]),
            ...createTerminVonFetchFilter(-14),
            ...createTerminBisFetchFilter(-6)
        }
    },
    {
        ReportDefID: -205,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.CRM',
        Bezeichnung: 'LIST_BEZEICHNUNG.GUEST_ARTIST_WIEDER_DA',
        Definition: PROJEKT_205_GUEST_ARTIST_WIEDER_DA,
        FetchFilter: {
            ...createProjStatusFetchFilter([PROJEKTSTATUS_GESCHLOSSEN]),
            ...createTerminVonFetchFilter(-3, LIST_YEAR_UNIT)
        }
    },
    {
        ReportDefID: -206,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.FEHLER_BEIM_ANZAHLUNGSSALDO',
        Definition: PROJEKT_206_12_FEHLER_BEIM_ANZAHLUNGSSALDO,
        FetchFilter: {}
    },
    {
        ReportDefID: -207,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.DATENPFLEGE',
        Bezeichnung: 'LIST_BEZEICHNUNG.PROJEKTNAMEN_PFLEGEN',
        Definition: PROJEKT_207_PROJEKTNAMEN_PFLEGEN,
        FetchFilter: {...createProjStatusFetchFilter(PROJEKTSTATUS_OFFEN_RUHEND_OR_WARTEND)}
    },
    {
        ReportDefID: -208,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.CRM',
        Bezeichnung: 'LIST_BEZEICHNUNG.WARTELISTE',
        Definition: PROJEKT_208_WARTELISTE,
        FetchFilter: {...createProjStatusFetchFilter([PROJEKTSTATUS_WARTEND])}
    },
    {
        ReportDefID: -209,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.TERMIN_ERSATZ',
        Definition: PROJEKT_209_TERMIN_ERSATZ,
        FetchFilter: {...createProjStatusFetchFilter([PROJEKTSTATUS_OFFEN])}
    },
    {
        ReportDefID: -210,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.FOLLOWUP',
        Bezeichnung: 'LIST_BEZEICHNUNG.OFFENE_PROJEKTANFRAGEN',
        Definition: PROJEKT_210_OFFENE_PROJEKTANFRAGEN,
        FetchFilter: {...OHNE_TERMIN_CHECKED, ...createProjStatusFetchFilter([PROJEKTSTATUS_OFFEN])}
    },
    {
        ReportDefID: -211,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.FOLLOWUP',
        Bezeichnung: 'LIST_BEZEICHNUNG.LAUFENDE_PROJEKTE_OHNE_TERMIN',
        Definition: PROJEKT_211_LAUFENDE_PROJEKTE_OHNE_TERMIN,
        FetchFilter: {
            ...createProjStatusFetchFilter([PROJEKTSTATUS_OFFEN]),
            ...createTerminBisFetchFilter(0)
        }
    },
    {
        ReportDefID: -212,
        Typ: 'projekt',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.ANZAHLUNG_AUSBUCHEN',
        Definition: PROJEKT_212_ANZAHLUNG_AUSBUCHEN,
        isAdmin: true,
        FetchFilter: {
            ...createProjStatusFetchFilter([PROJEKTSTATUS_GESCHLOSSEN]),
            ...createTerminBisFetchFilter(-3, LIST_YEAR_UNIT)
        }
    },
    {
        ReportDefID: -300,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.TERMINE_SORTIERT_NACH_ANLAGE',
        Definition: STANDARD_TERMIN_LISTEN_DEFINITION,
    },
    {
        ReportDefID: -301,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.FEHLENDE_ANZAHLUNGEN',
        Definition: TERMIN_301_FEHLENDE_ANZAHLUNGEN,
        FetchFilter: {
            ...TERMINTYP_LIST_COUNT_IN_PS_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN]),
            ...createTerminVonFetchFilter(0)
        }
    },
    {
        ReportDefID: -302,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.CHECK',
        Bezeichnung: 'LIST_BEZEICHNUNG.HINFAELLIGE_TERMINE',
        Definition: TERMIN_302_HINFAELLIGE_TERMINE,
        FetchFilter: {
            ...TERMINTYP_LIST_COUNT_IN_PS_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN, TERMINSTATUS_VORGEMERKT]),
            ...createTerminVonFetchFilter(1)
        }
    },
    {
        ReportDefID: -303,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.CHECK',
        Bezeichnung: 'LIST_BEZEICHNUNG.TAGESABSCHLUSS',
        Definition: TERMIN_303_TAGESABSCHLUSS,
        FetchFilter: {
            ...TERMINTYP_LIST_SHOW_IN_FAELLIG_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN, TERMINSTATUS_VORGEMERKT]),
            ...createTerminBisFetchFilter(0)
        }
    },
    {
        ReportDefID: -304,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.VORGEMERKTE_TERMINE',
        Definition: TERMIN_304_VORGEMERKTE_TERMINE,
        FetchFilter: {
            ...TERMINTYP_LIST_SHOW_IN_FAELLIG_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_VORGEMERKT]),
            ...createTerminVonFetchFilter(0)
        }
    },
    {
        ReportDefID: -305,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.CHECK',
        Bezeichnung: 'LIST_BEZEICHNUNG.FEHLENDE_DOKUMENTE',
        Definition: TERMIN_305_FEHLENDE_DOKUMENTE,
        FetchFilter: {
            ...TERMINTYP_LIST_COUNT_IN_PS_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_ERLEDIGT]),
            ...createTerminBisFetchFilter(1)
        }
    },
    {
        ReportDefID: -306,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.BITTE_UM_BESTAETIGUNG',
        Definition: TERMIN_306_BITTE_UM_BESTAETIGUNG,
        FetchFilter: {
            ...TERMINTYP_LIST_SHOW_IN_FAELLIG_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN]),
            ...createTerminVonFetchFilter(7),
            ...createTerminBisFetchFilter(15)
        }
    },
    {
        ReportDefID: -307,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.TERMINVERSCHIEBUNG',
        Definition: TERMIN_307_TERMINVERSCHIEBUNG,
        FetchFilter: {
            ...TERMINTYP_LIST_SHOW_IN_FAELLIG_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN]),
            ...createTerminVonFetchFilter(0),
            ...createTerminBisFetchFilter(8)
        }
    },
    {
        ReportDefID: -309,
        Typ: 'termin',
        Category: 'LIST_CATEGORY.TERMIN_VORBEREITUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.TERMIN_VORZIEHEN',
        Definition: TERMIN_309_TERMIN_VORZIEHEN,
        FetchFilter: {
            ...TERMINTYP_LIST_COUNT_IN_PS_FETCH_FILTER,
            ...createTerminStatusIdListFetchFilter([TERMINSTATUS_OFFEN]),
            ...createTerminVonFetchFilter(2),
            ...createTerminBisFetchFilter(15)
        }
    },
    {
        ReportDefID: -400,
        Typ: 'checkout',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.ZAHLUNGSVORGAENGE_NACH_ANLAGE',
        Definition: STANDARD_CHECKOUT_LISTEN_DEFINITION,
        isAdmin: true
    },
    {
        ReportDefID: -500,
        Typ: 'vouchers',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.VOUCHERS_AUSGABE',
        Definition: STANDARD_VOUCHERS_LISTEN_DEFINITION
    },
    {
        ReportDefID: -501,
        Typ: 'vouchers',
        Category: 'LIST_CATEGORY.VOUCHERS',
        Bezeichnung: 'LIST_BEZEICHNUNG.VOUCHERS_MANAGE',
        Definition: {
            visibility: {...MANAGE_VOUCHERS_LISTEN_VISIBILITY_DEFINITION, Stichtagssaldo: false },
            sort: STANDARD_VOUCHERS_SORT_DEFINITION
        },
        FetchFilter: {
            ...createTerminVonFetchFilter(-28)
        }
    },
    {
        ReportDefID: -502,
        Typ: 'vouchers',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.VOUCHERS_VERKAEUFE',
        Definition: {
            visibility: {...MANAGE_VOUCHERS_LISTEN_VISIBILITY_DEFINITION },
            sort: STANDARD_VOUCHERS_SORT_DEFINITION
        },
        isAdmin: true
    },
    {
        ReportDefID: -503,
        Typ: 'vouchers',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.VOUCHERS_AUSBUCHEN',
        Definition: {
            visibility: {...MANAGE_VOUCHERS_LISTEN_VISIBILITY_DEFINITION, Stichtagssaldo: false },
            sort: [{field: "DatOut", sort: "asc" }],
            filter: VOUCHERS_AUSBUCHEN_FILTER_DEFINITION
        },
        isAdmin: true
    },
    {
        ReportDefID: -550,
        Typ: 'vouchin',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.VOUCHERS_EINLOESUNG',
        Definition: STANDARD_VOUCHIN_LISTEN_DEFINITION,
        isAdmin: true
    },
    {
        ReportDefID: -600,
        Typ: 'einnahmen',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.EINNAHMEN_SORTIERT_NACH_ANLAGE',
        Definition: STANDARD_EINNAHMEN_LISTEN_DEFINITION,
    },
    {
        ReportDefID: -601,
        Typ: 'einnahmen',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.EINNAHMENBUECHER_ARTISTS',
        Definition: EINNAHMENBUECHER_ARTISTS,
    },
    {
        ReportDefID: -602,
        Typ: 'einnahmen',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.EIN_AUS_TERMINKAUTION',
        Definition: EIN_AUS_TERMINKAUTION,
        isAdmin: true
    },
    {
        ReportDefID: -603,
        Typ: 'einnahmen',
        Category: 'LIST_CATEGORY.BUCHHALTUNG',
        Bezeichnung: 'LIST_BEZEICHNUNG.EINNAHMENBUECHER_STUDIO',
        Definition: {...EIN_AUS_TERMINKAUTION, filter: undefined},
    },
    {
        ReportDefID: -700,
        Typ: 'nachrichten',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.NACHRICHTEN',
        Definition: {visibility: {...STANDARD_NACHRICHTEN_LISTEN_VISIBILITY_DEFINITION}, filter: undefined},
    },
    {
        ReportDefID: -900,
        Typ: 'jobs',
        Category: 'LIST_CATEGORY.ADMINISTRATION',
        Bezeichnung: 'LIST_BEZEICHNUNG.ALLE_AUFTRAEGE_NACH_ANLAGE',
        Definition: STANDARD_JOBS_LISTEN_DEFINITION
    }
]

export const _getStandardReportDefinitions = (t) => STANDARD_LISTEN.map(liste => ({
    ...liste
    , Category: t(liste.Category, {ns: i18_LIST})
    , Bezeichnung: t(liste.Bezeichnung, {ns: i18_LIST})
    , Bemerkung: t(liste.Bezeichnung.replace("LIST_BEZEICHNUNG", "LIST_DOC"), {ns: i18_LISTDOC})
}))

