import {getArtistToken} from "../auth/token.saga";
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {deleteProjekt, getProjektById, insertProjekt, updateProjekt} from "../../api/projektApi";
import {emitNotificationAction} from "../notification/notificationAction";
import {createErrorNotification, createInfoNotification, createSuccessNotification} from "../../models/notification";
import i18next from "i18next";
import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    ADD_PROJEKT_IMAGES,
    addProjektImagesFailureAction,
    addProjektImagesSuccessAction,
    clearProjektAction,
    DELETE_PROJEKT,
    deleteProjektFailedAction,
    deleteProjektSuccessAction,
    LOAD_PROJEKT_IMAGES,
    loadProjektImagesSuccess,
    setProjektIsUpdatingAction,
    UPDATE_PROJEKT,
    updateProjektFailure,
    updateProjektSuccess
} from "./projektAction";
import {isGespeichertesProjekt} from "../../models/projekt";
import {selectDeletedProjektImages, selectInsertedTempImages} from "./projektSelector";
import {addTempImage} from "../../api/studioApi";
import {selectArtistMap} from "../common/commonSelector";
import {extractMessageFromAxiosError} from "../utils/error";
import {i18_EDITPROJEKT, i18_TRANSLATION} from "../../i18nReferences";

function* loadProjektImages({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const projektData = extractDataFromResponse(yield call(getProjektById, {
                token,
                projektId: payload.projektId
            }))
            yield put(loadProjektImagesSuccess(projektData.images || []))
            if (projektData.errors?.find(e => e.type === 'imageList')) {
                yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_IMAGES_FAILED_INFO'), 'loadProjektImages')))
            }
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_IMAGES_FAILED_INFO', {ns: i18_TRANSLATION}), 'loadProjektImages')))
        console.log(err)
    }
}

function* watchLoadProjektImages() {
    yield takeLatest(LOAD_PROJEKT_IMAGES, loadProjektImages)
}

export function* writeProjektData(projekt, besprechungen) {
    const token = yield getArtistToken()
    if (token) {
        const deletedImages = yield select(state => selectDeletedProjektImages(state))
        const deletedImageKeys = deletedImages && deletedImages.map(i => i.key ? i.key : i)
        const insertedImages = yield select(state => selectInsertedTempImages(state))
        const projektData = {
            projekt
            , images: {insertedImages, deletedImages: deletedImageKeys}
            , besprechungen
        }
        yield put(setProjektIsUpdatingAction())
        if (isGespeichertesProjekt(projekt.ProjektID)) {
            yield call(updateProjekt, {token, projektData})
        } else {
            yield call(insertProjekt, {token, projektData})
        }
        yield put(clearProjektAction())
        yield put(emitNotificationAction(createSuccessNotification(i18next.t('EDIT_PROJEKT.PROJEKT_SAVE_DATA_SUCCESS_INFO', {ns: i18_EDITPROJEKT, ProjName: projekt.ProjName}), 'saveProject')))
        yield put(updateProjektSuccess())
        return true
    }
    return false
}

function* processUpdateProjekt({payload}) {
    try {
        const success = yield* writeProjektData(payload.projekt)
        if (success) {
            yield put(clearProjektAction())
        }
    } catch (err) {
        yield put(updateProjektFailure())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_PROJEKT.PROJEKT_SAVE_DATA_FAILURE_INFO', {ns: i18_EDITPROJEKT, ProjName: payload.projekt.ProjName}), 'saveProject')))
        console.log(err)
    }
}

function* watchUpdateProjekt() {
    yield takeLatest(UPDATE_PROJEKT, processUpdateProjekt)
}

function* processAddProjektImage({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const keyList = []
            for (let image of payload.images) {
                const {key} = extractDataFromResponse(yield call(addTempImage, {token, image}))
                keyList.push(key)
            }
            yield put(addProjektImagesSuccessAction(keyList))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT_IMAGE_VIEW.ADDING_IMAGE_FAILED', {ns: i18_TRANSLATION}), 'AddProjektImage')))
        yield put(addProjektImagesFailureAction())
        console.log(err)
    }
}

function* watchAddProjektImage() {
    yield takeLatest(ADD_PROJEKT_IMAGES, processAddProjektImage)
}

export function* deleteProjektSaga({payload}) {
    const token = yield getArtistToken()
    const artistMap = yield select(state => selectArtistMap(state))
    const projekt = payload.projekt
    if (token) {
        yield call(deleteProjekt, {token, projektId: projekt.ProjektID})
        yield put(deleteProjektSuccessAction())
        yield put(emitNotificationAction(createInfoNotification(i18next.t('PROJEKT.DELETE_SUCCESSFUL',
            {ns: i18_EDITPROJEKT, Kuerzel: artistMap[projekt.ArtistID].Kuerzel, ProjName: projekt.ProjName}), 'processDeleteProjekt')))

    }
}

function* processDeleteProjekt({payload}) {
    try {
        yield* deleteProjektSaga({payload})
    } catch (err) {
        const message = extractMessageFromAxiosError(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT.DELETE_FAILED',
            {ns: i18_EDITPROJEKT, message}), 'processDeleteProjekt')))
        yield put(deleteProjektFailedAction())
        console.log(err)
    }
}

function* watchDeleteProjekt() {
    yield takeLatest(DELETE_PROJEKT, processDeleteProjekt)
}

const projektSaga = [fork(watchLoadProjektImages), fork(watchUpdateProjekt), fork(watchAddProjektImage), fork(watchDeleteProjekt)]

export default projektSaga