import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {getVouchInListByDatOutVonBis} from "../../../../api/voucherApi";
import {
    LOAD_VOUCHIN_LIST_DATA,
    loadVouchInListDataFailureAction,
    loadVouchInListDataSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadVouchInListData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const parameters = payload.parameters
            const vouchers = extractDataFromResponse(yield call(getVouchInListByDatOutVonBis, {token, ...parameters}))
            yield put(loadVouchInListDataSuccessAction(vouchers))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.LOAD_VOUCHERS_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadVoucherListData')))
        yield put(loadVouchInListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadVouchInListData() {
    yield takeLatest(LOAD_VOUCHIN_LIST_DATA, processLoadVouchInListData)
}