import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_ARTIST_BASE_DATA = 'LOAD_ARTIST_BASE_DATA'
export const loadArtistMailBaseAction = createSimpleAction(LOAD_ARTIST_BASE_DATA, 'artistId')

export const LOAD_ARTIST_BASE_DATA_SUCCESS = 'LOAD_ARTIST_BASE_DATA_SUCCESS'
export const loadArtistBaseDataSuccessAction = createSimpleAction(LOAD_ARTIST_BASE_DATA_SUCCESS, 'artistBaseData')

export const SAVE_ARTIST_BASE = 'SAVE_ARTIST_BASE'
export const saveArtistBaseAction = createSimpleAction(SAVE_ARTIST_BASE, 'artistBaseData')

export const ARTIST_DATA_CHANGED = 'ARTIST_DATA_CHANGED'
export const artistDataChangedAction = createTypeAction(ARTIST_DATA_CHANGED)

export const ARTIST_DATA_RESET = 'ARTIST_DATA_RESET'
export const artistDataResetAction = createTypeAction(ARTIST_DATA_RESET)

export const SAVE_ARTIST_MAIL = 'SAVE_ARTIST_MAIL'
export const saveArtistMailAction = createSimpleAction(SAVE_ARTIST_MAIL, 'artistMailData')

export const ARTIST_MAIL_CHANGED = 'ARTIST_MAIL_CHANGED'
export const artistMailChangedAction = createTypeAction(ARTIST_MAIL_CHANGED)

export const ARTIST_MAIL_RESET = 'ARTIST_MAIL_RESET'
export const artistMailResetAction = createTypeAction(ARTIST_MAIL_RESET)

export const LOAD_ARTIST_MAIL_DATA = 'LOAD_ARTIST_MAIL'
export const loadArtistMailDataAction = createSimpleAction(LOAD_ARTIST_MAIL_DATA, 'artistId')

export const LOAD_ARTIST_MAIL_DATA_SUCCESS = 'LOAD_ARTIST_MAIL_DATA_SUCCESS'
export const loadArtistMailDataSuccessAction = createSimpleAction(LOAD_ARTIST_MAIL_DATA_SUCCESS, 'artistMailData')

export const CLEAR_ARTIST_DATA = 'CLEAR_ARTIST_DATA'
export const clearArtistDataAction = createTypeAction(CLEAR_ARTIST_DATA)

export const VERIFY_ARTIST_MAIL_CONFIGURATION = 'VERIFY_ARTIST_MAIL_CONFIGURATION'
export const verifyArtistMailConfigurationAction = createSimpleAction(VERIFY_ARTIST_MAIL_CONFIGURATION, 'artistMailConfiguration')

export const VERIFY_ARTIST_MAIL_CONFIGURATION_SUCCESS = 'VERIFY_ARTIST_MAIL_CONFIGURATION_SUCCESS'
export const verifyArtistMailConfigurationSuccessAction = createSimpleAction(VERIFY_ARTIST_MAIL_CONFIGURATION_SUCCESS, 'verifyMailResult')

export const SAVE_ARTIST_WORKTIME = 'SAVE_ARTIST_WORKTIME'
export const saveArtistWorkTimeAction = createSimpleAction(SAVE_ARTIST_WORKTIME, 'artistWorkTime')

export const DELETE_ARTIST_WORKTIME = 'DELETE_ARTIST_WORKTIME'
export const deleteArtistWorkTimeAction = createSimpleAction(DELETE_ARTIST_WORKTIME, 'artistWorkTime')

export const LOAD_ARTIST_RECHTE_DATA = 'LOAD_ARTIST_RECHTE_DATA'
export const loadArtistRechteDataAction = createSimpleAction(LOAD_ARTIST_RECHTE_DATA, 'artistId')

export const LOAD_ARTIST_RECHTE_DATA_SUCCESS = 'LOAD_ARTIST_RECHTE_DATA_SUCCESS'
export const loadArtistRechteDataSuccessAction = createSimpleAction(LOAD_ARTIST_RECHTE_DATA_SUCCESS, 'artistRechteData')

export const CHANGE_ARTIST_RECHT = "CHANGE_ARTIST_RECHT"
export const changeArtistRechtAction = createSimpleAction(CHANGE_ARTIST_RECHT, 'artistRecht')

export const CHANGE_ARTIST_RECHT_BASE = "CHANGE_ARTIST_RECHT_BASE"
export const changeArtistRechtBaseAction = createSimpleAction(CHANGE_ARTIST_RECHT_BASE, 'rechteBaseChanges')

export const SAVE_ARTIST_RECHTE = 'SAVE_ARTIST_RECHTE'
export const saveArtistRechteAction = createSimpleAction(SAVE_ARTIST_RECHTE, 'artistRechteData')

export const SAVE_ARTIST_ASSIST = 'SAVE_ARTIST_ASSIST'
export const saveArtistAssistDataAction = createSimpleAction(SAVE_ARTIST_ASSIST, 'artistAssistData')

export const DEACTIVATE_ARTISTS = 'DEACTIVATE_ARTISTS'
export const deactivateArtistAction = createSimpleAction(DEACTIVATE_ARTISTS, 'artistIdList')

export const REACTIVATE_ARTISTS = 'REACTIVATE_ARTISTS'
export const reactivateArtistAction = createSimpleAction(REACTIVATE_ARTISTS, 'artistChanges')

export const SET_ARTIST_ADMIN = 'SET_ARTIST_ADMIN'
export const setArtistAdminAction = createSimpleAction(SET_ARTIST_ADMIN, 'artistChanges')

export const SET_ARTIST_ISSUES_VOUCHER = 'SET_ARTIST_ISSUES_VOUCHER'
export const setArtistIssuesVouchers = createSimpleAction(SET_ARTIST_ISSUES_VOUCHER, 'artistChanges')


export const SET_ARTIST_GGL_SYNC_ENABLED = 'SET_ARTIST_GGL_SYNC_ENABLED'
export const setArtistGglSyncEnabled = createSimpleAction(SET_ARTIST_GGL_SYNC_ENABLED, 'artistChanges')

export const COPY_ARTIST = 'COPY_ARTIST'
export const copyArtistAction = createSimpleAction(COPY_ARTIST, 'artistId')

export const DELETE_ARTIST = 'DELETE_ARTIST'
export const deleteArtistAction = createSimpleAction(DELETE_ARTIST, 'artistId')