import {ListSubheader, MenuItem} from "@mui/material";
import React from "react";

const renderOption = (artist) => {
    return (
        <MenuItem key={artist.ArtistID} value={artist.ArtistID}>{artist.Kuerzel}</MenuItem>
    )
}

const filterArtist = (artist, tatortID, ausgeschiedene) => artist &&
    (!ausgeschiedene && artist.StdOrtID === +tatortID && !artist.Ausgeschieden
        || ausgeschiedene && artist.Ausgeschieden)

export const renderOptionGroup = ({artistsMap, tatortMap, tatortID, ausgeschiedene, key}) => {
    const label = ausgeschiedene ? 'Ausgeschiedene' : tatortMap[tatortID]?.Ort || tatortID
    const artists = Object.values(artistsMap).filter(a => filterArtist(a, tatortID, ausgeschiedene))
        .sort((a1, a2) => a1.Kuerzel > a2.Kuerzel ? 1 : -1)
    if (!artists.length) {
        return null
    }
    const options = artists.map(artist => renderOption(artist))
    return [
        <ListSubheader key={key}>{label}</ListSubheader>
        , options
    ]
}