import {put} from "redux-saga/effects";
import {PROJEKTSTATUS_GESCHLOSSEN} from "../../../models/projekt";
import {createNewProjekt} from "./createNewProjekt";
import {loadImagesOfEditedTerminProjektAction} from "../terminActions";

export function* evaluateProjektAndLoadingImagesIfNeeded(selectableProjekte, artistId, kundId) {
    let projekt = selectableProjekte.find(p => p.ProjStat !== PROJEKTSTATUS_GESCHLOSSEN && p.ArtistID === artistId)
    if (projekt === undefined) {
        projekt = yield* createNewProjekt(kundId, artistId)
    }
    if (projekt !== undefined) {
        if (projekt.ProjektID < 0) {
            selectableProjekte.push(projekt)
        } else {
            yield put(loadImagesOfEditedTerminProjektAction(projekt.ProjektID))
        }
    }
    return projekt
}