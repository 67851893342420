import {writeTerminData} from "./writeTerminData";
import {selectArtistMap, selectStudioTimezone} from "../../common/commonSelector";
import {selectLocale} from "../../auth/authSelector";
import {emitNotificationAction} from "../../notification/notificationAction";
import {
    constructTerminProjektInfoText,
    createErrorNotification,
    createInfoNotification
} from "../../../models/notification";
import i18next from "i18next";
import {put, select, takeLatest} from "redux-saga/effects";
import {SAVE_TERMIN_DATA} from "../terminActions";
import {i18_TRANSLATION} from "../../../i18nReferences";

export function* saveTerminData({payload}) {
    try {
        yield* writeTerminData(payload.terminData)
        const artistMap = yield select(state => selectArtistMap(state))
        const locale = yield select(state => selectLocale(state))
        const timezone = yield select(state => selectStudioTimezone(state))
        yield put(emitNotificationAction(createInfoNotification(constructTerminProjektInfoText({
            ...payload.terminData,
            artistMap,
            locale,
            timezone
        }), 'saveTerminData')))
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.SAVE_DATA_FAILED_INFO', {ns: i18_TRANSLATION}), 'saveTerminData')))
        console.log(err)
    }
}

export function* watchSaveTerminData() {
    yield takeLatest(SAVE_TERMIN_DATA, saveTerminData)
}
