import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {enrichWithArtistIdListForNoAdmin} from "../common/enrichWithArtistIdListForNoAdmin";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {getProjektListe} from "../../../../api/projektApi";
import {
    LOAD_PROJEKT_LIST_DATA,
    loadProjektListDataFailureAction,
    loadProjektListDataSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../../i18nReferences";

function* processLoadProjektListData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const parameters = yield enrichWithArtistIdListForNoAdmin(payload.parameters)
            const projekte = extractDataFromResponse(yield call(getProjektListe, {token, parameters}))
            yield put(loadProjektListDataSuccessAction(projekte))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.LOAD_PROJEKT_LIST_DATA_FAILED_INFO', {ns: i18_EDITPROJEKT}), 'loadListData')))
        yield put(loadProjektListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadProjektListData() {
    yield takeLatest(LOAD_PROJEKT_LIST_DATA, processLoadProjektListData)
}