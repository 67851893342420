import {takeLatest, put, select, call} from "redux-saga/effects";
import {CLEANUP_JOBS, loadMandantenJobsAction} from "../../listActions";
import {getArtistToken} from "../../../auth/token.saga";
import {selectParameterForJobsList} from "../../listSelector";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {deleteMandantenJobs} from "../../../../api/mandantenJobs";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processCleanupJobs({payload}) {
    try {
        const token = yield getArtistToken()
        const parameters = payload.parameters
        if (token) {
            const selectionParameters = yield select(state => selectParameterForJobsList(state))
            const {affectedRows} = extractDataFromResponse(yield call(deleteMandantenJobs, {token, parameters}))
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('JOBS_CLEANUP_DIALOG.CLEANUP_JOBS_SUCCESS_INFO'
                , {ns: i18_LIST, affectedRows}), 'cleanup')))
            if (affectedRows) {
                yield put(loadMandantenJobsAction(selectionParameters))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchCleanupJobs() {
    yield takeLatest(CLEANUP_JOBS, processCleanupJobs)
}