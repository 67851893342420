import {put, takeLatest, call} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {broadcastKunden} from "../../../../api/kundenApi";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {INITIATE_BROADCAST_KUNDEN_JOB} from "../../listActions";
import {i18_LIST} from "../../../../i18nReferences";

function* processBroadcastKunden({payload}) {
    const count = payload.parameters?.kundIdList?.length || payload.parameters?.projektIdList?.length
        || payload.parameters?.terminIdList?.length
    try {
        const token = yield getArtistToken()
        const parameters = payload.parameters
        if (token && count) {
            const {jobId} = extractDataFromResponse(yield call(broadcastKunden, {token, parameters}))
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('BROADCAST.SEND_SUCCESS_NOTIFICATION', {
                ns: i18_LIST,
                jobId,
                count
            }), 'processBroadcastKunden')))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('BROADCAST.SEND_FAILURE_NOTIFICATION', {
            ns: i18_LIST,
            count
        }), 'processBroadcastKunden')))
        console.log(err)
    }
}

export function* watchBroadcastKunden() {
    yield takeLatest(INITIATE_BROADCAST_KUNDEN_JOB, processBroadcastKunden)
}