import React from 'react';
import './timeline.css'
import {Box} from "@mui/material";

const DAYS_INDEXES = [0, 1, 2, 3, 4, 5, 6]

const EventSlot = ({slot}) => {
    if (!slot) {
        return null
    }
    if (slot.isBlank) {
        return (
            <Box flex={slot.length} sx={{opacity: 0}}/>
        )
    }
    return (
        <Box flex={slot.length} bgcolor={slot.isBlank ? 'inherit' : slot.color} sx={{opacity: slot.isBlank ? 0 : 1}}/>
    )
}
const OffBusinessSlot = ({slot, isDark}) => {
    if (!slot) {
        return null
    }
    if (slot.isBlank) {
        return (
            <Box flex={slot.length} sx={{opacity: 0}}/>
        )
    }
    return (
        <div className="off-business" style={{flex: slot.length, opacity: isDark ? 0.5 : 0.1}}>
        </div>
    )
}
const EventsRow = ({data}) => {
    if (!data) {
        return null
    }
    return (
        <div className="events-row">
            {data.map((slot, idx) => <EventSlot key={'slot_' + idx} slot={slot}/>)}
        </div>
    )
}

const Day = ({dayData, isDark, onClickDay}) => {
    if (!dayData) {
        return null
    }
    return (
        <div className="day" onClick={onClickDay}>
            {dayData.offSite.length ? <div className="events-container">
                {dayData.offSite.map((row, idx) => <EventsRow key={'row_' + idx} data={[row]}/>)}
            </div> : null}
            {!dayData.offSite.length ? <div className="events-container">
                {dayData.bookedSlots.map((row, idx) => <EventsRow key={'row_' + idx} data={row}/>)}
            </div> : null}
            {!dayData.offSite.length ? <div className="business-hours">
                {dayData.offBusiness.map((row, idx) => <OffBusinessSlot key={'off_row_' + idx} slot={row}
                                                                        isDark={isDark}/>)}
            </div> : null}
        </div>
    )
}
const TimelineData = ({artistData, isDark, onClickDay, onClickArtist}) => {
    if (!artistData) {
        return null
    }
    return (
        <div className="artist-row">
            <div className="artist-title-container">
                <div className={"title with-link " + (isDark ? 'dark' : 'light')} onClick={() => onClickArtist({ArtistID: artistData.ArtistID})}>
                    {artistData.Kuerzel}
                </div>
            </div>
            <div className="artist-days-container">
                {DAYS_INDEXES.map((d) => <Day key={'day_' + d} onClickDay={() => onClickDay({dayIndex: d})}
                                              dayData={{
                                                  bookedSlots: artistData.bookedSlots[d],
                                                  offBusiness: artistData.offBusiness[d],
                                                  offSite: artistData.offSite[d],
                                              }} isDark={isDark}/>)}
            </div>
        </div>
    );
};

export default TimelineData;
