import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {initiateStudioLoginReset, loginStudioAction} from "../../store/auth/authActions";
import {
    selectStudioToken,
    selectIsStudioLoginFailed,
    selectStudioLoginResetResult, selectStudioLoginResetResultError
} from "../../store/auth/authSelector";
import {useTranslation} from "react-i18next";
import Login from "./Login";

const LoginStudioContainer = () => {
    const dispatch = useDispatch()
    const isStudioLoginFailed = useSelector(state => selectIsStudioLoginFailed(state))
    const istStudioLoggedIn = useSelector(state => selectStudioToken(state))
    const studioLoginResetResult = useSelector(state => selectStudioLoginResetResult(state))
    const studioLoginResetResultError = useSelector(state => selectStudioLoginResetResultError(state))
    const loginHandler = data => dispatch(loginStudioAction(data))
    const {t} = useTranslation()

    const handleReset = (studioName) => dispatch(initiateStudioLoginReset(studioName))

    return (
        <>
            <Login onSubmit={loginHandler}
                   isLoginFailed={isStudioLoginFailed}
                   istLoggedIn={!!istStudioLoggedIn}
                   successText={t('LOGIN_STUDIO.SUCCESS_TEXT')}
                   loginFailedText={t('LOGIN_STUDIO.FAILED_TEXT')}
                   namespace={'Studio'}
                   nameText={t('LOGIN.STUDIO_NAME')}
                   onClickReset={handleReset}
                   loginResetResult={studioLoginResetResult}
                   loginResetError={studioLoginResetResultError}
                   t={t}
            />
        </>

    );
};

export default LoginStudioContainer;