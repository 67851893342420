import {
    ARTIST_TOKEN_LOADED,
    CLEAR_ARTIST_AUTH_FAILURE,
    INITIATE_ARTIST_LOGIN_RESET,
    INITIATE_STUDIO_LOGIN_RESET,
    LOGIN_ARTIST,
    LOGIN_ARTIST_FAILURE,
    LOGIN_ARTIST_REFRESH_SUCCESS,
    LOGIN_ARTIST_SUCCESS,
    LOGIN_STUDIO,
    LOGIN_STUDIO_FAILURE,
    LOGIN_STUDIO_REFRESH_SUCCESS,
    LOGIN_STUDIO_SUCCESS,
    LOGOUT_ARTIST,
    LOGOUT_STUDIO,
    REGISTER_ARTIST_LOGIN_RESET_RESPONSE,
    REGISTER_STUDIO_LOGIN_RESET_RESPONSE,
    STUDIO_TOKEN_LOADED
} from "./authActions";
import {
    asyncLoadingActionFailure,
    asyncLoadingActionSuccess,
    initiateAsyncLoadingAction,
    removeField, replacePayloadFields
} from "../utils/reducerUtils";

const INITIAL = {};

export default function authReducer(state = INITIAL, action) {
    switch (action.type) {
        case LOGIN_STUDIO:
            return {
                ...initiateAsyncLoadingAction(state, 'studioAuth'),
                studioLoginResetResult: undefined
            }
        case LOGIN_STUDIO_REFRESH_SUCCESS:
            return {
                ...state,
                studioAuth: {
                    ...state.studioAuth,
                    studioToken: action.payload.studioToken
                }
            }
        case LOGOUT_STUDIO:
            return removeField(state, 'studioAuth')
        case LOGIN_STUDIO_SUCCESS:
        case STUDIO_TOKEN_LOADED:
            return asyncLoadingActionSuccess(state, action, 'studioAuth')
        case LOGIN_STUDIO_FAILURE:
            return asyncLoadingActionFailure(state, 'studioAuth')
        case LOGIN_ARTIST:
            return {
                ...initiateAsyncLoadingAction(state, 'artistAuth')
                , artistLoginResetResult: undefined
            }

        case LOGIN_ARTIST_REFRESH_SUCCESS:
            return {
                ...state,
                artistAuth: {
                    ...state.artistAuth,
                    artistToken: action.payload.artistToken
                }
            }
        case LOGOUT_ARTIST:
            return removeField(state, 'artistAuth')
        case LOGIN_ARTIST_SUCCESS:
        case ARTIST_TOKEN_LOADED:
            return asyncLoadingActionSuccess(state, action, 'artistAuth')
        case LOGIN_ARTIST_FAILURE:
            return asyncLoadingActionFailure(state, 'artistAuth')

        case CLEAR_ARTIST_AUTH_FAILURE:
            return {
                ...state,
                artistAuthFailure: undefined
            }

        case INITIATE_STUDIO_LOGIN_RESET:
            return {
                ...state,
                studioLoginResetResult: undefined
            }
        case INITIATE_ARTIST_LOGIN_RESET:
            return {
                ...state,
                artistLoginResetResult: undefined
            }
        case REGISTER_STUDIO_LOGIN_RESET_RESPONSE:
        case REGISTER_ARTIST_LOGIN_RESET_RESPONSE:
            return replacePayloadFields(state, action)
        default:
            return state;
    }
}