import { fork } from "redux-saga/effects";
import { watchLoadCheckoutListData } from "./sagas/checkout/loadCheckoutListData";
import { watchLoadEinnahmenListData } from "./sagas/einnahmen/loadEinnahmenListData";
import { watchCleanupJobs } from "./sagas/jobs/cleanupJobs";
import { watchLoadJobDetails } from "./sagas/jobs/LoadJobDetails";
import { watchLoadMandantenJobsListData } from "./sagas/jobs/loadMandantenJobsListData";
import { watchAddExclusions } from "./sagas/kunden/addDblExclusions";
import { watchBroadcastKunden } from "./sagas/kunden/broadcastKunden";
import { watchLoadDblExclusionListData } from "./sagas/kunden/dblExclusionsList";
import { watchDeleteDblExclusion } from "./sagas/kunden/deleteDblExclusion";
import { watchLoadDoublettenListData } from "./sagas/kunden/doublettenList";
import { watchLoadKundenListData } from "./sagas/kunden/loadKundenList";
import { watchLoadMergeKundenData } from "./sagas/kunden/loadMergeKundenData";
import { watchMergeDoublets } from "./sagas/kunden/mergeDoublets";
import { watchAddStandardLists } from "./sagas/lists/addStandardLists";
import { watchCopyListDefinitionAndNavigate } from "./sagas/lists/copyListDefinitionAndNavigate";
import { watchDeleteListDefinition } from "./sagas/lists/deleteListDefinition";
import { watchLoadAllReportDefinition } from "./sagas/lists/loadAllReportDefinition";
import { watchLoadReportDefinition } from "./sagas/lists/loadReportDefinition";
import { watchSaveListDefinition } from "./sagas/lists/saveListDefinition";
import { watchDeleteNachrichten } from "./sagas/nachrichten/deleteNachrichten";
import { watchLoadNachrichtenListData } from "./sagas/nachrichten/loadNachrichtenListData";
import { watchLoadProjektListData } from "./sagas/projekt/loadProjektListData";
import { watchUpdateProjektSuccess } from "./sagas/projekt/reloadAfterUpdateProjekt";
import { watchSaveProjekt } from "./sagas/projekt/saveProjekt";
import { watchUpdateProjectList } from "./sagas/projekt/updateProjectList";
import { watchLoadTerminListData } from "./sagas/termin/loadTerminListData";
import { watchUpdateTerminList } from "./sagas/termin/updateTerminList";
import { watchLoadVouchInListData } from "./sagas/voucher/loadVouchInListData";

const listsSaga = [fork(watchLoadKundenListData), fork(watchLoadReportDefinition), fork(watchLoadAllReportDefinition), fork(watchSaveListDefinition)
    , fork(watchDeleteListDefinition), fork(watchCopyListDefinitionAndNavigate), fork(watchLoadProjektListData), fork(watchSaveProjekt)
    , fork(watchBroadcastKunden), fork(watchLoadMandantenJobsListData), fork(watchCleanupJobs), fork(watchLoadJobDetails), fork(watchAddStandardLists)
    , fork(watchLoadTerminListData), fork(watchLoadCheckoutListData), fork(watchUpdateProjectList), fork(watchUpdateTerminList)
    , fork(watchLoadEinnahmenListData), fork(watchLoadVouchInListData), fork(watchLoadDblExclusionListData), fork(watchDeleteDblExclusion), fork(watchLoadDoublettenListData)
    , fork(watchAddExclusions), fork(watchLoadMergeKundenData), fork(watchMergeDoublets), fork(watchUpdateProjektSuccess), fork(watchLoadNachrichtenListData)
    , fork(watchDeleteNachrichten)]

export default listsSaga