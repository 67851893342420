import {getArtistToken} from "../../../auth/token.saga";
import {clearDoublettenMergeKundenDataAction, loadDoublettenListDataAction, MERGE_DOUBLETS} from "../../listActions";
import {call, put, takeLatest, select} from "redux-saga/effects";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {mergeDoubletten} from "../../../../api/kundenApi";
import {selectCalendarEventZeitraum} from "../../../calendar/calendarSelector";
import {refreshLoadCalendarAction} from "../../../calendar/calendarActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processMergeDoublets({payload}) {
    try {
        const token = yield getArtistToken()
        const mergeData = payload.mergeData
        if (token) {
            const {kundIdFrom, kundIdTo} = mergeData
            const result = extractDataFromResponse(yield call(mergeDoubletten, {token, mergeData}))
            const {terminMergeResult, projektMergeResult} = result
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('LIST_DOUBLETTEN_DIALOG.MERGE_SUCCESS_INFO'
                , {
                    ns: i18_LIST,
                    kundIdFrom,
                    kundIdTo,
                    terminCount: terminMergeResult.affectedRows,
                    projektCount: projektMergeResult.affectedRows
                }), 'mergeDoublets')))
            yield put(clearDoublettenMergeKundenDataAction())
            yield put(loadDoublettenListDataAction())
            const selectedZeitraum = yield select(state => selectCalendarEventZeitraum(state))
            if (selectedZeitraum) {
                yield put(refreshLoadCalendarAction(selectedZeitraum))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchMergeDoublets() {
    yield takeLatest(MERGE_DOUBLETS, processMergeDoublets)
}