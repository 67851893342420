import {all, put, select, takeLatest} from "redux-saga/effects";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {selectStudioTimezone} from "../../common/commonSelector";
import {selectSelectedTerminKundID, selectTermin, selectTerminEditState} from "../terminSelectors";
import {
    ADD_TERMIN_DATA,
    AddTerminFailureAction,
    addTerminSuccessAction,
    loadHolidaysForTerminAction,
    loadTerminNoChangeAction,
    removeProjektFromEditStateAction,
    setEditStateTerminChangesAction
} from "../terminActions";
import {createHolidayParams} from "../../../models/termin";
import {getArtistToken} from "../../auth/token.saga";
import {getKundenData, getKundenProjekte} from "../../../api/kundenApi";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {evaluateProjektAndLoadingImagesIfNeeded} from "./evaluateProjektAndLoadingImagesIfNeeded";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {pushHistory} from "../../router/routerActions";
import {call} from "redux-saga-test-plan/matchers";
import {TERMINSTATUS_OFFEN} from "../../../models/terminStatus";

export function* addTermin({payload}) {
    try {
        const loggedInArtistId = yield select(state => selectLoggedInArtistID(state))
        const timezone = yield select(state => selectStudioTimezone(state))
        const {KundID, Typ, Start, DatBis, ArtistID, Ganztags} = payload.terminParms
        const termin = yield select(state => selectTermin(state))
        const terminEditState = yield select(state => selectTerminEditState(state))
        const selectedKundID = yield select(state => selectSelectedTerminKundID(state))
        if (termin != null && (selectedKundID === KundID || KundID === undefined && terminEditState !== undefined)) {
            yield put(loadTerminNoChangeAction())
            return
        }
        const newTermin = {
            ArtistID
            , Typ
            , TrmStat: TERMINSTATUS_OFFEN
            , Start
            , Ganztags
            , DatBis
            , KundID
            , _createdID: loggedInArtistId
            , _createdTS: new Date().toISOString()
            , teilnehmer: []
            , einnahmen: []
        }
        const terminData = {termin: newTermin}
        if (KundID != null) {
            const token = yield getArtistToken()
            if (token) {
                const {kundenData, selectableProjektData} = yield all({
                    kundenData: call(getKundenData, {token, kundId: KundID}),
                    selectableProjektData: call(getKundenProjekte, {token, kundId: KundID})
                })
                const kunde = extractDataFromResponse(kundenData)?.kunde
                const selectableProjekte = extractDataFromResponse(selectableProjektData)
                const projekt = yield* evaluateProjektAndLoadingImagesIfNeeded(selectableProjekte, ArtistID, KundID)
                yield put(setEditStateTerminChangesAction({KundID: kunde.KundID, ProjektID: projekt.ProjektID}))
                yield put(removeProjektFromEditStateAction())
                terminData.termin.ProjektID = projekt?.ProjektID
                terminData.kunde = kunde
                terminData.selectableProjekte = selectableProjekte
                terminData.projekt = projekt
                terminData.einnahmen = []
                terminData.teilnehmer = []
            }
        }
        yield put(addTerminSuccessAction(terminData))
        yield put(loadHolidaysForTerminAction(createHolidayParams(terminData.termin, timezone)))
    } catch (err) {
        console.log(err)
        yield put(AddTerminFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.LOAD_DATA_FAILED_INFO'), 'loadTerminData')))
        yield put(pushHistory('/'))
    }
}

export function* watchAddTermin() {
    yield takeLatest(ADD_TERMIN_DATA, addTermin)
}