import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";
import {convertImagesRaw2ProjektImages} from "../../models/projekt";

export const selectProjektState = createSelector(
    (state) => state.projekt
    , identityFunction
)

export const selectProjekt = createFieldSelector(selectProjektState, 'projekt')
export const selectProjektImagesRaw = createFieldSelector(selectProjektState, 'projektImages', [])
export const selectProjektImagesBase = createSelector(
    selectProjektImagesRaw
    , convertImagesRaw2ProjektImages
)
export const selectInsertedTempImages = createFieldSelector(selectProjektState, 'insertedImages', [])
export const selectDeletedProjektImages = createFieldSelector(selectProjektState, 'deletedImages', [])
export const selectDeletedProjektImagekeys = createSelector(
    selectDeletedProjektImages
    , deletedImages => deletedImages?.length > 0 ? deletedImages.map(t => t.key) : []
)

export const selectProjektImages = createSelector(
    selectProjektImagesBase
    , selectDeletedProjektImagekeys
    , selectInsertedTempImages
    , (projektImages, deletedKeys, insertedImages) => projektImages.filter(t => !deletedKeys.includes(t.key))
        .concat(insertedImages.map(key =>({type: 'temp', key})))
)
export const selectIsProjektUpdating = createBooleanFieldSelector(selectProjektState,'projektUpdatePending')
export const selectIsUploadingProjektImages = createBooleanFieldSelector(selectProjektState,'isUploadingImages')