import {getArtistToken} from "../../../auth/token.saga";
import {convertReportDefinitionSchemaToDb} from "../../../../models/lists";
import {insertStudioReportDefinition, updateStudioReportDefinition} from "../../../../api/studioApi";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {call, put} from "redux-saga/effects";
import {i18_LIST} from "../../../../i18nReferences";

export function* writeReportDefinition({payload}) {
    const token = yield getArtistToken()
    if (token) {
        let result = null
        const reportDefinition = convertReportDefinitionSchemaToDb(payload.reportDefinition)
        if (reportDefinition?.ReportDefID > 0) {
            yield call(updateStudioReportDefinition, {token, reportDefinition})
        } else {
            result = extractDataFromResponse(yield call(insertStudioReportDefinition, {token, reportDefinition}))
        }
        yield put(emitNotificationAction(createSuccessNotification(i18next.t('LIST.SAVE_DATA_SUCCESS_INFO', {ns: i18_LIST}), 'saveReportDefinition')))
        return result
    }
}