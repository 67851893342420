import {selectLoggedInArtist, selectSchreibRechteArtistIDList} from "../../../auth/authSelector";
import {select} from "redux-saga/effects";

export function* enrichWithArtistIdListForNoAdmin(parameters) {
    const {isAdmin, CannotEdit} = yield select(state => selectLoggedInArtist(state))
    if (!CannotEdit && !isAdmin && !parameters.artistIdList?.length) {
        const artistIdList = yield select(state => selectSchreibRechteArtistIDList(state))
        return {...parameters, artistIdList}
    }
    return parameters
}