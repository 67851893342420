import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {getAllStudioReportDefinitions} from "../../../../api/studioApi";
import {
    LOAD_ALL_REPORT_DEFINITIONS,
    loadAllReportDefinitionsFailureAction,
    loadAllReportDefinitionsSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {pushHistory} from "../../../router/routerActions";
import {i18_TRANSLATION} from "../../../../i18nReferences";

function* processLoadAllReportDefinition() {
    try {
        const token = yield getArtistToken()
        if (token) {
            const reportDefinitions = yield call(getAllStudioReportDefinitions, {token})
            yield put(loadAllReportDefinitionsSuccessAction(reportDefinitions))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.ERROR.LOAD_DATA_FAILED_INFO', {ns: i18_TRANSLATION}), 'loadReportDefinition')))
        yield put(loadAllReportDefinitionsFailureAction())
        yield put(pushHistory('/'))
        console.log(err)
    }
}

export function* watchLoadAllReportDefinition() {
    yield takeLatest(LOAD_ALL_REPORT_DEFINITIONS, processLoadAllReportDefinition)
}