import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {
    LOAD_DBLEXCLUSION_LIST_DATA,
    loadDblExclusionListDataFailureAction,
    loadDblExclusionListDataSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {getDblExclusionList} from "../../../../api/kundenApi";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadDblExclusionListData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const parameters = payload.parameters
            const vouchers = extractDataFromResponse(yield call(getDblExclusionList, {token, ...parameters}))
            yield put(loadDblExclusionListDataSuccessAction(vouchers))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.LOAD_DBLEXCLUSION_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadDoubletExclusions')))
        yield put(loadDblExclusionListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadDblExclusionListData() {
    yield takeLatest(LOAD_DBLEXCLUSION_LIST_DATA, processLoadDblExclusionListData)
}