import {createSelector} from "reselect";
import {createFieldSelector, identityFunction} from "../utils/selectors";

export const selectTimelineState = createSelector(
    (state) => state.timeline
    , identityFunction
)

export const selectTimelineData = createFieldSelector(selectTimelineState, 'data')
export const selectTimelineHolidays = createFieldSelector(selectTimelineData, 'holidayPeriods')
export const selectTimelineArtistDataMap = createFieldSelector(selectTimelineData, 'artistData', [])
