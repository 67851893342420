import * as React from 'react';
import BackendError from "./BackendError";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {loadCommonAction} from "../../store/common/commonActions";
import {pushHistory} from "../../store/router/routerActions";

const BackendErrorContainer = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const handleRetry = () => {
        console.log('retry')
        dispatch(loadCommonAction())
        dispatch(pushHistory('/'))
    }
    return (
        <BackendError t={t} onRetry={handleRetry}/>
    )
}

export default BackendErrorContainer

