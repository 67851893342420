import i18next from "i18next";
import {i18_STUDIO} from "../../../../i18nReferences";
import {getArtistToken} from "../../../auth/token.saga";
import {startStudioSubscription, updateSubscription} from "../../../../api/studioApi";
import {emitChangedNotification} from "../../../notification/notificationSaga";
import {loadSubscriptionAction, SAVE_SUBSCRIPTION, START_SUBSCRIPTION} from "../subscriptionActions";
import {call, put, takeLatest} from "redux-saga/effects"
import {getStudioSubscriptionTranslation} from "../../../../components/settings/helper";
import {clearStudioChangedAction} from "../../../common/commonActions";
import { SAVING_FAILED, SHOW_SAVED_SUCCESSFULLY } from "../../../../models/notification";


function* writeSubscription(subscription, actionType) {
    const category = i18next.t('STUDIO_SUBSCRIPTION_SETTINGS.HEADER', {ns: i18_STUDIO})
    const data = actionType === START_SUBSCRIPTION ? getStudioSubscriptionTranslation(i18next.t, 'START_INFO')
        : category
    try {
        const token = yield getArtistToken()
        if (token) {
            if (actionType === START_SUBSCRIPTION) {
                yield call(startStudioSubscription, {token, subscription})
            } else {
                yield call(updateSubscription, {token, subscription})
            }
            yield* emitChangedNotification({
                type: SHOW_SAVED_SUCCESSFULLY,
                data,
                category
            })
            yield put(clearStudioChangedAction())
            yield put(loadSubscriptionAction())
        }
    } catch (err) {
        console.log(err)
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

function* processStartSubscription({payload}) {
    const {subscription} = payload
    yield* writeSubscription(subscription, START_SUBSCRIPTION)
}

export function* watchStartSubscription() {
    yield takeLatest(START_SUBSCRIPTION, processStartSubscription)
}

function* processSaveSubscription({payload}) {
    const {subscription} = payload
    yield* writeSubscription(subscription, SAVE_SUBSCRIPTION)
}

export function* watchSaveSubscription() {
    yield takeLatest(SAVE_SUBSCRIPTION, processSaveSubscription)
}