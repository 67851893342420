import {getArtistToken} from "../auth/token.saga";
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {getKundenData, sendMail, sendSms} from "../../api/kundenApi";
import {
    LOAD_NACHRICHT_KUNDE,
    LOAD_NACHRICHT_TERMIN,
    loadNachrichtKundeSuccess,
    loadNachrichtTerminSuccess,
    SEND_NACHRICHT_ACTION,
    sendNachrichtFailedAction,
    sendNachrichtSuccessAction
} from "./nachrichtAction";
import {fork} from "redux-saga-test-plan/matchers";
import {MSGPREF_SMS} from "../../models/kunde";
import {emitNotificationAction} from "../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../models/notification";
import {selectNachrichtKunde} from "./nachrichtSelector";
import {getNachrichtTerminData} from "../../api/nachrichtApi";
import {extractMessageFromAxiosError} from "../utils/error";
import i18next from "i18next";
import {i18_NACHRICHT} from "../../i18nReferences";

function* processLoadNachrichtKunde({payload}) {
    try {
        const kundId = payload.kundId
        const token = yield getArtistToken()
        if (token) {
            const kundeData = extractDataFromResponse(yield call(getKundenData, {token, kundId}))
            yield put(loadNachrichtKundeSuccess(kundeData))
        }

    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_NACHRICHT.LOAD_DATA_FAILED', {ns: i18_NACHRICHT}))))
    }
}

function* watchLoadNachrichtKunde() {
    yield takeLatest(LOAD_NACHRICHT_KUNDE, processLoadNachrichtKunde)
}

function* processLoadNachrichtTermin({payload}) {
    try {
        const terminId = payload.terminId
        const token = yield getArtistToken()
        if (token) {
            const nachrichtData = extractDataFromResponse(yield call(getNachrichtTerminData, {token, terminId}))
            yield put(loadNachrichtTerminSuccess(nachrichtData))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_NACHRICHT.LOAD_DATA_FAILED', {ns: i18_NACHRICHT}))))
        console.log(err)
    }
}

function* watchLoadNachrichtTermin() {
    yield takeLatest(LOAD_NACHRICHT_TERMIN, processLoadNachrichtTermin)
}

function* processSendNachricht({payload}) {
    const kunde = yield select(state => selectNachrichtKunde(state))
    if (payload.nachricht?.TerminID) {
        payload.nachricht.ProjektID = undefined
    }
    const {NachrichtTyp, KundID} = payload.nachricht
    const receiver = NachrichtTyp === MSGPREF_SMS ? (kunde?.Mobil || '--') : (kunde?.Email || '--')
    try {
        const token = yield getArtistToken()
        if (token) {
            if (NachrichtTyp === MSGPREF_SMS) {
                yield call(sendSms, {token, kundId: KundID, nachricht: payload.nachricht})
            } else {
                yield call(sendMail, {token, kundId: KundID, nachricht: payload.nachricht})
            }
            yield put(sendNachrichtSuccessAction())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_NACHRICHT.SENT_SUCCESSFULLY', {
                ns: i18_NACHRICHT,
                receiver,
                name: kunde.Name
            }), 'processSendNachricht')))
        }
    } catch (err) {
        yield put(sendNachrichtFailedAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_NACHRICHT.SENDING_FAILED'
            , {ns: i18_NACHRICHT, receiver, name: kunde.Name, message: extractMessageFromAxiosError(err)}))))
    }
}

function* watchSendNachricht() {
    yield takeLatest(SEND_NACHRICHT_ACTION, processSendNachricht)
}

const nachrichtSaga = [fork(watchLoadNachrichtKunde), fork(watchSendNachricht), fork(watchLoadNachrichtTermin)]

export default nachrichtSaga
