export const ARTIST_SETTINGS = 'artistSettings'

const loadSettings = () => {
    const artistSettingsItem = localStorage.getItem(ARTIST_SETTINGS)
    return artistSettingsItem && JSON.parse(artistSettingsItem) || {}
}

export const loadArtistSettings = (artistId) => {
    const allArtistSettings = loadSettings()
    return allArtistSettings && allArtistSettings[artistId] || {}
}

const saveAllArtistSettings = (artistSettings) => localStorage.setItem(ARTIST_SETTINGS, JSON.stringify(artistSettings || {}))

const saveArtistSetting = (artistId, fieldValues) => {
    const allArtistSettings = loadSettings()
    const artistSettings = allArtistSettings[artistId] || {}
    allArtistSettings[artistId] = {
        ...artistSettings
        , ...fieldValues
    }
    saveAllArtistSettings(allArtistSettings)
}

export const saveArtistEventFilter = (artistId, eventFilter) => {
    saveArtistSetting(artistId, {eventFilter})
}

export const saveArtistInfoSettings = ({artistId, disabledInfoChanges}) => {
    const allArtistSettings = loadSettings()
    const artistSettings = allArtistSettings[artistId] || {}
    const artistInfoSettings = artistSettings?.disabledInfo || {}
    const disabledInfo = {
            ...artistInfoSettings
            , ...disabledInfoChanges
        }
    saveArtistSetting(artistId, {disabledInfo})
}

export const saveArtistResourceFilter = (artistId, resourceFilter) => {
    saveArtistSetting(artistId, {resourceFilter})
}

export const saveArtistThemeSetting = (artistId, themeSettings) => {
    saveArtistSetting(artistId, {themeSettings})
}
