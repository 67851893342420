import {getArtistToken} from "../../auth/token.saga";
import {selectPdfVorlagenMap} from "../commonSelector";
import {downloadTerminListe} from "../../../api/terminApi";
import {FILLED_VORLAGE_PDF} from "../../../models/downloads";
import {DOWNLOAD_FILLED_TERMINLIST_VORLAGE, downloadFilledTerminlistVorlageSuccessAction} from "../commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processDownloadFilledTerminlistVorlage({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const pdfVorlagenMap = yield select(state => selectPdfVorlagenMap(state))
            const bezeichnung = pdfVorlagenMap[payload.vorlagenConfig.PDFVorlagenID].Bezeichnung.replaceAll(" ", '_')
            const fileName = `${bezeichnung}.pdf`
            const response = yield call(downloadTerminListe, {
                token,
                terminId: payload.vorlagenConfig.terminId,
                pdfVorlagenId: payload.vorlagenConfig.PDFVorlagenID
            })
            const pdf = {blob: response.data, type: FILLED_VORLAGE_PDF, fileName}
            yield put(downloadFilledTerminlistVorlageSuccessAction(pdf))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('DOWNLOAD_FILE_FAILED_INFO', {ns: i18_TRANSLATION}), 'processDownloadFilledVorlage')))
        console.log(err)
    }
}

export function* watchDownloadFilledTerminlistVorlage() {
    yield takeLatest(DOWNLOAD_FILLED_TERMINLIST_VORLAGE, processDownloadFilledTerminlistVorlage)
}