// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.darkScrollbars  ::-webkit-scrollbar,
.lightScrollbars ::-webkit-scrollbar {
    width: 5px;
}

.darkScrollbars  ::-webkit-scrollbar-track,
.lightScrollbars  ::-webkit-scrollbar-track {
    background: lightgrey;
}

.darkScrollbars ::-webkit-scrollbar-track {
    background: gray;
}

.darkScrollbars ::-webkit-scrollbar-thumb {
    background: lightgrey;
}

.darkScrollbars ::-webkit-scrollbar-thumb:hover {
    background: white;
}

.lightScrollbars ::-webkit-scrollbar-track {
    background: lightgrey;
}

.lightScrollbars ::-webkit-scrollbar-thumb {
    background: gray;
}

.lightScrollbars ::-webkit-scrollbar-thumb:hover {
    background: black;
}`, "",{"version":3,"sources":["webpack://./src/components/common/listScrollbar.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;AACd;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".darkScrollbars  ::-webkit-scrollbar,\n.lightScrollbars ::-webkit-scrollbar {\n    width: 5px;\n}\n\n.darkScrollbars  ::-webkit-scrollbar-track,\n.lightScrollbars  ::-webkit-scrollbar-track {\n    background: lightgrey;\n}\n\n.darkScrollbars ::-webkit-scrollbar-track {\n    background: gray;\n}\n\n.darkScrollbars ::-webkit-scrollbar-thumb {\n    background: lightgrey;\n}\n\n.darkScrollbars ::-webkit-scrollbar-thumb:hover {\n    background: white;\n}\n\n.lightScrollbars ::-webkit-scrollbar-track {\n    background: lightgrey;\n}\n\n.lightScrollbars ::-webkit-scrollbar-thumb {\n    background: gray;\n}\n\n.lightScrollbars ::-webkit-scrollbar-thumb:hover {\n    background: black;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
