import {takeLatest, call, put} from "redux-saga/effects";
import {LOAD_MANDANTEN_JOBS_DATA, loadMandantenJobsFailureAction, loadMandantenJobsSuccessAction} from "../../listActions";
import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {getMandantenJobs} from "../../../../api/mandantenJobs";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadMandantenJobsListData({payload}) {
    try {
        const token = yield getArtistToken()
        const parameters = payload.parameters
        if (token) {
            const jobs = extractDataFromResponse(yield call(getMandantenJobs, {token, parameters}))
            yield put(loadMandantenJobsSuccessAction(jobs))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('JOBS_LIST.LOAD_JOB_DATA_FAILED_INFO'
            , {ns: i18_LIST}), 'loadListData')))
        yield put(loadMandantenJobsFailureAction())
        console.log(err)
    }
}

export function* watchLoadMandantenJobsListData() {
    yield takeLatest(LOAD_MANDANTEN_JOBS_DATA, processLoadMandantenJobsListData)
}