import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKunden} from "../../../api/kundenApi";
import {SEARCH_TERMIN_KUNDEN, searchTerminKundenSuccess} from "../terminActions";

export function* searchTerminKunden({payload}) {
    try {
        const namePart = payload.namePart
        const token = yield getArtistToken()
        if (token) {
            const kunden = extractDataFromResponse(yield call(getKunden, {token, namePart}))
            yield put(searchTerminKundenSuccess(kunden))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchSearchTerminKunden() {
    yield takeLatest(SEARCH_TERMIN_KUNDEN, searchTerminKunden)
}