import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {selectStudioData} from "../commonSelector";
import {getArtistToken} from "../../auth/token.saga";
import {updateStudio} from "../../../api/commonApi";
import {clearStudioChangedAction, reloadCommonStudioAction, UPDATE_STUDIO} from "../commonActions";
import {emitChangedNotification} from "../../notification/notificationSaga";
import {call, put, select, takeLatest} from "redux-saga/effects"
import { SAVING_FAILED, SHOW_SAVED_SUCCESSFULLY } from "../../../models/notification";

function* processUpdateStudio({payload}) {
    const {studio} = payload
    const {StudioName} = studio
    const category = i18next.t('CALENDAR_MENU.STUDIO_SETTINGS', {ns: i18_TRANSLATION})
    const studioBase = yield select(state => selectStudioData(state))
    studio.StudioID = studioBase.StudioID
    const data = StudioName
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(updateStudio, {token, studio})
            yield put(clearStudioChangedAction())
            yield put(reloadCommonStudioAction())
            yield* emitChangedNotification({
                type: SHOW_SAVED_SUCCESSFULLY,
                data,
                category
            })
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

export function* watchUpdateStudio() {
    yield takeLatest(UPDATE_STUDIO, processUpdateStudio)
}