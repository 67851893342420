import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

// Termin
export const LOAD_TERMIN_DATA = 'LOAD_TERMIN_DATA'
export const initiateLoadTerminAction = createSimpleAction(LOAD_TERMIN_DATA, 'terminParms')
export const LOAD_TERMIN_DATA_SUCCESS = 'LOAD_TERMIN_DATA_SUCCESS'
export const loadTerminSuccessAction = createSimpleAction(LOAD_TERMIN_DATA_SUCCESS, 'terminData')
export const LOAD_TERMIN_DATA_FAILURE = 'LOAD_TERMIN_DATA_FAILURE'
export const loadTerminFailureAction = createTypeAction(LOAD_TERMIN_DATA_FAILURE)
export const LOAD_TERMIN_DATA_NO_CHANGE = 'LOAD_TERMIN_DATA_NO_CHANGE'
export const loadTerminNoChangeAction = createTypeAction(LOAD_TERMIN_DATA_NO_CHANGE)

export const ADD_TERMIN_DATA = 'ADD_TERMIN_DATA'
export const initiateAddTerminAction = createSimpleAction(ADD_TERMIN_DATA, 'terminParms')
export const ADD_TERMIN_DATA_SUCCESS = 'ADD_TERMIN_DATA_SUCCESS'
export const addTerminSuccessAction = createSimpleAction(ADD_TERMIN_DATA_SUCCESS, 'terminData')
export const ADD_TERMIN_DATA_FAILURE = 'ADD_TERMIN_DATA_FAILURE'
export const AddTerminFailureAction = createTypeAction(ADD_TERMIN_DATA_FAILURE)

// Holidays
export const LOAD_HOLIDAYS_FOR_TERMIN = 'LOAD_HOLIDAYS_FOR_TERMIN'
export const loadHolidaysForTerminAction = createSimpleAction(LOAD_HOLIDAYS_FOR_TERMIN, 'holidayParameters')
export const LOAD_HOLIDAYS_FOR_TERMIN_SUCCESS = 'LOAD_HOLIDAYS_FOR_TERMIN_SUCCESS'
export const loadHolidaysForTerminSuccessAction = createSimpleAction(LOAD_HOLIDAYS_FOR_TERMIN_SUCCESS, 'terminHoliday')

// Kunde
export const SEARCH_TERMIN_KUNDEN = 'SEARCH_TERMIN_KUNDEN'
export const searchTerminKunden = createSimpleAction(SEARCH_TERMIN_KUNDEN, 'namePart')
export const SEARCH_TERMIN_KUNDEN_SUCCESS = 'SEARCH_TERMIN_KUNDEN_SUCCESS'
export const searchTerminKundenSuccess = createSimpleAction(SEARCH_TERMIN_KUNDEN_SUCCESS, 'terminKunden')
export const CHANGE_TERMIN_KUNDE = "CHANGE_TERMIN_KUNDE"
export const changeTerminKundeAction = createSimpleAction(CHANGE_TERMIN_KUNDE, 'changeKundeParameters')
export const CHANGE_TERMIN_KUNDE_SUCCESS = "CHANGE_TERMIN_KUNDE_SUCCESS"
export const changeTerminKundeSuccessAction = createSimpleAction(CHANGE_TERMIN_KUNDE_SUCCESS, 'changeTerminKundeData')
export const CHANGE_TERMIN_KUNDE_FAILURE = "CHANGE_TERMIN_KUNDE_FAILURE"
export const changeTerminKundeFailureAction = createTypeAction(CHANGE_TERMIN_KUNDE_FAILURE)

// Projekt
export const EDIT_TERMIN_ADD_PROJEKT = 'EDIT_TERMIN_ADD_PROJEKT'
export const editTerminAddProjektAction = createTypeAction(EDIT_TERMIN_ADD_PROJEKT)
export const EDIT_TERMIN_ADD_PROJEKT_SUCCESS = 'EDIT_TERMIN_ADD_PROJEKT_SUCCESS'
export const editTerminAddProjektSuccessAction = createSimpleAction(EDIT_TERMIN_ADD_PROJEKT_SUCCESS, 'newProjekt')

//common
export const CLEAR_TERMIN_DATA = 'CLEAR_TERMIN_DATA'
export const clearTerminDataAction = createTypeAction(CLEAR_TERMIN_DATA)
export const SET_EDIT_STATE_TERMIN_CHANGES = 'SET_EDIT_STATE_TERMIN_CHANGES'
export const setEditStateTerminChangesAction = createSimpleAction(SET_EDIT_STATE_TERMIN_CHANGES, 'terminChanges')
export const SET_EDIT_STATE_PROJEKT = 'SET_EDIT_STATE_PROJEKT'
export const setEditStateProjektAction = createSimpleAction(SET_EDIT_STATE_PROJEKT, 'projekt')
export const SET_EDIT_STATE_PROJEKT_CHANGES = 'SET_EDIT_STATE_PROJEKT_CHANGES'
export const setEditStateProjektChangesAction = createSimpleAction(SET_EDIT_STATE_PROJEKT_CHANGES, 'projektChanges')
export const SET_EDIT_STATE_REMOVE_PROJEKT = 'SET_EDIT_STATE_REMOVE_PROJEKT'
export const setEditStateRemoveProjektAction = createTypeAction(SET_EDIT_STATE_REMOVE_PROJEKT)
export const SET_EDIT_STATE_REMOVE_KUNDE = 'SET_EDIT_STATE_REMOVE_KUNDE'
export const setEditStateRemoveKundeAction = createTypeAction(SET_EDIT_STATE_REMOVE_KUNDE)
export const REMOVE_PROJEKT_FROM_EDITSTATE = 'REMOVE_PROJEKT_FROM_EDITSTATE'
export const removeProjektFromEditStateAction = createTypeAction(REMOVE_PROJEKT_FROM_EDITSTATE)

// images
export const DELETE_TERMIN_IMAGE = 'DELETE_TERMIN_IMAGE'
export const deleteTerminImage = createSimpleAction(DELETE_TERMIN_IMAGE, 'deletedImage')

export const ADD_TERMIN_IMAGES = 'ADD_TERMIN_IMAGES'
export const addTerminImagesAction = createSimpleAction(ADD_TERMIN_IMAGES, 'images')

export const ADD_TERMIN_IMAGES_SUCCESS = 'ADD_TERMIN_IMAGES_SUCCESS'
export const addTerminImagesSuccessAction = createSimpleAction(ADD_TERMIN_IMAGES_SUCCESS, 'keyList')

export const ADD_TERMIN_IMAGES_FAILURE = 'ADD_TERMIN_IMAGES_FAILURE'
export const addTerminImagesFailureAction = createTypeAction(ADD_TERMIN_IMAGES_FAILURE)

export const LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT = 'LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT'
export const loadImagesOfEditedTerminProjektAction = createSimpleAction(LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT, 'projektId')
export const LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT_SUCCESS = 'LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT_SUCCESS'
export const loadImagesOfEditedTerminProjektSuccessAction = createSimpleAction(LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT_SUCCESS, 'projektImages')

// einnahmen
export const UPDATE_EDIT_TERMIN_EINNAHME = 'UPDATE_EDIT_TERMIN_EINNAHME'
export const updateEditTerminEinnahmeAction = createSimpleAction(UPDATE_EDIT_TERMIN_EINNAHME, 'einnahme')
export const ADD_EDIT_TERMIN_EINNAHME = 'ADD_EDIT_TERMIN_EINNAHME'
export const addEditTerminEinnahmeAction = createSimpleAction(ADD_EDIT_TERMIN_EINNAHME, 'einnahme')
export const DELETE_EDIT_TERMIN_EINNAHME = 'DELETE_EDIT_TERMIN_EINNAHME'
export const deleteEditTerminEinnahmeAction = createSimpleAction(DELETE_EDIT_TERMIN_EINNAHME, 'einnahmeId')

export const LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME = 'LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME'
export const loadVoucherDataForTerminEinnahme = createSimpleAction(LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME, 'voucherNo')

export const LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_SUCCESS = 'LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_SUCCESS'
export const loadVoucherDataForTerminEinnahmeSuccess = createSimpleAction(LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_SUCCESS, 'editEinnahmeVoucher')

export const LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_NOT_FOUND = 'LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_NOT_FOUND'
export const loadVoucherDataForTerminEinnahmeNotFound = createTypeAction(LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_NOT_FOUND)

export const CLEAR_VOUCHER_DATA_FOR_TERMIN_EINNAHME = 'CLEAR_VOUCHER_DATA_FOR_TERMIN_EINNAHME'
export const clearVoucherDataForTerminEinnahmeAction = createTypeAction(CLEAR_VOUCHER_DATA_FOR_TERMIN_EINNAHME)


// teilnehmer
export const UPDATE_EDIT_TERMIN_TEILNEHMER = 'UPDATE_EDIT_TERMIN_TEILNEHMER'
export const updateEditTerminTeilnehmer = createSimpleAction(UPDATE_EDIT_TERMIN_TEILNEHMER, 'teilnehmer')


// saving
export const SAVE_TERMIN_DATA = 'SAVE_TERMIN_DATA'
export const saveTerminData = createSimpleAction(SAVE_TERMIN_DATA, 'terminData')

//deleting
export const DELETE_TERMIN = 'DELETE_TERMIN'
export const deleteTerminAction = createSimpleAction(DELETE_TERMIN, 'terminId')

export const IMPORT_ICAL_TERMINE = 'IMPORT_ICAL_TERMINE'
export const importICalTermineAction = createSimpleAction(IMPORT_ICAL_TERMINE, 'icalTermine')
export const IMPORT_ICAL_TERMINE_SUCCESS = 'IMPORT_ICAL_TERMINE_SUCCESS'
export const importICalTermineSuccessAction = createSimpleAction(IMPORT_ICAL_TERMINE_SUCCESS, 'icalImportCounters')
export const IMPORT_ICAL_TERMINE_FAILURE = 'IMPORT_ICAL_TERMINE_FAILURE'
export const importICalTermineFailureAction = createSimpleAction(IMPORT_ICAL_TERMINE_FAILURE, 'icalImportCounters')
export const IMPORT_ICAL_RESET_REQUEST = 'IMPORT_ICAL_RESET_REQUEST'
export const importICalResetRequestAction = createTypeAction(IMPORT_ICAL_RESET_REQUEST)
export const SKIP_IMPORT_ICAL_TERMINE = 'SKIP_IMPORT_ICAL_TERMINE'
export const skipImportIcalTermine = createSimpleAction(SKIP_IMPORT_ICAL_TERMINE, 'icalImportCounters')
