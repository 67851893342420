import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {call} from "redux-saga-test-plan/matchers";
import {getArtistDetailData} from "../../../api/artistApi";
import {LOAD_ARTIST_MAIL_DATA, loadArtistMailDataSuccessAction} from "../artistActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {pushHistory} from "../../router/routerActions";
import {put, takeLatest} from "redux-saga/effects";
import {i18_ARTIST} from "../../../i18nReferences";

function* processLoadArtistMailData({payload}) {
    try {
        const artistId = payload.artistId
        const token = yield getArtistToken()
        if (token) {
            const kundeData = extractDataFromResponse(yield call(getArtistDetailData, {
                token,
                artistId,
                segments: 'mail'
            }))
            yield put(loadArtistMailDataSuccessAction(kundeData))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.LOAD_DATA_FAILED_INFO', {ns: i18_ARTIST}), 'loadArtistData')))
        yield put(pushHistory('/'))
        console.log(err)
    }
}

export function* watchLoadArtistMailData() {
    yield takeLatest(LOAD_ARTIST_MAIL_DATA, processLoadArtistMailData)
}