import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";

export const PROJEKT_201_11_AUSSTEHENDE_TERMINE_PRUEFEN = {
    filter: {
        items: [
            {
                field: "Ausstehend",
                operator: "<",
                id: 84865,
                value: "0"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        ProjektBemerkung: true,
        _createdKuerzel: false,
        _createdTS: false,
        hatAnzDifferenz: false
    }
}

export const PROJEKT_202_30_RUHENDE_PROJEKTE_MIT_TKAZ = {
    sort: [
        {
            field: "ArtistKuerzel",
            sort: "asc"
        },
        {
            field: "LetzterTermin",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "AnzStatus",
                operator: ">",
                id: 98296,
                value: "0"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "ArtistKuerzel",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        hatAnzDifferenz: false,
        _createdKuerzel: false,
        _createdTS: false
    }
}

export const PROJEKT_203_11_ERLEDIGTE_PROJEKTE_MIT_TKAZ = {
    filter: {
        items: [
            {
                field: "AnzStatus",
                operator: ">",
                id: 52950,
                value: "0"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        _createdKuerzel: false,
        _createdTS: false,
        hatAnzDifferenz: false
    }
}

export const PROJEKT_204_20_ALLES_OK_MIT_NEUEM_TATTOO = {
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        _createdKuerzel: false,
        _createdTS: false,
        hatAnzDifferenz: false
    }
}

export const PROJEKT_205_GUEST_ARTIST_WIEDER_DA = {
    sort: [
        {
            field: "ArtistKuerzel",
            sort: "asc"
        },
        {
            field: "LetzterTermin",
            sort: "asc"
        }
    ],
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "ArtistKuerzel"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzterTermin: true,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        _createdKuerzel: false,
        _createdTS: false,
        hatAnzDifferenz: false
    }
}

export const KUNDE_101_GEBURTSTAGSAKTION = {
    sort: [
        {
            field: "NearestBirthday",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "NearestBirthday",
                id: 34223,
                operator: "onOrAfter",
                value: "2022-09-01"
            },
            {
                field: "NearestBirthday",
                operator: "onOrBefore",
                id: 9261,
                value: "2022-09-30"
            },
            {
                field: "LetztArbTerm_Tattoo",
                id: 46602,
                operator: "isNotEmpty"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        Geschlecht: false,
        Birthday: false,
        Telefon: false,
        Mobil: false,
        Email: false,
        MsgPref: false,
        hasKontaktErr: false,
        KannKuFri: false,
        Strasse: false,
        Plz: false,
        Ort: false,
        Land: false,
        Beruf: false,
        Deseases: false,
        ArtistID: false,
        Kuerzel: false,
        _createdKuerzel: false,
        _createdTS: false
    }
}

export const KUNDE_102_KUNDEN_NACH_STILRICHTUNGEN = {
    filter: {
        items: [
            {
                field: "Bemerkungen",
                operator: "contains",
                id: 73376,
                value: "#realistic"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        Geschlecht: false,
        Birthday: false,
        NearestBirthday: false,
        Telefon: false,
        Mobil: false,
        Email: false,
        MsgPref: false,
        hasKontaktErr: false,
        Strasse: false,
        Plz: false,
        Ort: false,
        Land: false,
        Beruf: false,
        Deseases: false,
        ArtistID: false,
        Kuerzel: false,
        _createdKuerzel: false,
        _createdTS: false
    }
}

export const PROJEKT_206_12_FEHLER_BEIM_ANZAHLUNGSSALDO = {
    sort: [
        {
            field: "Nachname",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "hatAnzDifferenz",
                operator: "is",
                id: 68343,
                value: "true"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsEingang: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzterTermin: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        _createdKuerzel: false,
        _createdTS: false,
        DiffAnzSaldo: false
    }
}

export const TERMIN_301_FEHLENDE_ANZAHLUNGEN = {
    sort: [
        {
            field: "Start",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "DiffAnzSaldo",
                operator: "is",
                id: 82629,
                value: "true"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektArtistID: false,
        ProjStat: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const TERMIN_302_HINFAELLIGE_TERMINE = {
    sort: [
        {
            field: "ArtistID",
            sort: "asc"
        },
        {
            field: "Start",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "ProjStat",
                operator: "isAnyOf",
                id: 35030,
                value: [
                    "geschlossen",
                    "closed"
                ]
            },
            {
                field: "Betreff",
                operator: "containsNot",
                id: 89907,
                value: "touch-up"
            },
            {
                field: "Betreff",
                operator: "containsNot",
                id: 79829,
                value: "Nachstechen"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "TrmStat",
            "Start",
            "DatBis",
            "Betreff",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektArtistID: false,
        ProjStat: false,
        AnzAgreed: false,
        AnzStatus: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const KUNDE_103_KRANKHEITEN_BEREINIGEN = {
    filter: {
        items: [
            {
                field: "Deseases",
                operator: "contains",
                id: 37749,
                value: "nein"
            },
            {
                field: "Deseases",
                operator: "contains",
                id: 78166,
                value: "kein"
            },
            {
                field: "Deseases",
                operator: "contains",
                id: 99989,
                value: "nicht"
            },
            {
                field: "Deseases",
                operator: "contains",
                id: 73081,
                value: "nix"
            },
            {
                field: "Deseases",
                operator: "contains",
                id: 15933,
                value: "alles"
            }
        ],
        logicOperator: "or",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KundID: false,
        Geschlecht: false,
        Birthday: false,
        NearestBirthday: false,
        Telefon: false,
        Mobil: false,
        Email: false,
        MsgPref: false,
        hasKontaktErr: false,
        KannKuFri: false,
        Strasse: false,
        Plz: false,
        Ort: false,
        Land: false,
        Beruf: false,
        ArtistID: false,
        Kuerzel: false,
        AnzahlTermine_Tattoo: false,
        AnzahlTermine_Piercing: false
    }
}

export const KUNDE_104_KONTAKTPROBLEME = {
    filter: {
        items: [
            {
                field: "hasKontaktErr",
                operator: "is",
                id: 34269,
                value: "true"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KundID: false,
        Geschlecht: false,
        Birthday: false,
        NearestBirthday: false,
        KannKuFri: false,
        Strasse: false,
        Plz: false,
        Ort: false,
        Land: false,
        Beruf: false,
        Deseases: false,
        ArtistID: false,
        Kuerzel: false,
        AnzahlTermine_Tattoo: false,
        AnzahlTermine_Piercing: false
    }
}

export const KUNDE_105_FEHLENDE_GEBURTSTAGE = {
    filter: {
        items: [
            {
                field: "Birthday",
                operator: "isEmpty",
                id: 81506
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KundID: false,
        Geschlecht: false,
        NearestBirthday: false,
        Telefon: false,
        Mobil: false,
        Email: false,
        MsgPref: false,
        hasKontaktErr: false,
        KannKuFri: false,
        Strasse: false,
        Plz: false,
        Ort: false,
        Land: false,
        Beruf: false,
        Deseases: false,
        ArtistID: false,
        Kuerzel: false,
        AnzahlTermine_Tattoo: false,
        AnzahlTermine_Piercing: false
    }
}

export const PROJEKT_207_PROJEKTNAMEN_PFLEGEN = {
    sort: [
        {
            field: "ArtistKuerzel",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "ProjName",
                operator: "contains",
                id: 58861,
                value: "Sitzung"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 13770,
                value: "Besprechung"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 94850,
                value: "Azubi"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 78144,
                value: "Was ganz"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 6046,
                value: "Projekt"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 92659,
                value: "Project"
            },
            {
                field: "ProjName",
                operator: "contains",
                id: 52240,
                value: "Anfrage"
            }
        ],
        logicOperator: "or",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzterTermin: false,
        AnzTageLetzTermPast: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        ProjektBemerkung: false,
        hatAnzDifferenz: false
    }
}

export const TERMIN_303_TAGESABSCHLUSS = {
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "TrmStat",
            "Start",
            "Betreff",
            "ProjName",
            "ProjStat",
            "AnzStatus"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektArtistID: false,
        AnzAgreed: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        DatBis: false,
        Betreff: true,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const TERMIN_304_VORGEMERKTE_TERMINE = {
    sort: [
        {
            field: "ArtistID",
            sort: "asc"
        },
        {
            field: "Start",
            sort: "asc"
        }
    ],
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektArtistID: false,
        ProjStat: false,
        AnzAgreed: false,
        AnzStatus: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const TERMIN_305_FEHLENDE_DOKUMENTE = {
    sort: [
        {
            field: "ArtistID",
            sort: "asc"
        },
        {
            field: "Start",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "isDokuFehlerhaft",
                operator: "is",
                id: 55787,
                value: "true"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "TrmStat",
            "Start",
            "Betreff"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        gesperrt: false,
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektArtistID: false,
        ProjStat: false,
        AnzAgreed: false,
        AnzStatus: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        TrmStat: false,
        DatBis: false,
        isDokuFehlerhaft: false,
        fehltGueltigeAnamnese: false,
        Dauer: false
    }
}

export const TERMIN_306_BITTE_UM_BESTAETIGUNG = {
    filter: {
        items: [
            {
                field: "ProjektBemerkung",
                operator: "contains",
                id: 52696,
                value: "#conf_open"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "Start",
            "Betreff"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        ProjektArtistID: false,
        ProjStat: false,
        DiffAnzSaldo: false,
        Preis: false,
        ProjektBemerkung: false,
        TrmStat: false,
        DatBis: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const TERMIN_307_TERMINVERSCHIEBUNG = {
    sort: [
        {
            field: "ArtistID",
            sort: "asc"
        },
        {
            field: "Start",
            sort: "asc"
        }
    ],
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "TrmStat",
            "Start",
            "DatBis",
            "Betreff",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        __check__: false,
        KundID: false,
        ProjektID: false,
        TerminID: false,
        KannKuFri: false,
        ProjektArtistID: false,
        ProjStat: false,
        AnzAgreed: false,
        AnzStatus: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false,
        Dauer: false
    }
}

export const PROJEKT_208_WARTELISTE = {
    sort: [
        {
            field: "WarteVon",
            sort: "asc"
        }
    ],
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "ArtistKuerzel",
            "ProjName",
            "Preis",
            "Ausstehend",
            "WarteVon",
            "WarteMonate"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Offen: false,
        Erledigt: false,
        LetzterTermin: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteBis: false,
        KannKuFri: false,
        hatAnzDifferenz: false
    }
}

export const PROJEKT_209_TERMIN_ERSATZ = {
    sort: [
        {
            field: "LetzTermPast",
            sort: "asc"
        }
    ],
    filter: {
        items: [
            {
                field: "KannKuFri",
                operator: "is",
                id: 98312,
                value: "true"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        ProjektID: false,
        KundID: false,
        ArtistKuerzel: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzterTermin: false,
        AnzTageLetzTermPast: false,
        WarteVon: false,
        hatAnzDifferenz: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        _createdKuerzel: false,
        _createdTS: false
    }
}

export const TERMIN_309_TERMIN_VORZIEHEN = {
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "TatOrtID",
            "ArtistID",
            "Typ",
            "TrmStat",
            "Start",
            "DatBis",
            "Betreff",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KundID: false,
        ProjektID: false,
        TerminID: false,
        ProjektArtistID: false,
        ProjStat: false,
        AnzAgreed: false,
        AnzStatus: false,
        DiffAnzSaldo: false,
        AnzInfo: false,
        Preis: false,
        ProjektBemerkung: false,
        maxAnamnese: false,
        fehltGueltigeAnamnese: false,
        maxDatenschutz: false,
        maxEinverstaendnis: false,
        maxElternEve: false,
        maxTerminDoku: false,
        isDokuFehlerhaft: false
    }
}

export const PROJEKT_210_OFFENE_PROJEKTANFRAGEN = {
    sort: [
        {
            field: "ArtistKuerzel",
            sort: "asc"
        },
        {
            field: "_createdTS",
            sort: "asc"
        }
    ],
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "Vorname",
            "Nachname",
            "gesperrt",
            "ArtistKuerzel",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzAgreed: false,
        AnzStatus: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        hatAnzDifferenz: false
    }
}

export const PROJEKT_211_LAUFENDE_PROJEKTE_OHNE_TERMIN = {
    sort: [
        {
            field: "ArtistKuerzel",
            sort: "asc"
        },
        {
            field: "LetzterTermin",
            sort: "asc"
        }
    ],
    pinned: {
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            "ArtistKuerzel",
            "ProjName"
        ],
        right: [
            "actions"
        ]
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        hatAnzDifferenz: false,
        AnzahlungsEingang: false,
        AnzInfo: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        ProjektBemerkung: false,
        _createdKuerzel: false,
        _createdTS: false,
    }
}

export const PROJEKT_212_ANZAHLUNG_AUSBUCHEN = {
    filter: {
        items: [
            {
                field: "AnzStatus",
                operator: ">",
                id: 94446,
                value: "0"
            }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and"
    },
    visibility: {
        KannKuFri: false,
        MsgPref: false,
        Mobil: false,
        Email: false,
        ProjektID: false,
        KundID: false,
        ProjStatText: false,
        AnzahlungsSumme: false,
        AnzahlungsAuszahlungen: false,
        AnzahlungsSaldo: false,
        DiffAnzSaldo: false,
        Preis: false,
        Offen: false,
        Erledigt: false,
        Ausstehend: false,
        LetzTermPast: false,
        AnzTageLetzTermPast: false,
        NextTermin: false,
        WarteVon: false,
        WarteBis: false,
        WarteMonate: false,
        successMsgCount: false,
        failMsgCount: false,
        firstMsgSuccessDate: false,
        lastMsgSuccessDate: false,
        ProjektBemerkung: true,
        _createdKuerzel: false,
        _createdTS: false,
        hatAnzDifferenz: false
    }
}
