import {call, put, select} from "redux-saga/effects";
import {selectArtistAuth, selectStudioAuth} from "./authSelector";
import {getArtistRefreshToken, getStudioRefreshToken} from "../../api/commonApi";
import {
    initiateLogoutArtist,
    initiateLogoutStudio,
    refreshArtistTokenSuccessAction,
    refreshStudioTokenSuccessAction
} from "./authActions";
import {checkValidSeconds, MINIMUM_VALIDITY_SECONDS} from "../../models/auth";


export function* getStudioToken(minimumSecondsValidity = MINIMUM_VALIDITY_SECONDS) {
    const tokens = yield select(state => selectStudioAuth(state))
    const studioToken = tokens?.studioToken
    const studioRefreshToken = tokens?.studioRefreshToken

    if (studioToken && checkValidSeconds(studioToken, minimumSecondsValidity)) {
        return studioToken
    }
    if (studioRefreshToken) {
        try {
            const {data} = yield call(getStudioRefreshToken, {refreshToken: studioRefreshToken})
            yield put(refreshStudioTokenSuccessAction(data.data))
            return data.data
        } catch (err) {
            yield put(initiateLogoutStudio())
        }
    }
}


export function* getArtistToken(minimumSecondsValidity = MINIMUM_VALIDITY_SECONDS) {
    let tokens = yield select(state => selectArtistAuth(state))
    const artistToken = tokens?.artistToken
    const artistRefreshToken = tokens?.artistRefreshToken

    if (!artistToken) {
        return undefined
    }

    if (checkValidSeconds(artistToken, minimumSecondsValidity)) {
        return artistToken
    }

    const studioToken = yield* getStudioToken()

    if (!studioToken) {
        return undefined
    }

    try {
        const {data} = yield call(getArtistRefreshToken, {refreshToken: artistRefreshToken, token: studioToken})
        yield put(refreshArtistTokenSuccessAction(data.data))
        return data.data
    } catch (err) {
        yield put(initiateLogoutArtist())
    }
}