import axios from "axios";
import {createAuthHeader} from "./commonApi";
import {BACKEND} from "../config/backend";
import {_getStandardReportDefinitions} from "./static/staticReportDefinition";
import {extractDataFromResponse} from "../store/saga-utils/httpInterceptorWrapper";
import {convertReportDefinitionToSchema, sortByCategoryAndName} from "../models/lists";

export const STUDIO_BASE_URL = `${BACKEND}/api/v1/studio`

export const convertTempImageKeyToUrl = (key) => {
    const encodedKey = encodeURIComponent(key)
    return `${STUDIO_BASE_URL}/tempimage?key=${encodedKey}`
}

export const addTempImage = ({token, image}) => {
    const data = {filename: image.name, dataUrl: image.dataURL}
    return axios.post(`${STUDIO_BASE_URL}/tempimage`, data, {
        ...createAuthHeader(token)
    })
}

export const getFileById = ({token, fileId}) => {
    return axios.get(`${STUDIO_BASE_URL}/files/${fileId}`, {
        ...createAuthHeader(token)
        , responseType: 'blob'
    })
}

export const getPads = ({token}) => {
    return axios.get(`${STUDIO_BASE_URL}/pads`, {
        ...createAuthHeader(token)
    })
}

export const getNewPadSecret = ({token, padId}) => {
    return axios.get(`${STUDIO_BASE_URL}/pads/${padId}/secret`, {
        ...createAuthHeader(token)
    })
}

export const getStudioReportDefinition = async ({token, reportDefID}) => {
    const data = extractDataFromResponse(await axios.get(`${STUDIO_BASE_URL}/reportdefinitions/${reportDefID}`, {
        ...createAuthHeader(token)
    }))
    return convertReportDefinitionToSchema(data)

}

export const getAllStudioReportDefinitions = async ({token}) => {
   return extractDataFromResponse(await axios.get(`${STUDIO_BASE_URL}/reportdefinitions`, {
        ...createAuthHeader(token)
    }))
}

export const getStandardReportDefinitions = (t) => _getStandardReportDefinitions(t).sort(sortByCategoryAndName)

export const insertStudioReportDefinition = ({token, reportDefinition}) => {
    return axios.post(`${STUDIO_BASE_URL}/reportdefinitions`, reportDefinition, {
        ...createAuthHeader(token)
    })
}

export const insertStudioReportDefinitionList = ({token, reportDefinitionList}) => {
    return axios.post(`${STUDIO_BASE_URL}/reportdefinitions`, reportDefinitionList, {
        ...createAuthHeader(token)
    })
}

export const updateStudioReportDefinition = ({token, reportDefinition}) => {
    return axios.put(`${STUDIO_BASE_URL}/reportdefinitions/${reportDefinition.ReportDefID}`, reportDefinition, {
        ...createAuthHeader(token)
    })
}

export const deleteStudioReportDefinition = ({token, reportDefID}) => {
    return axios.delete(`${STUDIO_BASE_URL}/reportdefinitions/${reportDefID}`, {
        ...createAuthHeader(token)
    })
}

export const deletePad = ({token, padId}) => {
    return axios.delete(`${STUDIO_BASE_URL}/pads/${padId}`, {
        ...createAuthHeader(token)
    })
}

export const insertPad = ({token, pad}) => {
    return axios.post(`${STUDIO_BASE_URL}/pads`, pad, {
        ...createAuthHeader(token)
    })
}

export const updateDocuVersandArtist = ({token, artistId}) => {
    return axios.put(`${STUDIO_BASE_URL}/docuversand`, {DocuVersandArtist: artistId}, {
        ...createAuthHeader(token)
    })
}

export const getStudioSubscription = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio/subscription`, {
        ...createAuthHeader(token)
    })
}

export const getStudioSubscriptionStatus = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio/subscription/status`, {
        ...createAuthHeader(token)
    })
}

export const startStudioSubscription = ({token, subscription}) => {
    return axios.post(`${BACKEND}/api/v1/studio/subscription`, subscription, {
        ...createAuthHeader(token)
    })
}

export const updateSubscription = ({token, subscription}) => {
    return axios.put(`${BACKEND}/api/v1/studio/subscription`, subscription, {
        ...createAuthHeader(token)
    })
}

export const downloadSubscriptionContract = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio/subscription/contract`, {
        ...createAuthHeader(token)
        , responseType: 'blob'
    })
}

export const getTermsOfUse = () => {
    return axios.get(`${BACKEND}/pdf/Nutzungsvereinbarung.pdf` , {responseType: 'blob'})
}

export const deleteLocation = ({token, tatortId}) => {
    return axios.delete(`${STUDIO_BASE_URL}/locations/${tatortId}`, {
        ...createAuthHeader(token)
    })
}

export const updateStudioLocation = ({token, location}) => {
    return axios.put(`${STUDIO_BASE_URL}/locations`, location, {
        ...createAuthHeader(token)
    })
}

export const insertStudioLocation = ({token, location}) => {
    return axios.post(`${STUDIO_BASE_URL}/locations`, location, {
        ...createAuthHeader(token)
    })
}


