import {getArtistToken} from "../../auth/token.saga";
import {selectArtistMap} from "../../common/commonSelector";
import {call} from "redux-saga-test-plan/matchers";
import {deleteArtistWorkTime} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {DELETE_ARTIST_WORKTIME} from "../artistActions";
import {put, select, takeLatest} from "redux-saga/effects";
import {i18_ARTIST} from "../../../i18nReferences";

export function* processDeleteWorkTime({payload}) {
    try {
        const token = yield getArtistToken()
        const artistMap = yield select(state => selectArtistMap(state))
        if (token) {
            const artistWorkTime = payload.artistWorkTime
            yield call(deleteArtistWorkTime, {token, artistWorkTime})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_ARTIST.SAVE_MAIL_DATA_SUCCESS_INFO', {
                ns: i18_ARTIST,
                name: artistMap[artistWorkTime.ArtistID].Kuerzel
            }), 'saveArtist')))
        }
    } catch (err) {
        const artistMap = yield select(state => selectArtistMap(state))
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_MAIL_DATA_FAILED_INFO', {
            ns: i18_ARTIST,
            name: artistMap[payload.artistWorkTime.ArtistID].Kuerzel
        }), 'saveArtist')))
        console.log(err)
    }
}

export function* watchDeleteWorkTime() {
    yield takeLatest([DELETE_ARTIST_WORKTIME], processDeleteWorkTime)
}