import {createSimpleAction, createTypeAction} from "../../utils/actionUtils";

export const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION'
export const loadSubscriptionAction = createTypeAction(LOAD_SUBSCRIPTION)
export const LOAD_SUBSCRIPTION_SUCCESS = 'LOAD_SUBSCRIPTION_SUCCESS'
export const loadSubscriptionSuccess = createSimpleAction(LOAD_SUBSCRIPTION_SUCCESS, 'data')
export const LOAD_SUBSCRIPTION_FAILURE = 'LOAD_SUBSCRIPTION_FAILURE'
export const loadSubscriptionFailure = createTypeAction(LOAD_SUBSCRIPTION_FAILURE)

export const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION'
export const clearSubscriptionAction = createTypeAction(CLEAR_SUBSCRIPTION)

export const START_SUBSCRIPTION = 'START_SUBSCRIPTION'
export const startSubscriptionAction = createSimpleAction(START_SUBSCRIPTION, 'subscription')

export const SAVE_SUBSCRIPTION = 'SAVE_SUBSCRIPTION'
export const saveSubscriptionAction = createSimpleAction(SAVE_SUBSCRIPTION, 'subscription')