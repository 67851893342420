import { formatDate, reduceIsoDateToDateString, toDateString } from "./date";
import { isSameOrBefore } from "@kiss-solutions/dateutils";
import * as yup from "yup";
import { GUTSCHEINVERKAUF_STUDIOKASSE } from "./studio";
import { i18_ARTIST } from "../i18nReferences";
import { PlainDate, TIMEUNIT_DAYS } from "@kiss-solutions/plaindate";
import { keyBy, pick } from "../store/utils/lodash-replacement";

export const ARTIST_VERTRAGSTYP_SELBSTAENDIG = 1;
export const ARTIST_VERTRAGSTYP_ANGESTELLT = 2;
export const ARTIST_VERTRAGSTYP_MAP = { ARTIST_VERTRAGSTYP_SELBSTAENDIG, ARTIST_VERTRAGSTYP_ANGESTELLT };


const isLocationInZeitraum = (zeitraum, location) =>
    reduceIsoDateToDateString(location.DatVon) < zeitraum.end &&
    reduceIsoDateToDateString(location.DatBis) >= zeitraum.start;

export const isArtistAtLocationInZeitraum = (artist, tatortId, zeitraum, ausgeschiedene = false) => {
    if (artist.Ausgeschieden && !ausgeschiedene) {
        return false;
    }
    if (
        tatortId < 0 ||
        (artist.StdOrtID === tatortId &&
            artist.artLocList.find((l) => tatortId !== l.TatOrtID && isLocationInZeitraum(zeitraum, l)) === undefined)
    ) {
        return true;
    }
    const location = artist.artLocList.find((l) => tatortId === l.TatOrtID && isLocationInZeitraum(zeitraum, l));

    return location !== undefined;
};

export const getArtistLocation = (date, artist, tatortMap, timezone) => {
    const pointInTime = new PlainDate(date).adjustForTimezone(timezone);
    let location = artist.artLocList.find(
        (l) =>
            pointInTime.isSameOrAfter(new PlainDate(l.DatVon), TIMEUNIT_DAYS) &&
            new PlainDate(l.DatBis).isSameOrAfter(pointInTime, TIMEUNIT_DAYS)
    );
    if (!location) {
        location = tatortMap[artist.StdOrtID];
    }
    return tatortMap[location.TatOrtID];
};

export const getIntersectingLocations = (von, bis, artist, ArtAtOrtID) =>
    artist.artLocList.find(
        (l) =>
            (!ArtAtOrtID || ArtAtOrtID !== l.ArtAtOrtID) &&
            isSameOrBefore(new Date(l.DatVon), bis, "date") &&
            isSameOrBefore(von, new Date(l.DatBis), "date")
    );

export const getOffSiteLocations = (artist, zeitraum) =>
    artist.artLocList.filter(
        (l) =>
            reduceIsoDateToDateString(l.DatVon) <= zeitraum.end && reduceIsoDateToDateString(l.DatBis) >= zeitraum.start
    );

export const hasRubrik = (artist, rubrikID) => artist.rubriken.find((t) => t.RubrikID === rubrikID) !== undefined;
export const getArtistsStdPreisFor = (artist, anzTermine) =>
    artist ? (anzTermine > 1 ? artist.Preis : artist.PreisSingle) : "";
export const extractArtistSettingsFromArtistMap = (artistMap) =>
    Object.keys(artistMap).reduce(
        (pv, cv) => ({
            ...pv,
            [cv]: {
                ManageAZ: artistMap[cv].ManageAZ,
                EditIncome: artistMap[cv].EditIncome,
                Kuerzel: artistMap[cv].Kuerzel,
                Vertragstyp: artistMap[cv].Vertragstyp,
            },
        }),
        {}
    );

export const getArtistTatorte = (artistMap, tatOrtMap, studioTatOrtID) => {
    if (!artistMap || !tatOrtMap) {
        return [];
    }
    const tatOrtSortKeys = keyBy(
        Object.values(tatOrtMap).map((t) => ({
            id: t.TatOrtID,
            sortKey: t.IstStudioEigen ? (studioTatOrtID === t.TatOrtID ? "0" : "1") + t.Ort : "2" + t.Ort,
        })),
        "id"
    );
    return [...new Set(Object.values(artistMap).map((t) => t.StdOrtID))].sort((id1, id2) =>
        tatOrtSortKeys[id1].sortKey > tatOrtSortKeys[id2].sortKey ? 1 : -1
    );
};

export const convertLocationDates = (location) => ({
    ...location,
    DatVon: new Date(location.DatVon),
    DatBis: new Date(location.DatBis),
});

export const convertArtistLocation2DB = (artistLocation) => ({
    ...artistLocation,
    DatVon: toDateString(artistLocation.DatVon),
    DatBis: toDateString(artistLocation.DatBis),
});

const passwordConfirmationSchema = (schema, pwd) =>
    schema.oneOf([pwd], { key: "YUP.ERROR.PASSWORD_DIFFERS" }).required();

export const createArtistBaseSchema = () =>
    yup.object().shape({
        Kuerzel: yup
            .string()
            .transform((k) => k && k.trim())
            .min(2)
            .max(8)
            .when(["$allOtherArtistKuerzel"], ([allOtherKuerzel], schema) =>
                schema.notOneOf(allOtherKuerzel, { key: "YUP.ERROR.KUERZEL_IN_USE" })
            )
            .required(),
        Artist: yup.string().min(2).max(40).required(),
        Bank: yup.string().max(200).nullable(),
        IBAN: yup.string().max(22).nullable(),
        Adresse: yup.string().max(500).nullable(),
        Geburtstag: yup.date().max(new Date()).nullable(),
        Anzahlung: yup.number().min(0).required(),
        PreisLabel: yup.string().max(15).required(),
        Preis: yup.string().max(30).required(),
        PreisSingle: yup.string().max(30).required(),
        StdOrtID: yup
            .number()
            .when(["Vertragstyp", "$studioLocationIds"], ([vertragstyp, studioLocationIds], schema) =>
                vertragstyp === ARTIST_VERTRAGSTYP_ANGESTELLT
                    ? schema.oneOf(studioLocationIds, { key: "YUP.ERROR.ANGESTELLTE_NUR_IN_STUDIOLOCATIONS" })
                    : schema.min(1)
            ),
        BIC: yup.string().max(12).nullable(),
        Passwort: yup
            .string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,25})/, { message: { key: "YUP.ERROR.PASSWORD_FORMAT" } })
            .nullable(),
        PasswortBestaetigung: yup
            .string()
            .when(["Passwort"], ([pwd], schema) => (pwd ? passwordConfirmationSchema(schema, pwd) : schema.optional())),
    });

export const createArtistMailSchema = () =>
    yup.object().shape({
        MailAdr: yup.string().email({ key: "YUP.ERROR.KUNDE_EMAIL_FORMAT" }).max(100).required(),
        MailUser: yup.string().max(40).nullable(),
        MailSrv: yup.string().max(100).required(),
        MailPort: yup.number().min(1).max(999).required(),
        MailPwd: yup.string().max(50).required(),
    });

export const createArtistHoursSchema2 = () =>
    yup.object().shape({
        Anmerkung: yup.string().max(150).required(),
        Ab: yup
            .date()
            .test("onlyMondays", { key: "YUP.ERROR.ONLY_MONDAYS" }, (d) => d != null && d.getDay() === 1)
            .required(),
        ArbVon1: yup.date().required(),
        ArbBis1: yup.date().min(yup.ref("ArbVon1")).required(),
        ArbVon2: yup.date().required(),
        ArbBis2: yup.date().min(yup.ref("ArbVon2")).required(),
        ArbVon3: yup.date().required(),
        ArbBis3: yup.date().min(yup.ref("ArbVon3")).required(),
        ArbVon4: yup.date().required(),
        ArbBis4: yup.date().min(yup.ref("ArbVon4")).required(),
        ArbVon5: yup.date().required(),
        ArbBis5: yup.date().min(yup.ref("ArbVon5")).required(),
        ArbVon6: yup.date().required(),
        ArbBis6: yup.date().min(yup.ref("ArbVon6")).required(),
        ArbVon7: yup.date().required(),
        ArbBis7: yup.date().min(yup.ref("ArbVon7")).required(),
    });

export const convertArtistToSchema = (artistData, fields) => ({
    ...pick(artistData, fields),
    Geburtstag: artistData.Geburtstag ? new Date(artistData.Geburtstag) : null,
    PreferredTermintyp: artistData.PreferredTermintyp || ""
});

export const convertArtWorkTime = (d) => new Date(d.substring(0, 16).replace("T", " "));

export const convertArtistHoursToSchema = (artistData) =>
    artistData &&
    Object.keys(artistData).reduce((result, key) => {
        if (key.startsWith("ArbVon") || key.startsWith("ArbBis")) {
            result[key] = convertArtWorkTime(artistData[key]);
        } else if (key === "Ab") {
            result[key] = new Date(artistData[key]);
        } else {
            result[key] = artistData[key];
        }
        return result;
    }, {});

const convertArtWorkTimeBack = (d) => `1899-12-30T${d.toLocaleTimeString("de")}.000Z`;
export const getStdWorkTimes = () => {
    const result = [1, 2, 3, 4, 5, 6].reduce((result, day) => {
        result[`ArbVon${day}`] = "1899-12-30T10:00:00.000Z";
        result[`ArbBis${day}`] = "1899-12-30T18:00:00.000Z";
        return result;
    }, {});
    result.ArbVon7 = "1899-12-30T00:00:00.000Z";
    result.ArbBis7 = "1899-12-30T00:00:00.000Z";
    result.Pause = 60;
    return result;
};
export const convertFromArtistHoursSchema = (schemaData) =>
    schemaData &&
    Object.keys(schemaData).reduce((result, key) => {
        if (key.startsWith("ArbVon") || key.startsWith("ArbBis")) {
            result[key] = convertArtWorkTimeBack(schemaData[key]);
        } else if (key === "Ab") {
            result[key] = schemaData.Ab.toISOString();
        } else {
            result[key] = schemaData[key];
        }
        return result;
    }, {});

export const ARTIST_LANGUAGES = ["German", "English"];

export const convertArtistLocationToSchema = (location) =>
    location && {
        ...location,
        DatVon: new Date(location.DatVon.substring(0, 10)),
        DatBis: new Date(location.DatBis.substring(0, 10)),
    };

export const overlappingPeriod = (datVon, datBis, artAtOrtID, allLocations) => {
    const pdDatVon = new PlainDate(datVon).startOfDay();
    const pdDatBis = new PlainDate(datBis).add(1, TIMEUNIT_DAYS).startOfDay();
    return (
        datVon &&
        datBis &&
        allLocations.find(
            (l) =>
                l.ArtAtOrtID !== artAtOrtID &&
                pdDatBis.isAfter(new PlainDate(l.DatVon).startOfDay(), TIMEUNIT_DAYS) &&
                new PlainDate(l.DatBis).startOfDay().add(1, TIMEUNIT_DAYS).isAfter(pdDatVon, TIMEUNIT_DAYS)
        )
    );
};

export const formatArtistLocation = (locale, timezone, location) => {
    if (!location) {
        return "";
    }
    return formatDate(location.DatVon) + " - " + formatDate(location.DatBis) + " " + location.Ort;
};

export const createArtistLocationSchema = () =>
    yup.object().shape({
        DatVon: yup.date().required(),
        DatBis: yup.date().min(yup.ref("DatVon")).required(),
        TatOrtID: yup
            .number()
            .when(["$StdOrtID"], ([stdOrtID], schema) =>
                schema.notOneOf([stdOrtID], { key: "YUP.ERROR.LOCATION_IS_STANDARD" })
            )
            .required(),
    });

export const LESERECHT = 1;
export const SCHREIBRECHT = 2;
export const LOESCHRECHT = 4;

export const checkForUpgrades = (neueRechte, oldRechte) => {
    const result = { ...neueRechte };
    if ((result.Rechte & LOESCHRECHT) > 0 && (oldRechte.Rechte & LOESCHRECHT) === 0) {
        result.Rechte = result.Rechte | SCHREIBRECHT | LESERECHT;
    }
    if (result.EinnBearbeiten && !oldRechte.EinnBearbeiten) {
        result.Rechte = result.Rechte | LESERECHT | SCHREIBRECHT;
    }
    if ((result.Rechte & SCHREIBRECHT) > 0 && (result.Rechte & SCHREIBRECHT) === 0) {
        result.Rechte = result.Rechte | LESERECHT;
    }

    if ((result.Rechte & LESERECHT) === 0 && (result.Rechte & SCHREIBRECHT) > 0) {
        result.Rechte = result.Rechte & ~SCHREIBRECHT;
    }
    if ((result.Rechte & SCHREIBRECHT) === 0 && (result.Rechte & LOESCHRECHT) > 0) {
        result.Rechte = result.Rechte & ~LOESCHRECHT;
    }
    if ((result.Rechte & SCHREIBRECHT) === 0 && result.EinnBearbeiten) {
        result.EinnBearbeiten = false;
    }
    return result;
};

export const createArtistAssistScheme = () =>
    yup.object().shape({
        EditIncome: yup.boolean().required(),
        InfoPending: yup.boolean().required(),
        AutoDayClose: yup.boolean().required(),
        ManagePreis: yup.boolean().required(),
        ManageForecast: yup.boolean().required(),
        InfoNeedAppt: yup.boolean().required(),
        ManageAZ: yup.boolean().required(),
        InfoDeadAppt: yup.boolean().required(),
        InfoWaitTime: yup.boolean().required(),
        InfoLostAppt: yup.boolean().required(),
    });

export const createArtistMapForGutscheinausgabe = (artistMap, studioGutscheinverkauf, inludedArtistId) =>
    Object.fromEntries(
        Object.entries(artistMap).filter(
            ([, a]) =>
                studioGutscheinverkauf === GUTSCHEINVERKAUF_STUDIOKASSE ||
                a.GetVoucher ||
                (inludedArtistId && inludedArtistId === a.ArtistID)
        )
    );

export const getArtistColumns = ({ t, tatortMap, isGoogleSyncEnabled }) => {
    const coldef = [
        { field: "Kuerzel", headerName: t("EDIT_ARTIST.KUERZEL_LABEL", { ns: i18_ARTIST }) },
        { field: "Artist", headerName: t("EDIT_ARTIST.ARTIST_LABEL", { ns: i18_ARTIST }), minWidth: 200 },
        {
            field: "StdOrtID",
            headerName: t("EDIT_ARTIST.LOCATION_LABEL", { ns: i18_ARTIST }),
            valueGetter: ({ value }) => tatortMap[value].Ort,
            minWidth: 200,
        },
        { field: "MailAdr", headerName: t("EDIT_ARTIST.MAILADR_LABEL", { ns: i18_ARTIST }), minWidth: 200 },
        {
            field: "Ausgeschieden",
            type: "boolean",
            headerName: t("EDIT_ARTIST.AUSGESCHIEDEN_LABEL", { ns: i18_ARTIST }),
        },
        { field: "isAdmin", type: "boolean", headerName: t("EDIT_ARTIST.ISADMIN_LABEL", { ns: i18_ARTIST }) },
        { field: "GetVoucher", type: "boolean", headerName: t("EDIT_ARTIST.GUTSCHEIN_LABEL", { ns: i18_ARTIST }) },
    ];
    if (isGoogleSyncEnabled) {
        coldef.push({ field: "GglSyncEnabled", type: "boolean", headerName: "Google Sync" })
    }
    return coldef;
};

export const sortByKuerzel = (a1, a2) => (a1.Kuerzel > a2.Kuerzel ? 1 : -1);

export const getLocationUsage = (artistMap) =>
    artistMap &&
    Object.values(artistMap).reduce((result, artist) => {
        for (let loc of artist.artLocList) {
            if (!result[loc.TatOrtID]) {
                result[loc.TatOrtID] = [];
            }
            result[loc.TatOrtID].push(artist.Kuerzel);
        }
        return result;
    }, {});

export const isCommonArtistMailConfigSet = (artist) => artist?.MailAdr != null;
