import {getArtistToken} from "../../auth/token.saga";
import {call} from "redux-saga-test-plan/matchers";
import {copyArtist} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {put, takeLatest, select} from "redux-saga/effects"
import {COPY_ARTIST} from "../artistActions";
import {createErrorNotification, createSuccessNotification} from "../../../models/notification";
import i18next from "i18next";
import {selectArtistMap} from "../../common/commonSelector";
import {emitNotificationAction} from "../../notification/notificationAction";
import {i18_ARTIST} from "../../../i18nReferences";

function* processCopyArtists({payload}) {
    const {artistId} = payload
    const artistMap = yield select(state => selectArtistMap(state))
    const name = artistMap[artistId].Kuerzel
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(copyArtist, {token, artistId})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('EDIT_ARTIST.COPY_ARTIST_SUCCESS_INFO', {
                ns: i18_ARTIST,
                name
            }), 'copyArtist')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.COPY_ARTIST_FAILED_INFO', {
            ns: i18_ARTIST,
            name
        }), 'copyArtist')))
        console.log(err)
    }
}

export function* watchCopyArtists() {
    yield takeLatest([COPY_ARTIST], processCopyArtists)
}