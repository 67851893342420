import {CLEAR_HISTORY, PUSH_HISTORY} from "./routerActions";
import {replacePayloadFields} from "../utils/reducerUtils";

const INITIAL = {};

export default function routerReducer(state = INITIAL, action) {
    switch (action.type) {
        case PUSH_HISTORY:
            return replacePayloadFields(state, action)
        case CLEAR_HISTORY:
            return INITIAL
        default:
            return state;
    }
}