import {
    CLEAR_SUBSCRIPTION,
    LOAD_SUBSCRIPTION,
    LOAD_SUBSCRIPTION_FAILURE,
    LOAD_SUBSCRIPTION_SUCCESS
} from "./subscriptionActions";

const INITIAL = {}

export default function subscriptionReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
            }
        case LOAD_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        case LOAD_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        case CLEAR_SUBSCRIPTION:
            return INITIAL

        default:
            return state;
    }
}