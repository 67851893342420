import {clearKundeAction, SAVE_KUNDE} from "../kundeAction";
import {getArtistToken} from "../../auth/token.saga";
import {replaceDateFields} from "../../utils/modelConverter";
import {unpackAndExtendSearch} from "../../utils/urlHelper";
import {updateKunde} from "../../../api/kundenApi";
import {pushHistory} from "../../router/routerActions";
import {cleardownloadCommonFile} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* saveKunde({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const kunde = replaceDateFields(payload.saveKundeData.kunde)
            const back = payload.saveKundeData.back ? unpackAndExtendSearch(payload.saveKundeData.back, {kundid: kunde.KundID}) : -1
            yield call(updateKunde, {token, kunde})
            yield put(pushHistory(back))
            yield put(clearKundeAction())
            yield put(cleardownloadCommonFile())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_SUCCESS_INFO', {
                ns: i18_KUNDE,
                name: kunde.Name
            }), 'saveKunde')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_FAILED_INFO', {
            ns: i18_KUNDE,
            name: payload.saveKundeData.kunde.Name
        }), 'saveKunde')))
        console.log(err)
    }
}

export function* watchSaveKunde() {
    yield takeLatest(SAVE_KUNDE, saveKunde)
}