import {call, put, takeLatest} from "redux-saga/effects"
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKundenByKundIdList, getKundenBySearchStringAndArtist} from "../../../api/kundenApi";
import {SEARCH_CALENDAR_KUNDEN, searchCalendarKundenSuccess} from "../calendarActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";

function* searchCalendarKunden({payload}) {
    try {
        const token = yield* getArtistToken()
        const searchOptions = payload.searchOptions
        if (token) {
            let kunden;
            if (searchOptions.kundIdList) {
                const kundenMap = extractDataFromResponse(yield call(getKundenByKundIdList, {
                    token,
                    kundIdList: searchOptions.kundIdList
                }))
                kunden = Object.values(kundenMap)

            } else {
                kunden = extractDataFromResponse(yield call(getKundenBySearchStringAndArtist, {token, ...payload.searchOptions}))
            }
            yield put(searchCalendarKundenSuccess(kunden))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_DATA_FAILED_INFO'), 'searchCalendarKunden')))
        console.log(err)
    }
}

export function* watchSearchCalendarKunden() {
    yield takeLatest(SEARCH_CALENDAR_KUNDEN, searchCalendarKunden)
}