import { createSelector } from "reselect";
import { createBooleanFieldSelector, createFieldSelector, identityFunction } from "../utils/selectors";
import {
    convertMsgVorlagenMapToList,
    convertPdfVorlagenMapToList,
    MSG_VORLAGE_TYP_AUTO_REMINDER,
    PDF_VORLAGENTYP_GUTSCHEIN,
} from "../../models/vorlagen";
import { getCountryByPreDial } from "@kiss-solutions/countries/lib/countries";
import { termintypSort } from "../../models/terminStatus";
import { keyBy } from "../utils/lodash-replacement";

export const selectCommonState = createSelector((state) => state.common, identityFunction);

export const selectDisabledInfo = createFieldSelector(selectCommonState, "disabledInfo");
export const selectCountryMap = createFieldSelector(selectCommonState, "countryMap");
export const selectTermintypMap = createFieldSelector(selectCommonState, "termintypMap");
export const selectTermintypList = createSelector(selectTermintypMap, (map) => map && Object.values(map));
export const selectMsgVorlagenMap = createFieldSelector(selectCommonState, "msgVorlagenMap");
export const selectMsgVorlagenList = createSelector(selectMsgVorlagenMap, (map) => convertMsgVorlagenMapToList(map));
export const selectPdfVorlagenMap = createFieldSelector(selectCommonState, "pdfVorlagenMap");
export const selectpDFVorlagenList = createSelector(selectPdfVorlagenMap, (map) => convertPdfVorlagenMapToList(map));
export const selectVoucherVorlagenSelection = createSelector(
    selectPdfVorlagenMap,
    (vorlagenMap) =>
        (vorlagenMap &&
            Object.values(vorlagenMap)
                .filter((t) => t.Typ === PDF_VORLAGENTYP_GUTSCHEIN)
                .map((v) => ({ id: v.PDFVorlagenID, text: v.Bezeichnung }))) ||
        []
);
export const selectStudioData = createFieldSelector(selectCommonState, "studio");
export const selectSubscriptionStatus = createFieldSelector(selectCommonState, "subscriptionStatus");
export const selectStudioTatOrtID = createFieldSelector(selectStudioData, "TatOrtID");

export const selectStudioTimezone = createFieldSelector(selectStudioData, "Timezone");
export const selectStudioPads = createFieldSelector(selectStudioData, "pads", []);
export const selectStudioPadMap = createSelector(selectStudioPads, (pads) => keyBy(pads, "PadID"));
export const selectStudioGutscheinverkauf = createFieldSelector(selectStudioData, "Gutscheinverkauf");
export const selectStudioWaehrung = createFieldSelector(selectStudioData, "Waehrung");
export const selectStudioTatortMap = createSelector(
    selectStudioData,
    (studio) => studio && keyBy(studio.tatorte, "TatOrtID")
);

export const selectStudioCountry = createSelector(selectStudioData, (studio) =>
    studio?.preDial ? getCountryByPreDial(studio.preDial) : getCountryByPreDial("+49")
);

export const selectMainBranchLocation = createSelector(
    selectStudioTatortMap,
    selectStudioTatOrtID,
    (map, tatortId) => map && tatortId && map[tatortId]
);
export const selectCountrySelection = createSelector(
    selectCountryMap,
    (state) => (state && Object.values(state).map((t) => ({ id: t.country_code, text: t.name }))) || []
);


export const selectIsCommonStateLoading = createBooleanFieldSelector(selectCommonState, "commonLoading");
export const selectArtistMap = createFieldSelector(selectCommonState, "artistMap");

export const selectTerminReminderMsgVorlage = createSelector(
    selectMsgVorlagenMap,
    (vorlagenMap) => vorlagenMap && Object.values(vorlagenMap).find((t) => t.MsgTyp === MSG_VORLAGE_TYP_AUTO_REMINDER)
);
export const selectTerminReminderVorlauf = createFieldSelector(selectTerminReminderMsgVorlage, "DaysOffset");
export const selectTermintypSelection = createSelector(
    selectTermintypMap,
    (termintypMap) =>
        (termintypMap &&
            Object.values(termintypMap)
                .sort(termintypSort)
                .map((t) => ({ ...t, id: t.TermintypID, text: t.Termintyp }))) ||
        []
);

export const selectDownloadedPdf = createFieldSelector(selectCommonState, "pdf");
export const selectDownloadedPdfNotFound = createBooleanFieldSelector(selectCommonState, "pdfNotFound");
export const selectHasStudioChanged = createBooleanFieldSelector(selectCommonState, "studioChanged");

export const selectSupportTicketStatus = createFieldSelector(selectCommonState, "supportTicketStatus");
