import {getArtistToken} from "../../auth/token.saga";
import {call} from "redux-saga-test-plan/matchers";
import {deleteArtist} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {put, takeLatest, select} from "redux-saga/effects"
import {DELETE_ARTIST} from "../artistActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../models/notification";
import i18next from "i18next";
import {selectArtistMap} from "../../common/commonSelector";
import {i18_ARTIST} from "../../../i18nReferences";
import {selectSelectedCalendarArtistID} from "../../calendar/calendarSelector";
import { selectLoggedInArtistID} from "../../auth/authSelector";
import {selectCalendarArtistAction} from "../../calendar/calendarActions";

function* processDeleteArtist({payload}) {
    const {artistId} = payload
    const artistMap = yield select(state => selectArtistMap(state))
    const selectedArtistID = yield select(state => selectSelectedCalendarArtistID(state))
    const loggedInArtistId = yield select(state => selectLoggedInArtistID(state))
    const name = artistMap[artistId].Kuerzel
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(deleteArtist, {token, artistId})
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('EDIT_ARTIST.DELETE_ARTIST_SUCCESS_INFO', {
                ns: i18_ARTIST,
                name
            }), 'deleteArtist')))
            if (selectedArtistID === artistId) {
                yield put(selectCalendarArtistAction(loggedInArtistId))
            }
            yield put(reloadCommonArtistsAction())
        }
    } catch (err) {
        if (err.response.status === 409) {
            yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.DELETE_ARTIST_FAILED_FOR_REFERENCES_INFO', {
                ns: i18_ARTIST,
                name
            }), 'deleteArtist')))
            console.log(err.response.data)
        } else {
            yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.DELETE_ARTIST_FAILED_INFO', {
                ns: i18_ARTIST,
                name
            }), 'deleteArtist')))
            console.log(err)
        }
    }
}

export function* watchDeleteArtists() {
    yield takeLatest([DELETE_ARTIST], processDeleteArtist)
}
