export const unpackAndExtendSearch = (url, params) => {
    const urlParams = unpackLocation(url)
    urlParams.search = addSearchParamsToUrl(urlParams.search, params)
    return urlParams
}

export const addSearchParamsToUrl = (search, params) => {
    const urlSearchParams = search.length > 1 ? new URLSearchParams(search) : new URLSearchParams()
    Object.keys(params).forEach(key => urlSearchParams.set(key, params[key]))
    return '?' + urlSearchParams.toString()
}

export const wrapUrlComponents = (queryParms) => Object.keys(queryParms)
    .filter(key => !!queryParms[key])
    .map(key => `&${key}=${encodeURIComponent(queryParms[key])}`).join('')

export const packLocation = location => encodeURIComponent(JSON.stringify({pathname: location.pathname, search: location.search}))
export const packLocationAndExtendSearch = (location, searchString) => {
    const search = location.search ? replaceSearchParameter(location.search, searchString) : searchString
    const urlJson = JSON.stringify({pathname: location.pathname, search})
    return encodeURIComponent(urlJson)
}
export const unpackLocation = packedLocation => JSON.parse(packedLocation)
export const unpackAndConvertUrl = packedLocation => {
    const  {pathname, search} = unpackLocation(packedLocation)
    return pathname + '?' + search
}

export const replaceSearchParameter = (search, addedSearchString) => {
    const [key, newValue] = addedSearchString.split("=")
    let changed = false
    const searchStrings = search.substring(1).split("&").map(searchString => {
        const [actKey,] = searchString.split("=")
        if (actKey === key) {
            changed = true
            return `${key}=${newValue}`
        }
        return searchString
    })
    if (!changed) {
        return '?' + searchStrings.join("&") + '&' + addedSearchString
    }
    return  '?' + searchStrings.join("&")
}
