import {call, put, takeLatest, select} from "redux-saga/effects";
import {ADD_STANDARD_LISTS, loadAllReportDefinitionsAction} from "../../listActions";
import {getArtistToken} from "../../../auth/token.saga";
import {selectLoggedInArtistID} from "../../../auth/authSelector";
import {getStandardReportDefinitions, insertStudioReportDefinitionList} from "../../../../api/studioApi";
import i18next from "i18next";
import {convertReportDefinitionSchemaToDb} from "../../../../models/lists";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import {i18_LIST} from "../../../../i18nReferences";

function* processAddStandardLists({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const artistId = yield select(state => selectLoggedInArtistID(state))
            const selectedListIds = payload.listIds
            const reportDefinitionList = getStandardReportDefinitions(i18next.t)
                .filter(t => selectedListIds.includes(t.ReportDefID))
                .map(r => ({...r, _createdID: artistId, _createdTS: new Date()}))
                .map(r => convertReportDefinitionSchemaToDb(r))
            yield call(insertStudioReportDefinitionList, {token, reportDefinitionList})
            yield put(loadAllReportDefinitionsAction())
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('LIST.SAVE_DATA_SUCCESS_INFO', {ns: i18_LIST}), 'saveReportDefinition')))
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.ERROR.SAVE_DATA_FAILED_INFO', {ns: i18_LIST}), 'saveReportDefinition')))
    }
}

export function* watchAddStandardLists() {
    yield takeLatest(ADD_STANDARD_LISTS, processAddStandardLists)
}