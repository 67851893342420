import {call, put, select, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {updateTerminList} from "../../../../api/terminApi";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {selectTerminListParameters} from "../../listSelector";
import {loadTerminListDataAction, UPDATE_TERMIN_STATUS_FOR_LIST} from "../../listActions";
import {i18_LIST} from "../../../../i18nReferences";

function* processUpdateTerminList({payload}) {
    try {
        const token = yield getArtistToken()
        const terminChanges = payload.terminChanges
        const count = terminChanges.terminIdList?.length || 0
        const statusName = terminChanges.statusName
        if (token && count) {
            yield call(updateTerminList, {token, parameters: terminChanges})
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('TERMIN_LIST.SAVE_DATA_SUCCESS_INFO', {
                ns: i18_LIST,
                count,
                status: statusName
            }), 'terminListUpdate')))
            const parameters = yield select(state => selectTerminListParameters(state))
            if (parameters) {
                yield put(loadTerminListDataAction(parameters))
            }
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('TERMIN_LIST.SAVE_DATA_FAILED_INFO',
            {ns: i18_LIST}), 'terminListUpdate')))
    }
}

export function* watchUpdateTerminList() {
    yield takeLatest(UPDATE_TERMIN_STATUS_FOR_LIST, processUpdateTerminList)
}