import {setLocale} from "yup";
import {i18_TRANSLATION} from "./i18nReferences";

setLocale({
    mixed: {
        required: () => ({key: 'YUP.ERROR.NEED'})
    },
    number: {
        min: ({min}) => ( { key: 'YUP.ERROR.FIELD_TOO_SHORT', values: { min } }),
        max: ({max}) => ( { key: 'YUP.ERROR.FIELD_TOO_BIG', values: { max } }),
    },
    string: {
        min: ({min}) => ( { key: 'YUP.ERROR.STRING_FIELD_TOO_SHORT', values: { min } }),
        max: ({max}) => ( { key: 'YUP.ERROR.STRING_FIELD_TOO_LONG', values: { max } })
    },
    date: {
        required: () => ({key: 'YUP.ERROR.NEED'}),
        min: () => ( { key: 'YUP.ERROR.TOO_EARLY' }),
    }
})

export const getHelperText = (t, errors, fieldName, ns) => {
    if (!errors[fieldName] || !errors[fieldName].message) {
        return undefined
    }
    const values = errors[fieldName].message.values || {}
    return t(errors[fieldName].message.key, {...values, ns: ns || i18_TRANSLATION})
}

export const isError = (errors, fieldName) => errors[fieldName] && errors[fieldName].message && errors[fieldName].message.key && errors[fieldName].message.key.indexOf('ERROR') > 0
