import axios from "axios";
import {BACKEND} from "../config/backend";
import {createAuthHeader} from "./commonApi";

export const getNachrichtTerminData = ({token, terminId}) => {
    return axios.get(`${BACKEND}/api/v1/nachricht/termin/${terminId}`, {
        ...createAuthHeader(token)
    })
}
export const getNachrichtListData = ({token, nachrichtenListParameter}) => {
    return axios.get(`${BACKEND}/api/v1/nachricht/list`, {
        ...createAuthHeader(token)
        , params: nachrichtenListParameter
    })
}

export const deleteNachrichtenByMsgIdList = ({token, nachrichtenIdList}) => {
    return axios.delete(`${BACKEND}/api/v1/nachricht/list`, {
        ...createAuthHeader(token)
        , params: {nachrichtenIdList}
    })
}