import {emitNotificationAction} from "./notificationAction";
import {createErrorNotification, createInfoNotification, createSuccessNotification} from "../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../i18nReferences";
import {put} from "redux-saga/effects"
import { INFO_NOTIFICATION } from "../../models/notification";

export function* emitSavedSuccessNotification({type, data, category}) {
    const notification = type === INFO_NOTIFICATION ?
        createInfoNotification(i18next.t('SAVED_SUCCESSFULLY', {
            ns: i18_TRANSLATION,
            data
        }), category)
        : createSuccessNotification(i18next.t('SAVED_SUCCESSFULLY', {
            ns: i18_TRANSLATION,
            data
        }), category)
    yield put(emitNotificationAction(notification))
}

export function* emitSavingFailedNotification({data, category}) {
    const notification = createErrorNotification(i18next.t('SAVING_FAILED', {
        ns: i18_TRANSLATION,
        data
    }), category)

    yield put(emitNotificationAction(notification))
}

export function* emitChangedNotification({type, data, category, errorMsg}) {
    const {key, success, notificationType} = type
    const notification = ! success ? createErrorNotification(i18next.t(key, {
            ns: i18_TRANSLATION,
            data, errorMsg
        }), category)
        : (notificationType === INFO_NOTIFICATION ?
            createInfoNotification(i18next.t(key, {
                ns: i18_TRANSLATION,
                data
            }), category)
            : createSuccessNotification(i18next.t(key, {
                ns: i18_TRANSLATION,
                data
            }), category))
    yield put(emitNotificationAction(notification))
}
