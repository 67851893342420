import { call, put, select, takeLatest } from "redux-saga/effects";
import { CHANGE_CALENDAR_EVENT_PARTICIPANT_ARTIST, refreshLoadCalendarAction } from "../calendarActions";
import { updateTerminParticipant } from "../../../api/artistApi";
import { getArtistToken } from "../../auth/token.saga";
import { selectCalendarEventZeitraum, selectTermine } from "../calendarSelector";
import { selectArtistMap } from "../../common/commonSelector";
import { createTerminNotificationInfoText } from "./moveCalendarEventSaga";
import { emitNotificationAction } from "../../notification/notificationAction";
import i18next from "i18next";
import { createErrorNotification, createInfoNotification } from "../../../models/notification";

function* processChangeParticipantArtist({ payload }) {
    const zeitraum = yield select((state) => selectCalendarEventZeitraum(state));
    const notificationCategory = i18next.t("CALENDAR.MOVE_TERMIN_CATEGORY");
    try {
        const { ArtistID, TrmAttID, fromArtistID, toArtistID, TerminID } = payload.terminChanges;
        const token = yield* getArtistToken();
        const artistMap = yield select((state) => selectArtistMap(state));
        const termine = yield select((state) => selectTermine(state));
        const termin = termine.find((t) => t.TerminID === TerminID && t.TrmAttID);
        if (token && termin) {
            yield call(updateTerminParticipant, {
                token,
                artistId: ArtistID,
                terminParticipantChange: { TrmAttID, toArtistID },
            });
            yield put(refreshLoadCalendarAction(zeitraum));
            const originTerminDetails = createTerminNotificationInfoText(termin, artistMap);
            const oldParticipant = artistMap[fromArtistID].Kuerzel;
            const participant = artistMap[toArtistID].Kuerzel;
            yield put(
                emitNotificationAction(
                    createInfoNotification(
                        i18next.t("CALENDAR.CHANGE_TERMIN_PARTICIPANT_SUCCESS_INFO", {
                            details: originTerminDetails,
                            oldParticipant,
                            participant,
                        }),
                        notificationCategory
                    )
                )
            );
        }
    } catch (err) {
        if (err.response && err.response.status === 403) {
            yield put(
                emitNotificationAction(
                    createErrorNotification(i18next.t("AUTHORIZATION_ERROR_MESSAGE"), notificationCategory)
                )
            );
        } else {
            yield put(
                emitNotificationAction(
                    createErrorNotification(i18next.t("CALENDAR.SAVE_DATA_FAILED_INFO"), notificationCategory)
                )
            );
        }
        const zeitraum = yield select((state) => selectCalendarEventZeitraum(state));
        yield put(refreshLoadCalendarAction(zeitraum));
        console.log(err);
    }
}

export function* watchChangeAttendentArtist() {
    yield takeLatest([CHANGE_CALENDAR_EVENT_PARTICIPANT_ARTIST], processChangeParticipantArtist);
}
