import i18next from "i18next";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { updateArtistLocation } from "../../../api/artistApi";
import { i18_TRANSLATION } from "../../../i18nReferences";
import { convertArtistLocation2DB } from "../../../models/artist";
import { toDateString } from "../../../models/date";
import { createErrorNotification, createInfoNotification } from "../../../models/notification";
import { getArtistToken } from "../../auth/token.saga";
import { clearCalendarSelectedArtistLocationAction } from "../../calendar/calendarActions";
import { emitNotificationAction } from "../../notification/notificationAction";
import { UPDATE_ARTIST_LOCATION, reloadCommonArtistsAction } from "../commonActions";
import { selectArtistMap } from "../commonSelector";

export function* processUpdateArtistLocation({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    yield put(clearCalendarSelectedArtistLocationAction())
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(updateArtistLocation, {
                token,
                artistLocation: convertArtistLocation2DB(payload.artistLocation)
            })
            const {DatVon, DatBis} = payload.artistLocation
            yield put(emitNotificationAction(createInfoNotification(i18next.t('CALENDAR_LOCATION.LOCATION_UPDATED', {
                ns: i18_TRANSLATION,
                Kuerzel: artistMap[payload.artistLocation.ArtistID].Kuerzel,
                DatVon: toDateString(DatVon),
                DatBis: toDateString(DatBis)
            }), 'processUpdateArtistLocation')))
            yield put(reloadCommonArtistsAction())
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR_LOCATION.LOCATION_FAILED', {
            ns: i18_TRANSLATION,
            Kuerzel: artistMap[payload.artistLocation.ArtistID].Kuerzel,
        }), 'processUpdateArtistLocation')))
        console.log(err)
    }
}

export function* watchUpdateArtistLocation() {
    yield takeLatest([UPDATE_ARTIST_LOCATION], processUpdateArtistLocation)
}
