import {createSelector} from "reselect";
import {createFieldSelector, identityFunction} from "../utils/selectors";

export const selectNotificationState = createSelector(
    (state) => state.notification
    , identityFunction
)

export const selectActiveNotification = createFieldSelector(selectNotificationState, 'activeNotification')
export const selectAllButInfoNotification = createSelector(
    selectActiveNotification
    , notification => notification && notification?.severity !== 'info' ? notification : undefined
)
export const selectLastNotifications = createFieldSelector(selectNotificationState, 'lastNotifications')
