import {getArtistToken} from "../../auth/token.saga";
import {selectKunde} from "../kundeSelector";
import {mergeProjekte} from "../../../api/projektApi";
import {MERGE_PROJEKTE, mergeProjekteFailedAction, mergeProjekteSuccessAction} from "../kundeAction";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../i18nReferences";
import {loadKundeData} from "./loadKundeDataSaga";
import {call, put, select, takeLatest} from "redux-saga/effects"

export function* processMergeProjekte({payload}) {
    try {
        const token = yield getArtistToken()
        let kunde = yield select(state => selectKunde(state))
        const mergeConfig = payload.mergeConfig
        if (token && mergeConfig) {
            yield call(mergeProjekte, {token, mergeConfig})
            yield put(mergeProjekteSuccessAction())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('PROJEKT_MERGE_DIALOG.MERGING_SUCCESSFUL',
                {ns: i18_EDITPROJEKT}), 'processMergeProjekte')))
            if (kunde.KundID) {
                yield* loadKundeData({payload: {kundId: kunde.KundID}})
            }
        }
    } catch (err) {
        yield put(mergeProjekteFailedAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT_MERGE_DIALOG.MERGING_FAILED',
            {ns: i18_EDITPROJEKT}), 'processMergeProjekte')))
        console.log(err)
    }
}

export function* watchMergeProjekte() {
    yield takeLatest(MERGE_PROJEKTE, processMergeProjekte)
}