import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { insertEventLog } from '../api/eventLogApi';

const EventLogContext = createContext(null);

const sendEvents = async (events) => {
  if (events.length > 0) {
      try {
          await insertEventLog(events);
      } catch (error) {
          console.error("Fehler beim Senden von Events:", error);
      }
  }
};

const MAX_EVENT_QUEUE_SIZE = 3;

export function EventLogProvider({ children }) {
  const [events, setEvents] = useState([]);

  // Funktion zum Hinzufügen eines Events
  const logEvent = useCallback(event => {
    setTimeout(() => setEvents(prev => [...prev, event]), 0)
  }, [])

  useEffect(() => {
    if (events.length >= MAX_EVENT_QUEUE_SIZE) {

      sendEvents(events)
      setEvents([])
    }
  }, [events])

  return (
    <EventLogContext.Provider value={{ logEvent }}>
      {children}
    </EventLogContext.Provider>
  );
}

// Custom Hook, um EventLog-Kontext zu verwenden
export function useEventLog() {
  const context = useContext(EventLogContext);
  if (!context) {
    throw new Error('useEventLog must be used within an EventLogProvider');
  }
  return context;
}
