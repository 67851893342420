import {selectSelectedTerminArtistID, selectSelectedTerminKundID} from "../terminSelectors";
import {createNewProjekt} from "./createNewProjekt";
import {EDIT_TERMIN_ADD_PROJEKT, editTerminAddProjektSuccessAction} from "../terminActions";
import {put, select, takeLatest} from "redux-saga/effects";

export function* processEditTerminAddProjektAction() {
    const selectedKundID = yield select(state => selectSelectedTerminKundID(state))
    const selectedTerminArtistID = yield select(state => selectSelectedTerminArtistID(state))
    const projekt = yield* createNewProjekt(selectedKundID, selectedTerminArtistID)
    yield put(editTerminAddProjektSuccessAction(projekt))
}

export function* watchEditTerminAddProjektAction() {
    yield takeLatest(EDIT_TERMIN_ADD_PROJEKT, processEditTerminAddProjektAction)
}