import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getAllArtists} from "../../../api/artistApi";
import {
    RELOAD_COMMON_ARTISTS,
    reloadCommonArtistsFailedAction,
    reloadCommonArtistsSuccessAction
} from "../commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* processReloadArtists() {
    try {
        const token = yield getArtistToken()
        if (token) {
            const artistMap = extractDataFromResponse(yield call(getAllArtists, {token}))
            yield put(reloadCommonArtistsSuccessAction(artistMap))
        }
    } catch (err) {
        console.log(err)
        yield put(reloadCommonArtistsFailedAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('COMMON.LOAD_ARTIST_DATA_FAILED_INFO', {ns: i18_TRANSLATION}), 'loadCommon')))
    }
}

export function* watchReloadArtists() {
    yield takeLatest(RELOAD_COMMON_ARTISTS, processReloadArtists)
}