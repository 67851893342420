import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_EDITED_PROJEKT_SUCCESS = 'LOAD_EDITED_PROJEKT_SUCCESS'
export const loadEditedProjektSuccess = createSimpleAction(LOAD_EDITED_PROJEKT_SUCCESS, 'projekt')

export const LOAD_PROJEKT_IMAGES = 'LOAD_PROJEKT_IMAGES'
export const loadProjektImages = createSimpleAction(LOAD_PROJEKT_IMAGES, 'projektId')

export const LOAD_PROJEKT_IMAGES_SUCCESS = 'LOAD_PROJEKT_IMAGES_SUCCESS'
export const loadProjektImagesSuccess = createSimpleAction(LOAD_PROJEKT_IMAGES_SUCCESS, 'projektImages')

export const ADD_PROJEKT_IMAGES = 'ADD_PROJEKT_IMAGES'
export const addProjektImagesAction = createSimpleAction(ADD_PROJEKT_IMAGES, 'images')

export const ADD_PROJEKT_IMAGES_SUCCESS = 'ADD_PROJEKT_IMAGES_SUCCESS'
export const addProjektImagesSuccessAction = createSimpleAction(ADD_PROJEKT_IMAGES_SUCCESS, 'keyList')

export const ADD_PROJEKT_IMAGES_FAILURE = 'ADD_PROJEKT_IMAGES_FAILURE'
export const addProjektImagesFailureAction = createTypeAction(ADD_PROJEKT_IMAGES_FAILURE)

export const DELETE_PROJEKT_IMAGE = 'DELETE_PROJEKT_IMAGE'
export const deleteProjektImage = createSimpleAction(DELETE_PROJEKT_IMAGE, 'deletedImage')

export const CLEAR_PROJEKT = 'CLEAR_PROJEKT'
export const clearProjektAction = createTypeAction(CLEAR_PROJEKT)

export const UPDATE_PROJEKT = 'UPDATE_PROJEKT'
export const updateProjektAction = createSimpleAction(UPDATE_PROJEKT, 'projekt')

export const SET_PROJEKT_IS_UPDATING = 'SET_PROJEKT_IS_UPDATING'
export const setProjektIsUpdatingAction = createTypeAction(SET_PROJEKT_IS_UPDATING)

export const UPDATE_PROJEKT_SUCCESS = 'UPDATE_PROJEKT_SUCCESS'
export const updateProjektSuccess = createTypeAction(UPDATE_PROJEKT_SUCCESS)

export const UPDATE_PROJEKT_FAILURE = 'UPDATE_PROJEKT_FAILURE'
export const updateProjektFailure = createTypeAction(UPDATE_PROJEKT_FAILURE)

export const DELETE_PROJEKT = 'DELETE_PROJEKT'
export const deleteProjektAction = createSimpleAction(DELETE_PROJEKT, 'projekt')

export const DELETE_PROJEKT_SUCCESS = 'DELETE_PROJEKT_SUCCESS'
export const deleteProjektSuccessAction = createTypeAction(DELETE_PROJEKT_SUCCESS)

export const DELETE_PROJEKT_FAILED = 'DELETE_PROJEKT_FAILED'
export const deleteProjektFailedAction = createTypeAction(DELETE_PROJEKT_FAILED)
