import {CLEAR_ACTIVE_NOTIFICATION, EMIT_NOTIFICATION} from "./notificationAction";

const MAX_NOTIFICATIONS = 10

const INITIAL = {lastNotifications: []}

export default function notificationReducers(state = INITIAL, action) {
    switch (action.type) {

        case EMIT_NOTIFICATION:
            return {
                ...state,
                lastNotifications: state.lastNotifications ? [action.payload.notification].concat(state.lastNotifications).slice(0, MAX_NOTIFICATIONS) : [action.payload.notification],
                activeNotification: action.payload.notification
            }
        case CLEAR_ACTIVE_NOTIFICATION:
            return {
                ...state,
                lastNotifications: [...state.lastNotifications],
                activeNotification: undefined
            }
        default:
            return state;
    }
}