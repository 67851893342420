import {fork} from "redux-saga/effects";
import {
    watchChangArtistAndZeitraum,
    watchChangeCalendarArtist,
    watchLoadTermine,
    watchSwitchToArtistView
} from "./sagas/loadCalendarSaga";
import {watchChangeEventFilter} from "./sagas/eventFilterChangedSaga";
import {changeViewTypeSaga} from "./sagas/changeViewTypeSaga";
import {watchChangeResourceFilter} from "./sagas/changeResourceFilterSaga";
import {watchLoadSelectedKundenTermine} from "./sagas/loadSelectedKundenTermineSaga";
import {watchMoveCalendarEvent} from "./sagas/moveCalendarEventSaga";
import {watchLoadCalendarToasterData, watchLoadCalendarToasterImages} from "./sagas/loadCalendarToasterDataSaga";
import {watchDeleteSelectedTermin} from "./sagas/deleteSelectedTerminSaga";
import {watchSearchCalendarKunden} from "./sagas/searchCalendarKundenSaga";
import {watchSearchCalendarTerminslots} from "./sagas/searchCalendarTerminslotsSaga";
import { watchChangeAttendentArtist } from "./sagas/changeParticipantArtistSaga";

const calendarSagas = [fork(watchLoadTermine)
    , fork(changeViewTypeSaga)
    , fork(watchChangeEventFilter)
    , fork(watchChangeResourceFilter)
    , fork(watchLoadSelectedKundenTermine)
    , fork(watchMoveCalendarEvent)
    , fork(watchLoadCalendarToasterData)
    , fork(watchLoadCalendarToasterImages)
    , fork(watchDeleteSelectedTermin)
    , fork(watchSearchCalendarKunden)
    , fork(watchSearchCalendarTerminslots)
    , fork(watchChangeCalendarArtist)
    , fork(watchSwitchToArtistView)
    , fork(watchChangArtistAndZeitraum)
    , fork(watchChangeAttendentArtist)
]

export default calendarSagas
