import {loadArtistMailBaseAction, SAVE_ARTIST_BASE} from "../artistActions";
import {select, takeLatest, put} from "redux-saga/effects";
import {processUpdateArtist} from "./updateArtist";
import {selectArtistMap} from "../../common/commonSelector";
import i18next from "i18next";
import {createErrorNotification, createSuccessNotification} from "../../../models/notification";
import {i18_ARTIST} from "../../../i18nReferences";

function* processSaveArtistBase({payload}) {
    const artistData = payload.artistBaseData
    const artistMap = yield select(state => selectArtistMap(state))
    const kuerzel = artistData.Kuerzel || artistMap[artistData.ArtistID].Kuerzel
    const successNotification = createSuccessNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_SUCCESS_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'saveArtist')
    const failureNotification = createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_FAILED_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'saveArtist')
    yield processUpdateArtist(payload.artistBaseData, successNotification, failureNotification)
    yield put(loadArtistMailBaseAction(artistData.ArtistID))
}

export function* watchSaveArtistBase() {
    yield takeLatest(SAVE_ARTIST_BASE, processSaveArtistBase)
}