import {fork} from 'redux-saga/effects';
import {watchLoadCommon} from "./sagas/bootstrapCommon";
import {watchReloadArtists} from "./sagas/reloadArtists";
import {watchReloadMsgVorlagen} from "./sagas/reloadMsgVorlagen";
import {watchReloadPdfVorlagen} from "./sagas/reloadPdfVorlagen";
import {watchDownloadFile} from "./sagas/downloadFile";
import {watchDownloadFilledTerminlistVorlage} from "./sagas/downloadFilledTerminlistVorlage";
import {watchDownloadVoucher} from "./sagas/downloadVoucher";
import {watchInitiateTerminlistWorkflow} from "./sagas/initiateTerminlistWorkflow";
import {watchDownloadKundenformular} from "./sagas/downloadKundenformular";
import {watchInitiateKundenformularWorkflow} from "./sagas/initiateKundenformularWorkflow";
import {watchDownloadTermsOfUse} from "./sagas/downloadTermsOfUse";
import {watchDownloadSubscriptionContract} from "./sagas/downloadSubscriptionContract";
import {watchDisableArtistInfo} from "./sagas/disableArtistInfo";
import {watchInsertArtistLocation} from "./sagas/insertArtistLocation";
import {watchDeleteArtistLocation} from "./sagas/deleteArtistLocation";
import {watchUpdateArtistLocation} from "./sagas/updateArtistLocation";
import {watchMoveCalendarLocation} from "./sagas/moveArtistLocation";
import {watchReloadStudio} from "./sagas/reloadStudio";
import {watchUpdateStudio} from "./sagas/updateStudio";
import {watchDeleteLocation} from "./sagas/deleteStudioLocationSaga";
import {watchSaveStudioLocation} from "./sagas/saveLocationSaga";
import {watchCreateSupportTicket} from "./sagas/createSupportTicketSaga";

const commonSagas = [fork(watchLoadCommon), fork(watchReloadArtists)
    , fork(watchDownloadFile), fork(watchDownloadFilledTerminlistVorlage), fork(watchInitiateTerminlistWorkflow)
    , fork(watchDownloadKundenformular), fork(watchInitiateKundenformularWorkflow), fork(watchDisableArtistInfo), fork(watchInsertArtistLocation)
    , fork(watchDeleteArtistLocation), fork(watchUpdateArtistLocation), fork(watchMoveCalendarLocation), fork(watchDownloadVoucher)
    , fork(watchReloadMsgVorlagen), fork(watchReloadPdfVorlagen), fork(watchReloadStudio), fork(watchUpdateStudio), fork(watchDownloadTermsOfUse)
    , fork(watchDownloadSubscriptionContract), fork(watchDeleteLocation), fork(watchSaveStudioLocation), fork(watchCreateSupportTicket)]

export default commonSagas
