import {DISABLE_ARTIST_INFO} from "../commonActions";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {saveArtistInfoSettings} from "../../../api/localArtistPersistence";
import {call, select, takeLatest} from "redux-saga/effects"

function* processDisableArtistInfo({payload}) {
    try {
        const artistId = yield select(state => selectLoggedInArtistID(state))
        if (artistId) {
            const disabledInfoChanges = {[payload.infoType]: true}
            yield call(saveArtistInfoSettings, {artistId, disabledInfoChanges})
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchDisableArtistInfo() {
    yield takeLatest([DISABLE_ARTIST_INFO], processDisableArtistInfo)
}