import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getProjektById} from "../../../api/projektApi";
import {LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT, loadImagesOfEditedTerminProjektSuccessAction} from "../terminActions";
import {call, put, takeLatest} from "redux-saga/effects";

export function* processLoadImagesOfEditedTerminProjektAction({payload}) {
    const token = yield getArtistToken()
    if (token) {
        const projektData = extractDataFromResponse(yield call(getProjektById, {token, projektId: payload.projektId}))
        yield put(loadImagesOfEditedTerminProjektSuccessAction(projektData.images || []))
    }
}

export function* watchLoadImagesOfEditedTerminProjektAction() {
    yield takeLatest(LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT, processLoadImagesOfEditedTerminProjektAction)
}