export const replacePayloadFields = (state, action) => ({...state, ...action.payload});

export const addCounterStates = (state, actionPayload) => {
    const stateFields = state && Object.keys(state) || []
    const fieldNames =  Object.keys(actionPayload).concat(stateFields)
    return fieldNames.reduce((result, fieldName) => {
        result[fieldName] = (state && state[fieldName] || 0) + (actionPayload[fieldName] || 0)
        return result
    }, {})
}
export const removeField = (state, fieldName) => ({...state, [fieldName]: undefined});
export const initiateAsyncLoadingAction = (state, fieldName) => ({...state
    , [fieldName + 'Loading']: true
    , [fieldName + 'Failure']: undefined
})
export const initiateAsyncLoadingWithParamsAction = (state, fieldName, params) => ({...state
    , [fieldName + 'NewParams']: params
    , [fieldName + 'Loading']: true
    , [fieldName + 'Failure']: undefined
})
export const initiateClearAndAsyncLoadingWithParamsAction = (state, fieldName, params) => ({...state
    , [fieldName + 'NewParams']: params
    , [fieldName + 'Loading']: true
    , [fieldName + 'Failure']: undefined
    , [fieldName]: undefined
})
export const asyncLoadingActionSuccess = (state, action, fieldName) => ({...state
    , [fieldName + 'Params']: state[fieldName + 'NewParams']
    , [fieldName + 'NewParams']: undefined
    , [fieldName + 'Loading']: false
    , [fieldName]: action.payload[fieldName]
})
export const asyncLoadingNoDataChange = (state, fieldName) => ({...state
    , [fieldName + 'Params']: state[fieldName + 'NewParams']
    , [fieldName + 'NewParams']: undefined
    , [fieldName + 'Loading']: false
})
export const clearAsyncLoading = (state, fieldName) => ({...state
    , [fieldName + 'Params']: undefined
    , [fieldName + 'NewParams']: undefined
    , [fieldName + 'Loading']: false
    , [fieldName]: undefined
})
export const asyncLoadingActionFailure = (state, fieldName) => ({...state
    , [fieldName + 'NewParams']: undefined
    , [fieldName + 'Loading']: false
    , [fieldName]: undefined
    , [fieldName + 'Failure']: true
})
export const clearAsyncLoadingAction= (state, fieldName) => ({...state
    , [fieldName + 'NewParams']: undefined
    , [fieldName + 'Loading']: false
    , [fieldName]: undefined
    , [fieldName + 'Failure']: undefined
})

export const asyncLoadingActionSuccessPaged = (state, action, fieldName) => ({...state
        , [fieldName + 'Params']: state[fieldName + 'NewParams']
        , [fieldName + 'NewParams']: undefined
        , [fieldName + 'Loading']: false
        , [fieldName]: action.payload[fieldName]
        , [fieldName + 'Retained']: (state[fieldName + 'Retained'] || []).concat(state[fieldName]?.list || [])
    })
