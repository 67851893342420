export const replaceDateFields = (model) => {
    return Object.keys(model).reduce((result, key) => {
        result[key] = (model[key] && model[key] instanceof Date) ? model[key].toISOString() :  model[key]
        return result
    }, {})
}

export const convertCurrency = (betrag, waehrung, locale) => {
    if (waehrung && locale) {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: waehrung }).format(betrag)
    }
    return new Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' }).format(betrag)
}