import {getArtistToken} from "../../auth/token.saga";
import {replaceDateFields} from "../../utils/modelConverter";
import {call} from "redux-saga-test-plan/matchers";
import {updateArtist} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {put} from "redux-saga/effects"

export function* processUpdateArtist(artistData, successNotification, failureNotification) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const artist = replaceDateFields(artistData)
            yield call(updateArtist, {token, artist})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(successNotification))
        }
    } catch (err) {
        yield put(emitNotificationAction(failureNotification))
        console.log(err)
    }
}