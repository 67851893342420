import {getArtistToken} from "../auth/token.saga";
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {getKundenData} from "../../api/kundenApi";
import {emitNotificationAction} from "../notification/notificationAction";
import {createErrorNotification} from "../../models/notification";
import {call, put, takeLatest} from "redux-saga/effects";
import i18next from "i18next";
import {LOAD_KUNDEN_TERMINE, loadKundenTermineSuccessAction} from "./kundenTermineAction";
import {fork} from "redux-saga-test-plan/matchers";
import {i18_KUNDE} from "../../i18nReferences";

function* processLoadKundenTermine({payload}) {
    try {
        const kundId = payload.kundId
        const token = yield getArtistToken()
        if (token) {
            const {termine, kunde} = extractDataFromResponse(yield call(getKundenData, {token, kundId}))
            yield put(loadKundenTermineSuccessAction({termine, kunde}))
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.LOAD_DATA_FAILED_INFO',
            {ns: i18_KUNDE}), 'loadKundeData')))
        console.log(err)
    }
}

function* watchLoadKundenTermine() {
    yield takeLatest(LOAD_KUNDEN_TERMINE, processLoadKundenTermine)
}

const kundenTermineSaga = [fork(watchLoadKundenTermine)]

export default kundenTermineSaga