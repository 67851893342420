const ONE_DAY = 1000 * 60 * 60 * 24
const plus = (date, days) => new Date(date.getTime() + ONE_DAY * days)
    
const startOfDay = (date) => {
    date.setHours(0)
    return date;
}
const endOfDay = (date) => {
    date.setHours(24)
    return date;
}

export const convertZeitraum2Period = (zeitraum) => {
    return {von: startOfDay(new Date(zeitraum.start)), bis: endOfDay(new Date(zeitraum.end))}
}


export const expandEndOneDay = zeitraum => {
    return {
        ...zeitraum
        , bis: plus(zeitraum.bis, 1)
    }
}

export const reduceIsoDateToDateString = date => date.substring(0, 10)

export const formatDateWithShortTime = (date, locale, timeZone) => date && locale && timeZone &&
    new Intl.DateTimeFormat(locale, { timeZone, dateStyle: 'medium', timeStyle: 'short' }).format(date)


export const formationShortTime = (date, locale, timeZone) => date && locale && timeZone &&
    new Intl.DateTimeFormat(locale, { timeZone, timeStyle: 'medium' }).format(date)

export const formatDate = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatDateWithWeekday = (date, locale) => {
    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const getNextMonday = () => {
    const newDate = new Date()
    if (newDate.getDay() === 1) {
        return newDate
    }
    if (newDate.getDay() === 0) {
        return plus(newDate, 1)
    }
    return plus(newDate, 8 - newDate.getDay())

}

export const convertDateStringToDate = (dateString) => {
    if (!dateString) {
        return null
    }
    if (typeof dateString ==='string') {
        return new Date(dateString)
    }
    // check if it is a date
    if (Object.prototype.toString.call(dateString) === '[object Date]') {
        return dateString
    }
    return null
}

export const isAfterByDate = (date1, date2) => {
    const date1Count = date1.getFullYear() * 365 + date1.getMonth() * 30 + date1.getDate()
    const date2Count = date2.getFullYear() * 365 + date2.getMonth() * 30 + date2.getDate()
    return date1Count > date2Count
}

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export const toDateString = (date) => date.getFullYear() + '-' + pad(date.getMonth() + 1, 2) + '-' + pad(date.getDate(), 2)
