export function pick(object, keys) {
    return keys.reduce((obj, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (object && object.hasOwnProperty(key)) {
            obj[key] = object[key];
        }
        return obj;
    }, {});
}

export const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export function debounce(func, wait, immediate) {
    let timeout;
    let debouncedFunction = function() {
        const context = this;
        const args = arguments;
        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        }, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };

    // Add a cancel method to the debounced function
    debouncedFunction.cancel = function() {
        clearTimeout(timeout);
        timeout = null;
    };

    return debouncedFunction;
}



export const omitId = (obj) => {
    if (!obj) {
        return obj
    }
    // eslint-disable-next-line no-unused-vars
    const {id, ...result} = obj
    return result
}

export const omit = (obj, fields) => {
    return Object.keys(obj)
       .filter(key =>!fields.includes(key))
       .reduce((result, key) => {
            result[key] = obj[key];
            return result;
        }, {});
}