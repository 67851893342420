import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_MSG_VORLAGE_SUCCESS = 'LOAD_MSG_VORLAGE_SUCCESS'
export const loadMsgVorlageSuccessAction = createSimpleAction(LOAD_MSG_VORLAGE_SUCCESS, 'msgVorlage')

export const SAVE_MSG_VORLAGE = 'SAVE_MSG_VORLAGE'
export const saveMsgVorlageAction = createSimpleAction(SAVE_MSG_VORLAGE, 'msgVorlage')

export const COPY_MSG_VORLAGE = 'COPY_MSG_VORLAGE'
export const copyMsgVorlageAction = createSimpleAction(COPY_MSG_VORLAGE, 'msgVorlageId')

export const DELETE_MSG_VORLAGE = 'DELETE_MSG_VORLAGE'
export const deleteMsgVorlageAction = createSimpleAction(DELETE_MSG_VORLAGE, 'msgVorlageId')

export const VORLAGEN_DATA_CHANGED = 'VORLAGEN_DATA_CHANGED'
export const vorlagenDataHasChangedAction = createTypeAction(VORLAGEN_DATA_CHANGED)

export const CLEAR_VORLAGEN_DATA_CHANGED = 'CLEAR_VORLAGEN_DATA_CHANGED'
export const clearVorlagenDataChangedAction = createTypeAction(CLEAR_VORLAGEN_DATA_CHANGED)

export const CLEAR_VORLAGEN_MSG_DATA = 'CLEAR_VORLAGEN_MSG_DATA'
export const clearVorlagenMsgDataAction = createTypeAction(CLEAR_VORLAGEN_MSG_DATA)

export const SAVE_AUTO_MSG_SETTINGS = 'SAVE_AUTO_MSG_SETTINGS'
export const saveAutoMsgSettings = createSimpleAction(SAVE_AUTO_MSG_SETTINGS, 'autoMsgSettings')

export const SAVE_PDF_TEMPLATE = 'SAVE_PDF_TEMPLATE'
export const savePdfTemplateAction = createSimpleAction(SAVE_PDF_TEMPLATE, 'pdfVorlage')

export const ADD_PDF_TEMPLATE = 'ADD_PDF_TEMPLATE'
export const addPdfTemplateAction = createSimpleAction(ADD_PDF_TEMPLATE, 'pdfVorlage')

export const DELETE_PDF_VORLAGE = 'DELETE_PDF_VORLAGE'
export const deletePdfVorlageAction = createSimpleAction(DELETE_PDF_VORLAGE, 'pdfVorlagenId')

export const COPY_STD_PDF_VORLAGEN = 'COPY_STD_PDF_VORLAGEN'
export const copyStdPdfVorlageAction = createTypeAction(COPY_STD_PDF_VORLAGEN)