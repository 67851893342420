import {getArtistToken} from "../../auth/token.saga";
import {call} from "redux-saga-test-plan/matchers";
import {deactivateArtists} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {put, select, takeLatest} from "redux-saga/effects"
import {selectArtistMap} from "../../common/commonSelector";
import {DEACTIVATE_ARTISTS} from "../artistActions";
import {i18_ARTIST} from "../../../i18nReferences";

function* processDeactivateArtists({payload}) {
    const {artistIdList} = payload
    const artistMap = yield select(state => selectArtistMap(state))
    const artistNames = artistIdList.map(id => artistMap && artistMap[id]?.Kuerzel || 'not found').join(', ')
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(deactivateArtists, {token, artistIdList})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_ARTIST.DEACTIVATE_ARTISTS_SUCCESS_INFO', {
                ns: i18_ARTIST,
                artistNames
            }), 'changeArtistActivState')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.DEACTIVATE_ARTISTS_FAILED_INFO', {
            ns: i18_ARTIST,
            artistNames
        }), 'changeArtistActivState')))
        console.log(err)
    }
}

export function* watchDeactivateArtists() {
    yield takeLatest([DEACTIVATE_ARTISTS], processDeactivateArtists)
}