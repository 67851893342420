import { PlainDate } from "@kiss-solutions/plaindate";

export const isWithinPeriod = (basePeriod, period) => {
    if (!basePeriod) {
        return false
    }
    return basePeriod.start <= period.start && basePeriod.end >= period.end
}

export const intersectsPeriod = (basePeriod, period) => {
    if (!basePeriod || !period) {
        return undefined
    }
    return basePeriod.start <= period.end && period.start <= basePeriod.end
}

export const getWeekFromDate = (pdDate) => {
    const diffStart = pdDate.getDayOfWeek() === 0 ? 6 : pdDate.getDayOfWeek() - 1
    const diffEnd = !pdDate.getDayOfWeek() ? 7 : 8 - pdDate.getDayOfWeek()
    return {
        start: new PlainDate(pdDate).add(-diffStart).toDateString(),
        end: new PlainDate(pdDate).add(diffEnd).toDateString()
    }
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const getResourcePeriodForDate = (date) => ({start: new PlainDate(date).toDateString(), end: new PlainDate(date).add(1).toDateString()})

export const changeZeitraumForResourceView = (zeitraum) => {
    const currentWeek = getWeekFromDate(new PlainDate())
    if (currentWeek.start === zeitraum.start) {
        return getResourcePeriodForDate(new PlainDate())
    } else {
        return getResourcePeriodForDate(new PlainDate(zeitraum.start))
    }
}

const ONE_DAY = 24 * 60 * 60 * 1000;

export const constructTerminPeriod = (termin, locale, timeZone) => {
    const start = new Date(termin.Start)
    const datBis = new Date(termin.DatBis)
    const multiDay = datBis.getTime() - start.getTime() > ONE_DAY;

    const fmtLongPart = new Intl.DateTimeFormat(locale, {
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
    const datePart = new Intl.DateTimeFormat(locale, {
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const fmtTimePart = new Intl.DateTimeFormat(locale, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric'
    });
    return termin.Ganztags ? datePart.format(start) + ' - '
        + datePart.format(datBis)
        : multiDay ? datePart.format(start) + ' - '
            + datePart.format(datBis)
            : fmtLongPart.format(start) + ' - '
            + fmtTimePart.format(datBis)

}

export const convertTerminToText = (termin, locale, timeZone, terminTyp) => {
    if (!termin) {
        return undefined;
    }
    return (termin.Kuerzel ? (termin.Kuerzel + ': ') : '') + constructTerminPeriod(termin, locale, timeZone, !!terminTyp) + (terminTyp !== undefined ? (' ' + terminTyp) : '')
}

export const createNewPeriod = (startDate, durationMinutes, roundMinutes) => {
    const coeff = 60_000 * roundMinutes
    const start = new Date(Math.round(startDate.getTime() / coeff) * coeff)
    const end = new Date(start.getTime() + durationMinutes * 60_000)
    return {start, end}
}
