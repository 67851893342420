import {getArtistToken} from "../../../auth/token.saga";
import {deleteNachrichtenByMsgIdList} from "../../../../api/nachrichtApi";
import {
    DELETE_NACHRICHTEN,
    loadNachrichtenListDataAction,
    loadNachrichtenListDataFailureAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

import {call, put, select, takeLatest} from "redux-saga/effects"
import {selectParameterForNachrichtenList} from "../../listSelector";

function* processDeleteNachrichten({payload}) {
    try {
        const nachrichtenIdList = payload.nachrichtenIdList
        const token = yield getArtistToken()
        if (token) {
            const selectionParameters = yield select(state => selectParameterForNachrichtenList(state))
            yield call(deleteNachrichtenByMsgIdList, {token, nachrichtenIdList})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('LIST_NACHRICHTEN.DELETE_NACHRICHTEN_LIST_DATA_SUCCESS_INFO'
                , {ns: i18_LIST, count: nachrichtenIdList.length}), 'deleteNachrichten')))
            yield put(loadNachrichtenListDataAction(selectionParameters))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST_NACHRICHTEN.DELETE_NACHRICHTEN_LIST_DATA_FAILED_INFO', {ns: i18_LIST}), 'deleteNachrichten')))
        yield put(loadNachrichtenListDataFailureAction())
        console.log(err)
    }
}

export function* watchDeleteNachrichten() {
    yield takeLatest(DELETE_NACHRICHTEN, processDeleteNachrichten)
}