import axios from "axios";
import {createAuthHeader} from "./commonApi";
import {BACKEND} from "../config/backend";
import {wrapUrlComponents} from "../store/utils/urlHelper";

export const getKunden = ({token, kundenIds, namePart}) => {
    const kundenIdQuery = kundenIds && kundenIds.join(',')
    const search = kundenIdQuery ? `?kundenids=${kundenIdQuery}` : `?namepart=${encodeURIComponent(namePart)}`
    return axios.get(`${BACKEND}/api/v1/kunden${search}`, {
        ...createAuthHeader(token)
    })
}

export const getKundenListData = ({token, kundenListParameter}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/list`, {
        ...createAuthHeader(token)
        , params: kundenListParameter
    })
}

export const getKundenBySearchStringAndArtist = ({token, searchString, selectedArtistID}) => {
    const searchParameters = wrapUrlComponents({searchstring: searchString, artistid: selectedArtistID})
    return axios.get(`${BACKEND}/api/v1/kunden?${searchParameters}`, {
        ...createAuthHeader(token)
    })
}

export const getKundenByKundIdList = ({token, kundIdList}) => {
    const kundenids = kundIdList.join(",")
    const searchParameters = wrapUrlComponents({kundenids})
    return axios.get(`${BACKEND}/api/v1/kunden?${searchParameters}`, {
        ...createAuthHeader(token)
    })
}

export const getKundenTermine = ({token, kundId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}/termine`, {
        ...createAuthHeader(token)
    })
}

export const getKundenProjekte = ({token, kundId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}/projekte`, {
        ...createAuthHeader(token)
    })
}

export const getKundenFormularstatusOfTermin = ({token, terminId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/formularstatus`, {
        ...createAuthHeader(token)
        , params: {terminid: terminId}
    })
}

export const getKundenData = ({token, kundId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}`, {
        ...createAuthHeader(token)
    })
}

export const getOnlyKundeBase = ({token, kundId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}?onlybase=true`, {
        ...createAuthHeader(token)
    })
}

export const getKundenDoubletten = ({token, Vorname, Nachname, Email, Mobil}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/doubletten`, {
        ...createAuthHeader(token)
        , params: {
            Vorname, Nachname, Email, Mobil
        }
    })
}

export const getDoubletten = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/doubletten`, {
        ...createAuthHeader(token)
    })
}

export const mergeDoubletten = ({token, mergeData}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/doubletten/merge`, mergeData, {
        ...createAuthHeader(token)
    })
}

export const updateKunde = ({token, kunde}) => {
    return axios.put(`${BACKEND}/api/v1/kunden/${kunde.KundID}`, kunde, {
        ...createAuthHeader(token)
    })
}

export const insertKunde = ({token, kunde}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/`, kunde, {
        ...createAuthHeader(token)
    })
}

export const importKunden = ({token, kunden}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/import`, kunden, {
        ...createAuthHeader(token)
    })
}

export const sendFileLink = ({token, kundId, fileId}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/messages/filelink`, {kundId, fileId}, {
        ...createAuthHeader(token)
    })
}

export const sendMail = ({token, kundId, nachricht}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/${kundId}/mail`, nachricht, {
        ...createAuthHeader(token)
    })
}

export const sendSms = ({token, kundId, nachricht}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/${kundId}/sms`, nachricht, {
        ...createAuthHeader(token)
    })
}

export const deleteKunde = ({token, kundId}) => {
    return axios.delete(`${BACKEND}/api/v1/kunden/${kundId}`, {
        ...createAuthHeader(token)
    })
}

export const downloadKundenformular = ({token, kundId, pdfVorlagenId}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}/kundenformular/${pdfVorlagenId}`, {
        ...createAuthHeader(token)
        , responseType: 'blob'
    })
}

export const initiateKundenformularWorkflow = ({token, kundId, pdfVorlagenId, pad}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/${kundId}/kundenformular/${pdfVorlagenId}/${encodeURIComponent(pad)}`, {
        ...createAuthHeader(token)
    })
}

export const broadcastKunden = ({token, parameters}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/broadcast`, parameters, {
        ...createAuthHeader(token)
    })
}

export const getDblExclusionList = ({token, datVon, datBis}) => {
    return axios.get(`${BACKEND}/api/v1/kunden/doubletexclusions`, {
        ...createAuthHeader(token)
        , params: {datVon, datBis}
    })
}

export const deleteDblExclusion = ({token, kdExDblId}) => {
    return axios.delete(`${BACKEND}/api/v1/kunden/doubletexclusions/${kdExDblId}`, {
        ...createAuthHeader(token)
    })
}

export const addDblExclusions = ({token, exclusionData}) => {
    return axios.post(`${BACKEND}/api/v1/kunden/doubletexclusions`, exclusionData, {
        ...createAuthHeader(token)
    })
}