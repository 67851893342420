import i18next from "i18next";
import {i18_TEMPLATES} from "../../../i18nReferences";
import {getArtistToken} from "../../auth/token.saga";
import {reloadCommonMsgVorlagenAction} from "../../common/commonActions";
import {call, put, takeLatest} from "redux-saga/effects"
import {updateMessageVorlage} from "../../../api/commonApi";
import {clearVorlagenDataChangedAction, SAVE_MSG_VORLAGE} from "../templatesActions";
import {emitChangedNotification} from "../../notification/notificationSaga";
import { INFO_SAVED_SUCCESSFULLY, SAVING_FAILED } from "../../../models/notification";

function* processSaveMsgVorlage({payload}) {
    const msgVorlage = payload.msgVorlage
    const {Bezeichnung} = msgVorlage
    const category = i18next.t('VORLAGEN_DIALOG.MESSAGE_MENU_ITEM_TEXT', {ns: i18_TEMPLATES})
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(updateMessageVorlage, {token, msgVorlage})
            yield put(clearVorlagenDataChangedAction())
            yield put(reloadCommonMsgVorlagenAction())
            yield* emitChangedNotification({
                type: INFO_SAVED_SUCCESSFULLY,
                data: Bezeichnung,
                category
            })

        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data: Bezeichnung,
            category
        })
        console.log(err)
    }
}

export function* watchSaveMsgVorlage() {
    yield takeLatest(SAVE_MSG_VORLAGE, processSaveMsgVorlage)
}