import {call, delay, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    artistTokenLoaded,
    INITIATE_ARTIST_LOGIN_RESET,
    INITIATE_LOGOUT_ARTIST,
    INITIATE_LOGOUT_STUDIO,
    INITIATE_STUDIO_LOGIN_RESET,
    LOGIN_ARTIST,
    LOGIN_ARTIST_REFRESH_SUCCESS,
    LOGIN_STUDIO,
    LOGIN_STUDIO_REFRESH_SUCCESS,
    loginArtistFailureAction,
    loginArtistSuccessAction,
    loginStudioFailureAction,
    loginStudioSuccessAction,
    logoutArtist,
    logoutStudio,
    registerArtistLoginResetResponseAction,
    registerStudioLoginResetResponseAction,
    studioTokenLoaded
} from "./authActions";
import {initiateStudioLoginReset, loginArtist, loginStudio} from "../../api/commonApi";
import {
    loadArtistTokens,
    loadStudioTokens,
    removeArtistTokens,
    removeStudioTokens,
    saveArtistToken,
    saveArtistTokens,
    saveStudioToken,
    saveStudioTokens
} from "../../api/localAuthPersistence";
import {pushHistory} from "../router/routerActions";
import {selectIsArtistLoggedIn} from "./authSelector";
import {loadCommonAction} from "../common/commonActions";
import {getStudioToken} from "./token.saga";
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {initiateArtistPasswordReset} from "../../api/artistApi";


export function* bootstrapAuth() {
    const isArtistLoggedIn = yield select(state => selectIsArtistLoggedIn(state))
    if (isArtistLoggedIn) {
        return {isArtistLoggedIn: true, isStudioLoggedIn: true}
    }
    const studioTokens = loadStudioTokens()
    const artistTokens = loadArtistTokens()
    if (studioTokens.studioToken && studioTokens.studioRefreshToken) {
        yield put(studioTokenLoaded(studioTokens))
    }
    if (artistTokens.artistToken && artistTokens.artistRefreshToken) {
        yield put(artistTokenLoaded(artistTokens))
    }
    return {isArtistLoggedIn: !!artistTokens.artistToken, isStudioLoggedIn: !!studioTokens.studioToken}
}

function* processLoginStudio({payload: {loginData}}) {
    try {
        const {data} = yield call(loginStudio, {studio: loginData.name, password: loginData.password})
        const studioAuthData = {studioToken: data.data, studioRefreshToken: data.studioRT}
        saveStudioTokens(studioAuthData)
        yield put(loginStudioSuccessAction(studioAuthData))
        yield delay(1000);
        yield put(pushHistory('/login-artist'))

    } catch (err) {
        console.log(err)
        removeStudioTokens()
        yield put(loginStudioFailureAction())
    }
}

function* processLoginArtist({payload: {loginData}}) {
    try {
        const token = yield* getStudioToken()
        const {data} = yield call(loginArtist, {token, kuerzel: loginData.name, password: loginData.password})
        const artistAuthData = {artistToken: data.data, artistRefreshToken: data.artistRT}
        saveArtistTokens(artistAuthData)
        yield put(loginArtistSuccessAction(artistAuthData))
        yield put(loadCommonAction())
        yield delay(1000);
        yield put(pushHistory('/'))

    } catch (err) {
        console.log(err)
        removeStudioTokens(true)
        yield put(loginArtistFailureAction())
    }
}

function* processRefreshArtistToken({payload}) {
    yield call(saveArtistToken,{artistToken: payload.artistToken})
}

function* processRefreshStudioToken({payload}) {
    yield call(saveStudioToken, {studioToken: payload.studioToken})
}


function* watchLoginStudio() {
    yield takeLatest(LOGIN_STUDIO, processLoginStudio)
}

function* watchRefresStudioTokenSucdess() {
    yield takeLatest(LOGIN_STUDIO_REFRESH_SUCCESS, processRefreshStudioToken)
}

function* watchLoginArtist() {
    yield takeLatest(LOGIN_ARTIST, processLoginArtist)
}

function* watchRefresArtistTokenSucdess() {
    yield takeLatest(LOGIN_ARTIST_REFRESH_SUCCESS, processRefreshArtistToken)
}


function* processLogoutStudio() {
    removeStudioTokens(true)
    yield put(logoutArtist())
    yield put(logoutStudio())
    yield put(pushHistory('/login-studio'))
}

function* processLogoutArtist() {
    removeArtistTokens(true)
    yield put(logoutArtist())
    yield put(pushHistory('/login-artist'))
}

function* watchInitiateLogoutArtist() {
    yield takeLatest(INITIATE_LOGOUT_ARTIST, processLogoutArtist)
}

function* watchInitiateLogoutStudio() {
    yield takeLatest(INITIATE_LOGOUT_STUDIO, processLogoutStudio)
}

function* processInitiateStudioLoginReset({payload}) {
    try {
        const studio = payload.studioName
        const status = extractDataFromResponse(yield call(initiateStudioLoginReset, {studio}))
        yield put(registerStudioLoginResetResponseAction(status))
    } catch (error) {
        console.log(error)
        yield put(registerStudioLoginResetResponseAction({error: error.message}))
    }

}

function* watchInitiateStudioLoginReset() {
    yield takeLatest([INITIATE_STUDIO_LOGIN_RESET], processInitiateStudioLoginReset)
}

function* processInitiateArtistLoginReset({payload}) {
    try {
        const token = yield* getStudioToken()
        if (token) {
            const kuerzel = payload.kuerzel
            const status = extractDataFromResponse(yield call(initiateArtistPasswordReset, {token, kuerzel}))
            yield put(registerArtistLoginResetResponseAction(status))
        }
    } catch (error) {
        console.log(error)
        yield put(registerArtistLoginResetResponseAction({error: error.message}))
    }

}

function* watchInitiateArtistLoginReset() {
    yield takeLatest([INITIATE_ARTIST_LOGIN_RESET], processInitiateArtistLoginReset)
}

const authSaga = [fork(watchLoginStudio), fork(watchLoginArtist), fork(watchInitiateArtistLoginReset)
    , fork(watchRefresArtistTokenSucdess), fork(watchRefresStudioTokenSucdess)
    , fork(watchInitiateLogoutArtist), fork(watchInitiateLogoutStudio), fork(watchInitiateStudioLoginReset)]

export default authSaga