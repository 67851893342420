export const VOUCHERS_AUSBUCHEN_FILTER_DEFINITION = {
    items: [
        {
            field: "Saldo",
            operator: ">",
            id: 18590,
            value: "0"
        }
    ],
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and"
}

