import { isSameOrBefore, minus, plus } from "@kiss-solutions/dateutils";
import { addDays } from "date-fns";
import { reduceIsoDateToDateString } from "../../models/date";
import { TERMINSTATUS_AUSGEFALLEN, TERMINSTATUS_ERLEDIGT, TERMINSTATUS_OFFEN, TERMINTYP_INTERNE_VORBEREITUNG } from "../../models/terminStatus";

let ID = 1

export const convertHoliday2Event = ({name, date}, resource) => {
    return {
        id: (ID++).toString()
        , title: name
        , start: date
        , end: date
        , color: '#FF99FF'
        , specialType: 'holiday'
        , allDay: true
        , editable: false
        , resourceId: resource
    }
}

export const convertOffsite2Event = (location) => ({
    id: 'location#' + location.ArtAtOrtID
    , title: location.Ort
    , start: new Date(reduceIsoDateToDateString(location.DatVon))
    , end: addDays(new Date(reduceIsoDateToDateString(location.DatBis)), 1)
    , color: '#' + location.Farbe
    , textColor: 'black'
    , ArtAtOrtID: location.ArtAtOrtID
    , ArtistID: location.ArtistID
    , specialType: 'location'
    , resourceId: location.ArtistID
    , allDay: true
    , editable: false
})

export const convertOffBusiness2Event = (period, resourceId) => {
    return {
        id: (ID++).toString()
        , title: ' '
        , start: period.von
        , end: period.bis
        , color: 'lightgrey'
        , specialType: 'offBusiness'
        , resourceId
        , display: 'background'
    }
}

function createEvent({start, end, termin, shouldBeReminded, artist, terminArtist, isEditable, isSelected}) {
    return {
        id: (ID++).toString()
        , title: constructTitle({termin, artistKuerzel: terminArtist?.Kuerzel})
        , start
        , end
        , allDay: termin.Ganztags
        , backgroundColor: termin.color
        , textColor: 'black'
        , TerminID: termin.TerminID
        , Typ: termin.Typ
        , KundID: termin.KundID
        , invited: termin.TrmAttID !== undefined
        , isAusgefallen: termin.TrmStat === TERMINSTATUS_AUSGEFALLEN
        , NoDeposit: artist.ManageAZ && termin.NoDeposit
        , HasDeseases: termin.HasDeseases
        , NoContact: termin.NoContact
        , NoReminder: shouldBeReminded && termin.NoReminder
        , resourceId: artist.ArtistID
        , editable: isEditable && ! termin.Ganztags
        , ArtistID: termin.ArtistID
        , borderColor: isSelected ? 'red' : 'blue'
        , TrmAttID: termin.TrmAttID 
    }
}

export const constructTerminTitle = (termin) => (termin.ProjName ? termin.ProjName + ' ' : '') + (termin.ProjName && termin.Betreff ? '/' : '') + (termin.Betreff || '')

export const constructTitle = ({termin, artistKuerzel}) => {
    let ret = '';

    if (termin.invited || termin.TrmAttID) {
        ret += artistKuerzel + ':';
    }

    if (termin.Vorname) {
        ret += (termin.Vorname + ' ');
    }

    if (termin.Nachname) {
        ret += (termin.Nachname + ' ');
    }

    ret += constructTerminTitle(termin) || ' '

    if (!ret.length) {
        return artistKuerzel
    }
    return ret
}

function isMultipleDateTermin(termin) {
    const bis = new Date(termin.DatBis)
    const von = new Date(termin.Start)
    return bis.getTime() - von.getTime() > 24 * 3_600_000
        && bis.getHours() * 60 + bis.getMinutes() > von.getHours() * 60 + von.getMinutes()
}

export const convertTermin = ({
                                  artistMap,
                                  editableArtistIDList,
                                  terminVorlauf,
                                  start,
                                  end,
                                  termin,
                                  selectedTerminID
                              }) => {
    const result = []
    const terminStart = new Date(termin.Start)
    const artistId = termin.AttArtistID ? termin.AttArtistID : termin.ArtistID
    const isEditable = editableArtistIDList.includes(artistId) && !termin.AttArtistID
    const isSelected = selectedTerminID === termin.TerminID
    const artist = artistMap[artistId]
    const shouldBeReminded = terminVorlauf && termin.KundID
        && isSameOrBefore(minus(terminStart, terminVorlauf, 'date'), new Date(), 'date')
    && [TERMINSTATUS_OFFEN, TERMINSTATUS_ERLEDIGT].includes(termin.TrmStat)
    && TERMINTYP_INTERNE_VORBEREITUNG !== termin.Typ
    if (termin.Ganztags || !isMultipleDateTermin(termin)) {
        return [createEvent({
            start: new Date(termin.Start),
            // fullcalender will only display whole days in all day row
            end: termin.Ganztags ? plus(new Date(termin.DatBis), 1, 'date') : new Date(termin.DatBis),
            shouldBeReminded,
            termin,
            terminArtist: termin.ArtistID && artistMap[termin.ArtistID],
            artist, isEditable, isSelected
        })]
    }

    const datBis = new Date(termin.DatBis)
    const vonDate = new Date(start)
    const bisDate = new Date(end)
    let dat = isSameOrBefore(terminStart, vonDate) ? vonDate : terminStart
    const endPeriod = isSameOrBefore(bisDate, datBis) ? bisDate : datBis
    do {
        const eventStart = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate(), terminStart.getHours(), terminStart.getMinutes())
        const eventEnd = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate(), datBis.getHours(), datBis.getMinutes())
        const event = createEvent({
            start: eventStart,
            end: eventEnd,
            shouldBeReminded,
            termin,
            artist,
            isEditable,
            isSelected
        })
        result.push(event)
        dat = plus(dat, 1, 'date')
    } while (isSameOrBefore(dat, endPeriod, 'date'))

    return result
}