import {getArtistToken} from "../../auth/token.saga";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TEMPLATES} from "../../../i18nReferences";
import {reloadCommonPdfVorlagenAction} from "../../common/commonActions";
import {call, put, takeLatest} from "redux-saga/effects"
import {copyStdPdfVorlagen} from "../../../api/commonApi";
import {COPY_STD_PDF_VORLAGEN} from "../templatesActions";
import {getPdfTemplateTranslation} from "../../../components/templates/helper";

function* processCopyStdPdfVorlagen() {
    const category = getPdfTemplateTranslation(i18next.t, 'HEADER')
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(copyStdPdfVorlagen, {token})
            yield put(reloadCommonPdfVorlagenAction())
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PDF_VORLAGEN.COPY_STD_PDF_VORLAGEN_FAILED_INFO', {
            ns: i18_TEMPLATES,
        }), category)))
        console.log(err)
    }
}

export function* watchCopyStdPdfVorlagen() {
    yield takeLatest(COPY_STD_PDF_VORLAGEN, processCopyStdPdfVorlagen)
}