import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {addDblExclusions} from "../../../../api/kundenApi";
import {ADD_EXCLUSIONS, loadDoublettenListDataAction} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {i18_LIST} from "../../../../i18nReferences";

function* processAddExclusions({payload}) {
    try {
        const token = yield getArtistToken()
        const exclusionData = payload.exclusionData
        if (token) {
            const {affectedRows} = extractDataFromResponse(yield call(addDblExclusions, {token, exclusionData}))
            if (affectedRows) {
                yield put(loadDoublettenListDataAction())
                yield put(emitNotificationAction(createSuccessNotification(i18next.t('CHECKOUT_LIST.ADD_DBLEXCLUSION_DATA_SUCCESS_INFO'
                    , {ns: i18_LIST, affectedRows: affectedRows || 0, count: exclusionData.exclusions.length}), 'addDblExclusions')))
            } else {
                yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.ADD_DBLEXCLUSION_DATA_FAILED_INFO'
                    , {ns: i18_LIST}), 'addDblExclusions')))
            }

        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.ADD_DBLEXCLUSION_DATA_FAILED_INFO'
            , {ns: i18_LIST}), 'addDblExclusions')))
        console.log(err)
    }
}

export function* watchAddExclusions() {
    yield takeLatest(ADD_EXCLUSIONS, processAddExclusions)
}