import {getArtistToken} from "../../auth/token.saga";
import {call} from "redux-saga-test-plan/matchers";
import {updateArtist} from "../../../api/artistApi";
import {reloadCommonArtistsAction} from "../../common/commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../models/notification";
import i18next from "i18next";
import {loadArtistMailBaseAction, SAVE_ARTIST_ASSIST} from "../artistActions";
import {put, takeLatest} from "redux-saga/effects"
import {i18_ARTIST} from "../../../i18nReferences";

export function* processSaveArtistAssistData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const artist = payload.artistAssistData
            yield call(updateArtist, {token, artist})
            yield put(reloadCommonArtistsAction())
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('EDIT_ARTIST.SAVE_ASSIST_DATA_SUCCESS_INFO', {
                ns: i18_ARTIST,
                name: artist.Kuerzel
            }), 'saveArtist')))
            yield put(loadArtistMailBaseAction(artist.ArtistID))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_ASSIST_DATA_FAILED_INFO', {
            ns: i18_ARTIST,
            name: payload.artistAssistData.Kuerzel
        }), 'saveArtist')))
        console.log(err)
    }
}

export function* watchSaveArtistAssistData() {
    yield takeLatest(SAVE_ARTIST_ASSIST, processSaveArtistAssistData)
}