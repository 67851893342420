import {INITIATE_TERMINLIST_WORKFLOW} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {selectStudioPadMap} from "../commonSelector";
import {initiateTerminListeWorkflow} from "../../../api/terminApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processInitiateTerminlistWorkflow({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const padMap = yield select(state => selectStudioPadMap(state))
            const padName = padMap[payload.workflowConfig.PadID].Name
            yield call(initiateTerminListeWorkflow, {
                token
                , terminId: payload.workflowConfig.terminId
                , pdfVorlagenId: payload.workflowConfig.PDFVorlagenID
                , pad: padName
            })
            yield put(emitNotificationAction(createInfoNotification(i18next.t('PDF_PRINT_OPTION_DIALOG.WORKFLOW_INITIATED', {
                ns: i18_TRANSLATION,
                padName
            }), 'processInitiateTerminlistWorkflow')))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchInitiateTerminlistWorkflow() {
    yield takeLatest(INITIATE_TERMINLIST_WORKFLOW, processInitiateTerminlistWorkflow)
}