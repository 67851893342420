import {
    ADD_EDIT_TERMIN_EINNAHME,
    CHANGE_TERMIN_KUNDE,
    CHANGE_TERMIN_KUNDE_FAILURE,
    CHANGE_TERMIN_KUNDE_SUCCESS,
    DELETE_EDIT_TERMIN_EINNAHME,
    EDIT_TERMIN_ADD_PROJEKT_SUCCESS,
    LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT,
    LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT_SUCCESS,
    SET_EDIT_STATE_PROJEKT,
    SET_EDIT_STATE_PROJEKT_CHANGES,
    SET_EDIT_STATE_REMOVE_KUNDE,
    SET_EDIT_STATE_REMOVE_PROJEKT,
    SET_EDIT_STATE_TERMIN_CHANGES,
    UPDATE_EDIT_TERMIN_EINNAHME,
    UPDATE_EDIT_TERMIN_TEILNEHMER,
    ADD_TERMIN_DATA,
    ADD_TERMIN_DATA_FAILURE,
    ADD_TERMIN_DATA_SUCCESS,
    ADD_TERMIN_IMAGES,
    ADD_TERMIN_IMAGES_FAILURE,
    ADD_TERMIN_IMAGES_SUCCESS,
    CLEAR_TERMIN_DATA,
    DELETE_TERMIN_IMAGE,
    LOAD_HOLIDAYS_FOR_TERMIN,
    LOAD_HOLIDAYS_FOR_TERMIN_SUCCESS,
    LOAD_TERMIN_DATA,
    LOAD_TERMIN_DATA_FAILURE,
    LOAD_TERMIN_DATA_NO_CHANGE,
    LOAD_TERMIN_DATA_SUCCESS,
    LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_NOT_FOUND,
    LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_SUCCESS,
    SAVE_TERMIN_DATA,
    SEARCH_TERMIN_KUNDEN,
    SEARCH_TERMIN_KUNDEN_SUCCESS,
    CLEAR_VOUCHER_DATA_FOR_TERMIN_EINNAHME,
    REMOVE_PROJEKT_FROM_EDITSTATE,
    IMPORT_ICAL_TERMINE,
    IMPORT_ICAL_TERMINE_SUCCESS,
    IMPORT_ICAL_RESET_REQUEST,
    IMPORT_ICAL_TERMINE_FAILURE,
    SKIP_IMPORT_ICAL_TERMINE
} from "./terminActions";
import {
    addCounterStates,
    asyncLoadingActionFailure,
    asyncLoadingActionSuccess,
    asyncLoadingNoDataChange, clearAsyncLoadingAction,
    initiateAsyncLoadingAction,
    initiateAsyncLoadingWithParamsAction,
    initiateClearAndAsyncLoadingWithParamsAction
} from "../utils/reducerUtils";

const INITIAL = {};

export const TERMIN_DATA_NAME = 'terminData'

const changeEditStateProjekt = (projekt, state) => ({
    ...state.editState,
    termin: {
        ...state.editState?.termin,
        ProjektID: projekt?.ProjektID
    },
    projektBase: state.terminData.projekt,
    projekt
})

const removeProjektFromEditState = (state) => ({
    ...state.editState,
    termin: {
        ...state.editState?.termin,
        ProjektID: null
    },
    projekt: null,
    deletedImages: [],
    insertedImages: []
})

const removeKundeFromEditState = (state) => ({
    ...state.editState,
    termin: {
        ...state.editState?.termin,
        ProjektID: null,
        KundID: null
    },
    projekt: null
})

const filterOnlyProjektImages = (images) => images.filter(i => i.type === 'projekt')

export default function terminReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_TERMIN_DATA:
            return initiateAsyncLoadingAction(state, TERMIN_DATA_NAME, {terminId: action.payload.terminParms})
        case LOAD_TERMIN_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, TERMIN_DATA_NAME)
        case LOAD_TERMIN_DATA_FAILURE:
            return asyncLoadingActionFailure(state, TERMIN_DATA_NAME)
        case LOAD_TERMIN_DATA_NO_CHANGE:
            return asyncLoadingNoDataChange(state, TERMIN_DATA_NAME)

        case ADD_TERMIN_DATA:
            return initiateAsyncLoadingAction(state, TERMIN_DATA_NAME, {terminId: action.payload.terminParms})
        case ADD_TERMIN_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, TERMIN_DATA_NAME)
        case ADD_TERMIN_DATA_FAILURE:
            return asyncLoadingActionFailure(state, TERMIN_DATA_NAME)

        case LOAD_HOLIDAYS_FOR_TERMIN:
            return initiateClearAndAsyncLoadingWithParamsAction(state, 'terminHoliday', {...action.payload.holidayParameters})
        case LOAD_HOLIDAYS_FOR_TERMIN_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'terminHoliday')

        case SEARCH_TERMIN_KUNDEN:
            return initiateAsyncLoadingWithParamsAction(state, 'terminKunden', {namePart: action.payload.namePart})
        case SEARCH_TERMIN_KUNDEN_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'terminKunden')

        case CHANGE_TERMIN_KUNDE:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    loadingProjektData: true,
                    selectableProjekte: null,
                    projekt: null,
                    projektImages: undefined
                }
            }
        case CHANGE_TERMIN_KUNDE_SUCCESS:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    loadingProjektData: false,
                    projektBase: state.terminData.projekt,
                    ...action.payload.changeTerminKundeData,
                },
                editState: changeEditStateProjekt(action.payload.changeTerminKundeData.projekt, state)
            }
        case CHANGE_TERMIN_KUNDE_FAILURE:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    loadingProjektData: false,
                }
            }

        case SET_EDIT_STATE_TERMIN_CHANGES:
            return {
                ...state,
                editState: {
                    ...state.editState,
                    termin: {
                        ...state.editState?.termin
                        , ...action.payload.terminChanges
                    }
                }
            }
        case SET_EDIT_STATE_PROJEKT:
            return {
                ...state,
                editState: changeEditStateProjekt(action.payload.projekt, state)
            }

        case SET_EDIT_STATE_REMOVE_PROJEKT:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    projektBase: state.terminData.projektBase || state.terminData.projekt,
                    projekt: null,
                    projektImages: []
                },
                editState: removeProjektFromEditState(state)
            }
        case REMOVE_PROJEKT_FROM_EDITSTATE:
            return {
                ...state,
                editState: {
                    ...state.editState,
                    projekt: undefined,
                    deletedImages: undefined,
                    insertedImages: undefined
                }
            }

        case SET_EDIT_STATE_REMOVE_KUNDE:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    selectableProjekte: null,
                    projektBase: state.terminData.projektBase || state.terminData.projekt,
                    projekt: null
                },
                editState: removeKundeFromEditState(state)
            }

        case SET_EDIT_STATE_PROJEKT_CHANGES:
            return {
                ...state,
                editState: {
                    ...state.editState,
                    projekt: {
                        ...state.editState?.projekt
                        , ...action.payload.projektChanges
                    }
                }
            }


        case EDIT_TERMIN_ADD_PROJEKT_SUCCESS: {
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    selectableProjekte: (state.terminData.selectableProjekte || []).concat([action.payload.newProjekt]),
                    projektBase: state.terminData.projektBase || state.terminData.projekt,
                    projekt: action.payload.newProjekt
                },
                editState: {
                    ...state.editState,
                    projekt: action.payload.newProjekt
                }
            }
        }

        case DELETE_TERMIN_IMAGE:
            return {
                ...state,
                editState: {
                    ...state.editState,
                    deletedImages: (state.editState?.deletedImages || []).concat(filterOnlyProjektImages([action.payload.deletedImage])),
                    insertedImages: state.editState?.insertedImages && state.editState.insertedImages.filter(t => t !== action.payload.deletedImage.key)
                }
            }

        case ADD_TERMIN_IMAGES:
            return {
                ...state,
                uploadingImages: true
            }

        case ADD_TERMIN_IMAGES_SUCCESS:
            return {
                ...state,
                editState: {
                    ...state.editState,
                    insertedImages: state.editState?.insertedImages?.length ? state.editState.insertedImages.concat(action.payload.keyList) : action.payload.keyList,
                },
                uploadingImages: false
            }

        case ADD_TERMIN_IMAGES_FAILURE:
            return {
                ...state,
                uploadingImages: false
            }

        case LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    projektImages: undefined
                }

            }
        case LOAD_IMAGES_OF_EDITED_TERMIN_PROJEKT_SUCCESS:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    projektImages: action.payload.projektImages
                }
            }

        case UPDATE_EDIT_TERMIN_EINNAHME:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    einnahmen: state.terminData.einnahmen.filter(e => e.id !== action.payload.einnahme.id).concat([action.payload.einnahme])
                }
            }

        case ADD_EDIT_TERMIN_EINNAHME:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    einnahmen: state.terminData.einnahmen?.length ? state.terminData.einnahmen.concat(action.payload.einnahme) : [action.payload.einnahme]
                }
            }

        case DELETE_EDIT_TERMIN_EINNAHME:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    einnahmen: state.terminData.einnahmen.filter(e => e.id !== action.payload.einnahmeId)
                }
            }

        case LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'editEinnahmeVoucher')
        case LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME_NOT_FOUND:
            return asyncLoadingActionFailure(state, 'editEinnahmeVoucher')
        case CLEAR_VOUCHER_DATA_FOR_TERMIN_EINNAHME:
            return clearAsyncLoadingAction(state, 'editEinnahmeVoucher')

        case UPDATE_EDIT_TERMIN_TEILNEHMER:
            return {
                ...state,
                terminData: {
                    ...state.terminData,
                    teilnehmer: action.payload.teilnehmer
                }
            }

        case IMPORT_ICAL_TERMINE:
                return {
                    ...state,
                    iCalTermineImporting: true
                }
        case IMPORT_ICAL_TERMINE_SUCCESS:
        case IMPORT_ICAL_TERMINE_FAILURE:
        case SKIP_IMPORT_ICAL_TERMINE:
            return {
                ...state,
                iCalTermineImporting: state.iCalTermineImporting && false,
                iCalTermineCounters: addCounterStates(state.iCalTermineCounters, action.payload.icalImportCounters)
            }

        case IMPORT_ICAL_RESET_REQUEST:
        case CLEAR_TERMIN_DATA:
            return INITIAL

        case SAVE_TERMIN_DATA:
            return {
                ...state,
                isSaving: true
            }

        default:
            return state;
    }
}
