import {fork} from "redux-saga-test-plan/matchers";
import {watchSaveMsgVorlage} from "./sagas/updateMsgVorlageSaga";
import {watchCopyMsgVorlage} from "./sagas/copyMsgVorlageSaga";
import {watchDeleteMsgVorlage} from "./sagas/deleteMsgVorlageSaga";
import {watchSaveAutoMsgSettings} from "./sagas/saveAutoMsgSettings";
import {watchSavePdfVorlage} from "./sagas/savePdfTemplateSaga";
import {watchDeletePdfVorlage} from "./sagas/deletePdfVorlageSaga";
import {watchCopyStdPdfVorlagen} from "./sagas/copyStdPdfVorlagenSaga";
import {watchAddPdfVorlage} from "./sagas/addPdfTemplateSaga";

const templateSagas = [fork(watchSaveMsgVorlage), fork(watchCopyMsgVorlage), fork(watchDeleteMsgVorlage)
    , fork(watchSaveAutoMsgSettings), fork(watchSavePdfVorlage), fork(watchDeletePdfVorlage)
    , fork(watchCopyStdPdfVorlagen), fork(watchAddPdfVorlage)]
export default templateSagas