import {selectKunde} from "../kundeSelector";
import {getArtistToken} from "../../auth/token.saga";
import {deleteKunde} from "../../../api/kundenApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {DELETE_KUNDE} from "../kundeAction";
import {put, takeLatest, select, call} from "redux-saga/effects"
function* processDeleteKunde({payload}) {
    let kunde = yield select(state => selectKunde(state))
    try {
        const token = yield getArtistToken()
        if (token && kunde) {
            yield call(deleteKunde, {token, kundId: payload.kundId})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.DELETION_SUCCESS_INFO',
                {ns: i18_KUNDE, name: kunde.Name}), 'deleteKunde')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.DELETION_FAILURE_INFO',
            {ns: i18_KUNDE, name: kunde.Name}), 'deleteKunde')))
        console.log(err)
    }
}

export function* watchDeleteKunde() {
    yield takeLatest(DELETE_KUNDE, processDeleteKunde)
}