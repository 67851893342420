import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getVoucherByVouchNo} from "../../../api/voucherApi";
import {
    LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME,
    loadVoucherDataForTerminEinnahmeNotFound,
    loadVoucherDataForTerminEinnahmeSuccess
} from "../terminActions";
import {call, put, takeLatest} from "redux-saga/effects";

export function* processLoadVoucherDataForTerminEinnahme({payload}) {
    try {
        const voucherNo = payload.voucherNo
        const token = yield getArtistToken()
        if (token) {
            const voucher = extractDataFromResponse(yield call(getVoucherByVouchNo, {token, voucherNo}))
            if (voucher) {
                yield put(loadVoucherDataForTerminEinnahmeSuccess(voucher))
            } else {
                yield put(loadVoucherDataForTerminEinnahmeNotFound())
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchLoadVoucherDataForTerminEinnahme() {
    yield takeLatest(LOAD_VOUCHER_DATA_FOR_TERMIN_EINNAHME, processLoadVoucherDataForTerminEinnahme)
}