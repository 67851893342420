import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_CALENDAR = 'LOAD_CALENDAR'
export const LOAD_CALENDAR_SUCCESS = 'LOAD_CALENDAR_SUCCESS'
export const LOAD_CALENDAR_NOCHANGE = 'LOAD_CALENDAR_NOCHANGE'
export const LOAD_CALENDAR_FAILURE = 'LOAD_CALENDAR_FAILURE'
export const CHANGE_VIEW_TYPE = 'CHANGE_VIEW_TYPE'
export const SELECT_CALENDAR_ARTIST = 'SELECT_CALENDAR_ARTIST'
export const SWITCH_CALENDAR_TO_ARTIST_VIEW = 'SWITCH_CALENDAR_TO_ARTIST_VIEW'
export const CHANGE_CALENDAR_SELECTED_LOCATION = 'CHANGE_CALENDAR_SELECTED_LOCATION'
export const CHANGE_CALENDAR_EVENT_FILTER = 'CHANGE_CALENDAR_EVENT_FILTER'
export const LOAD_CALENDAR_KUNDEN_SUCCESS = 'LOAD_CALENDAR_KUNDEN_SUCCESS'
export const INCREASE_CALENDAR_SLOT_DURATION = 'INCREASE_CALENDAR_SLOT_DURATION'
export const DECREASE_CALENDAR_SLOT_DURATION = 'DECREASE_CALENDAR_SLOT_DURATION'
export const CHANGE_CALENDAR_SELECTED_TERMIN_ID = 'CHANGE_CALENDAR_SELECTED_TERMIN_ID'
export const CHANGE_CALENDAR_SELECTED_TERMIN_AND_KUND_ID = 'CHANGE_CALENDAR_SELECTED_TERMIN_AND_KUND_ID'
export const CHANGE_CALENDER_SELECTED_KUND_ID = 'CHANGE_CALENDER_SELECTED_KUND_ID'
export const LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE = 'LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE'
export const LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_SUCCESS = 'LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_SUCCESS'
export const LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_FAILURE = 'LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_FAILURE'
export const SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM = 'SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM'
export const MOVE_CALENDAR_EVENT = 'MOVE_CALENDAR_EVENT'
export const CHANGE_CALENDAR_EVENT_PARTICIPANT_ARTIST = 'CHANGE_CALENDAR_EVENT_PARTICIPANT_ARTIST'
export const LOAD_CALENDAR_TERMIN_TOASTER_DATA = 'LOAD_CALENDAR_TERMIN_TOASTER_DATA'
export const LOAD_CALENDAR_TERMIN_TOASTER_DATA_SUCCESS = 'LOAD_CALENDAR_TERMIN_TOASTER_DATA_SUCCESS'
export const LOAD_CALENDAR_TERMIN_TOASTER_DATA_FAILURE = 'LOAD_CALENDAR_TERMIN_TOASTER_DATA_FAILURE'
export const LOAD_CALENDAR_TERMIN_TOASTER_IMAGES = 'LOAD_CALENDAR_TERMIN_TOASTER_IMAGES'
export const LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_SUCCESS = 'LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_SUCCESS'
export const LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_FAILURE = 'LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_FAILURE'
export const DELETE_SELECTED_TERMIN = 'DELETE_SELECTED_TERMIN'
export const DELETE_SELECTED_TERMIN_SUCCESS = 'DELETE_SELECTED_TERMIN_SUCCESS'

export const CLEAR_CALENDAR_SELECTED_TERMIN_ID = 'CLEAR_CALENDAR_SELECTED_TERMIN_ID'
export const clearCalendarSelectedTermin = createTypeAction(CLEAR_CALENDAR_SELECTED_TERMIN_ID)

export const deleteSelectedTerminAction = createTypeAction(DELETE_SELECTED_TERMIN)
export const deleteSelectedTerminSuccessAction = createTypeAction(DELETE_SELECTED_TERMIN_SUCCESS)
export const loadCalendarTerminToasterDataAction = createTypeAction(LOAD_CALENDAR_TERMIN_TOASTER_DATA)
export const loadCalendarTerminToasterDataSuccessAction = createSimpleAction(LOAD_CALENDAR_TERMIN_TOASTER_DATA_SUCCESS, 'selectedTerminToaster')
export const loadCalendarTerminToasterDataFailureAction = createTypeAction(LOAD_CALENDAR_TERMIN_TOASTER_DATA_FAILURE)
export const loadCalendarTerminToasterImagesAction = createSimpleAction(LOAD_CALENDAR_TERMIN_TOASTER_IMAGES, 'projektId')
export const loadCalendarTerminToasterImagesSuccessAction = createSimpleAction(LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_SUCCESS, 'toasterImages')
export const loadCalendarTerminToasterImagesFailureAction = createTypeAction(LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_FAILURE)
export const moveCalendarEventAction = createSimpleAction(MOVE_CALENDAR_EVENT, 'terminChanges')
export const changeCalendarEventAttendentArtistAction = createSimpleAction(CHANGE_CALENDAR_EVENT_PARTICIPANT_ARTIST, 'terminChanges')
export const showSingleArtistViewForZeitraumAction = createSimpleAction(SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM, 'zeitraum')
export const loadSelectedKundenTermineAction = createSimpleAction(LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE, 'selectedKundID')
export const loadSelectedKundenTermineSuccessAction = createSimpleAction(LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_SUCCESS, 'selectedKundenTermine')
export const loadSelectedKundenTermineFailureAction = createTypeAction(LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_FAILURE)
export const changeCalenderSelectedKundIDAction = createSimpleAction(CHANGE_CALENDER_SELECTED_KUND_ID, 'selectedKundID')
export const changeCalendarSelectedTerminIDAction = createSimpleAction(CHANGE_CALENDAR_SELECTED_TERMIN_ID, 'selectedTerminID')
export const changeCalendarSelectedTerminIDKundIDAction = createSimpleAction(CHANGE_CALENDAR_SELECTED_TERMIN_AND_KUND_ID, 'terminAndKundId')
export const increaseCalendarSlotDurationAction = createTypeAction(INCREASE_CALENDAR_SLOT_DURATION)
export const decreaseCalendarSlotDurationAction = createTypeAction(DECREASE_CALENDAR_SLOT_DURATION)
export const loadCalendarKundenSuccessAction = createSimpleAction(LOAD_CALENDAR_KUNDEN_SUCCESS, 'kunden')
export const changeCalendarEventFilterAction = createSimpleAction(CHANGE_CALENDAR_EVENT_FILTER, 'eventFilter')
export const changeCalendarSelectedLocationAction = createSimpleAction(CHANGE_CALENDAR_SELECTED_LOCATION, 'tatortId')
export const changeViewTypeAction = createSimpleAction(CHANGE_VIEW_TYPE, 'viewType')
export const selectCalendarArtistAction = createSimpleAction(SELECT_CALENDAR_ARTIST, 'calendarArtist')
export const switchCalendarToArtistViewAction = createSimpleAction(SWITCH_CALENDAR_TO_ARTIST_VIEW, 'calendarArtist')
export const initiateLoadCalendarAction = createSimpleAction(LOAD_CALENDAR, 'zeitraum')
export const refreshLoadCalendarAction = (zeitraum) => ({type: LOAD_CALENDAR, payload: {zeitraum, refresh: true}})
export const loadCalendarSuccessAction = createSimpleAction(LOAD_CALENDAR_SUCCESS, 'events')
export const loadCalendarNoDataChangeAction = createTypeAction(LOAD_CALENDAR_NOCHANGE)
export const loadCalendarFailureAction = createTypeAction(LOAD_CALENDAR_FAILURE)

export const SEARCH_CALENDAR_KUNDEN = 'SEARCH_CALENDAR_KUNDEN'
export const searchCalendarKunden = createSimpleAction(SEARCH_CALENDAR_KUNDEN, 'searchOptions')

export const CLEAR_CALENDAR_SEARCH_KUNDE = 'CLEAR_CALENDAR_SEARCH_KUNDE'
export const clearCalendarSearchKunde = createTypeAction(CLEAR_CALENDAR_SEARCH_KUNDE)

export const SEARCH_CALENDAR_KUNDEN_SUCCESS = 'SEARCH_CALENDAR_KUNDEN_SUCCESS'
export const searchCalendarKundenSuccess = createSimpleAction(SEARCH_CALENDAR_KUNDEN_SUCCESS, 'searchedKunden')

export const ADD_SEARCHED_CALENDAR_KUNDEN = 'ADD_SEARCHED_CALENDAR_KUNDEN'
export const addSearchedCalendarKundenAction = createSimpleAction(ADD_SEARCHED_CALENDAR_KUNDEN, 'searchedKunden')

export const SELECT_SEARCHED_KUNDID = 'SELECT_SEARCHED_KUNDID'
export const selectSearchedCalendarKundID = createSimpleAction(SELECT_SEARCHED_KUNDID, 'selectedSearchKundID')
export const SELECT_CALENDAR_ARTIST_LOCATION = 'SELECT_CALENDAR_ARTIST_LOCATION'
export const selectCalendarArtistLocationAction = createSimpleAction(SELECT_CALENDAR_ARTIST_LOCATION, 'selectedLocationKeys')

export const CLEAR_CALENDAR_SELECTED_ARTIST_LOCATION = 'CLEAR_CALENDAR_SELECTED_ARTIST_LOCATION'
export const clearCalendarSelectedArtistLocationAction = createTypeAction(CLEAR_CALENDAR_SELECTED_ARTIST_LOCATION)

export const SEARCH_CALENDAR_TERMINSLOTS = 'SEARCH_CALENDAR_TERMINSLOTS'
export const searchCalendarTerminslotsAction = createSimpleAction(SEARCH_CALENDAR_TERMINSLOTS, 'searchOptions')
export const SEARCH_CALENDAR_TERMINSLOTS_SUCCESS = 'SEARCH_CALENDAR_TERMINSLOTS_SUCCESS'
export const searchCalendarTerminslotsSuccessAction = createSimpleAction(SEARCH_CALENDAR_TERMINSLOTS_SUCCESS, 'timeslots')

export const SEARCH_CALENDAR_TERMINSLOTS_FAILURE = 'SEARCH_CALENDAR_TERMINSLOTS_FAILURE'
export const searchCalendarTimeslotsFailureAction = createTypeAction(SEARCH_CALENDAR_TERMINSLOTS_FAILURE)

export const CLEAR_CALENDAR_TIMESLOTS = 'CLEAR_CALENDAR_TIMESLOTS'
export const clearCalendarTimeslotsAction = createTypeAction(CLEAR_CALENDAR_TIMESLOTS)


export const CHANGE_ARTIST_AND_ZEITRAUM = 'CHANGE_ARTIST_AND_ZEITRAUM'
export const changeArtistAndZeitraum = createSimpleAction(CHANGE_ARTIST_AND_ZEITRAUM, 'params')
