import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_KUNDEN_DATA = 'LOAD_KUNDEN_DATA'
export const initiateLoadKunde = createSimpleAction(LOAD_KUNDEN_DATA, 'kundId')

export const LOAD_KUNDEN_DATA_SUCCESS = 'LOAD_KUNDEN_DATA_SUCCESS'
export const loadKundeSuccessAction = createSimpleAction(LOAD_KUNDEN_DATA_SUCCESS, 'kundeData')

export const SAVE_KUNDE = 'SAVE_KUNDE'
export const saveKunde = createSimpleAction(SAVE_KUNDE, 'saveKundeData')

export const SEARCH_KUNDEN_DOUBLETTEN = 'SEARCH_KUNDEN_DOUBLETTEN'
export const searchKundenDoubletten = createSimpleAction(SEARCH_KUNDEN_DOUBLETTEN, 'doublettenSearchOptions')

export const SEARCH_KUNDEN_DOUBLETTEN_SUCCESS = 'SEARCH_KUNDEN_DOUBLETTEN_SUCCESS'
export const searchKundenDoublettenSuccess = createSimpleAction(SEARCH_KUNDEN_DOUBLETTEN_SUCCESS, 'doubletten')

export const CLEAR_KUNDEN_DOUBLETTEN = 'CLEAR_KUNDEN_DOUBLETTEN'
export const clearKundenDoublettenAction = createTypeAction(CLEAR_KUNDEN_DOUBLETTEN)

export const SAVE_KUNDE_AND_RELOAD = 'SAVE_KUNDE_AND_RELOAD'
export const saveAndReloadKunde = createSimpleAction(SAVE_KUNDE_AND_RELOAD, 'saveKundeData')

export const ADD_NEUKUNDE = 'ADD_NEUKUNDE'
export const addNeukunde = createSimpleAction(ADD_NEUKUNDE, 'kundeAndSearchParameters')

export const UPDATE_KUNDE_PROJEKT = 'UPDATE_KUNDE_PROJEKT'
export const updateKundeProjekt = createSimpleAction(UPDATE_KUNDE_PROJEKT, 'projekt')

export const SEND_FILE_LINK_TO_KUNDE = 'SEND_FILE_LINK_TO_KUNDE'
export const sendFileLinkToKunde = createSimpleAction(SEND_FILE_LINK_TO_KUNDE, 'fileId')

export const DELETE_KUNDE = 'DELETE_KUNDE'
export const deleteKundeAction = createSimpleAction(DELETE_KUNDE, 'kundId')

export const MERGE_PROJEKTE = 'MERGE_PROJEKTE'
export const mergeProjekteAction = createSimpleAction(MERGE_PROJEKTE, 'mergeConfig')

export const MERGE_PROJEKTE_SUCCESS = 'MERGE_PROJEKTE_SUCCESS'
export const mergeProjekteSuccessAction = createTypeAction(MERGE_PROJEKTE_SUCCESS)

export const MERGE_PROJEKTE_FAILED = 'MERGE_PROJEKTE_FAILED'
export const mergeProjekteFailedAction = createTypeAction(MERGE_PROJEKTE_FAILED)

export const DELETE_KUNDEN_PROJEKT = 'DELETE_KUNDEN_PROJEKT'
export const deleteKundenProjektAction = createSimpleAction(DELETE_KUNDEN_PROJEKT, 'projekt')

export const CLEAR_KUNDE = 'CLEAR_KUNDE'
export const clearKundeAction = createTypeAction(CLEAR_KUNDE)

export const SET_PAGE = 'SET_PAGE'
export const setPageAction = createSimpleAction(SET_PAGE, 'page')

export const ADD_KUNDEN_BATCH = 'ADD_KUNDEN_BATCH'
export const addKundenBatchAction = createSimpleAction(ADD_KUNDEN_BATCH, 'kunden')

export const ADD_KUNDEN_BATCH_SUCCESS = 'ADD_KUNDEN_BATCH_SUCCESS'
export const addKundenBatchSuccessAction = createSimpleAction(ADD_KUNDEN_BATCH_SUCCESS, 'importResult')
