import {BACKEND} from "../config/backend";
import axios from "axios";
import {createMultipartFormHeader} from "./commonApi";

export const STUDIO_BASE_URL = `${BACKEND}/api/v1/studio`

export const createTicket = ({token, ticket}) => {
    const {files, subject, description, type, email, phone} = ticket
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
    }
    formData.append('type', type)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('subject', subject)
    formData.append('description', description)


    return axios.post(`${STUDIO_BASE_URL}/tickets`, formData, {
        ...createMultipartFormHeader(token)
    })
}
