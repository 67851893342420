import {
    asyncLoadingActionSuccess,
    clearAsyncLoading,
    initiateAsyncLoadingWithParamsAction,
    replacePayloadFields
} from "../utils/reducerUtils";
import {
    ADD_KUNDEN_BATCH, ADD_KUNDEN_BATCH_SUCCESS,
    CLEAR_KUNDE,
    CLEAR_KUNDEN_DOUBLETTEN,
    LOAD_KUNDEN_DATA,
    LOAD_KUNDEN_DATA_SUCCESS,
    MERGE_PROJEKTE,
    MERGE_PROJEKTE_FAILED,
    MERGE_PROJEKTE_SUCCESS,
    SEARCH_KUNDEN_DOUBLETTEN,
    SEARCH_KUNDEN_DOUBLETTEN_SUCCESS,
    SET_PAGE
} from "./kundeAction";


const INITIAL = {
};

export default function kundeReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_KUNDEN_DATA:
            return initiateAsyncLoadingWithParamsAction(state, 'artistMailData', {artistId: action.payload.artistId})
        case LOAD_KUNDEN_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'kundeData')

        case SEARCH_KUNDEN_DOUBLETTEN:
            return initiateAsyncLoadingWithParamsAction(state, 'doubletten', {kundId: action.payload.doublettenSearchOptions})
        case SEARCH_KUNDEN_DOUBLETTEN_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'doubletten')

        case CLEAR_KUNDEN_DOUBLETTEN:
            return clearAsyncLoading(state, 'doubletten')

        case MERGE_PROJEKTE:
            return {
                ...state,
                mergingProjectsOngoing: true
            }

        case MERGE_PROJEKTE_SUCCESS:
        case MERGE_PROJEKTE_FAILED:
            return {
                ...state,
                mergingProjectsOngoing: undefined
            }

        case SET_PAGE:
            return replacePayloadFields(state, action)

        case ADD_KUNDEN_BATCH:
            return {
                ...state,
                batchInProgress: true
            }

        case ADD_KUNDEN_BATCH_SUCCESS:
            return {
                ...state,
                batchInProgress: false,
                importResult: {
                    success: (state.importResult?.success || 0) + (action.payload.importResult?.success || 0),
                    doublets: (state.importResult?.doublets || []).concat(action.payload.importResult.doublets || []),
                    failed: (state.importResult?.failed || []).concat(action.payload.importResult?.failed || [])
                }
            }

        case CLEAR_KUNDE:
            return INITIAL

        default:
            return state;
    }
}