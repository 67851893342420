import {AppBar, Box, IconButton, Toolbar, Typography, Fade} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import React, {useCallback} from "react";
import './list.css'

const ListDialogContainer = ({onExit, children, header, isFullView, helpLink}) => {
    const [visible, setVisible] = React.useState(true)

    const handleOpenHelp = useCallback(() => window.open(helpLink, '_blank'), [helpLink])

    const handleExit = useCallback(() => {
        setVisible(false)
        onExit()
    }, [onExit, setVisible])

    return (
        <Fade in={visible} timeout={500}>
            <Box display="flex" flexDirection="column" height="100%" bgcolor="background.default" color="text.primary">
                {!isFullView ? <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h4" component="span"
                                    color="text.primary">{header}</Typography>
                        {helpLink ? <IconButton
                            size="large"
                            color="inherit"
                            onClick={handleOpenHelp}
                        >
                            <HelpIcon/>
                        </IconButton> : null}
                        <Box flexGrow={1}></Box>
                        <IconButton
                            size="large"
                            color="inherit"
                            onClick={handleExit}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar> : null}
                <Fade in={visible} timeout={1000}>
                    <Box sx={{flex: 1, display: "flex", flexDirection: "column", minHeight: 0, padding: "0 2em"}}>
                        {visible ? children : null}
                    </Box>
                </Fade>
            </Box>
        </Fade>
    );
};

export default ListDialogContainer;
