import {
    CHANGE_ARTIST_AND_ZEITRAUM,
    initiateLoadCalendarAction,
    LOAD_CALENDAR,
    loadCalendarFailureAction,
    loadCalendarKundenSuccessAction,
    loadCalendarSuccessAction,
    SELECT_CALENDAR_ARTIST,
    SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM,
    SWITCH_CALENDAR_TO_ARTIST_VIEW
} from "../calendarActions";
import {
    isSingleArtistView,
    selectLoadedZeitraumOfEvents,
    selectSelectedCalendarArtistID,
    selectSelectedKundeCalendar,
    selectViewConfigurationType
} from "../calendarSelector";
import {getArtistToken} from "../../auth/token.saga";
import {getCalendarData} from "../../../api/artistApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {call, put, select, takeLatest} from "redux-saga/effects"
import {calculateRelevantArtistForResourceList, selectResourceFilter} from "../calendarResourceSelector";
import {getWeekFromDate} from "../../utils/period";
import {selectArtistMap} from "../../common/commonSelector";
import {selectLeseRechtArtistIDList} from "../../auth/authSelector";
import {PlainDate} from "@kiss-solutions/plaindate";


function* loadAndProcessCalendarData(zeitraum, artistIds) {
    try {
        const token = yield* getArtistToken()
        if (token) {

            const {data: {termine, holidays, kunden}} = yield call(getCalendarData, {token, zeitraum, artistIds})
            yield put(loadCalendarSuccessAction({termine, holidays, loadedZeitraum: zeitraum}))
            const selectedKunde = yield select(state => selectSelectedKundeCalendar(state))
            if (selectedKunde && !kunden[selectedKunde.KundID]) {
                kunden[selectedKunde.KundID] = selectedKunde
            }
            yield put(loadCalendarKundenSuccessAction(kunden))
        }
    } catch (err) {
        console.log(err)
        yield put(loadCalendarFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.LOAD_DATA_FAILED_INFO'), 'loadCalendar')))
    }
}

function* loadCalendar({payload}) {
    const zeitraum = payload.zeitraum
    const viewType = yield select(state => selectViewConfigurationType(state))
    let artistIds
    if (isSingleArtistView(viewType)) {
        artistIds = [yield select(state => selectSelectedCalendarArtistID(state))]
    } else {
        const artistMap = yield select(state => selectArtistMap(state))
        const resourceFilter = yield select(state => selectResourceFilter(state))
        const viewableArtistIdList = yield select(state => selectLeseRechtArtistIDList(state))
        const artistList = calculateRelevantArtistForResourceList(artistMap, resourceFilter, viewableArtistIdList, zeitraum)
        artistIds = artistList.map(a => a.ArtistID)
    }
    yield loadAndProcessCalendarData(zeitraum, artistIds)
}

export function* watchLoadTermine() {
    yield takeLatest([LOAD_CALENDAR, SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM], loadCalendar)
}


function* processChangedArtist({payload}) {
    const zeitraum = yield select(state => selectLoadedZeitraumOfEvents(state))
    if (zeitraum) {
        const artistId = payload.calendarArtist
        yield loadAndProcessCalendarData(zeitraum, [artistId])
    }
}
export function* watchChangeCalendarArtist() {
    yield takeLatest([SELECT_CALENDAR_ARTIST], processChangedArtist)
}

function* processChangArtistAndZeitraum({payload}) {
    const {calendarArtist, zeitraum} = payload.params
    if (zeitraum && calendarArtist) {
        yield loadAndProcessCalendarData(zeitraum, [calendarArtist])
    }
}
export function* watchChangArtistAndZeitraum() {
    yield takeLatest([CHANGE_ARTIST_AND_ZEITRAUM], processChangArtistAndZeitraum)
}

function* processSwitchToArtistView() {
    let zeitraum = yield select(state => selectLoadedZeitraumOfEvents(state))
    zeitraum = getWeekFromDate(new PlainDate(zeitraum.start).startOfDay())
    yield put(initiateLoadCalendarAction(zeitraum))
}

export function* watchSwitchToArtistView() {
    yield takeLatest([SWITCH_CALENDAR_TO_ARTIST_VIEW], processSwitchToArtistView)
}
