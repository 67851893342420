import {isOverlapping} from "@kiss-solutions/plaindate/plainPeriod";
import {PlainDate, TIMEUNIT_DAYS, TIMEUNIT_SECONDS} from "@kiss-solutions/plaindate";

export function sortAndDistributePeriodsInRows(periods) {
    const rows = [];
  
    // Sort the periods by their start dates
    periods.sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1));
  
    periods.forEach((period) => {
      let placed = false;
  
      for (const row of rows) {
        if (!row.some((p) => isOverlapping(p, period, TIMEUNIT_SECONDS, false))) {
          row.push(period);
          placed = true;
          break;
        }
      }
  
      if (!placed) {
        rows.push([period]);
      }
    });
  
    return rows;
  }
  

export const splitTargetPeriodIntoDays = targetPeriod => {
    let day = new PlainDate(targetPeriod.start)
    const dayPeriods = []
    while (targetPeriod.end.isAfter(day)) {
        dayPeriods.push({start: new PlainDate(day), end: new PlainDate(day).add(1, TIMEUNIT_DAYS)})
        day.add(1)
    }
    return dayPeriods
}
