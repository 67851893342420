import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {call, select, takeLatest, put} from "redux-saga/effects"
import {getTerminslots} from "../../../api/artistApi";
import {
    SEARCH_CALENDAR_TERMINSLOTS,
    searchCalendarTerminslotsSuccessAction,
    searchCalendarTimeslotsFailureAction
} from "../calendarActions";
import {
    selectRelevantArtistsForTerminslotSearch,
    selectSelectedTatorIdOfResourceFilter
} from "../calendarResourceSelector";

function* processSearchCalendarTerminslotsSaga({payload}) {
    try {
        const token = yield* getArtistToken()
        const searchOptions = payload.searchOptions
        const artistidlist = yield select(state => selectRelevantArtistsForTerminslotSearch(state))
        const options = {
            start: searchOptions.start.toDateString(),
            end: searchOptions.end.toDateString(),
            slotsize: searchOptions.slotSizeMinutes,
            searchforfirst: searchOptions.searchForFirst,
            artistidlist,
            tatortid: searchOptions.selectedTatortId
        }
        if (token) {
            const data = extractDataFromResponse(yield call(getTerminslots, {
                token,
                options
            }))
            yield put(searchCalendarTerminslotsSuccessAction(data))
        }
    } catch (err) {
        console.log(err)
        yield put(searchCalendarTimeslotsFailureAction())
    }
}

export function* watchSearchCalendarTerminslots() {
    yield takeLatest(SEARCH_CALENDAR_TERMINSLOTS, processSearchCalendarTerminslotsSaga)
}
