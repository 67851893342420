import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";

export const selectNachrichtState = createSelector(
    (state) => state.nachricht
    , identityFunction
)

export const selectNachrichtKundeData = createFieldSelector(selectNachrichtState, 'kundeData')
export const selectNachrichtKundenProjekte = createFieldSelector(selectNachrichtKundeData, 'projekte', [])

export const selectNachrichtTerminData = createFieldSelector(selectNachrichtState, 'terminData')

export const selectNachrichtKunde = createSelector(
    selectNachrichtKundeData
    , selectNachrichtTerminData
    , (kundeData, terminData) => kundeData ? kundeData.kunde : terminData?.kunde
)

export const selectNachrichtTermin = createFieldSelector(selectNachrichtTerminData, 'termin')
export const selectNachrichtProjekt = createFieldSelector(selectNachrichtTerminData, 'projekt')

export const selectIsSending = createBooleanFieldSelector(selectNachrichtState, 'sending')