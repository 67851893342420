import {selectKunde} from "../kundeSelector";
import {getArtistToken} from "../../auth/token.saga";
import {sendFileLink} from "../../../api/kundenApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {extractMessageFromAxiosError} from "../../utils/error";
import {SEND_FILE_LINK_TO_KUNDE} from "../kundeAction";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processSendFileLink({payload}) {
    let kunde = yield select(state => selectKunde(state))
    try {
        const token = yield getArtistToken()
        if (token && kunde) {
            yield call(sendFileLink, {token, fileId: payload.fileId, kundId: kunde.KundID})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.FILE_LINK_SENT_SUCCESSFULLY', {
                ns: i18_KUNDE,
                name: kunde.Name
            }), 'processSendFileLink')))
        }
    } catch (err) {
        const message = extractMessageFromAxiosError(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.FILE_LINK_SENT_FAILED', {
            ns: i18_KUNDE,
            name: kunde.Name,
            message
        }), 'processSendFileLink')))
    }
}

export function* watchSendFileLink() {
    yield takeLatest(SEND_FILE_LINK_TO_KUNDE, processSendFileLink)
}