import {call, takeLatest, put} from "redux-saga/effects"
import {getArtistToken} from "../../auth/token.saga";
import {
    CREATE_SUPPORT_TICKET,
    createSupportTicketFailureAction,
    createSupportTicketSuccessAction
} from "../commonActions";
import {createTicket} from "../../../api/support";

function* processCreateSupportTicketSaga({payload}) {
    try {
        const token = yield* getArtistToken()
        const ticket = payload.ticket
        if (token) {
           yield call(createTicket, {
                token, ticket
            })
            yield put(createSupportTicketSuccessAction())
        }
    } catch (err) {
        console.log(err)
        yield put(createSupportTicketFailureAction())
    }
}

export function* watchCreateSupportTicket() {
    yield takeLatest(CREATE_SUPPORT_TICKET, processCreateSupportTicketSaga)
}
