import {
    selectCalendarEventZeitraum,
    selectSelectedKundIDCalendar,
    selectSelectedTerminIDCalendar
} from "../calendarSelector";
import {getArtistToken} from "../../auth/token.saga";
import {deleteCalendarTerminToaster} from "../../../api/artistApi";
import {
    clearCalendarSelectedTermin,
    DELETE_SELECTED_TERMIN,
    deleteSelectedTerminSuccessAction,
    loadSelectedKundenTermineAction,
    refreshLoadCalendarAction
} from "../calendarActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* deleteSelectedTermin() {
    try {
        const terminId = yield select(state => selectSelectedTerminIDCalendar(state))
        const kundId = yield select(state => selectSelectedKundIDCalendar(state))
        const token = yield* getArtistToken()
        if (terminId && token) {
            yield call(deleteCalendarTerminToaster, {token, terminId})
            yield put(deleteSelectedTerminSuccessAction())
            const selectedZeitraum = yield select(state => selectCalendarEventZeitraum(state))
            yield put(refreshLoadCalendarAction(selectedZeitraum))
            if (kundId) {
                yield put(loadSelectedKundenTermineAction(kundId))
            }
            yield put(clearCalendarSelectedTermin())
        }
    } catch (err) {
        yield put(clearCalendarSelectedTermin())
        if (err.response?.status === 404) {
            yield put(deleteSelectedTerminSuccessAction())
            yield put(emitNotificationAction(createInfoNotification(i18next.t('TERMIN.DELETE_TERMIN_SUCCESS_INFO'), 'deleteSelectedTermin')))
        } else {
            yield put(emitNotificationAction(createErrorNotification(i18next.t('TERMIN.DELETE_DATA_FAILED_INFO'), 'deleteSelectedTermin')))
            console.log(err)
        }
    }
}

export function* watchDeleteSelectedTermin() {
    yield takeLatest(DELETE_SELECTED_TERMIN, deleteSelectedTermin)
}