import {getArtistToken} from "../../auth/token.saga";
import i18next from "i18next";
import {i18_TEMPLATES} from "../../../i18nReferences";
import {reloadCommonMsgVorlagenAction} from "../../common/commonActions";
import {selectMsgVorlagenMap} from "../../common/commonSelector";
import {call, put, select, takeLatest} from "redux-saga/effects"
import {deleteMessageVorlage} from "../../../api/commonApi";
import {DELETE_MSG_VORLAGE} from "../templatesActions";
import {emitChangedNotification} from "../../notification/notificationSaga";
import { DELETION_FAILED, DELETION_FAILED_WITH_DETAILS, INFO_DELETED_SUCCESSFULLY } from "../../../models/notification";

function* processDeleteMsgVorlage({payload}) {
    const {msgVorlageId} = payload
    const msgVorlagenMap = yield select(state => selectMsgVorlagenMap(state))
    const bezeichnung = msgVorlagenMap[msgVorlageId].Bezeichnung
    const category = i18next.t('VORLAGEN_DIALOG.MESSAGE_MENU_ITEM_TEXT', {ns: i18_TEMPLATES})
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(deleteMessageVorlage, {token, msgVorlageId})
            yield* emitChangedNotification({
                type: INFO_DELETED_SUCCESSFULLY,
                data: bezeichnung,
                category
            })
            yield put(reloadCommonMsgVorlagenAction())
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: err.response?.data != null ? DELETION_FAILED_WITH_DETAILS : DELETION_FAILED,
            data: bezeichnung,
            errorMsg: err.response?.data,
            category
        })
        console.log(err)
    }
}

export function* watchDeleteMsgVorlage() {
    yield takeLatest(DELETE_MSG_VORLAGE, processDeleteMsgVorlage)
}