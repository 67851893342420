import {DELETE_ARTIST_LOCATION, reloadCommonArtistsAction} from "../commonActions";
import {selectArtistMap, selectStudioTimezone} from "../commonSelector";
import {clearCalendarSelectedArtistLocationAction} from "../../calendar/calendarActions";
import {getArtistToken} from "../../auth/token.saga";
import {selectLocale} from "../../auth/authSelector";
import {deleteArtistLocation} from "../../../api/artistApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {formatDate} from "../../../models/date";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processDeleteArtistLocation({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    yield put(clearCalendarSelectedArtistLocationAction())
    try {
        const token = yield getArtistToken()
        if (token) {
            const locale = yield select(state => selectLocale(state))
            const timezone = yield select(state => selectStudioTimezone(state))
            yield call(deleteArtistLocation, {token, artistLocation: payload.location})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('CALENDAR_LOCATION.LOCATION_DELETED_SUCCESS', {
                ns: i18_TRANSLATION,
                Kuerzel: artistMap[payload.location.ArtistID].Kuerzel,
                DatVon: formatDate(new Date(payload.location.DatVon), locale, timezone),
                DatBis: formatDate(new Date(payload.location.DatBis), locale, timezone)
            }), 'processDeleteArtistLocation')))
            yield put(reloadCommonArtistsAction())
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR_LOCATION.DELETE_LOCATION_FAILED', {
            ns: i18_TRANSLATION,
            Kuerzel: artistMap[payload.location.ArtistID].Kuerzel,
        }), 'processDeleteArtistLocation')))
        console.log(err)
    }
}

export function* watchDeleteArtistLocation() {
    yield takeLatest([DELETE_ARTIST_LOCATION], processDeleteArtistLocation)
}