import * as React from 'react';
import StandardContainer from "../common/StandardContainer";
import {Alert, Button, Typography} from "@mui/material";
import Cable from '../../public/images/cable.jpg'


const BackendError = ({t, onRetry}) => {
    return (
        <StandardContainer>
            <Typography style={{textAlign: 'center'}} variant='h4'>{t('VERBINDUNGSFEHLER')}</Typography>
            <img src={Cable} width="100%"/>
            <Alert severity="error">{t('VERBINDUNGSAUFBAU_NICHT_MÖGLICH')}</Alert>
            <Button variant="contained" onClick={onRetry}>{t('RETRY')}</Button>
        </StandardContainer>
    )
}

export default BackendError