export const TERMINSTATUS_AUSGEFALLEN = 2
export const TERMINSTATUS_OFFEN = 0
export const TERMINSTATUS_ERLEDIGT = 1
export const TERMINSTATUS_VORGEMERKT = 3
const TERMINSTATI_ALL = [TERMINSTATUS_OFFEN, TERMINSTATUS_ERLEDIGT, TERMINSTATUS_AUSGEFALLEN, TERMINSTATUS_VORGEMERKT]
export const getTerminstatusId = (status) => TERMINSTATUS_MAP.indexOf(status)
export const convertTerminStatusIdList2SelectionIdList = (idList) => idList.map(t => TERMINSTATUS_MAP[t])
export const TERMINSTATUS_MAP = ['TERMINSTATUS_OFFEN', 'TERMINSTATUS_ERLEDIGT', 'TERMINSTATUS_AUSGEFALLEN', 'TERMINSTATUS_VORGEMERKT']
export const TERMINSTATI_MAP = {
    TERMINSTATUS_OFFEN,
    TERMINSTATUS_ERLEDIGT,
    TERMINSTATUS_AUSGEFALLEN,
    TERMINSTATUS_VORGEMERKT
}
export const TERMINSTATUS_OPTIONS_WITH_EINNAHME = [TERMINSTATUS_ERLEDIGT, TERMINSTATUS_AUSGEFALLEN]
export const TERMINSTATUS_SELECTION = TERMINSTATI_ALL.map(id => ({id, text: TERMINSTATUS_MAP[id]}))
export const TERMINSTATUS_SELECTION_BEI_EINNAHMEN = TERMINSTATUS_OPTIONS_WITH_EINNAHME.map(id => ({
    id,
    text: TERMINSTATUS_MAP[id]
}))
export const TERMINTYP_BESPRECHUNG = 1
export const TERMINTYP_TELEFON_BESPRECHNUNG = 9
export const TERMINTYP_INTERNE_VORBEREITUNG = 10

export const TERMINTYPEN_DEPOSIT = [TERMINTYP_BESPRECHUNG, TERMINTYP_TELEFON_BESPRECHNUNG]

export const termintypSort = (a, b) => a.Sort - b.Sort