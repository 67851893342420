import {convertTZ} from "@kiss-solutions/dateutils";
import {PlainDate} from "@kiss-solutions/plaindate";

export const getDatePartAtTimezone = (isoDateString, timezone) => {
    const date = new Date(isoDateString)
    const shiftedDate = convertTZ(date, timezone)
    return new Intl.DateTimeFormat('fr-ca').format(shiftedDate)
}

export const formatDateTime = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatDate = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const isValidDate = (date) => {
    if (date == null) {
        return false
    }
    if ( Object.prototype.toString.call(date) === "[object Date]" ) {
        return !isNaN(date.getTime());
    } else {
        return false
    }
}

const DATE_TYPE_MMMBDDBYYYY_REGEX = new RegExp(/\w{3}\s\d{1,2}\s\d{4}$/)
const DATE_TYPE_YYYY_MM_DD_REGEX = new RegExp(/\d{4}-\d{2}-\d{2}$/)
const DATE_TYPE_NN_NN_YYYY_REGEX = new RegExp(/\d{1,2}[\\.-/]\d{1,2}[\\.-/]\d{4}$/)

export const DATE_TYPE_MMMBDDBYYYY = {
    isFormatMatching: (dateString) => DATE_TYPE_MMMBDDBYYYY_REGEX.test(dateString),
    name: 'DATE_TYPE_MMMBDDBYYYY',
    toDate: (dateString) => {
        const result = new Date(dateString)
        return isValidDate(result) && result || undefined
    }
}
export const DATE_TYPE_YYYY_MM_DD = {
    isFormatMatching: (dateString) => DATE_TYPE_YYYY_MM_DD_REGEX.test(dateString),
    name: 'DATE_TYPE_YYYY_MM_DD',
    toDate: (dateString) => {
        const result = new Date(dateString)
        return isValidDate(result) && result || undefined
    }
}

const convertDateStrings = (day, month, year) => new Date(`${year.trim()}-${month.trim()}-${day.trim()}`)

export const convertToLocalDatestring = date => new PlainDate(date.getFullYear(), date.getMonth(), date.getDate()).toDateString()
export const DATE_TYPE_DD_MM_YYYY = {
    isFormatMatching: (dateString) => DATE_TYPE_NN_NN_YYYY_REGEX.test(dateString),
    name: 'DATE_TYPE_DD_MM_YYYY',
    toDate: (dateString) => {
        const [day, month, year] = dateString.split(/[\\.-/]/g)
        const result = convertDateStrings(day, month, year)
        return isValidDate(result) && result || undefined
    }
}

export const DATE_TYPE_MM_DD_YYYY = {
    isFormatMatching: (dateString) => DATE_TYPE_NN_NN_YYYY_REGEX.test(dateString),
    name: 'DATE_TYPE_MM_DD_YYYY',
    toDate: (dateString) => {
        const [month, day, year] = dateString.split(/[\\.-/]/g)
        const result = convertDateStrings(day, month, year)
        return isValidDate(result) && result || undefined
    }
}

export const DATE_FORMAT_MAP = {DATE_TYPE_MMMBDDBYYYY, DATE_TYPE_YYYY_MM_DD, DATE_TYPE_DD_MM_YYYY, DATE_TYPE_MM_DD_YYYY}
const DATE_FORMAT_KEYS = Object.keys(DATE_FORMAT_MAP)
const DATE_FORMAT_LIST = Object.values(DATE_FORMAT_MAP)

const INITIAL_DATE_TYPE_COUNTER = DATE_FORMAT_KEYS.reduce((result, key) => {
    result[key] = 0;
    return result;
}, {})


const getPossibleDateTypes = (dateString) => DATE_FORMAT_LIST.reduce((result, df) => {
    if (dateString) {
        const trimmedDateString = dateString.trim()
        if (trimmedDateString.length >= 8 && trimmedDateString.length <= 12 && df.isFormatMatching(trimmedDateString)) {
            const date = df.toDate(trimmedDateString)
            if (date != null) {
                result.push(df.name)
            }
        }
    }
    return result
}, [])

export const guessDateType = (dates, maxErrorPct = 10) => {
    if (!dates?.length) {
        return
    }
    const stats = dates.map(d => getPossibleDateTypes(d))
    const convertableCount = stats.filter(row => row.length > 0).reduce((result) => result + 1, 0)
    if (convertableCount > 0) {
        const aggregatedStats = stats.reduce((result, row) => {
            row.forEach(key => result[key] += 1)
            return result
        }, {...INITIAL_DATE_TYPE_COUNTER})
        const bestType = Object.keys(aggregatedStats).reduce((result, key) =>
            !result || aggregatedStats[key] > aggregatedStats[result] ? key : result, null)
        const errorCount = stats.reduce((result, row) => {
            if (!row.includes(bestType) && row.length) {
                result += 1
            }
            return result
        }, 0)
        if (errorCount * 100 / convertableCount <= maxErrorPct) {
            return bestType
        }
    }
}

export const parseDate = (dateString) => {
    const shortRegex = new RegExp(/\d{1,2}[\\.-/]\d{1,2}[\\.-/]\d{4}$/)
    const isoRegex = new RegExp(/\d{4}-\d{2}-\d{2}$/)
    const longRegex = new RegExp(/\w{3}\s\d{1,2} \d{1,2}$/)
    if (shortRegex.test(dateString)) {
        const parts = dateString.split(/[\\.-/]/g)
        const date1 = new Date(parts[2], parts[1], parts[0])
        const date2 = new Date(parts[2], parts[0], parts[1])
        return {type: 'SHORT', firstMonth: isValidDate(date1) && date1, firstDate: isValidDate(date2) && date2}
    } else if (isoRegex.test(dateString)) {
        return {type: 'ISO', date: new Date(dateString)}
    } else if (longRegex.test(dateString)) {
        const date = new Date(dateString)
        return {type: 'LONG', date: isValidDate(date) && date}
    }
}
