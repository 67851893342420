import { v4 as uuid } from 'uuid';
import {formationShortTime} from "./date";
import {formatDateTime} from "../store/utils/dateHelper";
import i18next from "i18next";
import {i18_EDITPROJEKT, i18_EDITTERMIN} from "../i18nReferences";

export const SEVERITY_ERROR = 'error'
export const SEVERITY_SUCCESS = 'success'
export const SEVERITY_INFO = 'info'

export const createErrorNotification = (message, category='general') => ({severity: SEVERITY_ERROR, message, zeit: new Date(), id: uuid(), category})
export const createInfoNotification = (message, category='general') => ({severity: SEVERITY_INFO, message, zeit: new Date(), id: uuid(), category})
export const createSuccessNotification = (message, category='general') => ({severity: SEVERITY_SUCCESS, message, zeit: new Date(), id: uuid(), category})

export const createNotificationTitle = (notification, locale, timezone) => (`${notification.category} ${formationShortTime(notification.zeit, locale, timezone)}`)

const createBesprechungsText = (artistMap, locale, timezone, besprechung) => {
    const Kuerzel = artistMap[besprechung.ArtistID].Kuerzel
    const Start = formatDateTime(new Date(besprechung.Start), locale, timezone)
    return ' ' + i18next.t('EDIT_TERMIN.ADD_BESPRECHUNG_SUCCESS', {ns: i18_EDITTERMIN, Start, Kuerzel})
}

export const constructTerminProjektInfoText = ({termin, projekt, besprechungen, artistMap, locale, timezone}) => {
    let text = ''
    if (termin) {
        const Kuerzel = artistMap[termin.ArtistID].Kuerzel
        const Start = formatDateTime(new Date(termin.Start), locale, timezone)
        text += termin.TerminID > 0 ? i18next.t('EDIT_TERMIN.UPDATE_TERMIN_SUCCESS', {ns: i18_EDITTERMIN, Start, Kuerzel})
            : i18next.t('EDIT_TERMIN.ADD_TERMIN_SUCCESS', {ns: i18_EDITTERMIN, Start, Kuerzel})
    }
    if (projekt) {
        const Kuerzel = artistMap[projekt.ArtistID].Kuerzel
        text += ' ' + projekt.ProjektID > 0 ? i18next.t('EDIT_PROJEKT.UPDATE_PROJEKT_SUCCESS', {
                ns: i18_EDITPROJEKT,
                ProjName: projekt.ProjName,
                Kuerzel
            })
            : i18next.t('EDIT_PROJEKT.ADD_PROJEKT_SUCCESS', {ns: i18_EDITPROJEKT, ProjName: projekt.ProjName, Kuerzel})
    }
    if (besprechungen) {
        text += besprechungen.reduce((pv, cv) => createBesprechungsText(artistMap, locale, timezone, cv), '')
    }
    return text
};
export const INFO_NOTIFICATION = 'INFO_NOTIFICATION'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

export const INFO_SAVED_SUCCESSFULLY = {
    key: 'SAVED_SUCCESSFULLY',
    success: true,
    notificationType: INFO_NOTIFICATION
};
export const SHOW_SAVED_SUCCESSFULLY = {
    key: 'SAVED_SUCCESSFULLY',
    success: true,
    notificationType: SHOW_NOTIFICATION
};
export const SAVING_FAILED = {
    key: 'SAVING_FAILED',
    success: false,
    notificationType: SHOW_NOTIFICATION
};
export const LOADING_FAILED = {
    key: 'LOADING_DATA_FAILED',
    success: false,
    notificationType: SHOW_NOTIFICATION
};
export const INFO_DELETED_SUCCESSFULLY = {
    key: 'DELETED_SUCCESSFULLY',
    success: true,
    notificationType: INFO_NOTIFICATION
};
export const SHOW_DELETED_SUCCESSFULLY = {
    key: 'DELETED_SUCCESSFULLY',
    success: true,
    notificationType: SHOW_NOTIFICATION
};
export const DELETION_FAILED = {
    key: 'DELETION_FAILED',
    success: false,
    notificationType: SHOW_NOTIFICATION
};
export const DELETION_FAILED_WITH_DETAILS = {
    key: 'DELETION_FAILED_WITH_DETAILS',
    success: false,
    notificationType: SHOW_NOTIFICATION
};

