import {CHANGE_CALENDAR_EVENT_FILTER} from "../calendarActions";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {saveArtistEventFilter} from "../../../api/localArtistPersistence";
import {select, takeLatest} from "redux-saga/effects"

function* processEventFilterChanged({payload}) {
    const artistId = yield select(state => selectLoggedInArtistID(state))
    if (artistId) {
        saveArtistEventFilter(artistId, payload.eventFilter)
    }
}

export function* watchChangeEventFilter() {
    yield takeLatest(CHANGE_CALENDAR_EVENT_FILTER, processEventFilterChanged)
}