import {replacePayloadFields} from "../utils/reducerUtils";
import {
    CLEAR_VORLAGEN_DATA_CHANGED,
    CLEAR_VORLAGEN_MSG_DATA,
    LOAD_MSG_VORLAGE_SUCCESS,
    VORLAGEN_DATA_CHANGED
} from "./templatesActions";

const INITIAL = {}

export default function templateReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_MSG_VORLAGE_SUCCESS:
            return replacePayloadFields(state, action)
        case VORLAGEN_DATA_CHANGED:
            return {
                ...state
                , vorlagenDataChanged: true
            }
        case CLEAR_VORLAGEN_DATA_CHANGED:
            return {
                ...state
                , vorlagenDataChanged: undefined
            }
        case CLEAR_VORLAGEN_MSG_DATA:
            return {
                ...state
                , msgVorlage: undefined
            }
        default:
            return state
    }
}