import {getArtistToken} from "../../auth/token.saga";
import i18next from "i18next";
import {reloadCommonPdfVorlagenAction} from "../../common/commonActions";
import {selectPdfVorlagenMap} from "../../common/commonSelector";
import {call, put, select, takeLatest} from "redux-saga/effects"
import {deletePdfVorlage} from "../../../api/commonApi";
import {DELETE_PDF_VORLAGE} from "../templatesActions";
import {getPdfTemplateDataTranslation, getPdfTemplateTranslation} from "../../../components/templates/helper";
import {emitChangedNotification} from "../../notification/notificationSaga";
import { DELETION_FAILED, INFO_DELETED_SUCCESSFULLY } from "../../../models/notification";

function* processDeletePdfVorlage({payload}) {
    const {pdfVorlagenId} = payload
    const pdfVorlagenMap = yield select(state => selectPdfVorlagenMap(state))
    const bezeichnung = pdfVorlagenMap[pdfVorlagenId].Bezeichnung
    const data = getPdfTemplateDataTranslation(i18next.t, bezeichnung)
    const category = getPdfTemplateTranslation(i18next.t, 'HEADER')
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(deletePdfVorlage, {token, pdfVorlagenId})
            yield* emitChangedNotification({
                type: INFO_DELETED_SUCCESSFULLY,
                data,
                category
            })
            yield put(reloadCommonPdfVorlagenAction())
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: DELETION_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

export function* watchDeletePdfVorlage() {
    yield takeLatest(DELETE_PDF_VORLAGE, processDeletePdfVorlage)
}