import {
    RELOAD_COMMON_MSG_VORLAGEN,
    reloadCommonMsgVorlagenFailedAction,
    reloadCommonMsgVorlagenSuccessAction
} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getMessageVorlagen} from "../../../api/commonApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* processReloadMsgVorlagen() {
    try {
        const token = yield getArtistToken()
        if (token) {
            const msgVorlagenMap = extractDataFromResponse(yield call(getMessageVorlagen, {token}))
            yield put(reloadCommonMsgVorlagenSuccessAction(msgVorlagenMap))
        }
    } catch (err) {
        console.log(err)
        yield put(reloadCommonMsgVorlagenFailedAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('COMMON.LOAD_MSG_VORLAGEN_DATA_FAILED_INFO', {ns: i18_TRANSLATION}), 'loadCommon')))
    }
}

export function* watchReloadMsgVorlagen() {
    yield takeLatest(RELOAD_COMMON_MSG_VORLAGEN, processReloadMsgVorlagen)
}