import {call, put, select, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {updateProjektList} from "../../../../api/projektApi";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../../../models/notification";
import i18next from "i18next";
import {selectProjektListParameters} from "../../listSelector";
import {loadProjektListDataAction, UPDATE_PROJECT_STATUS_FOR_LIST} from "../../listActions";
import {i18_LIST} from "../../../../i18nReferences";

function* processUpdateProjectList({payload}) {
    try {
        const token = yield getArtistToken()
        const projectChanges = payload.projectChanges
        const count = projectChanges.projektIdList?.length || 0
        const statusName = projectChanges.statusName
        if (token && count) {
            yield call(updateProjektList, {token, parameters: projectChanges})
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('PROJEKT_LIST.SAVE_DATA_SUCCESS_INFO', {
                ns: i18_LIST,
                count,
                status: statusName
            }), 'projectListUpdate')))
            const parameters = yield select(state => selectProjektListParameters(state))
            if (parameters) {
                yield put(loadProjektListDataAction(parameters))
            }
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT_LIST.SAVE_DATA_FAILED_INFO',
            {ns: i18_LIST}), 'projectListUpdate')))
    }
}

export function* watchUpdateProjectList() {
    yield takeLatest(UPDATE_PROJECT_STATUS_FOR_LIST, processUpdateProjectList)
}