import {getArtistToken} from "../../auth/token.saga";
import {selectArtistMap, selectStudioGutscheinverkauf, selectStudioTimezone} from "../../common/commonSelector";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import i18n from "../../../i18n";
import {selectDeletedProjektImages, selectInsertedTempImages} from "../terminSelectors";
import {convertTerminGutscheinEinnahmen} from "../../../models/finalizer";
import {saveTermin} from "../../../api/terminApi";
import {pushHistory} from "../../router/routerActions";
import {clearTerminDataAction} from "../terminActions";
import {call, put, select} from "redux-saga/effects";

export function* writeTerminData(terminWithProjekt) {
    const token = yield getArtistToken()
    if (token) {
        const gutscheinverkauf = yield select(state => selectStudioGutscheinverkauf(state))
        const loggedInArtistId = yield select(state => selectLoggedInArtistID(state))
        const artistSettingsMap = yield select(state => selectArtistMap(state))
        const timezone = yield select(state => selectStudioTimezone(state))
        const gutscheinText = i18n.t('GUTSCHEIN')
        const deletedImages = yield select(state => selectDeletedProjektImages(state))
        const insertedImages = yield select(state => selectInsertedTempImages(state))
        const deletedImageKeys = deletedImages && deletedImages.map(i => i.key ? i.key : i)
        const {termin, projekt, besprechungen} = terminWithProjekt
        const conversionResult = convertTerminGutscheinEinnahmen({
            termin,
            artistSettingsMap,
            gutscheinverkauf,
            gutscheinText,
            loggedInArtistId,
            timezone
        })
        const terminData = {
            termin: conversionResult.termin
            , besprechungen
            , vouchInList: conversionResult.vouchInList
            , projekt,
            images: {insertedImages, deletedImages: deletedImageKeys}
        }
        yield call(saveTermin, {token, terminData})
        yield put(pushHistory('/?refresh=true'))
        yield put(clearTerminDataAction())
    }
}