import * as yup from "yup";
import {getStudioBaseTranslation, getStudioLocationTranslation} from "../components/settings/helper";
import IBAN from "iban";
import {COUNTRY_MAP, SUBREGION_CODE_MAP} from "./countries";
import {Box} from "@mui/material";
import React from 'react'
import {mapSubRegionID2Region} from "@kiss-solutions/countries/lib/countries";
import {i18_TRANSLATION} from "../i18nReferences";
import { pick } from "../store/utils/lodash-replacement";
const MIN_RESTLAUFZEIT_LICENSE = 5
export const GUTSCHEINVERKAUF_ARTISTKASSE = 1
export const GUTSCHEINVERKAUF_STUDIOKASSE = 2

export const getGutscheinverkaufSelection = (t) => [
    {id: GUTSCHEINVERKAUF_ARTISTKASSE, text: getStudioBaseTranslation(t, 'GUTSCHEINVERKAUF_ARTISTKASSE')},
    {id: GUTSCHEINVERKAUF_STUDIOKASSE, text: getStudioBaseTranslation(t, 'GUTSCHEINVERKAUF_STUDIOKASSE')}
]

export const convertStudioName2dbName = (studioName) => {
    return 'ks_' + studioName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

export const tatortToSelection = (tatortMap, nurEigene = true) => tatortMap && Object.values(tatortMap).filter(l => !nurEigene || l.IstStudioEigen)
    .map(v => ({
        id: v.TatOrtID,
        text: v.Ort
    }))

export const createStudioBaseSchema = () => {
    return yup.object().shape({
        TatOrtID: yup.number().when(['$validLocationIds'], ([validLocationIds], schema) => schema.oneOf(validLocationIds, {key: 'STUDIO_BASE_SETTINGS.STANDARD_LOCATION_NOT_BRANCH'})).required(),
        StudioName: yup.string().min(2).max(45).required(),
        Ort: yup.string().min(2).max(30).required(),
        Waehrung: yup.string().min(2).max(3).required(),
        Passwort: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,25})/, {message: {key: 'YUP.ERROR.PASSWORD_FORMAT'}}).nullable(),
        PasswortBestaetigung: yup.string().when(['Passwort'], ([pwd], schema) => pwd ? schema.oneOf([pwd], {key: 'YUP.ERROR.PASSWORD_DIFFERS'}).required()
            : schema.optional())
    });
}

export const pickStudioBaseData = studio => pick(studio, ['StudioName', 'Ort', 'Waehrung', 'TatOrtID', 'Timezone', 'Lang', 'Gutscheinverkauf', 'CountryCode'])

export const cleanAboIban = iban => iban && iban.toUpperCase().replace(/[^A-Z0-9]/g, '')

export const createSubscriptionSchema = () => {
    return yup.object().shape({
        AboEmail: yup.string().max(50).email({key: 'YUP.ERROR.KUNDE_EMAIL_FORMAT'}).required(),
        AboAddress: yup.string().max(150).required(),
        AboBank: yup.string().max(150)
            .when(['AboLastschrVon'], ([aboLastschrVon], schema) =>  aboLastschrVon != null ? schema.required() : schema.nullable()),
        AboBic: yup.string().max(12)
            .when(['AboLastschrVon'], ([aboLastschrVon], schema) =>  aboLastschrVon != null ? schema.required() : schema.nullable()),
        AboAccOwner: yup.string().max(45)
            .when(['AboLastschrVon'], ([aboLastschrVon], schema) =>  aboLastschrVon != null ? schema.required() : schema.nullable()),
        AboIban: yup.string().max(150).transform(value => value && cleanAboIban(value))
            .test('iban', {key: 'YUP.ERROR.IBAN'}, (value) => value != null && IBAN.isValid(value) || value == null || value == '')
            .when(['AboLastschrVon'], ([aboLastschrVon], schema) => aboLastschrVon != null ? schema.required() : schema.nullable()),
    })
}

export const pickStudioSubscriptionData = subscription => pick(subscription, ['AboEmail', 'AboAddress', 'AboBank', 'AboBic', 'AboAccOwner', 'AboIban', 'AboLastschrVon', 'SignPadCount'])

export const pickPaymentData = studio => pick(studio, ['PaypalClientId', 'PaypalSecret', 'StripeSecretKey', 'StripeWebhookSigningSecret', 'PaypalAllowed', 'SofortAllowed'])

export const createPaymentSchema = () => {
    return yup.object().shape({
        PaypalClientId: yup.string().max(100).when(['$PaypalAllowed'], ([paypalAllowed], schema) => paypalAllowed ? schema.required() : schema.nullable()),
        PaypalSecret: yup.string().max(100).when(['$PaypalAllowed'], ([paypalAllowed], schema) => paypalAllowed ? schema.required() : schema.nullable()),
        StripeSecretKey: yup.string().max(100).when(['$SofortAllowed'], ([sofortAllowed], schema) => sofortAllowed ? schema.required() : schema.nullable()),
        StripeWebhookSigningSecret: yup.string().max(100).when(['$SofortAllowed'], ([sofortAllowed], schema) => sofortAllowed ? schema.required() : schema.nullable()),
    })
}


export const getStudioLocationColumns = (t, stdTatortId) => [
    {
        field: 'Farbe',
        headerName: getStudioLocationTranslation(t, 'CALENDAR_COLOR_HEADER'),
        flex: 1,
        renderCell: ({value}) => {
            return (
                <Box height="1em" width="1em" bgcolor={"#" + value}></Box>
            )
        }
    },
    {field: 'Ort', headerName: getStudioLocationTranslation(t, 'NAME_HEADER'), flex: 3},
    {
        field: 'Land',
        headerName: getStudioLocationTranslation(t, 'COUNTRY_HEADER'),
        flex: 3,
        valueGetter: ({value}) => value && COUNTRY_MAP[value.toUpperCase()]?.name
    },
    {
        field: 'SubregionID',
        headerName: getStudioLocationTranslation(t, 'SUBREGION_HEADER'),
        flex: 3,
        valueGetter: ({
                          row,
                          value
                      }) => value && row.Land && SUBREGION_CODE_MAP[`${row.Land.toUpperCase()}-${value.toUpperCase()}`]
    },
    {
        field: 'IstStudioEigen',
        type: 'boolean',
        flex: 1,
        headerName: getStudioLocationTranslation(t, 'OWNED_HEADER')
    },
    {
        field: 'Bookable',
        type: 'boolean',
        flex: 1,
        headerName: getStudioLocationTranslation(t, 'BOOKABLE_HEADER')
    },
    {
        field: 'Hauptfiliale',
        type: 'boolean',
        flex: 1,
        headerName: getStudioLocationTranslation(t, 'MAIN_BRANCH'),
        valueGetter: ({row}) => row.TatOrtID === stdTatortId,
    }
]
export const createLocationSchema = () => yup.object().shape({
    Ort: yup.string().max(45).required(),
    Stadt: yup.string().max(45).nullable(),
    SubregionID: yup.string().max(10).when(['IstStudioEigen'], ([istStudioEigen], schema) => istStudioEigen ? schema.required() : schema.nullable()),
    Plaetze: yup.number().min(0).nullable(),
    IstStudioEigen: yup.boolean().required(),
    Bookable: yup.boolean().required(),
    Telefon: yup.string().max(50).when(['IstStudioEigen'], ([istStudioEigen], schema) => istStudioEigen ? schema.required() : schema.nullable()),
    SmsFrom: yup.string().max(11).when(['IstStudioEigen'], ([istStudioEigen], schema) => istStudioEigen ? schema.required() : schema.nullable()),
    Footer1: yup.string().max(500).nullable(),
    Footer2: yup.string().max(500).nullable(),
    Footer3: yup.string().max(500).nullable(),
})

export const convertLocation = location => location && ({
    ...location
    , SubregionID: location.SubregionID || ''
    , Land: location.Land || ''
    , Telefon: location.Telefon || ''
    , Farbe: location?.Farbe && '#' + location.Farbe
    , Bookable: Boolean(location.Bookable) || false
    , IstStudioEigen: Boolean(location.IstStudioEigen) || false
})

export const convertSchemaToLocation = location => ({
    ...location,
    Farbe: location.Farbe.replace(/#/g, ''),
    RegionID: location.SubregionID && mapSubRegionID2Region(location.SubregionID)
})

export const convertSubscriptionStatus2EndOfServiceAlert = (status, t) => {
    if (!status) {
        return null
    }
    const {isTest, days} = status
    if (days > MIN_RESTLAUFZEIT_LICENSE) {
        return null
    }
    if (isTest) {
        return {days, text: t('SUBSCRIPTION.TEST_EXPIRY_NOTIFICATION_TEXT', {ns: i18_TRANSLATION})}
    }
    return {days, text: t('SUBSCRIPTION.PAYMENT_OVERDUE_NOTIFICATION_TEXT', {ns: i18_TRANSLATION})}
}

export const convertStudioLang2Locale = lang => lang === 'German' ? 'de' : 'en'
