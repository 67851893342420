import {select} from "redux-saga/effects";
import {selectArtistMap} from "../../common/commonSelector";
import {selectLoggedInArtistID} from "../../auth/authSelector";
import {createProjekt} from "../../../models/projekt";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../i18nReferences";

export function* createNewProjekt(kundId, artistId) {
    const artistMap = yield select(state => selectArtistMap(state))
    const loggedInArtistId = yield select(state => selectLoggedInArtistID(state))
    return createProjekt({
        ProjName: i18next.t('EDIT_PROJEKT.NEUES_PROJEKT_NAME', {ns: i18_EDITPROJEKT})
        , artist: artistMap[artistId]
        , KundID: kundId
        , _createdID: loggedInArtistId
        , anzInfoText: i18next.t('EDIT_PROJEKT.KEINE_ANZAHLUNG', {ns: i18_EDITPROJEKT})
    })
}