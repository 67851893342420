import {LOAD_PADS, LOAD_PADS_SUCCESS} from "./pdfSignActions";

const INITIAL = {}

export default function pdfSignReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_PADS:
            return {
                ...state,
                loading: true,
                pads: undefined
            }
        case LOAD_PADS_SUCCESS:
            return {
                ...state,
                loading: false,
                pads: action.payload.pads
            }

        default:
            return state;
    }
}