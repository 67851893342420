import React, {useMemo} from 'react';
import {PlainDate} from "@kiss-solutions/plaindate";
import {formatDate} from "../../models/date";
import './timeline.css'
const HeaderItem = ({title}) => {
    return (
        <div className="header-item">
            <div className="typography">
                {title}
            </div>
        </div>
        )
}
const TimelineHeader = ({start, locale = 'de'}) => {
    const headerCols = useMemo(() => {
        if (!start) {
            return null
        }
        return [0, 1, 2, 3, 4, 5, 6].map(days => formatDate(new PlainDate(start).add(days).toLocalDate(), locale))
    }, [locale, start])

    if (!headerCols) {
        return null
    }
    return (
        <div className="header-row">
            <div className="artist-title-container">
                <div className="title">
                    Artist
                </div>
            </div>
            <div className="artist-days-container">
                {headerCols.map((c, idx) => <HeaderItem key={'head_' + idx} title={c} />)}
            </div>
        </div>
    );
};

export default TimelineHeader;
