import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";

export const selectListsState = createSelector(
    (state) => state.lists
    , identityFunction
)

export const selectIsListDataLoading = createBooleanFieldSelector(selectListsState, 'listDataLoading')
export const selectSelectedListIdList = createFieldSelector(selectListsState, 'selectedListIdList', [])
export const selectIsReportDefinitionsLoading = createBooleanFieldSelector(selectListsState, 'allReportDefinitionsLoading')
export const selectKundenListData = createFieldSelector(selectListsState, 'kundenListData', [])
export const selectProjektListData = createFieldSelector(selectListsState, 'projektListData', [])
export const selectTerminListData = createFieldSelector(selectListsState, 'terminListData', [])
export const selectEinnahmenListData = createFieldSelector(selectListsState, 'einnahmenListData', [])
export const selectCheckoutListData = createFieldSelector(selectListsState, 'checkoutListData', [])
export const selectVouchInListData = createFieldSelector(selectListsState, 'vouchInListData', [])
export const selectDblExclusionListData = createFieldSelector(selectListsState, 'dblExclusionListData', [])
export const selectDoublettenListData = createFieldSelector(selectListsState, 'doublettenListData')
export const selectDoublettenMergeKundenData = createFieldSelector(selectListsState, 'mergeKundenData')
export const selectMandantenJobsListData = createFieldSelector(selectListsState, 'mandantenJobsListData', [])
export const selectProjektListParameters = createFieldSelector(selectListsState, 'projektParameters')
export const selectTerminListParameters = createFieldSelector(selectListsState, 'terminParameters')
export const selectReportDefinition = createFieldSelector(selectListsState, 'reportDefinition')
export const selectAllReportDefinitions = createFieldSelector(selectListsState, 'allReportDefinitions', [])

export const selectParameterForKundenList = createFieldSelector(selectListsState, 'kundenListParameter')
export const selectSelectedArtistIDForKundenList = createFieldSelector(selectParameterForKundenList, 'selectedArtistID')
export const selectAngelegtSeitForKundenList = createFieldSelector(selectParameterForKundenList, 'angelegtSeit', null)
export const selectletzterTerminNachForKundenList = createFieldSelector(selectParameterForKundenList, 'letzterTerminNach', null)
export const selectSelectedArtistIDListForProjektList = createFieldSelector(selectListsState, 'selectedArtistIdList')

export const selectParameterForTerminList = createFieldSelector(selectListsState, 'terminListParameter')
export const selectSelectedArtistIdListForTerminList = createFieldSelector(selectParameterForTerminList, 'selectedArtistIDList')
export const selectSelectedTatOrtIDListForTerminList = createFieldSelector(selectParameterForTerminList, 'selectedTatOrtIDList')
export const selectSelectedTermintypIdListForTerminList = createFieldSelector(selectParameterForTerminList, 'selectedTermintypIdList')
export const selectSelectedTerminstatusIdListForTerminList = createFieldSelector(selectParameterForTerminList, 'selectedTerminstatusIdList', [])
export const selectDatVonForTerminList = createFieldSelector(selectParameterForTerminList, 'datVon')
export const selectDatBisForTerminList = createFieldSelector(selectParameterForTerminList, 'datBis')

export const selectParameterForEinnahmenList = createFieldSelector(selectListsState, 'einnahmenListParameter')
export const selectSelectedArtistIdListForEinnahmenList = createFieldSelector(selectParameterForEinnahmenList, 'selectedArtistIDList')
export const selectSelectedTatOrtIDListForEinnahmenList = createFieldSelector(selectParameterForEinnahmenList, 'selectedTatOrtIDList')
export const selectDatVonForEinnahmenList = createFieldSelector(selectParameterForEinnahmenList, 'datVon')
export const selectDatBisForEinnahmenList = createFieldSelector(selectParameterForEinnahmenList, 'datBis', null)
export const selectZahlungsartTextForEinnahmenList = createFieldSelector(selectParameterForEinnahmenList, 'zahlungsart')

export const selectParameterForJobsList = createFieldSelector(selectListsState, 'jobsListParameter')
export const selectSelectedArtistIDForJobsList = createFieldSelector(selectParameterForJobsList, 'selectedArtistID')
export const selectAngelegtSeitForJobsList = createFieldSelector(selectParameterForJobsList, 'angelegtSeit', null)

export const selectParameterForVouchInList = createFieldSelector(selectListsState, 'vouchInListParameter')
export const selectDatInVonForVouchInList = createFieldSelector(selectParameterForVouchInList, 'datInVon', null)
export const selectDatInBisForVouchInList = createFieldSelector(selectParameterForVouchInList, 'datInBis', null)

export const selectJobDetails = createFieldSelector(selectListsState, 'jobDetails')
export const selectIsJobDetailsLoading = createBooleanFieldSelector(selectListsState, 'loadingJobDetails')

export const selectStatusListForProjektList = createFieldSelector(selectListsState, 'projektStatusListe', [])
export const selectDatVonForProjektList = createFieldSelector(selectListsState, 'datVon', null)
export const selectDatBisForProjektList = createFieldSelector(selectListsState, 'datBis', null)
export const selectWithoutAppointmentFlagForProjektList = createBooleanFieldSelector(selectListsState, 'withoutAppointments')

export const selectParameterForDblExclusionList = createFieldSelector(selectListsState, 'dblExclusionListParameter')
export const selectDatVonForDblExclusionList = createFieldSelector(selectParameterForDblExclusionList, 'datVon', null)
export const selectDatBisForDblExclusionList = createFieldSelector(selectParameterForDblExclusionList, 'datBis', null)

export const selectNachrichtenListData = createFieldSelector(selectListsState, 'nachrichtenListData', [])
export const selectParameterForNachrichtenList = createFieldSelector(selectListsState, 'nachrichtenListParameter')
export const selectDatVonForNachrichtenList = createFieldSelector(selectParameterForNachrichtenList, 'datVon')
export const selectDatBisForNachrichtenList = createFieldSelector(selectParameterForNachrichtenList, 'datBis', null)
export const selectSelectedArtistIdListForNachrichtenList = createFieldSelector(selectParameterForNachrichtenList, 'selectedArtistIDList')
