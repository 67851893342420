import {CLEAR_FINALIZER_DATA, SAVE_FINALIZER_TERMIN, SET_FINALIZER_DATA} from "./finalizerAction";

const INITIAL = {};

export default function finalizerReducers(state = INITIAL, action) {
    switch (action.type) {
        case SET_FINALIZER_DATA: {
            return {
                ...action.payload.finalizerData
            }
        }

        case SAVE_FINALIZER_TERMIN:
            return {
                ...state,
                savingOngoing: true
            }

        case CLEAR_FINALIZER_DATA:
            return INITIAL

        default:
            return state;
    }
}