import React from 'react';
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList} from "@mui/material";
import useDialogHandler from "../../../hooks/useDialogHandler";

const DialogWithMenuBurgerMenu = ({appBarItems}) => {
    const {anchorEl, open, handleClick, handleClose, closeAndExecute} = useDialogHandler()

    const renderMenuItem = (appBarItem, idx) => {
        return (
            <MenuItem key={idx} onClick={() => closeAndExecute(appBarItem.onClick)}>
                <ListItemIcon>
                    {appBarItem.icon}
                </ListItemIcon>
                {appBarItem.text ? <ListItemText>{appBarItem.text}</ListItemText> : null}
            </MenuItem>
        )
    }

    return (
        <Box ml="1em">
            <IconButton
                id='calendar-burger-menu'
                aria-controls={open ? 'calendar-burger-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <MenuIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuList>
                    {appBarItems?.length ? appBarItems.map((item, idx) => renderMenuItem(item, idx)) : null}
                </MenuList>
            </Menu>
        </Box>
    )
}

export default DialogWithMenuBurgerMenu;