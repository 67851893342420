import {fork} from "redux-saga/effects";
import {watchLoadTerminData} from "./sagas/loadTerminData";
import {watchAddTermin} from "./sagas/addTermin";
import {watchSearchTerminKunden} from "./sagas/searchTerminKunden";
import {watchLoadHolidays} from "./sagas/loadHolidays";
import {watchChangeTerminKunde} from "./sagas/changeTerminKunde";
import {watchEditTerminAddProjektAction} from "./sagas/editTerminAddProjektAction";
import {watchLoadImagesOfEditedTerminProjektAction} from "./sagas/loadImagesOfEditedTerminProjektAction";
import {watchAddTerminImage} from "./sagas/addTerminImage";
import {watchLoadVoucherDataForTerminEinnahme} from "./sagas/loadVoucherDataForTerminEinnahme";
import {watchSaveTerminData} from "./sagas/saveTerminData";
import {watchDeleteTermin} from "./sagas/deleteTermin";
import {watchImportICalTermine} from "./sagas/importICalTermineSaga";

const terminSagas = [
    fork(watchLoadTerminData), fork(watchSearchTerminKunden), fork(watchLoadHolidays), fork(watchChangeTerminKunde),
    fork(watchAddTermin), fork(watchEditTerminAddProjektAction), fork(watchLoadImagesOfEditedTerminProjektAction),
    fork(watchAddTerminImage), fork(watchLoadVoucherDataForTerminEinnahme), fork(watchSaveTerminData), fork(watchDeleteTermin),
    fork(watchImportICalTermine)
]

export default terminSagas
