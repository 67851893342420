import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { i18_TRANSLATION } from "../../i18nReferences";
import { useTranslation } from "react-i18next";

const DeleteConfirmation = ({ onDelete, disabled, color, sx, icon, tooltip, label }) => {
    const { t } = useTranslation([i18_TRANSLATION]);
    const [deletionRequestTimer, setDeletionRequestTimer] = useState();

    const clearDeletionRequestTimer = useCallback(() => {
        if (deletionRequestTimer) {
            clearTimeout(deletionRequestTimer);
            setDeletionRequestTimer(undefined);
        }
    }, [deletionRequestTimer]);

    useEffect(() => {
        return () => clearDeletionRequestTimer();
    }, [clearDeletionRequestTimer]);

    useEffect(() => {
        if (disabled && deletionRequestTimer) {
            clearDeletionRequestTimer();
        }
    }, [disabled, deletionRequestTimer, clearDeletionRequestTimer]);

    const handleDelete = () => {
        clearDeletionRequestTimer();
        onDelete();
    };

    const handleRequestDeletion = () => {
        setDeletionRequestTimer(setTimeout(() => setDeletionRequestTimer(undefined), 2000));
    };

    const renderButtonWithTooltip = () => {
        return (
            <Tooltip title={tooltip}>
                <span>{renderButton()}</span>
            </Tooltip>
        );
    };

    const renderButton = () => {
        if (label) {
            return (
                <Button
                    variant="contained"
                    startIcon={icon || <DeleteIcon />}
                    onClick={handleRequestDeletion}
                    disabled={disabled}
                    color={color || "inherit"}
                >
                    {label}
                </Button>
            );
        }
        return (
            <IconButton onClick={handleRequestDeletion} disabled={disabled} color={color || "inherit"}>
                {icon || <DeleteIcon />}
            </IconButton>
        );
    };

    return (
        <Box sx={{ ...sx }}>
            {!deletionRequestTimer ? (
                tooltip ? (
                    renderButtonWithTooltip()
                ) : (
                    renderButton()
                )
            ) : (
                <Button
                    startIcon={icon || <DeleteIcon />}
                    onClick={handleDelete}
                    disabled={disabled}
                    color={color || "inherit"}
                >
                    {t("CONFIRM_DELETION")}
                </Button>
            )}
        </Box>
    );
};

export default DeleteConfirmation;
