import axios from "axios";
import {BACKEND} from "../config/backend";
import {createAuthHeader} from "./commonApi";

export const getTermin = ({token, terminId, kundId}) => {
    return axios.get(`${BACKEND}/api/v1/termine/${terminId}`, {
        ...createAuthHeader(token)
        , params: kundId && {kundid: kundId}
    })
}

export const saveTermin = ({token, terminData}) => {
    return axios.post(`${BACKEND}/api/v1/termine/`, terminData, {
        ...createAuthHeader(token)
    })
}

export const downloadTerminListe = ({token, terminId, pdfVorlagenId}) => {
    return axios.get(`${BACKEND}/api/v1/termine/${terminId}/terminformular/${pdfVorlagenId}`, {
        ...createAuthHeader(token)
        , responseType: 'blob'
    })
}

export const initiateTerminListeWorkflow = ({token, terminId, pdfVorlagenId, pad}) => {
    return axios.get(`${BACKEND}/api/v1/termine/${terminId}/terminformular/${pdfVorlagenId}/${encodeURIComponent(pad)}`, {
        ...createAuthHeader(token)
    })
}

export const deleteTermin = ({token, terminId}) => {
    return axios.delete(`${BACKEND}/api/v1/termine/${terminId}`, {
        ...createAuthHeader(token)
    })
}

export const searchTermine = ({token, parameters}) => {
    return axios.get(`${BACKEND}/api/v1/termine`, {
        ...createAuthHeader(token)
        , params: parameters
    })
}

export const searchEinnahmen = ({token, parameters}) => {
    return axios.get(`${BACKEND}/api/v1/termine/einnahmen`, {
        ...createAuthHeader(token)
        , params: parameters
    })
}

export const updateTerminList = ({token, parameters}) => {
    return axios.put(`${BACKEND}/api/v1/termine/list`, parameters,{
        ...createAuthHeader(token)
    })
}

export const importICalTermine = ({token, icalTermine}) => {
    return axios.post(`${BACKEND}/api/v1/termine/icalimport`, icalTermine, {
        ...createAuthHeader(token)
    })
}
