import { MONDAY, PlainDate, SUNDAY, TIMEUNIT_DAYS, TIMEUNIT_SECONDS } from "@kiss-solutions/plaindate";
import { isOverlapping } from "@kiss-solutions/plaindate/plainPeriod";
import { Box, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCommonState from "../../hooks/UseCommonState";
import { i18_TIMELINE } from "../../i18nReferences";
import { addStartEnd, convertToTimelineData } from "../../models/timelineModel";
import { changeArtistAndZeitraum, initiateLoadCalendarAction } from "../../store/calendar/calendarActions";
import { selectStudioTatOrtID } from "../../store/common/commonSelector";
import { loadTimelineAction } from "../../store/timeline/timelineActions";
import { selectTimelineArtistDataMap, selectTimelineHolidays } from "../../store/timeline/timelineSelectors";
import { getWeekFromDate } from "../../store/utils/period";
import "../common/listScrollbar.css";
import ListDialogContainer from "../lists/ListDialogContainer";
import Timeline from "./Timeline";
import TimelineDetailsDialog from "./TimelineDetailsDialog";
import TimelineSelection from "./TimelineSelection";
import { selectSelectedCalendarArtistID } from "../../store/calendar/calendarSelector";
import { selectLoggedInArtistID } from "../../store/auth/authSelector";

const getStartOfWeek = (start) => {
    const pd = new PlainDate(start).startOfDay();
    if (pd.getDayOfWeek() !== MONDAY) {
        return pd.toDayOfWeek(MONDAY, false);
    }
    return pd;
};

const TimelineContainer = () => {
    const [searchParams] = useSearchParams()
    const [startOfWeek, setStartOfWeek] = useState(getStartOfWeek(searchParams?.get('start')));
    const [tatortId, setTatortId] = useState("");
    const [times, setTimes] = useState(["08:00", "20:00"]);
    const { t } = useTranslation([i18_TIMELINE]);
    const [detailData, setDetailData] = useState(null);
    const isDetailDialogOpen = Boolean(detailData);
    const theme = useTheme();
    const { artistMap, tatortMap, termintypMap, locale } = useCommonState();
    const studioTatortId = useSelector((state) => selectStudioTatOrtID(state));
    const holidays = useSelector((state) => selectTimelineHolidays(state));
    const artistData = useSelector((state) => selectTimelineArtistDataMap(state));
    const selectedArtistID = useSelector((state) => selectSelectedCalendarArtistID(state))
    const loggedInArtistId = useSelector((state) => selectLoggedInArtistID(state))
    const dispatch = useDispatch();
    useEffect(() => {
        if (studioTatortId) {
            setTatortId(studioTatortId);
        }
    }, [studioTatortId]);

    useEffect(() => {
        if (tatortId != "" && startOfWeek != null) {
            dispatch(
                loadTimelineAction({
                    tatortId,
                    datVon: startOfWeek.toDateString(),
                    datBis: new PlainDate(startOfWeek).toDayOfWeek(SUNDAY).add(1, TIMEUNIT_DAYS).toDateString(),
                })
            );
        }
    }, [tatortId, startOfWeek, dispatch]);
    const targetPeriod = useMemo(
        () =>
            startOfWeek != null && {
                start: new PlainDate(startOfWeek),
                end: new PlainDate(startOfWeek).toDayOfWeek(SUNDAY).add(1, TIMEUNIT_DAYS),
            },
        [startOfWeek]
    );
    const timelineData = useMemo(
        () =>
            artistData != null &&
            targetPeriod != null &&
            termintypMap != null &&
            artistData.map((a) =>
                convertToTimelineData({
                    tatortMap,
                    artistMap,
                    targetPeriod,
                    timelineArtistData: a,
                    slotSizeFactor: 60,
                    termintypMap,
                    startTimeString: times[0],
                    endTimeString: times[1],
                })
            ),
        [artistData, targetPeriod, termintypMap, tatortMap, artistMap, times]
    );
    const isDark = useMemo(() => theme?.palette.mode === "dark", [theme]);
    const navigate = useNavigate();

    const handleExit = useCallback(() => {
        const period = getWeekFromDate(startOfWeek);
        if (!selectedArtistID) {
            dispatch(changeArtistAndZeitraum({ zeitraum: period, calendarArtist: loggedInArtistId }));
        } else {
            dispatch(initiateLoadCalendarAction(period));
        }
        setTimeout(() => navigate("/"), 0);
    }, [startOfWeek, selectedArtistID, dispatch, loggedInArtistId, navigate]);
    const handleClickDay = useCallback(
        (detailParams) => {
            const date = new PlainDate(startOfWeek).add(detailParams.dayIndex);
            const endOfPeriod = new PlainDate(date).add(1);
            const dayPeriod = { start: date, end: endOfPeriod };
            const bookedSlots = artistData[detailParams.dataIndex].bookedSlots
                .map((s) => addStartEnd(s))
                .filter((s) => isOverlapping(dayPeriod, s, TIMEUNIT_SECONDS, false));

            const notAvailableAtLocation = artistData[detailParams.dataIndex].notAvailableAtLocation
                .map((s) => addStartEnd(s))
                .filter((t) => isOverlapping(t, dayPeriod, TIMEUNIT_SECONDS, false));
            const isOffsite = notAvailableAtLocation.length;
            const location = isOffsite
                ? tatortMap[artistData[detailParams.dataIndex].notAvailableAtLocation[0].TatOrtID].Ort
                : tatortMap[tatortId].Ort;
            setDetailData({
                Kuerzel: artistMap[detailParams.ArtistID].Kuerzel,
                ArtistID: detailParams.ArtistID,
                date: date.toLocalDate(),
                location,
                isOffsite,
                bookedSlots,
            });
        },
        [artistData, artistMap, startOfWeek, tatortId, tatortMap]
    );
    const handleClickToday = useCallback(() => setStartOfWeek(getStartOfWeek(new PlainDate())), [])
    const handleCloseDetailDialog = useCallback(() => setDetailData(null), []);
    const handleChangeTimes = useCallback((e) => setTimes(e), []);
    const handleClickArtist = useCallback(
        (artist) => {
            const period = getWeekFromDate(new PlainDate(startOfWeek).startOfDay());
            dispatch(changeArtistAndZeitraum({ zeitraum: period, calendarArtist: artist.ArtistID }));
            setTimeout(() => navigate("/"), 0);
        },
        [dispatch, navigate, startOfWeek]
    );
    return (
        <ListDialogContainer onExit={handleExit} header={t("TIMELINE.HEADER", { ns: i18_TIMELINE })}>
            <Box flex="0 0 auto" display="flex" justifyContent="space-around">
                <TimelineSelection
                    start={startOfWeek}
                    tatortMap={tatortMap}
                    onChangeStart={(e) => setStartOfWeek(e)}
                    tatortId={tatortId}
                    onChangeTatortId={(e) => setTatortId(e)}
                    onChangeTimes={handleChangeTimes}
                    onClickToday={handleClickToday}
                />
            </Box>
            <Box flex="1" minHeight="0" className={isDark ? "darkScrollbars" : "lightScrollbars"}>
                <Box overflow="auto" height="100%" padding="2em 0">
                    <Timeline
                        timelineData={timelineData}
                        holidays={holidays}
                        locale={locale}
                        start={startOfWeek}
                        isDark={isDark}
                        onClickDay={handleClickDay}
                        onClickArtist={handleClickArtist}
                    />
                </Box>
            </Box>
            <TimelineDetailsDialog
                t={t}
                locale={locale}
                onClose={handleCloseDetailDialog}
                open={isDetailDialogOpen}
                detailData={detailData}
            />
        </ListDialogContainer>
    );
};

export default TimelineContainer;
