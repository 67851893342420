import {put, select, takeLatest} from "redux-saga/effects";
import {fork} from "redux-saga-test-plan/matchers";
import {SAVE_FINALIZER_TERMIN} from "./finalizerAction";
import {pushHistory} from "../router/routerActions";
import {emitNotificationAction} from "../notification/notificationAction";
import {
    constructTerminProjektInfoText,
    createErrorNotification,
    createInfoNotification
} from "../../models/notification";
import i18next from "i18next";
import {getArtistToken} from "../auth/token.saga";
import {selectArtistMap, selectStudioTimezone} from "../common/commonSelector";
import {selectLocale} from "../auth/authSelector";
import {writeTerminData} from "../termin/sagas/writeTerminData";
import {writeProjektData} from "../projekt/projektSaga";
import {i18_TRANSLATION} from "../../i18nReferences";

function* saveFinalizerData({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    const locale = yield select(state => selectLocale(state))
    const timezone = yield select(state => selectStudioTimezone(state))
    try {
        const {type, projekt, termin, besprechungen, backUrl} = payload.finalizerData
        const token = yield getArtistToken()
        if (token) {
            if (type === 'termin') {
                yield* writeTerminData({projekt, termin, besprechungen})
            } else {
                yield* writeProjektData(projekt, besprechungen)
                yield put(pushHistory(backUrl))
            }
            yield put(emitNotificationAction(createInfoNotification(constructTerminProjektInfoText({
                projekt,
                termin,
                besprechungen,
                artistMap,
                locale,
                timezone
            }), 'saveFinalizerData')))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.SAVE_DATA_FAILED_INFO', {ns: i18_TRANSLATION}), 'saveFinalizerData')))
        console.log(err)
    }
}

function* watchFinalizerSave() {
    yield takeLatest(SAVE_FINALIZER_TERMIN, saveFinalizerData)
}

const finalizerSaga = [fork(watchFinalizerSave)]

export default finalizerSaga
