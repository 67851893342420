
import createReducers from './createReducers';
import {applyMiddleware, compose, createStore} from "redux";

const composeEnhancers =
  ((process.env.REACT_APP_ENV !== 'production') &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const configureStore = (sagaMiddleware) => {
    return createStore(
        createReducers(),
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );
}

export default configureStore