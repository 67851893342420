import {selectArtistMap} from "../commonSelector";
import {convertLocationDates} from "../../../models/artist";
import {plus} from "@kiss-solutions/dateutils";
import {processUpdateArtistLocation} from "./updateArtistLocation";
import {MOVE_ARTIST_LOCATION} from "../commonActions";
import {select, takeLatest} from "redux-saga/effects"

function* processMoveArtistLocation({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    if (payload.locationChanges) {
        const artistLocation = convertLocationDates(artistMap[payload.locationChanges.ArtistID]?.artLocList.find(l => l.ArtAtOrtID === payload.locationChanges.ArtAtOrtID))
        if (artistLocation) {
            if (payload.locationChanges.startDeltaDays) {
                artistLocation.DatVon = plus(artistLocation.DatVon, payload.locationChanges.startDeltaDays, 'date')
            }
            if (payload.locationChanges.endDeltaDays) {
                artistLocation.DatBis = plus(artistLocation.DatBis, payload.locationChanges.endDeltaDays, 'date')
            }
            yield* processUpdateArtistLocation({payload: {artistLocation}})
        }
    }
}

export function* watchMoveCalendarLocation() {
    yield takeLatest([MOVE_ARTIST_LOCATION], processMoveArtistLocation)
}