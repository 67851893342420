import {getArtistToken} from "../../auth/token.saga";
import {delay, put, takeLatest, select, call} from "redux-saga/effects"
import {
    clearTerminDataAction,
    IMPORT_ICAL_TERMINE,
    importICalTermineFailureAction,
    importICalTermineSuccessAction
} from "../terminActions";
import {selectIsImportingICalResetRequested} from "../terminSelectors";
import {importICalTermine} from "../../../api/terminApi";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";

function* processImportICalTermine({payload}) {
    const token = yield getArtistToken()
    const {icalTermine} = payload
    yield delay(1500)
    const resetRequested = yield select(state => selectIsImportingICalResetRequested(state))
    if (!resetRequested) {
        if (token) {
            try {
                const result = extractDataFromResponse(yield call(importICalTermine, {token, icalTermine}))
                result.success = icalTermine.length
                result.failed = 0
                yield put(importICalTermineSuccessAction(result))
            } catch (err) {
                console.log(err)
                yield put(importICalTermineFailureAction({failed: icalTermine.length, success: 0}))
            }
        }
    } else {
        yield put(clearTerminDataAction())
    }
}

export function* watchImportICalTermine() {
    yield takeLatest(IMPORT_ICAL_TERMINE, processImportICalTermine)
}


