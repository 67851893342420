import i18next from "i18next";
import { select, takeLatest } from "redux-saga/effects";
import { i18_ARTIST } from "../../../i18nReferences";
import { createErrorNotification, createInfoNotification } from "../../../models/notification";
import { selectArtistMap } from "../../common/commonSelector";
import { SET_ARTIST_GGL_SYNC_ENABLED } from "../artistActions";
import { processUpdateArtist } from "./updateArtist";

function* processSetArtistGoogleSync({payload}) {
    const artistData = payload.artistChanges
    const artistMap = yield select(state => selectArtistMap(state))
    const kuerzel = artistMap[artistData.ArtistID].Kuerzel
    const key = artistData.GglSyncEnabled ? 'EDIT_ARTIST.GGL_SYNC_ENABLED' : 'EDIT_ARTIST.GGL_SYNC_DISABLED'
    const successNotification = createInfoNotification(i18next.t(key, {
        ns: i18_ARTIST,
        kuerzel,
    }), 'changeArtistGoogleSync')
    const failureNotification = createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_FAILED_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'changeArtistGoogleSync')
    yield processUpdateArtist( payload.artistChanges, successNotification, failureNotification)
}

export function* watchSetArtistGglSync() {
    yield takeLatest([SET_ARTIST_GGL_SYNC_ENABLED], processSetArtistGoogleSync)
}