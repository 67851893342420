import {select, takeLatest} from "redux-saga/effects"
import {SET_ARTIST_ADMIN} from "../artistActions";
import {processUpdateArtist} from "./updateArtist";
import {selectArtistMap} from "../../common/commonSelector";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_ARTIST} from "../../../i18nReferences";

function* processSetArtistAdmin({payload}) {
    const artistData = payload.artistChanges
    const artistMap = yield select(state => selectArtistMap(state))
    const kuerzel = artistMap[artistData.ArtistID].Kuerzel
    const key = artistData.isAdmin ? 'EDIT_ARTIST.GRANTED_ARTISTS_ADMIN_RIGHT_SUCCESS_INFO' : 'EDIT_ARTIST.REVOKED_ARTISTS_ADMIN_RIGHT_SUCCESS_INFO'
    const successNotification = createInfoNotification(i18next.t(key, {
        ns: i18_ARTIST,
        kuerzel,
    }), 'changeAdminRights')
    const failureNotification = createErrorNotification(i18next.t('EDIT_ARTIST.SAVE_BASE_DATA_FAILED_INFO', {
        ns: i18_ARTIST,
        name: kuerzel
    }), 'changeAdminRights')
    yield processUpdateArtist( payload.artistChanges, successNotification, failureNotification)
}

export function* watchSetArtistAdmin() {
    yield takeLatest([SET_ARTIST_ADMIN], processSetArtistAdmin)
}