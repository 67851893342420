import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectSelectedTerminKundID, selectTermin} from "../terminSelectors";
import {selectStudioTimezone} from "../../common/commonSelector";
import {
    LOAD_TERMIN_DATA,
    loadHolidaysForTerminAction, loadImagesOfEditedTerminProjektAction,
    loadTerminFailureAction,
    loadTerminNoChangeAction,
    loadTerminSuccessAction,
    removeProjektFromEditStateAction,
    setEditStateTerminChangesAction
} from "../terminActions";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getTermin} from "../../../api/terminApi";
import {getKundenProjekte} from "../../../api/kundenApi";
import {evaluateProjektAndLoadingImagesIfNeeded} from "./evaluateProjektAndLoadingImagesIfNeeded";
import {convertEinnahmenForEdit} from "../../../models/einnahme";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {createHolidayParams} from "../../../models/termin";
import {pushHistory} from "../../router/routerActions";

export function* loadTerminData({payload}) {
    try {
        const terminId = payload.terminParms.terminId
        const kundId = payload.terminParms.kundId

        const termin = yield select(state => selectTermin(state))
        const timezone = yield select(state => selectStudioTimezone(state))
        const selectedKundID = yield select(state => selectSelectedTerminKundID(state))
        if (termin != null && (termin.TerminID === terminId && (!kundId || selectedKundID === kundId))) {
            yield put(loadTerminNoChangeAction())
            return
        }
        const token = yield getArtistToken()
        if (token) {
            const terminData = extractDataFromResponse(yield call(getTermin, {token, terminId, kundId}))
            const needProjektBeEvaluated = (kundId != null && selectedKundID !== kundId)
            if (needProjektBeEvaluated) {
                yield put(removeProjektFromEditStateAction())
                const selectableProjekte = extractDataFromResponse(yield call(getKundenProjekte, {token, kundId}))
                const projekt = yield* evaluateProjektAndLoadingImagesIfNeeded(selectableProjekte, terminData.termin.ArtistID, kundId)
                terminData.projektBase = terminData.projekt
                terminData.projekt = projekt
                terminData.selectableProjekte = selectableProjekte
                yield put(setEditStateTerminChangesAction({KundID: kundId}))
            } else if (terminData.projekt != null) {
                yield put(loadImagesOfEditedTerminProjektAction(terminData.projekt.ProjektID))
            }
            terminData.einnahmen = terminData.termin.einnahmen?.length ? convertEinnahmenForEdit(terminData.termin.einnahmen) : []
            terminData.teilnehmer = terminData.termin.teilnehmer?.length ? terminData.termin.teilnehmer : []
            yield put(loadTerminSuccessAction(terminData))
            if (terminData.errors?.find(e => e.projekt?.type === 'imageList')) {
                yield put(emitNotificationAction(createErrorNotification(i18next.t('LOAD_IMAGES_FAILED_INFO'), 'loadCalendarToasterData-images')))
            }
            yield put(loadHolidaysForTerminAction(createHolidayParams(terminData.termin, timezone)))
        }
    } catch (err) {
        yield put(loadTerminFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR.LOAD_DATA_FAILED_INFO'), 'loadTerminData')))
        yield put(pushHistory('/'))
        console.log(err)
    }
}

export function* watchLoadTerminData() {
    yield takeLatest(LOAD_TERMIN_DATA, loadTerminData)
}
