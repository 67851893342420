import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {getKundenListData} from "../../../../api/kundenApi";
import {LOAD_KUNDEN_LIST_DATA, loadKundenListDataFailureAction, loadKundenListDataSuccessAction} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import {call, put, takeLatest} from "redux-saga/effects";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

export function* processLoadKundenListData({payload}) {
    try {
        const token = yield getArtistToken()
        const kundenListParameter = payload.kundenListParameter
        if (token) {
            const kunden = extractDataFromResponse(yield call(getKundenListData, {token, kundenListParameter}))
            yield put(loadKundenListDataSuccessAction(kunden))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('LIST.LOAD_CUSTOMER_LIST_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadListData')))
        yield put(loadKundenListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadKundenListData() {
    yield takeLatest(LOAD_KUNDEN_LIST_DATA, processLoadKundenListData)
}