import {SAVE_KUNDE_AND_RELOAD} from "../kundeAction";
import {getArtistToken} from "../../auth/token.saga";
import {updateKunde} from "../../../api/kundenApi";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification, createInfoNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {loadKundeData} from "./loadKundeDataSaga";
import {call, put, takeLatest} from "redux-saga/effects"

function* saveAndReloadKunde({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const kunde = payload.saveKundeData
            yield call(updateKunde, {token, kunde})
            yield put(emitNotificationAction(createInfoNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_SUCCESS_INFO', {
                ns: i18_KUNDE,
                name: kunde.Name
            }), 'saveAndReloadKunde')))
            if (kunde.KundID) {
                yield* loadKundeData({payload: {kundId: kunde.KundID}})
            }
        }

    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.SAVE_DATA_FAILED_INFO', {
            ns: i18_KUNDE,
            name: payload.saveKundeData.kunde.Name
        }), 'saveAndReloadKunde')))
        console.log(err)
    }
}

export function* watchSaveAndReloadKunde() {
    yield takeLatest(SAVE_KUNDE_AND_RELOAD, saveAndReloadKunde)
}