import {call, put, takeLatest} from "redux-saga/effects"
import {reloadCommonStudioAction, SAVE_STUDIO_LOCATION} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {emitChangedNotification} from "../../notification/notificationSaga";
import {getStudioLocationTranslation} from "../../../components/settings/helper";
import i18next from "i18next";
import {insertStudioLocation, updateStudioLocation} from "../../../api/studioApi";
import { INFO_SAVED_SUCCESSFULLY, SAVING_FAILED } from "../../../models/notification";

function* processSaveStudioLocation({payload}) {
    const {location} = payload
    const {Ort: data} = location
    const category = getStudioLocationTranslation(i18next.t, 'HEADER')
    try {
        const token = yield getArtistToken()
        if (token) {
            if (location?.TatOrtID > 0) {
                yield call(updateStudioLocation, {token, location})
            } else {
                yield call(insertStudioLocation, {token, location})
            }
            yield* emitChangedNotification({
                type: INFO_SAVED_SUCCESSFULLY,
                data,
                category
            })
            yield put(reloadCommonStudioAction())
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

export function* watchSaveStudioLocation() {
    yield takeLatest([SAVE_STUDIO_LOCATION], processSaveStudioLocation)
}
