import React, {useCallback, useMemo} from 'react';

const UseDialogHandler = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), [])
    const handleClose = useCallback(() => setAnchorEl(null) ,[])
    const closeAndExecute = useCallback((handler) => {
        handleClose()
        handler()
    }, [handleClose])
   return {anchorEl, setAnchorEl, open, handleClick, handleClose, closeAndExecute}
};

export default UseDialogHandler;