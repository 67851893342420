import React from "react";
import {Grid, Paper} from "@mui/material";

const StandardContainer = ({children}) => {
    return (
        <React.Fragment>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                    <Paper style={{width: '100%', minHeight: '100vh', opacity: '0.95'}}>
                        {children}
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default StandardContainer