import {LOAD_MANAGE_PROJEKTE, LOAD_MANAGE_PROJEKTE_SUCCESS} from "./manageProjekteAction";
import {asyncLoadingActionSuccessPaged, initiateAsyncLoadingWithParamsAction} from "../../utils/reducerUtils";

const INITIAL = {}

export default function manageProjekteReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_MANAGE_PROJEKTE:
            return initiateAsyncLoadingWithParamsAction(state, 'page', {...action.payload.params})

        case LOAD_MANAGE_PROJEKTE_SUCCESS:
            return asyncLoadingActionSuccessPaged(state, action, 'page')
        default:
            return state;
    }
}