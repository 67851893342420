import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const TimelineTatortSelection = ({selectedTatortId, onChange, locationSelection}) => {
    return (
        <FormControl variant="standard" sx={{minWidth: '10em'}}>
            <InputLabel id="tatort-filter-label">Location</InputLabel>
            <Select
                value={selectedTatortId}
                onChange={e => onChange(e.target.value)}
                labelId="tatort-filter-label"
            >
                {locationSelection.map(tatort => (
                    <MenuItem key={tatort.id}
                              value={tatort.id}>{tatort.text}</MenuItem>))}
            </Select>
        </FormControl>
    )
}

export default TimelineTatortSelection;
