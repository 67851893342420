import {call, fork, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../auth/token.saga";
import {getPagedProjekte} from "../../../api/projektApi";
import {LOAD_MANAGE_PROJEKTE, loadMangedProjekteActionSuccess} from "./manageProjekteAction";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";

function* processLoadManageProjekte({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const params = payload.params
            const page = extractDataFromResponse(yield call(getPagedProjekte, {token, ...params}))
            yield put(loadMangedProjekteActionSuccess(page))
        }
    } catch (err) {
        console.log(err)
    }
}

function* watchLoadMangeProjekte() {
    yield takeLatest(LOAD_MANAGE_PROJEKTE, processLoadManageProjekte)
}

const manageProjekteSaga = [fork(watchLoadMangeProjekte)]

export default manageProjekteSaga