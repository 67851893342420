import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_COMMON = 'LOAD_COMMON'
export const LOAD_COMMON_SUCCESS = 'LOAD_COMMON_SUCCESS'
export const LOAD_COMMON_FAILURE = 'LOAD_COMMON_FAILURE'
export const LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS = 'LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS'

export const loadCommonFailureAction = createTypeAction(LOAD_COMMON_FAILURE)
export const loadCommonSuccessAction = createSimpleAction(LOAD_COMMON_SUCCESS, 'common')
export const loadCommonAction = createTypeAction(LOAD_COMMON)
export const loadLoggedArtistSettingsSuccessAction = createSimpleAction(LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS, 'artistSettings')

export const DOWNLOAD_COMMON_FILE = 'DOWNLOAD_COMMON_FILE'
export const downloadCommonFile = createSimpleAction(DOWNLOAD_COMMON_FILE, 'fileId')

export const DOWNLOAD_COMMON_FILE_SUCCESS = 'DOWNLOAD_COMMON_FILE_SUCCESS'
export const downloadCommonFileSuccess = createSimpleAction(DOWNLOAD_COMMON_FILE_SUCCESS, 'pdf')

export const CLEAR_DOWNLOAD_COMMON_FILE = 'CLEAR_DOWNLOAD_COMMON_FILE'
export const cleardownloadCommonFile = createTypeAction(CLEAR_DOWNLOAD_COMMON_FILE)

export const DOWNLOAD_COMMON_FILE_NOT_FOUND = 'DOWNLOAD_COMMON_FILE_NOT_FOUND'
export const downloadCommonFileNotFound = createTypeAction(DOWNLOAD_COMMON_FILE_NOT_FOUND)

export const DOWNLOAD_FILLED_TERMINLIST_VORLAGE = 'DOWNLOAD_FILLED_TERMINLIST_VORLAGE'
export const downloadFilledTerminlistVorlageAction = createSimpleAction(DOWNLOAD_FILLED_TERMINLIST_VORLAGE, 'vorlagenConfig')

export const DOWNLOAD_FILLED_TERMINLIST_VORLAGE_SUCCESS = 'DOWNLOAD_FILLED_TERMINLIST_VORLAGE_SUCCESS'
export const downloadFilledTerminlistVorlageSuccessAction = createSimpleAction(DOWNLOAD_FILLED_TERMINLIST_VORLAGE_SUCCESS, 'pdf')

export const DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE = 'DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE'
export const downloadFilledKundenformularorlageAction = createSimpleAction(DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE, 'vorlagenConfig')

export const DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE_SUCCESS = 'DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE_SUCCESS'
export const downloadFilledKundenformularorlageSuccessAction = createSimpleAction(DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE_SUCCESS, 'pdf')

export const DOWNLOAD_VOUCHER = 'DOWNLOAD_VOUCHER'
export const downloadVoucherAction = createSimpleAction(DOWNLOAD_VOUCHER, 'vorlagenConfig')

export const DOWNLOAD_VOUCHER_SUCCESS = 'DOWNLOAD_VOUCHER_SUCCESS'
export const downloadVoucherSuccessAction = createSimpleAction(DOWNLOAD_VOUCHER_SUCCESS, 'pdf')

export const CLEAR_DOWNLOAD_VORLAGE = 'CLEAR_DOWNLOAD_VORLAGE'
export const clearDownloadVorlageAction = createTypeAction(CLEAR_DOWNLOAD_VORLAGE)

export const INITIATE_TERMINLIST_WORKFLOW = 'INITIATE_TERMINLIST_WORKFLOW'
export const initiateTerminlistWorkflowAction = createSimpleAction(INITIATE_TERMINLIST_WORKFLOW, 'workflowConfig')

export const INITIATE_KUNDENFORMULAR_WORKFLOW = 'INITIATE_KUNDENFORMULAR_WORKFLOW'
export const initiateKundenformularWorkflowAction = createSimpleAction(INITIATE_KUNDENFORMULAR_WORKFLOW, 'workflowConfig')

export const DISABLE_ARTIST_INFO = 'DISABLE_ARTIST_INFO'
export const disableArtistInfoAction = createSimpleAction(DISABLE_ARTIST_INFO, 'infoType')

export const INSERT_ARTIST_LOCATION = 'INSERT_ARTIST_LOCATION'
export const insertArtistLocationAction = createSimpleAction(INSERT_ARTIST_LOCATION, 'artistLocation')

export const DELETE_ARTIST_LOCATION = 'DELETE_ARTIST_LOCATION'
export const deleteArtistLocationAction = createSimpleAction(DELETE_ARTIST_LOCATION, 'location')

export const UPDATE_ARTIST_LOCATION = 'UPDATE_ARTIST_LOCATION'
export const updateArtistLocationAction = createSimpleAction(UPDATE_ARTIST_LOCATION, 'artistLocation')

export const MOVE_ARTIST_LOCATION = 'MOVE_ARTIST_LOCATION'
export const moveArtistLocationAction = createSimpleAction(MOVE_ARTIST_LOCATION, 'locationChanges')

export const RELOAD_COMMON_ARTISTS = 'RELOAD_COMMON_ARTISTS'
export const reloadCommonArtistsAction = createTypeAction(RELOAD_COMMON_ARTISTS)

export const RELOAD_COMMON_ARTISTS_SUCCESS = 'RELOAD_COMMON_ARTISTS_SUCCESS'
export const reloadCommonArtistsSuccessAction = createSimpleAction(RELOAD_COMMON_ARTISTS_SUCCESS, 'artistMap')

export const RELOAD_COMMON_ARTISTS_FAILED = 'RELOAD_COMMON_ARTISTS_FAILED'
export const reloadCommonArtistsFailedAction = createTypeAction(RELOAD_COMMON_ARTISTS_FAILED)

export const RELOAD_COMMON_MSG_VORLAGEN = 'RELOAD_COMMON_MSG_VORLAGEN'
export const reloadCommonMsgVorlagenAction = createTypeAction(RELOAD_COMMON_MSG_VORLAGEN)

export const RELOAD_COMMON_MSG_VORLAGEN_SUCCESS = 'RELOAD_COMMON_MSG_VORLAGEN_SUCCESS'
export const reloadCommonMsgVorlagenSuccessAction = createSimpleAction(RELOAD_COMMON_MSG_VORLAGEN_SUCCESS, 'msgVorlagenMap')

export const RELOAD_COMMON_MSG_VORLAGEN_FAILED = 'RELOAD_COMMON_MSG_VORLAGEN_FAILED'
export const reloadCommonMsgVorlagenFailedAction = createTypeAction(RELOAD_COMMON_MSG_VORLAGEN_FAILED)

export const RELOAD_COMMON_PDF_VORLAGEN = 'RELOAD_COMMON_PDF_VORLAGEN'
export const reloadCommonPdfVorlagenAction = createTypeAction(RELOAD_COMMON_PDF_VORLAGEN)

export const RELOAD_COMMON_PDF_VORLAGEN_SUCCESS = 'RELOAD_COMMON_PDF_VORLAGEN_SUCCESS'
export const reloadCommonPdfVorlagenSuccessAction = createSimpleAction(RELOAD_COMMON_PDF_VORLAGEN_SUCCESS, 'pdfVorlagenMap')

export const RELOAD_COMMON_PDF_VORLAGEN_FAILED = 'RELOAD_COMMON_PDF_VORLAGEN_FAILED'
export const reloadCommonPdfVorlagenFailedAction = createTypeAction(RELOAD_COMMON_PDF_VORLAGEN_FAILED)

export const RELOAD_COMMON_STUDIO = 'RELOAD_COMMON_STUDIO'
export const reloadCommonStudioAction = createTypeAction(RELOAD_COMMON_STUDIO)
export const RELOAD_COMMON_STUDIO_SUCCESS = 'RELOAD_COMMON_STUDIO_SUCCESS'
export const reloadCommonStudioSuccessAction = createSimpleAction(RELOAD_COMMON_STUDIO_SUCCESS, 'studio')
export const RELOAD_COMMON_STUDIO_FAILURE = 'RELOAD_COMMON_STUDIO_FAILURE'
export const reloadCommonStudioFailureAction = createTypeAction(RELOAD_COMMON_STUDIO_FAILURE)

export const UPDATE_STUDIO = 'UPDATE_STUDIO'
export const updateStudioAction = createSimpleAction(UPDATE_STUDIO, 'studio')

export const STUDIO_CHANGED = "STUDIO_CHANGED"
export const studioChangedAction = createTypeAction(STUDIO_CHANGED)
export const CLEAR_STUDIO_CHANGED = 'STUDIO_CLEAR_CHANGED'
export const clearStudioChangedAction = createTypeAction(CLEAR_STUDIO_CHANGED)

export const DOWNLOAD_TERMS_OF_USE = 'DOWNLOAD_TERMS_OF_USE'
export const downloadTermsOfUseAction = createTypeAction(DOWNLOAD_TERMS_OF_USE)

export const DOWNLOAD_TERMS_OF_USE_SUCCESS_ACTION = 'DOWNLOAD_TERMS_OF_USE_SUCCESS_ACTION'
export const downloadTermsOfUseSuccessAction = createSimpleAction(DOWNLOAD_TERMS_OF_USE_SUCCESS_ACTION, 'pdf')

export const DOWNLOAD_SUBSCRIPTION_CONTRACT = 'DOWNLOAD_SUBSCRIPTION_CONTRACT'
export const downloadSubscriptionContractAction = createTypeAction(DOWNLOAD_SUBSCRIPTION_CONTRACT)

export const DOWNLOAD_SUBSCRIPTION_CONTRACT_SUCCESS = 'DOWNLOAD_SUBSCRIPTION_CONTRACT_SUCCESS'
export const downloadSubscriptionContractSuccessAction = createSimpleAction(DOWNLOAD_SUBSCRIPTION_CONTRACT_SUCCESS, 'pdf')

export const DELETE_STUDIO_LOCATION = 'DELETE_STUDIO_LOCATION'
export const deleteStudioLocation = createSimpleAction(DELETE_STUDIO_LOCATION, 'tatortId')

export const SAVE_STUDIO_LOCATION = 'SAVE_STUDIO_LOCATION'
export const saveStudioLocationAction = createSimpleAction(SAVE_STUDIO_LOCATION, 'location')

export const CREATE_SUPPORT_TICKET = 'CREATE_SUPPORT_TICKET'
export const createSupportTicketAction = createSimpleAction(CREATE_SUPPORT_TICKET, 'ticket')

export const CREATE_SUPPORT_TICKET_SUCCESS = 'CREATE_SUPPORT_TICKET_SUCCESS'
export const createSupportTicketSuccessAction = createTypeAction(CREATE_SUPPORT_TICKET_SUCCESS)

export const CREATE_SUPPORT_TICKET_FAILURE = 'CREATE_SUPPORT_TICKET_FAILURE'
export const createSupportTicketFailureAction = createTypeAction(CREATE_SUPPORT_TICKET_FAILURE)

export const CLEAR_SUPPORT_TICKET = 'CLEAR_SUPPORT_TICKET'
export const clearSupportTicketAction = createTypeAction(CLEAR_SUPPORT_TICKET)

