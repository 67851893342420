import {SEARCH_KUNDEN_DOUBLETTEN, searchKundenDoublettenSuccess} from "../kundeAction";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getKundenDoubletten} from "../../../api/kundenApi";
import {enrichKundeWithJoinedName} from "../../../models/kunde";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_KUNDE} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* searchKundenDoubletten({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const doublettenSearchOptions = payload.doublettenSearchOptions
            const kundenDoubletten = extractDataFromResponse(yield call(getKundenDoubletten, {token, ...doublettenSearchOptions}))
            const enrichedDoubletten = kundenDoubletten.map(kunde => enrichKundeWithJoinedName(kunde))
            yield put(searchKundenDoublettenSuccess(enrichedDoubletten))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('EDIT_KUNDE.LOAD_DATA_FAILED_INFO', {ns: i18_KUNDE}), 'searchKundenDoubletten')))
        console.log(err)
    }
}

export function* watchSearchKundenDoubletten() {
    yield takeLatest(SEARCH_KUNDEN_DOUBLETTEN, searchKundenDoubletten)
}