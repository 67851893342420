import {DOWNLOAD_TERMS_OF_USE, downloadTermsOfUseSuccessAction} from "../commonActions";
import {getTermsOfUse} from "../../../api/studioApi";
import {PLAIN_PDF} from "../../../models/downloads";
import {call, put, takeLatest} from "redux-saga/effects"

function* processDownloadTermsOfUse() {
    try {
        const response = yield call(getTermsOfUse)
        const pdf = {blob: response.data, type: PLAIN_PDF, filename: `Nutzungsvereinbarung.pdf`}
        yield put(downloadTermsOfUseSuccessAction(pdf))
    } catch (err) {
        console.log(err)
    }
}

export function* watchDownloadTermsOfUse() {
    yield takeLatest(DOWNLOAD_TERMS_OF_USE, processDownloadTermsOfUse)
}