import {call, takeLatest, put, select} from "redux-saga/effects"
import {DELETE_STUDIO_LOCATION, reloadCommonStudioAction} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {getStudioLocationTranslation} from "../../../components/settings/helper";
import i18next from "i18next";
import {deleteLocation} from "../../../api/studioApi";
import {emitChangedNotification} from "../../notification/notificationSaga";
import {selectStudioTatortMap} from "../commonSelector";
import { DELETION_FAILED, INFO_DELETED_SUCCESSFULLY } from "../../../models/notification";

function* processDeleteStudioLocation({payload}) {
    const {tatortId} = payload
    const tatortMap = yield select(state => selectStudioTatortMap(state))
    const location = tatortMap[tatortId]
    const category = getStudioLocationTranslation(i18next.t, 'HEADER')
    const {Ort: data, TatOrtID} = location
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(deleteLocation, {token, tatortId: TatOrtID})
            yield* emitChangedNotification({
                type: INFO_DELETED_SUCCESSFULLY,
                data,
                category
            })
            yield put(reloadCommonStudioAction())

        }
    } catch (err) {
        yield* emitChangedNotification({
            type: DELETION_FAILED,
            data,
            category
        })
        console.log(err)
    }
}

export function* watchDeleteLocation() {
    yield takeLatest([DELETE_STUDIO_LOCATION], processDeleteStudioLocation)
}