import { selectCalendarEventZeitraum, selectTermine } from "../calendarSelector";
import { getArtistToken } from "../../auth/token.saga";
import { updateTermin } from "../../../api/artistApi";
import { MOVE_CALENDAR_EVENT, refreshLoadCalendarAction } from "../calendarActions";
import { emitNotificationAction } from "../../notification/notificationAction";
import { createErrorNotification, createInfoNotification } from "../../../models/notification";
import i18next from "i18next";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectArtistMap } from "../../common/commonSelector";

export const createTerminNotificationInfoText = (termin, artistMap) =>
    `${termin.Nachname ? "Kunde: " + termin.Nachname + " / " : ""}Artist: ${
        artistMap[termin.ArtistID].Kuerzel
    } / ${termin.Start.toLocaleString()}`;

function* processMoveCalendarEvent({ payload }) {
    const notificationCategory = i18next.t("CALENDAR.MOVE_TERMIN_CATEGORY");
    try {
        const termine = yield select((state) => selectTermine(state));
        const artistMap = yield select((state) => selectArtistMap(state));
        const termin = termine.find((t) => t.TerminID === payload.terminChanges.TerminID && !t.TrmAttID);
        const ArtistID = payload.terminChanges.ArtistID || termin.ArtistID;
        if (termin) {
            const zeitraum = yield select((state) => selectCalendarEventZeitraum(state));
            const token = yield* getArtistToken();
            if (token) {
                const newTermin = {
                    TerminID: termin.TerminID,
                    ArtistID,
                };
                if (payload.terminChanges.startDiffMs) {
                    newTermin.Start = new Date(
                        new Date(termin.Start).getTime() + payload.terminChanges.startDiffMs
                    ).toISOString();
                }
                if (payload.terminChanges.datBisDiffMs) {
                    newTermin.DatBis = new Date(
                        new Date(termin.DatBis).getTime() + payload.terminChanges.datBisDiffMs
                    ).toISOString();
                }
                yield call(updateTermin, { token, artistId: termin.ArtistID, termin: newTermin });
                yield put(refreshLoadCalendarAction(zeitraum));
                const originTerminDetails = createTerminNotificationInfoText(termin, artistMap);
                const newTerminDetails = createTerminNotificationInfoText({ ...termin, ...newTermin }, artistMap);
                yield put(
                    emitNotificationAction(
                        createInfoNotification(
                            i18next.t("CALENDAR.MOVE_TERMIN_SUCCESS_INFO", {
                                details: originTerminDetails,
                                newDetails: newTerminDetails,
                            }),
                            notificationCategory
                        )
                    )
                );
            }
        }
    } catch (err) {
        if (err.response && err.response.status === 403) {
            yield put(
                emitNotificationAction(
                    createErrorNotification(i18next.t("AUTHORIZATION_ERROR_MESSAGE"), notificationCategory)
                )
            );
        } else {
            yield put(
                emitNotificationAction(
                    createErrorNotification(i18next.t("CALENDAR.SAVE_DATA_FAILED_INFO"), notificationCategory)
                )
            );
        }
        const zeitraum = yield select((state) => selectCalendarEventZeitraum(state));
        yield put(refreshLoadCalendarAction(zeitraum));
        console.log(err);
    }
}

export function* watchMoveCalendarEvent() {
    yield takeLatest([MOVE_CALENDAR_EVENT], processMoveCalendarEvent);
}
