import {CLEAR_TIMELINE, LOAD_TIMELINE, LOAD_TIMELINE_FAILURE, LOAD_TIMELINE_SUCCESS} from "./timelineActions";
import {
    asyncLoadingActionFailure,
    asyncLoadingActionSuccess,
    initiateAsyncLoadingWithParamsAction
} from "../utils/reducerUtils";

const INITIAL = {}
export default function timelineReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_TIMELINE:
            return initiateAsyncLoadingWithParamsAction(state, 'data', {...action.payload.params})
        case LOAD_TIMELINE_SUCCESS:
            return asyncLoadingActionSuccess(state, action,'data')
        case LOAD_TIMELINE_FAILURE:
            return asyncLoadingActionFailure(state, 'data')
        case CLEAR_TIMELINE:
            return INITIAL
        default:
            return state
    }
}
