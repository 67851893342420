import {put, takeLatest, call} from "redux-saga/effects";
import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {getArtistHolidays} from "../../../api/artistApi";
import {LOAD_HOLIDAYS_FOR_TERMIN, loadHolidaysForTerminSuccessAction} from "../terminActions";

export function* loadHolidays({payload}) {
    try {
        const {artistId, start, end} = payload.holidayParameters
        const token = yield* getArtistToken()
        if (token) {
            const holidays = extractDataFromResponse(yield call(getArtistHolidays, {
                token,
                artistIdList: [artistId],
                start,
                end
            }))
            yield put(loadHolidaysForTerminSuccessAction(holidays))
        }
    } catch (err) {
        console.log(err)
    }
}

export function* watchLoadHolidays() {
    yield takeLatest(LOAD_HOLIDAYS_FOR_TERMIN, loadHolidays)
}