import {DELETE_KUNDEN_PROJEKT} from "../kundeAction";
import {deleteProjektSaga} from "../../projekt/projektSaga";
import {selectKunde} from "../kundeSelector";
import {loadKundeData} from "./loadKundeDataSaga";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_EDITPROJEKT} from "../../../i18nReferences";
import {deleteProjektFailedAction} from "../../projekt/projektAction";
import {put, select, takeLatest} from "redux-saga/effects"

function* processDeleteProjekt({payload}) {
    try {
        yield* deleteProjektSaga({payload})
        let kunde = yield select(state => selectKunde(state))
        if (kunde.KundID) {
            yield* loadKundeData({payload: {kundId: kunde.KundID}})
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT.DELETE_FAILED',
            {ns: i18_EDITPROJEKT}), 'processDeleteProjekt')))
        yield put(deleteProjektFailedAction())
        console.log(err)
    }
}

export function* watchDeleteProjekt() {
    yield takeLatest(DELETE_KUNDEN_PROJEKT, processDeleteProjekt)
}