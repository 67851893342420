import React, {useMemo} from 'react';
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {formatDate} from "../../store/utils/dateHelper";
import {i18_TIMELINE} from "../../i18nReferences";
import {Box} from "@mui/system";

const formatSlotTime = (slot, formatter) => formatter.format(slot.start.toLocalDate()) + ' - ' + formatter.format(slot.end.toLocalDate())
const SlotInfo = ({slot, locale = 'de'}) => {
    const formatter = useMemo(() => new Intl.DateTimeFormat(locale,
        {hour: 'numeric', minute: 'numeric'}), [locale])
    return (
        <Box display="flex" gap={2}>
            <Box flex="1">
                {formatSlotTime(slot, formatter)}
            </Box>
            <Box flex="1">
                {slot.title}
            </Box>
        </Box>
    )
}
const TimelineDetailsDialog = ({open, onClose, detailData, locale, t}) => {
    const formatedDate = useMemo(() => detailData && formatDate(detailData.date, locale) || '', [detailData, locale])
    const dialogTitle = useMemo(() => detailData && formatedDate && t('TIMELINE_DETAILS_DIALOG.TITLE', {
        ns: i18_TIMELINE,
        kuerzel: detailData.Kuerzel,
        date: formatedDate
    }) || '', [detailData, formatedDate, t])

    const bookedSlots = useMemo(() => detailData?.bookedSlots.sort((s1, s2) =>
        s1.start.isAfter(s2.start) ? 1 : -1), [detailData])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                {open ?<Box display="flex" flexDirection="column">
                    {detailData?.isOffsite ? <Typography variant="p">{t('TIMELINE_DETAILS_DIALOG.OFFSITE_INFO_TEXT', {
                        ns: i18_TIMELINE,
                        Ort: detailData.location
                    })}</Typography> : null}
                    {!bookedSlots?.length ? <Typography
                        variant="p">{t('TIMELINE_DETAILS_DIALOG.NO_APPOINTMENTS_INFO_TEXT')}</Typography> : null}
                    {bookedSlots?.length ?
                        bookedSlots.map((s, idx) => <SlotInfo key={idx} slot={s} locale={locale}/>)
                        : null}
                </Box> : null}
            </DialogContent>
        </Dialog>
    );
};

export default TimelineDetailsDialog;
