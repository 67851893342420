import i18next from "i18next";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { insertArtistLocation } from "../../../api/artistApi";
import { i18_TRANSLATION } from "../../../i18nReferences";
import { convertArtistLocation2DB } from "../../../models/artist";
import { toDateString } from "../../../models/date";
import { createErrorNotification, createInfoNotification } from "../../../models/notification";
import { getArtistToken } from "../../auth/token.saga";
import { emitNotificationAction } from "../../notification/notificationAction";
import { INSERT_ARTIST_LOCATION, reloadCommonArtistsAction } from "../commonActions";
import { selectArtistMap } from "../commonSelector";
function* processInsertArtistLocation({payload}) {
    const artistMap = yield select(state => selectArtistMap(state))
    try {
        const token = yield getArtistToken()
        if (token) {
            yield call(insertArtistLocation, {
                token,
                artistLocation: convertArtistLocation2DB(payload.artistLocation)
            })
            const {DatVon, DatBis} = payload.artistLocation
            yield put(emitNotificationAction(createInfoNotification(i18next.t('CALENDAR_LOCATION.LOCATION_INSERTED', {
                ns: i18_TRANSLATION,
                Kuerzel: artistMap[payload.artistLocation.ArtistID].Kuerzel,
                DatVon: toDateString(DatVon),
                DatBis: toDateString(DatBis)
            }), 'processInsertArtistLocation')))
            yield put(reloadCommonArtistsAction())
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CALENDAR_LOCATION.LOCATION_FAILED', {
            ns: i18_TRANSLATION,
            Kuerzel: artistMap[payload.artistLocation.ArtistID].Kuerzel,
        }), 'processInsertArtistLocation')))
        console.log(err)
    }
}

export function* watchInsertArtistLocation() {
    yield takeLatest([INSERT_ARTIST_LOCATION], processInsertArtistLocation)
}
