import "core-js/proposals/string-replace-all";
import React from "react";
import App from "./components/App";
import { Provider } from "react-redux";
import rootSaga from "./store/sagas";
import createSagaMiddleware from "redux-saga";
import "./i18n";
import "./yupLocalization";
import configureStore from "./store/configureStore";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { EventLogProvider } from "./hooks/useEventLog";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore(sagaMiddleware);
sagaMiddleware.run(rootSaga);

const container = document.querySelector("#root");
const root = createRoot(container);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 1000,
            retry: 1,
        },
    },
});

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <EventLogProvider>
                    <App />
                </EventLogProvider>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left"/>
        </QueryClientProvider>
    </Provider>
);
