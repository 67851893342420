import {asyncLoadingActionSuccess, initiateAsyncLoadingWithParamsAction} from "../utils/reducerUtils";
import {LOAD_KUNDEN_TERMINE, LOAD_KUNDEN_TERMINE_SUCCESS} from "./kundenTermineAction";

const INITIAL = {}

export default function kundenTermineReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_KUNDEN_TERMINE:
            return initiateAsyncLoadingWithParamsAction(state, 'data', {kundId: action.payload.kundId})
        case LOAD_KUNDEN_TERMINE_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'data')
        default:
            return state
    }
}