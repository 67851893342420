import {call, delay, put, takeLatest} from "redux-saga/effects"
import {ADD_KUNDEN_BATCH, addKundenBatchSuccessAction} from "../kundeAction";
import {getArtistToken} from "../../auth/token.saga";
import {importKunden} from "../../../api/kundenApi";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";

function* processAddKundenBatch({payload}) {
    const token = yield getArtistToken()
    const {kunden} = payload
    yield delay(1500)
    if (token) {
        try {
            const result = extractDataFromResponse(yield call(importKunden, {token, kunden}))
            yield put(addKundenBatchSuccessAction({success: result.processed, doublets: result.doublets, failed: []}))
        } catch (err) {
            console.log(err)
            yield put(addKundenBatchSuccessAction({success: 0, doublets: [], failed: kunden}))
        }
    }
}

export function* watchAddKundenBatch() {
    yield takeLatest(ADD_KUNDEN_BATCH, processAddKundenBatch)
}