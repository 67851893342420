import {asyncLoadingActionSuccess, initiateAsyncLoadingWithParamsAction} from "../utils/reducerUtils";
import {
    LOAD_NACHRICHT_KUNDE,
    LOAD_NACHRICHT_KUNDE_SUCCESS, LOAD_NACHRICHT_TERMIN, LOAD_NACHRICHT_TERMIN_SUCCESS,
    SEND_NACHRICHT_ACTION, SEND_NACHRICHT_ACTION_FAILED,
    SEND_NACHRICHT_ACTION_SUCCESS
} from "./nachrichtAction";

const INITIAL = {}

export default function nachrichtReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_NACHRICHT_KUNDE: {
            return {
                ...initiateAsyncLoadingWithParamsAction(state, 'kundeData', {kundId: action.payload.kundId})
                , terminData: undefined
            }
        }

        case LOAD_NACHRICHT_KUNDE_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'kundeData')


        case LOAD_NACHRICHT_TERMIN: {
            return {
                ...initiateAsyncLoadingWithParamsAction(state, 'terminData', {kundId: action.payload.terminId})
                , kundeData: undefined
            }
        }

        case LOAD_NACHRICHT_TERMIN_SUCCESS:
            return asyncLoadingActionSuccess(state, action, 'terminData')

        case SEND_NACHRICHT_ACTION:
            return {
                ...state,
                sending: true
            }
        case SEND_NACHRICHT_ACTION_SUCCESS:
        case SEND_NACHRICHT_ACTION_FAILED:
            return {
                ...state,
                sending: false
            }
        default:
            return state;
    }
}