import {getArtistToken} from "../../../auth/token.saga";
import {call, put, takeLatest} from "redux-saga/effects"
import {getStudioSubscription} from "../../../../api/studioApi";
import {LOAD_SUBSCRIPTION, loadSubscriptionFailure, loadSubscriptionSuccess} from "../subscriptionActions";
import {emitChangedNotification} from "../../../notification/notificationSaga";
import i18next from "i18next";
import {i18_STUDIO} from "../../../../i18nReferences";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import { LOADING_FAILED } from "../../../../models/notification";

function* processLoadSubscription() {
    const category = i18next.t('STUDIO_SUBSCRIPTION_SETTINGS.HEADER', {ns: i18_STUDIO})
    try {
        const token = yield getArtistToken()
        if (token) {
            const data = extractDataFromResponse(yield call(getStudioSubscription, {token}))
            yield put(loadSubscriptionSuccess(data))
        }
    } catch (err) {
        yield put(loadSubscriptionFailure())
        yield* emitChangedNotification({
            type: LOADING_FAILED,
            data: category,
            category
        })
        console.log(err)
    }
}

export function* watchLoadSubscription() {
    yield takeLatest(LOAD_SUBSCRIPTION, processLoadSubscription)
}