import {createSelector} from "reselect";

export const identityFunction = t => t;
export const createFieldSelector = (baseSelector, field, defaultValue) => createSelector(
    baseSelector
    , state => state ? (state[field] !== undefined ? state[field] : defaultValue) : defaultValue
)
export const createBooleanFieldSelector = (baseSelector, field) => createSelector(
    baseSelector
    , state => state && !!state[field] || false
)