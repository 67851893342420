import React, {useMemo} from 'react';
import {AppBar, Container, IconButton, MenuItem, Toolbar, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from "@mui/icons-material/Logout";
import DialogWithMenuBurgerMenu from "./DialogWithMenuBurgerMenu";
import DeleteConfirmation from "../DeleteConfirmation";

const DialogWithMenuContainer = ({children, maxWidth = 'xl', onExit, appBarItems, isDesktop, showText, onDelete, deleteDisabled, logoutExit, headerText}) => {

    const toolBarItems = useMemo(() => appBarItems ? appBarItems.filter(i => isDesktop || !i.burgerMenu) : [], [appBarItems, isDesktop])
    const burgerItems = useMemo(() => appBarItems ? appBarItems.filter(i => !isDesktop && i.burgerMenu) : [], [appBarItems, isDesktop])

    const renderAppBarItem = (item, key) => {
        return (
            <MenuItem key={key} onClick={item.onClick} disabled={item.disabled}>
                {item.icon ? <IconButton color="inherit">{item.icon}</IconButton> : null}
                {item.icon && !showText ?  <Typography key={key} variant="h6" component="div" sx={{flexGrow: 1}}>
                    {item.count ? `(${item.count})` : null}
                </Typography> : null}
                {item.text && (showText || !item.icon) ? <Typography key={key} variant="h6" component="div" sx={{flexGrow: 1}}>
                    {item.text}
                </Typography> : null}
            </MenuItem>
        )
    }

    const deleteButtonMargin = useMemo(() => toolBarItems?.length || burgerItems?.length ? '2em' : 0, [toolBarItems, burgerItems])

    return (
        <Box minHeight='100vh' bgcolor="background.default" display="flex" flexDirection="column" color="text.primary">
            <AppBar>
                <Toolbar>
                    {headerText ? <Typography variant="h6" component="h1">{headerText}</Typography> : null}
                    {toolBarItems?.length ? toolBarItems.map((item, idx) => renderAppBarItem(item, idx)) : null}
                    {burgerItems?.length ? <DialogWithMenuBurgerMenu appBarItems={burgerItems} /> : null}
                    {onDelete ? <DeleteConfirmation onDelete={onDelete} disabled={deleteDisabled} sx={{marginLeft: deleteButtonMargin}}/>  : null}
                    <Box flexGrow={1}></Box>
                    {onExit ? <IconButton
                        size="large"
                        color="inherit"
                        onClick={onExit}
                    >
                        {logoutExit ? <LogoutIcon /> : <CloseIcon/>}
                    </IconButton> : null}
                </Toolbar>
            </AppBar>
            <Container maxWidth={maxWidth} sx={{flex: 1, marginTop: '70px'}}>
                {children}
            </Container>
        </Box>
    )
}

export default DialogWithMenuContainer;
