import {getArtistToken} from "../../auth/token.saga";
import {getFileById} from "../../../api/studioApi";
import {FINISHED_FILE_PDF} from "../../../models/downloads";
import {DOWNLOAD_COMMON_FILE, downloadCommonFileNotFound, downloadCommonFileSuccess} from "../commonActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, takeLatest} from "redux-saga/effects"

function* processDownloadFile({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const response = yield call(getFileById, {token, fileId: payload.fileId})
            if (response.status === 200) {
                const pdf = {fileId: payload.fileId, blob: response.data, type: FINISHED_FILE_PDF}
                yield put(downloadCommonFileSuccess(pdf))
            } else {
                yield put(emitNotificationAction(createErrorNotification(i18next.t('FILE_NOT_FOUND_INFO', {ns: i18_TRANSLATION}), 'processDownloadFile')))
                yield put(downloadCommonFileNotFound())
            }
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('DOWNLOAD_FILE_FAILED_INFO', {ns: i18_TRANSLATION}), 'processDownloadFile')))
        console.log(err)
    }
}

export function* watchDownloadFile() {
    yield takeLatest(DOWNLOAD_COMMON_FILE, processDownloadFile)
}