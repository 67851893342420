import {
    DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE,
    downloadFilledKundenformularorlageSuccessAction
} from "../commonActions";
import {getArtistToken} from "../../auth/token.saga";
import {selectPdfVorlagenMap} from "../commonSelector";
import {downloadKundenformular} from "../../../api/kundenApi";
import {FILLED_VORLAGE_PDF} from "../../../models/downloads";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {i18_TRANSLATION} from "../../../i18nReferences";
import {call, put, select, takeLatest} from "redux-saga/effects"

function* processDownloadKundenformular({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const pdfVorlagenMap = yield select(state => selectPdfVorlagenMap(state))
            const bezeichnung = pdfVorlagenMap[payload.vorlagenConfig.PDFVorlagenID].Bezeichnung.replaceAll(" ", '_')
            const response = yield call(downloadKundenformular, {
                token,
                kundId: payload.vorlagenConfig.kundId,
                pdfVorlagenId: payload.vorlagenConfig.PDFVorlagenID
            })
            const pdf = {blob: response.data, type: FILLED_VORLAGE_PDF, fileName: `${bezeichnung}.pdf`}
            yield put(downloadFilledKundenformularorlageSuccessAction(pdf))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('DOWNLOAD_FILE_FAILED_INFO', {ns: i18_TRANSLATION}), 'processDownloadFilledVorlage')))
        console.log(err)
    }
}

export function* watchDownloadKundenformular() {
    yield takeLatest(DOWNLOAD_FILLED_KUNDENFORMULAR_VORLAGE, processDownloadKundenformular)
}