import {getArtistToken} from "../../auth/token.saga";
import {extractDataFromResponse} from "../../saga-utils/httpInterceptorWrapper";
import {addTempImage} from "../../../api/studioApi";
import {ADD_TERMIN_IMAGES, addTerminImagesFailureAction, addTerminImagesSuccessAction} from "../terminActions";
import {emitNotificationAction} from "../../notification/notificationAction";
import {createErrorNotification} from "../../../models/notification";
import i18next from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";

export function* processAddTerminImage({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const keyList = []
            for (let image of payload.images) {
                const {key} = extractDataFromResponse(yield call(addTempImage, {token, image}))
                keyList.push(key)
            }
            yield put(addTerminImagesSuccessAction(keyList))
        }

    } catch (err) {
        yield put(addTerminImagesFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('PROJEKT_IMAGE_VIEW.ADDING_IMAGE_FAILED'), 'processAddTerminImage')))
        console.log(err)
    }
}

export function* watchAddTerminImage() {
    yield takeLatest(ADD_TERMIN_IMAGES, processAddTerminImage)
}