import {
    ADD_PROJEKT_IMAGES,
    ADD_PROJEKT_IMAGES_FAILURE,
    ADD_PROJEKT_IMAGES_SUCCESS,
    CLEAR_PROJEKT,
    DELETE_PROJEKT,
    DELETE_PROJEKT_FAILED,
    DELETE_PROJEKT_IMAGE,
    DELETE_PROJEKT_SUCCESS,
    LOAD_EDITED_PROJEKT_SUCCESS,
    LOAD_PROJEKT_IMAGES,
    LOAD_PROJEKT_IMAGES_SUCCESS,
    SET_PROJEKT_IS_UPDATING,
    UPDATE_PROJEKT,
    UPDATE_PROJEKT_FAILURE,
    UPDATE_PROJEKT_SUCCESS
} from "./projektAction";
import {replacePayloadFields} from "../utils/reducerUtils";

const INITIAL = {
    projektImages: [],
    insertedImages: [],
    deletedImages: []
}

const filterOnlyProjektImages = (images) => images.filter(i => i.type === 'projekt')

export default function projektReducers(state = INITIAL, action) {
    switch (action.type) {

        case LOAD_EDITED_PROJEKT_SUCCESS:
            return replacePayloadFields(state, action)

        case LOAD_PROJEKT_IMAGES:
            return {
                ...state,
                projektImages: [],
                deletedImages: [],
                insertedImages: []
            }

        case LOAD_PROJEKT_IMAGES_SUCCESS:
            return replacePayloadFields(state, action)

        case ADD_PROJEKT_IMAGES:
            return {
                ...state,
                isUploadingImages: true
            }
        case ADD_PROJEKT_IMAGES_SUCCESS:
            return {
                ...state,
                insertedImages: state.insertedImages ? state.insertedImages.concat(action.payload.keyList) : action.payload.keyList,
                isUploadingImages: false
            }
        case ADD_PROJEKT_IMAGES_FAILURE:
            return {
                ...state,
                isUploadingImages: false
            }

        case DELETE_PROJEKT_IMAGE:
            return {
                ...state,
                deletedImages: state.deletedImages.concat(filterOnlyProjektImages([action.payload.deletedImage])),
                insertedImages: state.insertedImages && state.insertedImages.filter(t => t !== action.payload.deletedImage.key)
            }

        case UPDATE_PROJEKT:
        case SET_PROJEKT_IS_UPDATING:
            return {
                ...state,
                projektUpdatePending: true
            }
        case UPDATE_PROJEKT_FAILURE:
            return {
                ...state,
                projektUpdatePending: false
            }

        case DELETE_PROJEKT:
            return {
                ...state,
                deleteProjectOngoing: true
            }

        case DELETE_PROJEKT_SUCCESS:
        case DELETE_PROJEKT_FAILED:
        case UPDATE_PROJEKT_SUCCESS:
        case CLEAR_PROJEKT:
            return INITIAL

        default:
            return state;
    }
}