import i18next from "i18next";
import { call, put, takeLatest } from "redux-saga/effects";
import { getMandantenJobLogsByJobId } from "../../../../api/mandantenJobs";
import { i18_LIST } from "../../../../i18nReferences";
import { JOBTYPE_BROADCAST_LIST } from "../../../../models/jobs";
import { createErrorNotification } from "../../../../models/notification";
import { getArtistToken } from "../../../auth/token.saga";
import { emitNotificationAction } from "../../../notification/notificationAction";
import { extractDataFromResponse } from "../../../saga-utils/httpInterceptorWrapper";
import { LOAD_JOB_DETAILS, loadJobDetailFailureAction, loadJobDetailSuccessAction } from "../../listActions";

function* processLoadJobDetails({payload}) {
    const {Type, Meta, JobID} = payload.job
    try {
        const token = yield getArtistToken()
        if (token && Meta?.length > 0 && JOBTYPE_BROADCAST_LIST.includes(Type)) {
            const jobLogs = extractDataFromResponse(yield call(getMandantenJobLogsByJobId, {token, jobId: JobID}))
            yield put(loadJobDetailSuccessAction(jobLogs))
        }
    } catch (err) {
        console.log(err)
        yield put(emitNotificationAction(createErrorNotification(i18next.t('JOBS_LIST.LOAD_JOB_DATA_FAILED_INFO'
            , {ns: i18_LIST}), 'loadListDetailsData')))
        yield put(loadJobDetailFailureAction())
    }
}

export function* watchLoadJobDetails() {
    yield takeLatest(LOAD_JOB_DETAILS, processLoadJobDetails)
}